import * as React from "react";
import { TextField } from "./";
import { Calendar } from "./icons";
import { TextFieldProps } from "@mui/material";
import { DateTimePicker as MaterialDateTimePicker, DateTimePickerProps as MaterialDateTimePickerProps } from "@mui/x-date-pickers-pro";
import { Moment } from "moment";

interface DateTimePickerProps extends Omit<MaterialDateTimePickerProps<Moment, Moment>, 'renderInput'> {
    renderInput?: (props: TextFieldProps) => React.ReactElement;
    error?: boolean;
    helperText?: string;
}

const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = React.forwardRef((props: DateTimePickerProps, ref: any) =>
    <div className="date-picker-container">
        <MaterialDateTimePicker
            ref={ref}
            renderInput={(params) => <TextField {...params} size="small" fullWidth error={props.error} helperText={props.helperText} />}
            components={{
                OpenPickerIcon: Calendar
            }}
            inputFormat="YYYY/MM/DD HH:mm"
            mask="____/__/__ __:__"
            ampm={false}
            {...props}
        />
    </div>
);

export default DateTimePicker;