export interface TracingState {
    traces: Trace[];
}

interface ParameterValueChangedEvent {
    kind: "ParameterValueChanged";
    name: string;
    oldValue?: any;
    newValue: any;
    source: TraceSource;
}

interface DatasourceRefreshedEvent {
    kind: "DatasourceRefreshed";
    name: string;
    oldValue?: any;
    newValue: any;
    source: TraceSource;
}

interface DataSourceBindingTraceSource {
    kind: "DatasourceBinding";
    datasourceName: string;
}

interface InputControlChangeTraceSource {
    kind: "InputControlChange";
}

interface ManualUpdateTraceSource {
    kind: "ManualUpdate";
}

interface InitialLoadTraceSource {
    kind: "InitialLoad";
}

interface ParameterValueChangedTraceSource {
    kind: "ParameterValueChanged";
}

export type TraceSource = DataSourceBindingTraceSource | InputControlChangeTraceSource | ManualUpdateTraceSource | InitialLoadTraceSource | ParameterValueChangedTraceSource;
export type TraceEvent = ParameterValueChangedEvent | DatasourceRefreshedEvent;
export type Trace = {
    timestamp: Date,
    event: TraceEvent
}

export const ADD_TRACE_EVENT = 'ADD_TRACE_EVENT';

interface AddTraceAction {
    type: typeof ADD_TRACE_EVENT,
    trace: Trace
}

export type TracingActionTypes = AddTraceAction;