import _ from "lodash";
import React from "react";
import { useThunkDispatch } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";

type NavFiltersProps = {
    children?: JSX.Element[],
};

function getAllParameterNames(children: JSX.Element[]): string[] {
    function* _getAllParameterNames(element: JSX.Element): Generator<string, any, string> {
        if (!element) return;

        const name = element.props?.["name"];
        if (name) {
            yield name;
        }

        let children = element.props?.["children"] ?? [];
        if (children && !_.isArray(children)) {
            children = [children];
        }

        for (let child of children) {
            yield* _getAllParameterNames(child);
        }
    }

    return _.uniq(children?.flatMap(c => Array.from(_getAllParameterNames(c))) ?? []);
}

const NavFiltersParameterName = "__nav_filters_linked_parameter_names";

function NavFilters(props: NavFiltersProps) {
    const { children } = props;
    const dispatch = useThunkDispatch();

    const allParameterNames = getAllParameterNames(children);

    React.useEffect(() => {
        dispatch(updateParameterValue(NavFiltersParameterName, allParameterNames));
    }, [allParameterNames]);

    return (
        <>
            {children}
        </>
    );
}

export { NavFilters, NavFiltersParameterName };