import { Button, ButtonProps, ButtonGroup as BaseButtonGroup, ButtonGroupProps } from "@mui/material";
import _ from "lodash";

export const PrimaryButton = (props: ButtonProps) => <Button variant="contained" color="primary" {...props} />;
export const SecondaryButton = (props: ButtonProps) => <Button variant="contained" color="secondary" {...props} />;
export const TertiaryButton = (props: ButtonProps) => <Button variant="outlined" color="primary" {...props} />;
export const LinkButton = (props: ButtonProps) => <Button variant="text" color="primary" {...props} />;
export const ButtonGroup = (props: ButtonGroupProps) => {
    const { children, ...rest } = props;

    // Workaround for the fact that JSX Parser wraps whitespace-enclosed elements in a fragment by default...
    const sanitizedChildren = _.isArray(children) ?
        children.map(c => c?.type?.toString()?.indexOf("fragment") > -1 ? c?.children?.[0] : c) :
        children?.["type"]?.toString()?.indexOf("fragment") > -1 ? children?.["children"]?.[0] : children;

    return (
        <BaseButtonGroup {...rest}>
            {sanitizedChildren}
        </BaseButtonGroup>
    );
};
