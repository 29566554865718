import * as React from "react";
import "./Loader.scss";
import loadingImage from "../../assets/loading-backdrop.png";
import spinner from "../../assets/loading-spinner.png";

interface LoaderProps {
    title?: string;
    description?: string;
}

const Loader: React.FunctionComponent<LoaderProps> = (props: LoaderProps) => {
    const { title, description } = props;

    return (
        <div className="d-flex flex-column align-items-center container-loader">
            <div className="container-images">
                <img src={loadingImage} id="loading-background" alt="" />
                <img src={spinner} id="loading-spinner" alt="" />
            </div>
            <h3 className="spaced-bottom">{title}</h3>
            {description}
        </div>
    );
};

export default Loader;