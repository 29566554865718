import React from "react";
import "./Groups.scss";
import { Card, Container, Button, NavBar, ConfirmationDialog, DataGrid, Icon, CardHeader } from "../../shared/components";
import * as coreApi from "../../shared/core-api-client";
import * as _ from "lodash";
import EditGroupDialog from "./EditGroup";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { useSettings } from "../../shared/providers/SettingsProvider";
import RequiresTags from "../../shared/components/RequiresTags";

function Groups(props) {
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [roles, setRoles] = React.useState<coreApi.RoleLookup[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showEdit, setShowEdit] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const settings = useSettings();

    React.useEffect(() => {
        setIsLoading(true);
        new coreApi.GroupsClient(settings.coreApiUrl).search(undefined, settings.domainName)
            .then(result => setData(result))
            .then(() => new coreApi.RolesClient(settings.coreApiUrl).getLookups(settings.domainName))
            .then(result => setRoles(result))
            .finally(() => setIsLoading(false));
    }, [refreshCounter]);

    const onGroupCreatedOrUpdated = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const createOrEditGroup = (model) => {
        setActiveItem(model);
        setShowEdit(true);
    }

    const deleteGroup = (model) => {
        setActiveItem(model);
        setConfirmOpen(true);
    }

    const onDeleteConfirmed = () => {
        new coreApi.GroupsClient(settings.coreApiUrl).delete(activeItem.id)
            .then(() => {
                props.showSuccess("Group deleted successfully.");
                setRefreshCounter(refreshCounter + 1);
            })
            .catch(error => {
                props.showError("Unable to delete group.  Please try again.");
            });
    }

    return (
        <>
            <NavBar
                title="Manage Groups">
            </NavBar>

            <Container className="plush groups-list" maxWidth="lg">
                <Card className="col-fill">
                    <CardHeader title="Group List" action={
                        <RequiresTags tags={[["groups::create"]]}>
                            <Button className="align-self-center" size="small" variant="contained" color="success" onClick={() => createOrEditGroup(null)}>
                                <Icon fontSize="small">add</Icon>
                                Add Group
                            </Button>
                        </RequiresTags>
                    } />
                    <DataGrid
                        disableSelectionOnClick
                        loading={isLoading}
                        columns={[
                            { headerName: 'Name', field: 'name', flex: 1 },
                            { headerName: 'Roles', field: 'roles', flex: 1, renderCell: (e) => (e.row.roles.toSorted() ?? []).map(roleId => roles.find(role => role.id === roleId)?.name).filter(r => Boolean(r)).join(", ") },
                            {
                                field: "actions", width: 150, type: "actions", getActions: (r) => [
                                    <RequiresTags tags={[["groups::update"]]}>
                                        <Button size="small" variant="contained" color="primary" onClick={(e) => { createOrEditGroup(r.row); e.preventDefault(); }} title="Edit Group"><Icon fontSize="small">edit</Icon></Button>
                                    </RequiresTags>,
                                    <RequiresTags tags={[["groups::delete"]]}>
                                        <Button size="small" variant="contained" color="error" onClick={(e) => { deleteGroup(r.row); e.preventDefault(); }} title="Delete Group"><Icon fontSize="small">delete</Icon></Button>
                                    </RequiresTags>
                                ]
                            }
                        ]}
                        rows={data}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'name', sort: 'asc' }
                                ]
                            }
                        }}
                    />
                </Card>
            </Container>

            <EditGroupDialog key={activeItem?.id} open={showEdit} setOpen={setShowEdit} roles={roles} group={activeItem} onSuccess={onGroupCreatedOrUpdated} />

            <ConfirmationDialog
                title="Delete Group?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirmed}
            >
                {`Are you sure you want to delete ${activeItem?.name}?`}
            </ConfirmationDialog>

        </>
    );
}

export default connect(
    null,
    { showSuccess, showError })(Groups);