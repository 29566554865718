import React from "react";
import "./Storyline.scss";
import Canvas from "../Canvas/Canvas";
import StorylineActions from "../actions";
import { StorylineState } from "../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { navigateTo, goToXYZ, goToID, updateParameterValue } from "../../store/storyline/actions";
import CanvasDebugger from "../../developer/CanvasDebugger/CanvasDebugger";
import { AppInsightsViewTracker, Loader, NavBar, PopoutWindow } from "../../shared/components";
import { useAuth0 } from "../../auth/AuthContext";
import { PERMISSIONS } from "../../auth/types";
import { AppError, BreadcrumbItem } from "../../store/app/types";
import { AppThemeProvider } from "../../shared/providers/AppThemeProvider";
import { ErrorContainer } from "../../shared/pages/ErrorContainer";

const storylineActions = <StorylineActions />;

interface StorylineProps {
    id: string,
    storyline: StorylineState,
    pageError?: AppError,
    navigateTo: typeof navigateTo,
    goToXYZ: typeof goToXYZ,
    goToID: typeof goToID,
    updateParameterValue: typeof updateParameterValue,
    breadcrumbStack: BreadcrumbItem[]
}

export function Storyline(props: StorylineProps) {
    const { storyline, pageError, navigateTo, updateParameterValue, breadcrumbStack } = props;
    const { id, name, loading, canvases, currentParagraph, chapterIndex, pageIndex, paragraphIndex } = storyline;
    const [devWindowIsOpen, setDevWindowIsOpen] = React.useState(false);
    const { effectiveTags, userMetadata } = useAuth0();
    const toggleDevWindow = () => {
        setDevWindowIsOpen(!devWindowIsOpen);
    }

    // Promote current storyline state and navigateTo action to global state in order to allow access via the storyline exporter...
    React.useEffect(() => {
        window["storyline"] = storyline;
        window["navigateTo"] = navigateTo;
        window["updateParameterValue"] = updateParameterValue;
    }, [storyline, navigateTo, updateParameterValue]);

    React.useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            switch (e.key) {
                case "F2":
                case "F9":
                    if (effectiveTags?.includes?.(PERMISSIONS.DEVELOPER_TOOLS_ACCESS)) {
                        toggleDevWindow();
                    }
                    else {
                        console.log("Current user does not have access to the Canvas Debug window.");
                    }
                    break;
            }

            // We only want to handle global keyboard events here - ignore those from input fields, dropdowns, sliders, etc...
            const target = e.target as Element;
            if (target?.tagName?.toLowerCase() !== "body") return;

            switch (e.key) {
                case "ArrowUp":
                    if (e.ctrlKey) {
                        navigateTo("PREVIOUS_PAGE");
                    }
                    else {
                        navigateTo("PREVIOUS_PARAGRAPH");
                    }
                    break;
                case "ArrowDown":
                    if (e.ctrlKey) {
                        navigateTo("NEXT_PAGE");
                    }
                    else {
                        navigateTo("NEXT_PARAGRAPH");
                    }
                    break;
                case "ArrowLeft":
                    navigateTo("PREVIOUS_CHAPTER");
                    break;
                case "ArrowRight":
                    navigateTo("NEXT_CHAPTER");
                    break;
            }
        }

        document.addEventListener("keyup", handleKeyUp);
        return () => document.removeEventListener("keyup", handleKeyUp);
    }, [navigateTo]);

    return (
        <>
                <NavBar title={currentParagraph?.data?.["title"]} breadcrumbStack={breadcrumbStack} actions={storylineActions}>
            </NavBar>
            <ErrorContainer error={pageError}>
                {
                    loading ?
                        <div className="home-content"><Loader title="Loading Storyline..." description="This shouldn't take long." /></div> :

                        <div className="storyline">
                            <div className="main-canvas">
                            <AppInsightsViewTracker key={`storyline-tracker-${id}`} componentType="storyline" title={name} metadata={{ storylineName: name, userMetadata }}>
                                <AppInsightsViewTracker key={`canvas-tracker-${chapterIndex}-${pageIndex}`} componentType="canvas" title={currentParagraph?.data?.["title"]} metadata={{ canvasName: canvases?.[chapterIndex]?.[pageIndex]?.[paragraphIndex]?.name, storylineName: name, userMetadata }}>
                                    <Canvas template={currentParagraph?.template} frameData={currentParagraph?.data} navigateTo={props.goToID} staticPlot={false} />
                                    </AppInsightsViewTracker>
                                </AppInsightsViewTracker>
                            </div>
                        </div>
                }
            </ErrorContainer>

            {devWindowIsOpen &&
                <>
                    <PopoutWindow onUnload={toggleDevWindow} url="" title="Canvas Debugger" features={{ width: 1440, height: 900 }}>
                        <AppThemeProvider>
                            <CanvasDebugger slide={canvases?.[chapterIndex]?.[pageIndex]?.[paragraphIndex]} frameData={currentParagraph?.data} />
                        </AppThemeProvider>
                    </PopoutWindow>
                </>
            }
        </>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline,
        breadcrumbStack: state.app.breadcrumbStack,
        pageError: state.app.errors.page,
    }),
    { navigateTo, goToXYZ, goToID, updateParameterValue: updateParameterValue as any })(Storyline);
