import React from "react";
import "./CascadingSelector.scss";
import { Autocomplete, Filter, FilterRow } from "../../../shared/components";
import { Option } from "../Autocomplete";
import clsx from "clsx";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";


type CascadingDimension = {
    label: string,
    fieldName: string,
    multiple?: boolean,
    isHidden?: boolean,
    bypassFilters?: boolean,
    excludeFromCascade?: boolean,
    [key: string]: any
};

type CascadingNode = {
    source: string,
    index: number,
    children: CascadingNode[],
};

type CascadingSelectorProps = {
    name: string,
    disabled?: boolean,
    dimensions: { [groupLabel: string]: CascadingDimension[] },
    sources: { [fieldName: string]: Option[] },
    nodes: CascadingNode[],
    className?: string,
    getFooterContent?: (fieldName: string) => JSX.Element
};

type OptionWithFilter = {
    dimension: string,
    value: string,
    label: string,
    filters: { [fieldName: string]: string }
};

function convertValueToArray<T>(value: T | T[]): T[] {
    if (_.isArray(value)) {
        return value;
    }

    return value ? [value] : [];
}

function CascadingSelector(props: CascadingSelectorProps) {
    const { name, dimensions = {}, sources = {}, nodes = [], className, getFooterContent, disabled, ...rest } = props;
    const flattenedDimensions = Object.values(dimensions ?? {}).flat().filter(d => d !== null && d !== undefined);
    const currentParameterValue = useSelector((s: RootState) => s.storyline.parameterValues?.get?.(name));
    const [values, setValues] = React.useState<{ [dimension: string]: OptionWithFilter | OptionWithFilter[] }>(currentParameterValue ?? {});
    const [open, setOpen] = React.useState(false);

    const dispatch = useThunkDispatch();

    function* getNodeOptions(node: CascadingNode, ancestors: { [fieldName: string]: string }): Generator<OptionWithFilter, any, never> {
        const sourceOption = sources?.[node.source]?.at?.(node.index);

        yield {
            dimension: node.source,
            value: sourceOption?.value,
            label: sourceOption?.label,
            filters: ancestors
        };

        for (let child of node?.children) {
            yield* getNodeOptions(child, { ...ancestors, [node.source]: sourceOption?.value });
        }
    }

    const nodeOptions = nodes.flatMap(n => Array.from(getNodeOptions(n, {})));

    React.useEffect(() => {
        if (!currentParameterValue) return;

        // Map the values from string[] to Option[]...
        const mappedValues = Object.fromEntries(
            Object.entries(currentParameterValue)
                .map(([key, values]) => [flattenedDimensions.find(d => d.fieldName === key), values])
                .filter(([dimension, _values]) => dimension != null)
                .map(([dimension, values]: [CascadingDimension, string | string[]]) => {
                    const matchingOptions = convertValueToArray(values).map(v => nodeOptions.find(no => no.dimension === dimension.fieldName && no.value === v)).filter(v => v != null);

                    return [
                        dimension.fieldName,
                        dimension.multiple === false ?
                            matchingOptions?.[0] :
                            matchingOptions
                    ];
                })
        );

        if (!_.isEqual(values, mappedValues)) {
            setValues(mappedValues);
        }

    }, [currentParameterValue]);

    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const persistChangesToParameterValue = React.useCallback((newValues: { [dimension: string]: OptionWithFilter | OptionWithFilter[] }) => {
        // Map the values from Option[] to string[]...
        const newParameterValue = Object.fromEntries(
            [
                ...Object.entries(currentParameterValue ?? {}),
                ...Object.entries(newValues).map(([key, options]) => {
                    const dimension = flattenedDimensions.find(d => d.fieldName === key);
                    const selectedValues = convertValueToArray(options).map(o => o.value);

                    return [
                        key,
                        dimension.multiple === false ?
                            selectedValues?.[0] :
                            selectedValues
                    ];
                })
            ]
        );

        dispatch(updateParameterValue(name, newParameterValue));
    }, [dispatch, dimensions, currentParameterValue]);

    const handleSelectionChange = React.useCallback((fieldName, newValues, isMultiSelect) => {
        const mappedValues = {
            ...values,
            [fieldName]: newValues
        };

        setValues(mappedValues);

        if ((!isMultiSelect || !open) && name) {
            persistChangesToParameterValue(mappedValues);
        }
    }, [values, setValues, name, open, persistChangesToParameterValue]);

    const handleClose = React.useCallback((isMultiSelect) => {
        setOpen(false);

        if (isMultiSelect && name) {
            persistChangesToParameterValue(values);
        }
    }, [values, setOpen, persistChangesToParameterValue]);

    const getAvailableOptions = (dimension: CascadingDimension) => {
        const fieldName = dimension.fieldName;

        // Given the currently selected parent items, only return child items which belong to those...
        function getChildFilters(): ((o: OptionWithFilter) => boolean)[] {
            if (dimension.bypassFilters) {
                return [];
            }

            return Object.entries(values) // Start with the currently selected values
                .filter(([dimensionName, _v]) => flattenedDimensions.find(d => d.fieldName === dimensionName)?.excludeFromCascade !== true) // Exclude the dimensions that are explicitly being excluded from cascading
                .map(([k, v]) => [k, convertValueToArray(v)]) // Convert the values to arrays
                .filter(([_k, v]) => v.length) // Exclude dimensions with no selected options
                .map(([key, selectedOptions]: [string, OptionWithFilter[]]) =>
                    o => !o.filters[key] || selectedOptions.some(so => so.value === o.filters[key])
                );
        }

        // Given the currently selected child items, only return parent items which contain those...
        function getParentFilters(): ((o: OptionWithFilter) => boolean)[] {
            if (dimension.bypassFilters) {
                return [];
            }

            const parentFilterGroups =
                _.chain(Object.entries(values)) // Start with currently selected values
                    .filter(([dimensionName, _selectedOptions]) => flattenedDimensions.find(d => d.fieldName === dimensionName)?.excludeFromCascade !== true) // Exclude the dimensions that are explicitly being excluded from cascading
                    .filter(([_key, selectedOptions]) => !_.isEmpty(selectedOptions)) // Exclude dimensions with no selected options
                    .flatMap(([key, selectedOptions]) => // Expand selection to all nodes which share the same dimension and value as the above
                        convertValueToArray(selectedOptions).flatMap(o => nodeOptions.filter(no => no.dimension === key && no.value === o.value))
                    )
                    .filter(so => !!so.filters[fieldName]) // Limit selection to descendents of this dimension
                    .groupBy(so => so.dimension) // Apply a filter per source dimension to ensure that more general options do not override the more specific ones
                    .value();

            return !_.isEmpty(parentFilterGroups) ?
                Object.values(parentFilterGroups).map(dimensionValues => o => _.uniq(dimensionValues.map(dv => dv.filters[fieldName])).some(v => o.value === v)) :
                [];
        }

        // Build up the full list of predicates...
        const predicates = [
            (o: OptionWithFilter) => o.dimension === fieldName,
            ...getParentFilters(),
            ...getChildFilters()
        ];
        // Apply a filter for each predicate to get the final available options...
        const validOptions = predicates.reduce((remainingOptions, predicate) => remainingOptions.filter(predicate), nodeOptions);
        return sources[fieldName]?.filter(s => validOptions.some(o => s.value === o.value)) ?? [];
    }

    const renderDimension = (dimension: CascadingDimension) => {
        const isMultiSelect = dimension?.multiple !== false;
        const availableOptions = getAvailableOptions(dimension);
        const value = values[dimension.fieldName] ?? (isMultiSelect ? [] : null);
        const placeholder = availableOptions?.length === 1 && _.isEmpty(value) ? availableOptions[0].label : null;
        const footerContent = getFooterContent?.(dimension.fieldName);

        return (
            <Autocomplete
                {...dimension}
                size="small"
                multiple={isMultiSelect}
                options={availableOptions}
                value={value}
                onOpen={handleOpen}
                onChange={(_e, newValues) => handleSelectionChange(dimension.fieldName, newValues, isMultiSelect)}
                onClose={() => handleClose(isMultiSelect)}
                label={dimension.label}
                placeholder={placeholder}
                InputLabelProps={{ shrink: placeholder ? true : undefined }}
                footerContent={footerContent}
                disabled={disabled}
            />);
    }

    return (
        <FilterRow className={clsx(className, "cascading-selector")} {...rest}>
            {
                Object.entries(dimensions).filter(([_groupLabel, dims]) => (dims ?? []).filter(d => !d.isHidden).length > 0).map(([groupLabel, dims]) => (
                    <Filter key={groupLabel} title={groupLabel}>
                        {dims.filter(d => !d.isHidden).map(renderDimension)}
                    </Filter>
                ))
            }
        </FilterRow>
    );
}

(CascadingSelector as DocumentedComponent).metadata = {
    description: `The CascadingSelector component renders a collection of \`Autocomplete\` input components, each linked to each other via the hierarchy declared.

Changes made to upstream values cascade down to the lower inputs, filtering out irrelevant options automatically.

Conversely, downstream selections restrict upstream options to only include valid ancestors.`,
    isSelfClosing: true,
    attributes: [
        {
            name: `name`, type: `string`, description: "The name of the variable that the selected value(s) will be read from and persisted to.Values are stored as a single dictionary, where the dimension names are keys, with the selected values for each dimension contained within."
        },
        {
            name: `dimensions`, type: `object`, description: `The dimensions to render in the component.  This prop should contain a dictionary, where each key is used as a group label and the values contained within are used to render the \`Autocomplete\` components for that group.  See below for the structure of the \`CascadingDimension\` type:

### CascadingDimension Fields:

| Name | Type | Description |
|------|------|-------------|
| \`label\` | \`string\` | The text to use as the label for this dimension.  Displayed alongside the associated \`Autocomplete\` component. |
| \`fieldName\` | \`string\` | The field name to use for persisting the selected value(s).  Selected value(s) for this dimension will be accessible via \`parameterValues.get(name)[fieldName]\`. |
| \`multiple\` | \`boolean\` | If \`false\`, only a single value can be selected for this dimension.  Optional - defaults to \`true\`. |
| \`isHidden\` | \`boolean\` | If \`false\`, no input component is displayed for this dimension.  However, the cascading functionality would still apply for it, based on its ancestors and/or the relevant field value from the input parameter.  Optional - defaults to \`true\`. |
| \`bypassFilters\` | \`boolean\` | If \`true\`, no ancestor/descendent filtering will be applied to the available options for this dimension.  I.e., all the options from the relevant source will be available in this dropdown at all times, regardless of values selected in the other dimensions.  NOTE: The selected value(s) for this dimension would still cause cascading across other linked dimensions, unless the \`excludeFromCascade\` field is also set to \`true\`.  Optional - defaults to \`false\`. |
| \`excludeFromCascade\` | \`boolean\` | If \`true\`, the value(s) selected for this dimension will not affect the available options for other dimensions.  Optional - defaults to \`false\`. |
` },
        {
            name: `sources`, type: `object`, description: "The sources to use for retrieving the dropdown options for each dimension.  This prop should contain a dictionary, where each key is used as the source name, as referenced by the nodes.  The structure of `Option` here matches that of a standard `Autocomplete` option: `value` for the option value and `label` for the display text."
        },
        {
            name: `nodes`, type: `object`, description: `The relationships between the different options are declared by this prop.  Root-level items for this prop have no upstream dependencies.  See below for the structure of the \`CascadingNode\` type.

### CascadingNode Fields:

| Name | Type | Description |
|------|------|-------------|
| \`source\` | \`string\` | The name of the source to use for retrieving the relevant dropdown option.  Must be one of the field names inside the \`sources\` object. |
| \`index\` | \`number\` | The index of the option to use for this node.  References the dropdown option at \`sources[source][index]\`. |
| \`children\` | \`CascadingNode[]\` | The child nodes linked to this node.  Children of this node are only available in their respective \`Autocomplete\` when this option is selected or when no options were selected for this dimension. |
`       },
        {
            name: `getFooterContent`, type: `object`, template: `getFooterContent={(fieldName) => {\n\t$1\n}}`, description: "Optional callback function to invoke for determining the `footerContent` value for the corresponding `Autocomplete` component.  The `fieldName`, as configured in the dimension is passed in as an input argument.  Can return any valid JSX for display in the footer of the pop-up panel."
        },
        {
            name: "disabled", type: "boolean", description: "If `true`, the input components are disabled and cannot be interacted with.  Optional - defaults to `false`."
        }
    ]
};

export { CascadingSelector };