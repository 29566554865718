import {
    TraceEvent,
    ADD_TRACE_EVENT,
} from "./types";

export function addTraceEvent(event: TraceEvent) {
    return {
        type: ADD_TRACE_EVENT,
        trace: {
            timestamp: new Date(),
            event
        }
    };
}