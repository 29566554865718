import "./LoginCallback.scss";
import { Navigate } from "react-router-dom";
import { Card, CardHeader, CardContent, Button, Alert } from "../shared/components";
import { useAuth0 } from "./AuthContext";
import history from "../shared/history";
import { useQueryParams } from "../shared/hooks";
import { useSettings } from "../shared/providers/SettingsProvider";

export default function LoginCallback() {
    const { loginWithRedirect, error } = useAuth0();
    const query = useQueryParams();
    const originalPath = query.get("original-path");
    const settings = useSettings();

    // Redirect to the original page after a prior unsuccessful login attempt, or the home page if we erroneously ended up here...
    if (!error) {
        setTimeout(() => {
            history.push(originalPath ?? window.location.origin);
        }, 100);
    }

    const accessDenied = error?.includes("not authorized");

    return error ?
        <Card className="screen-centered" style={{ maxWidth: "600px" }}>
            <CardHeader title={accessDenied ? "Access Denied" : "Login Failed"} />
            <CardContent className="text-align-center">
                {error}
                {
                    accessDenied &&
                    settings.owner &&
                    <Alert color="info" icon={false} className="margin-top-3 col-fill">
                        <div className="site-owner-details-grid">
                            <div className="field">Site Administrator</div>
                            <div className="value">{settings.owner.name}</div>

                            <div className="field">Email Address</div>
                            <div className="value">
                                <a href={`mailto:${settings.owner.emailAddress}&subject=Please grant me access to ${window.location.host}`}>{settings.owner.emailAddress}</a>
                            </div>
                        </div>
                    </Alert>
                }
                <Button variant="contained" color="primary" className="align-self-center margin-top-3" onClick={() => loginWithRedirect({ state: JSON.stringify({ targetUrl: originalPath ?? window.location.origin }) })}>Try Again</Button>
            </CardContent>
        </Card>
        :
        <Navigate to={"/"} replace={true} />;
}