import React from 'react';
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue, applyParameterValueChanges } from "../../../store/storyline/actions";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';
import { useStaticPlot } from '../../../shared/providers/StaticPlotProvider';
import moment from 'moment';

type ParameterPersisterProps = {
    parameters: string[] | { [field: string]: string },
    target?: "url" | "localStorage",
    isGlobal: boolean,
    autoApplyAfterRestore: boolean,
    storyline: StorylineState,
    updateParameterValue: typeof updateParameterValue,
    applyParameterValueChanges: typeof applyParameterValueChanges
};

function calculateNewUrl(originalUrl: string, parameterValues: [string, any][]) {
    const result = new URL(originalUrl);

    parameterValues.forEach(([key, value]) => {
        if (_.isDate(value) || moment.isMoment(value)) {
            result.searchParams.set(key, value.toISOString());
        } else if (_.isObject(value)) {
            result.searchParams.set(key, JSON.stringify(value));
        } else {
            result.searchParams.set(key, value?.toString ? value.toString() : JSON.stringify(value));
        }
    });

    return result;
}

function _BaseParameterPersister(props: ParameterPersisterProps) {
    const { parameters: _parameters, target = "localStorage", isGlobal, autoApplyAfterRestore, updateParameterValue, applyParameterValueChanges, storyline } = props;
    const parameters = Array.isArray(_parameters) ? _parameters.map(p => [p, p]) : Array.from(Object.entries(_parameters));
    const [originalUrl] = React.useState(document.location.toString());

    const getPersistenceKey = (parameterName: string) => 
        isGlobal ? parameterName : `${storyline.id}:${parameterName}`;

    React.useEffect(() => {
        // URL parameters are already handled by the Storyline loader - nothing to do here...
        if (target === "url") return;

        let isDirty = false;

        _.forEach(parameters, ([source, dest]) => {
            const previousRawValue = localStorage.getItem(getPersistenceKey(dest));
            if (!previousRawValue) return;

            const previousValue = JSON.parse(previousRawValue);
            const currentValue = storyline.parameterValues.get(source);

            if (previousValue && !currentValue) {
                isDirty = true;
                updateParameterValue(source, previousValue);
            }
        });

        if (autoApplyAfterRestore && isDirty) {
            applyParameterValueChanges();
        }
    }, []);

    React.useEffect(() => {
        const mappedParameterValues = parameters
            .map(([source, dest]) => [dest, storyline.parameterValues.get(source)])
            .filter(([_key, value]) => value != null) as [string, any][];

        switch (target) {
            case "url":
                const url = calculateNewUrl(originalUrl.toString(), mappedParameterValues);
                // Only push a new state once, preserving the original query parameters...
                if (document.location.toString() === originalUrl) {
                    window.history.pushState({}, null, url);
                }
                else {
                    window.history.replaceState({}, null, url);
                }
                break;

            default:
                mappedParameterValues.forEach(([key, value]) => {
                    localStorage.setItem(getPersistenceKey(key), JSON.stringify(value));
                });
                break;
        }
    }, [storyline.parameterValues]);

    return null;
}

const BaseParameterPersister = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any, applyParameterValueChanges: applyParameterValueChanges as any })(_BaseParameterPersister);

function ParameterPersister(props: ParameterPersisterProps) {
    const staticPlot = useStaticPlot();
    if (staticPlot) return;

    return <BaseParameterPersister {...props} />;
}

(ParameterPersister as DocumentedComponent).metadata = {
    description: `The ParameterPersister component allows for persistence of parameter values to the browser's local storage.  This allows us to retrieve and rebind these when the page is reloaded.  These retrieved values can/will still be overridden by URL parameters and data source values if present.  They are treated as a fallback in case no other parameter values are present.`,
    isSelfClosing: true,
    attributes: [
        { name: `parameters`, type: `object`, description: "The names of the storyline parameters to persist/retrieve.  Can either be a dictionary where key = [local parameter name] and value = [stored parameter name], or an array of strings.  In the latter case, the local parameter name is used for the stored parameter name as well.  Persisting (and retrieving) parameters using a mapping is mostly useful when parameters are persisted globally (via usage of the `isGlobal` prop).  This allows global stored parameter values to be shared across storylines, even when local parameter names do not match." },
        { name: `target`, type: `string`, options: ["url", "localStorage"], description: "Optional - defaults to `\"localStorage\"`.  Determines where the parameter values are persisted to.  If set to `\"url\"`, the URL in the browser address bar is kept in-sync with the currently selected parameter values.  If set to `\"localStorage\"`, the parameter values are persisted to local storage instead and will be reinstated from there when the user reloads the page." },
        { name: `isGlobal`, type: `boolean`, description: "Optional - defaults to `false`.  If `true`, the parameter value is persisted and retrieved in a way which allows it to be shared across storylines.  If `false`, a unique identifier is added to the persisted parameter name, which limits its visibility to the current storyline." },
        { name: `autoApplyAfterRestore`, type: `boolean`, description: "If `true`, any data sources that are affected by the restored parameter values are refreshed immediately.  Useful for loading initial data from sources where `AutoRefresh` is set to `false`." },
    ]
};

export default ParameterPersister;