export const PERMISSIONS = {
    USERS_READ: "users::read",
    USERS_CREATE: "users::create",
    USERS_UPDATE: "users::update",
    USERS_DELETE: "users::delete",
    ROLES_READ: "roles::read",
    ROLES_CREATE: "roles::create",
    ROLES_UPDATE: "roles::update",
    ROLES_DELETE: "roles::delete",
    DEVELOPER_TOOLS_ACCESS: "developer-tools::access",
}