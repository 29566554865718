import React from 'react';
import "./RadioGroup.scss";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { FormControlLabel, RadioGroup as BaseRadioGroup, Radio as BaseRadio } from "../../../shared/components";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';
import clsx from 'clsx';
import _ from 'lodash';

interface RadioGroupProps {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    [key: string]: any;
}

function _RadioGroup(props: RadioGroupProps) {
    const { storyline, updateParameterValue, name, children, className, ...rest } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name));

    const handleChange = (event: any) => {
        const newValue = JSON.parse(event.target.value);

        updateParameterValue(name, newValue);
        setValue(newValue);
    };

    React.useEffect(() => {

        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (!_.isEqual(newValue, value)) {
                setValue(newValue);
            }
        }

    }, [storyline.parameterValues]);

    return (
        <BaseRadioGroup
            className={clsx("radio-group", className)}
            aria-label={name}
            name={name}
            value={JSON.stringify(value)}
            onChange={handleChange}
            {...rest}
        >
            {children}
        </BaseRadioGroup>
    );
}

const RadioGroup = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(_RadioGroup);

(RadioGroup as DocumentedComponent).metadata = {
    description: "The RadioGroup component contains two or more radio buttons, presenting the user with an option to select only one of these at a time.  Used as an alternative to a single-select dropdown or autocomplete, where the selected option is more important or needs to be displayed more prominently.",
    isSelfClosing: false,
    attributes: [
        { name: "name", type: "string", description: "The name of the variable that the selected value(s) will be read from and persisted to." }
    ]
};

interface RadioProps {
    value: string;
    label: string;
    [key: string]: any;
}

function Radio(props: RadioProps) {
    const { value, label, ...rest } = props;
    return <FormControlLabel value={JSON.stringify(value)} control={<BaseRadio color="primary" />} label={label} {...rest} />;
}

(Radio as DocumentedComponent).metadata = {
    description: "The Radio component is a single radio button within a RadioGroup.  It is used to present the user with an option to select only one of these at a time.  Used as an alternative to a single-select dropdown or autocomplete, where the selected option is more important or needs to be displayed more prominently.",
    isSelfClosing: true,
    attributes: [
        { name: "value", type: "object", description: "The value that will be stored in the variable specified by the parent RadioGroup's name attribute." },
        { name: "label", type: "string", description: "The text to display next to the radio button." }
    ]
}

export { RadioGroup, Radio };