import "./Mosaic.scss";
import ReactTooltip from 'react-tooltip';
import * as _ from "lodash";
import { ProgressBar } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface Heading {
    foregroundColor?: string,
    backgroundColor?: string,
    text: string
}

interface MosaicCell {
    class: string,
    fields: string[],
    progressBar?: { fillPercentage: number, text: string, fillColor?: string },
    tooltip: string
}

interface MosaicProps {
    xHeadings: (string | Heading)[],
    yHeadings: (string | Heading)[],
    cells: MosaicCell[][],
    fontSize?: string
}

function Mosaic(props: { input: MosaicProps, [key: string]: any }) {
    const { input } = props;

    const xLength = input?.cells?.length;
    const yLength = input?.cells?.[0]?.length;

    if (!input) return "Mosaic requires a non-null 'input' property.";

    return (
        <div className="qbc-mosaic-container">
            <div className={`qbc-mosaic ${props.className} ${input.fontSize}`} style={{ gridTemplateColumns: `150px repeat(${xLength}, 1fr)`, gridTemplateRows: `80px repeat(${yLength}, 1fr)`, ...props.style }}>
                <div className="heading" style={{ gridColumnStart: 1, gridRowStart: 1 }}>&nbsp;</div>
                {_.map(input.xHeadings, (val, xi) => <div className="heading top-row" style={{ gridRowStart: 1, gridColumnStart: xi + 2, backgroundColor: val?.["backgroundColor"], color: val?.["foregroundColor"] }} dangerouslySetInnerHTML={{ __html: val?.["text"] || val }} />)}
                {_.map(input.yHeadings, (val, yi) => <div className="heading left-row" style={{ gridRowStart: yi + 2, gridColumnStart: 1, backgroundColor: val?.["backgroundColor"], color: val?.["foregroundColor"] }} dangerouslySetInnerHTML={{ __html: val?.["text"] || val }} />)}
                {
                    _.flatMap(input.cells, (column, xi) =>
                        _.map(column, (cell, yi) =>
                            <>
                                <div className={`cell ${cell.class} ${xi > 0 && "bordered-left"} ${yi > 0 && "bordered-top"}`} style={{ gridRowStart: yi + 2, gridColumnStart: xi + 2 }} data-for="mosaic-tooltip" data-tip={cell.tooltip}>

                                    <div className="fields">
                                        {
                                            _.map(cell.fields, field =>
                                                <div className="field" dangerouslySetInnerHTML={{ __html: field }}>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {
                                        cell.progressBar &&
                                        <ProgressBar value={cell.progressBar.fillPercentage} text={cell.progressBar.text} fillColor={cell.progressBar.fillColor} />
                                    }
                                </div>
                            </>
                        )
                    )
                }
            </div>
            <ReactTooltip id="mosaic-tooltip" html={true} />
        </div>);
}

(Mosaic as DocumentedComponent).metadata = {
    description: "The mosaic component is used to display an `m` x `n` grid of cell.  Each cell contains a title, a progress bar and an arbitrary array of field values.  This is primarily used as a more complex version of a heatmap.",
    isSelfClosing: true,
    attributes: [
        {
            name: `xHeadings`,
            type: `object`,
            description:
                `The headings to be shown horizontally along the \`x\` axis.  This can either be an array of strings or an array of \`Heading\` objects - in case more control is required over the formatting of the cells.  See below for the structure of the \`Heading\` object.
    
### \`Heading\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`foregroundColor\` | \`string\` | An optional color string to be used as the foreground color for this heading cell.  Will override the styles from the app/template CSS. |
| \`backgroundColor\` | \`string\` | An optional color string to be used as the background color for this heading cell.  Will override the styles from the app/template CSS. |
| \`text\` | \`string\` | The text to be displayed in this heading cell.  Can contain plain text or HTML. |`
        },
        {
            name: `yHeadings`,
            type: `object`,
            description:
                `The headings to be shown vertically along the \`y\` axis.  This can either be an array of strings or an array of \`Heading\` objects - in case more control is required over the formatting of the cells.  See below for the structure of the \`Heading\` object.
    
### \`Heading\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`foregroundColor\` | \`string\` | An optional color string to be used as the foreground color for this heading cell.  Will override the styles from the app/template CSS. |
| \`backgroundColor\` | \`string\` | An optional color string to be used as the background color for this heading cell.  Will override the styles from the app/template CSS. |
| \`text\` | \`string\` | The text to be displayed in this heading cell.  Can contain plain text or HTML. |`
        },
        {
            name: `cells`,
            type: `object`,
            description:
                `The 2-dimensional array of data for the cells.  Cells are accessed using the x index as the first array indexer and the y index as the second.  E.g., \`cells[xIndex][yIndex]\`.  The dimensionality of this array should match the length of the \`xHeadings\` and \`yHeadings\` array.  I.e., if \`xHeadings\` has a length of \`m\` and \`yHeadings\` has a length of \`n\`, then \`cells\` should be an \`m\` by \`n\` array of \`MosaicCell\` objects.  See below for the structure of the \`MosaicCell\` object.
    
### \`MosaicCell\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`class\` | \`string\` | The CSS class to be applied to this cell.  Predefined classes are: \`positive\` (green), \`negative\` (red) and \`warning\` (orange). |
| \`fields\` | \`string[]\` | The list of fields to display inside this cell.  Fields are stacked vertically and can contain any HTML. |
| \`progressBar\` | \`ProgressBar\` | An optional ProgressBar object that will be used to render the Progress Bar at the bottom of the cell.  See below for the structure of the \`ProgressBar\` object. |
| \`backgroundColor\` | \`string\` | An optional color string to use as the background for the cell. |
| \`foregroundColor\` | \`string\` | An optional color string to use as the foreground (text color) for the fields. |
| \`tooltip\` | \`string\` | Content to display when a user hovers over the cell.  Can be plain text or HTML. |

### \`ProgressBar\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`fillPercentage\` | \`number\` | A value between 0 and 1 to determine the width of the filled-in portion of the progress bar. |
| \`text\` | \`string\` | An optional label to be shown in the progress bar. |
| \`fillColor\` | \`string\` | An optional color string to use as the background for the filled-in portion of the progress bar. |
| \`backgroundColor\` | \`string\` | An optional color string to use as the background for the empty (not filled-in) portion of the progress bar. |
| \`foregroundColor\` | \`string\` | An optional color string to use as the foreground (text color) for the progress bar label. |`
        },
        {
            name: `fontSize`,
            type: `string`,
            options: [`small`, `medium`, `large`, `x-large`, `xx-large`, `huge`],
            description: `Name of the CSS class used to set the base font size for all cells.`
        },
    ]
};

export default Mosaic;