import _ from "lodash";

type Coordinates = string[];

function isDescendentOf(item: string[], prefix: string[]) {
    return prefix.length < item.length && _.isEqual(prefix, item.slice(0, prefix.length))
}

function isLeafNode(items: string[][], coordinates: string[]) {
    return _.isEmpty(items.filter(x => isDescendentOf(x, coordinates)));
}

export const getIdentifier = (coordinates: Coordinates) => coordinates.join("-").replace(/[^a-z0-9]+/gi, "-");
const getAreaNameAtDepth = (col, depth, prefix) => prefix + getIdentifier(col.slice(0, depth));

export class LayoutCalculator {
    allRows: Coordinates[];
    allColumns: Coordinates[];

    visibleRows: Coordinates[];
    visibleColumns: Coordinates[];

    leafRows: Coordinates[];
    leafCols: Coordinates[];

    colGroups: [string, Coordinates[]][];
    rowGroups: [string, Coordinates[]][];

    maxColDepth: number;
    maxRowDepth: number;

    totalColumnCount: number;
    subColumnCount: number;

    constructor(allRows: Coordinates[], visibleRows: Coordinates[], allColumns: Coordinates[], visibleColumns: Coordinates[]) {
        this.allRows = allRows;
        this.visibleRows = visibleRows;

        this.allColumns = allColumns;
        this.visibleColumns = visibleColumns;

        this.leafRows = visibleRows.filter(r => isLeafNode(visibleRows, r));
        this.leafCols = visibleColumns.filter(c => isLeafNode(visibleColumns, c));

        this.colGroups = Object.entries(_.groupBy(this.leafCols, c => c.at(0)));
        this.rowGroups = Object.entries(_.groupBy(this.leafRows, c => c.at(0)));

        this.maxColDepth = _.max(this.leafCols.map(r => r.length));
        this.maxRowDepth = _.max(this.leafRows.map(r => r.length));

        this.subColumnCount = this.getDeepestHierarchyLevelForRows();
        this.totalColumnCount = this.maxRowDepth + _.sum(this.colGroups.map(g => 1 + (g[1].length * this.subColumnCount)))
    }

    private get gridTemplateRows() {
        const columnHeaderRows = `repeat(${this.maxColDepth}, minmax(min-content, max-content))`;
        const dataRows = this.rowGroups.map(([_, leafNodes]) =>
            `auto repeat(${leafNodes.length}, minmax(min-content, max-content))`
        ).join(" ");

        return `${columnHeaderRows} ${dataRows} 1fr`; // The final 1fr here is a blank row to take up all the excess vertical space.  This prevents the spacers from growing in-between the data cells.
    }

    private get gridTemplateColumns() {
        const rowHeaderColumns = `repeat(${this.maxRowDepth}, minmax(min-content, max-content))`;
        const dataColumns = this.colGroups.map(([_, leafNodes]) =>
            `auto repeat(${leafNodes.length * this.subColumnCount}, minmax(min-content, 1fr))`
        ).join(" ");

        return `${rowHeaderColumns} ${dataColumns}`;
    }

    private get columnHeaderRowsAreas() {
        const getColHeadingAreaDefinition = (colGroup, colGroupIndex, depth) =>
            `col-header-spacer-${colGroupIndex} ${colGroup[1].map(col => _.range(1, this.subColumnCount + 1).map(_i => getAreaNameAtDepth(col, depth, "col-")).join(" ")).join(" ")}`;
        return _.range(1, this.maxColDepth + 1).map(depth =>
            // 1 row per level of the row headers...
            `"${_.range(1, this.maxRowDepth + 1).map(_ => "row-header-spacer").join(" ")} ${this.colGroups.map((c, cIndex) => getColHeadingAreaDefinition(c, cIndex, depth)).join(" ")}"`
        );
    }

    private get dataRowAreas() {
        const getAreaDefinitionsForRow = (row) => {
            const rowHierarchy = this.getHierarchyForLeafRow(row);
            const normalizedRowHierarchy = _.range(0, this.subColumnCount).map(i => rowHierarchy.at(i) ?? rowHierarchy.at(-1));
            return this.colGroups
                .flatMap(cg =>
                    [
                        ".",
                        ...cg[1].flatMap(col => normalizedRowHierarchy.map(r => "cell-" + getIdentifier(r) + "-" + getIdentifier(col)))
                    ]
                ).join(" ");
        };
        return this.rowGroups.flatMap((rowGroup, rowGroupIndex) => {
            const rowSpacerName = `row-header-spacer-${rowGroupIndex}`;

            return [
                // Spacer row...
                `"${_.range(0, this.maxRowDepth).map(_ => rowSpacerName).join(" ")} ${_.range(0, this.totalColumnCount - this.maxRowDepth).map(_ => ".").join(" ")}"`,
                // 1 row per leaf node in this row group...
                ...rowGroup[1].map(row => `"${_.range(1, this.maxRowDepth + 1).map(depth => getAreaNameAtDepth(row, depth, "row-")).join(" ")} ${getAreaDefinitionsForRow(row)}"`)
            ];
        });
    }

    private get gridTemplateAreas() {
        return [
            ...this.columnHeaderRowsAreas,
            ...this.dataRowAreas,
            `"${_.range(0, this.totalColumnCount).map(_ => ".").join(" ")}"` // Spacer row to ensure that scrollable area is at least 100% height...
        ].join("\n")
    }

    get gridLayout() {
        return {
            gridTemplateRows: this.gridTemplateRows,
            gridTemplateColumns: this.gridTemplateColumns,
            gridTemplateAreas: this.gridTemplateAreas,
        };
    }

    public getHierarchyForLeafRow(leafRow: string[]) {
        let hierarchy = [leafRow];
        let currentNode = leafRow;
        while (currentNode = this.visibleRows.find(c => _.isEqual(c, currentNode.slice(0, -1)))) {
            hierarchy.splice(0, 0, currentNode);
        }
        return hierarchy;
    }

    private getDeepestHierarchyLevelForRows() {
        const leafNodes = this.visibleRows.filter(r => isLeafNode(this.visibleRows, r));
        return _.max(leafNodes.map(r => this.getHierarchyForLeafRow(r).length));
    }

    public expandColumn(column: Coordinates) {
        const children = this.allColumns.filter(x => isDescendentOf(x, column) && x.length === column.length + 1);
        const insertionIndex = this.visibleColumns.findIndex(c => _.isEqual(c, column));
        this.visibleColumns = [
            ...this.visibleColumns.slice(0, insertionIndex),
            ...children,
            ...this.visibleColumns.slice(insertionIndex)
        ];
        return this.visibleColumns;
    }

    public collapseColumn(column: Coordinates) {
        const descendents = this.visibleColumns.filter(x => isDescendentOf(x, column));
        this.visibleColumns = _.differenceWith(this.visibleColumns, descendents, _.isEqual);
        return this.visibleColumns;
    }

    public expandRow(row: Coordinates) {
        const children = this.allRows.filter(x => isDescendentOf(x, row) && x.length === row.length + 1);
        const insertionIndex = this.visibleRows.findIndex(r => _.isEqual(r, row));
        this.visibleRows = [
            ...this.visibleRows.slice(0, insertionIndex),
            ...children,
            ...this.visibleRows.slice(insertionIndex)
        ];
        return this.visibleRows;
    }

    public collapseRow(row: Coordinates) {
        const descendents = this.visibleRows.filter(x => isDescendentOf(x, row));
        this.visibleRows = _.differenceWith(this.visibleRows, descendents, _.isEqual);
        return this.visibleRows;
    }

    get columnHeaderCells() {
        const visibleColumnHierarchy = _.uniqWith(this.visibleColumns.flatMap(c => _.range(1, c.length + 1).map(depth => c.slice(0, depth))), _.isEqual);
        return visibleColumnHierarchy.map(coords => ({
            coords,
            showCollapseExpand: !!this.allColumns.find(col => isDescendentOf(col, coords) && this.allColumns.find(vc => _.isEqual(vc, coords))),
            isExpanded: !!visibleColumnHierarchy.find(vch => isDescendentOf(vch, coords))
        }));
    }

    get rowHeaderCells() {
        const visibleRowHierarchy = _.uniqWith(this.visibleRows.flatMap(r => _.range(1, r.length + 1).map(depth => r.slice(0, depth))), _.isEqual)
        return visibleRowHierarchy.map(coords => ({
            coords,
            showCollapseExpand: !!this.allRows.find(row => isDescendentOf(row, coords) && this.allRows.find(vr => _.isEqual(vr, coords))),
            isExpanded: !!visibleRowHierarchy.find(vch => isDescendentOf(vch, coords))
        }));
    }
}