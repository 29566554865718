import { PopoverProps as BasePopoverProps } from '@mui/material';
import { Popover as BasePopover } from '@mui/material';
import * as React from 'react';
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';
import { useTooltipContainer } from '../../../shared/providers/TooltipContainerProvider';

type PopoverProps = BasePopoverProps & {
    trigger: React.ReactElement;
    content: React.ReactElement;
}

function Popover(props: PopoverProps) {
    const { trigger: Trigger, content, ...rest } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const tooltipContainerRef = useTooltipContainer();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!Trigger) {
        return "The 'trigger' prop is required.";
    }

    return (
        <>
            {React.cloneElement(Trigger, { onClick: handleClick, "data-open": open })}
            <BasePopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                container={tooltipContainerRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                {...rest}
            >
                {content}
            </BasePopover>
        </>
    );
}

(Popover as DocumentedComponent).metadata = {
    description: "The `Popover` component allows you to display an inline modal dialog that is positioned relative to another element on the page.  The dialog becomes visible when the user clicks on the `toggle` element and is hidden when the user clicks away.",
    attributes: [
        { name: "trigger", type: "object", description: "The element to use as the toggle for the popover.  Clicking on this element will toggle the visibility of the modal dialog.  Note that any existing `onClick` event handler for this component will be overridden." },
        { name: "content", type: "object", description: "The content to render inside the modal dialog.  Any JSX content is supported here." },
        { name: "anchorOrigin", type: "object", template: `anchorOrigin={{\n\tvertical: "bottom",\n\thorizontal: "left"\n}}`, description: "The position to display the modal dialog relative to the trigger." },
        { name: "transformOrigin", type: "object", template: `transformOrigin={{\n\tvertical: "top",\n\thorizontal: "left"\n}}`, description: "The point on the modal which will attach to the trigger." }
    ],
    isSelfClosing: true,
};

export { Popover };