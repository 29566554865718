import { VsCodeState, VsCodeActionTypes } from "./types";

const initialState: VsCodeState = {
    connectionStatus: "CLOSED"
};

export function vsCodeReducer(
    state = initialState,
    action: VsCodeActionTypes
): VsCodeState {
    switch (action.type) {
        case "SET_CONNECTION_STATUS":
            return {
                connectionStatus: action.status
            };

        default:
            return state;
    }
}