import * as React from "react";
import * as _ from "lodash";

// A custom hook that handles memoization of input props using a structural equality check.
export default function useStructuralMemo(valueToMemoize: any) {
    const [memoizedValue, setMemoizedValue] = React.useState(valueToMemoize);
    React.useEffect(() => {
        if (!_.isEqual(valueToMemoize, memoizedValue)) {
            setMemoizedValue(valueToMemoize);
        }
    }, [valueToMemoize]);

    return memoizedValue;
}