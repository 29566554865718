import { LinearProgress, Box, Typography, LinearProgressProps } from "@mui/material";

interface LinearProgressIndicatorProps extends LinearProgressProps {
    showLabel?: boolean;
}

const LinearProgressIndicator = (props: LinearProgressIndicatorProps) => {
    const { showLabel, variant, ...rest } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} className="linear-progress">
            <Box sx={{ width: '100%', mr: variant === "determinate" && showLabel ? 1 : 0 }}>
                <LinearProgress variant={variant} {...rest} />
            </Box>
            {
                variant === "determinate" && showLabel &&
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary" className="linear-progress-label">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            }
        </Box>
    );
}

export default LinearProgressIndicator;