import React from "react";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import type { TemplateRendererProps } from "./TemplateRenderer";

function _Template(props: TemplateRendererProps) {
    // Dynamic import to avoid circular dependencies...
    const { TemplateRenderer } = require("./TemplateRenderer");

    return <TemplateRenderer {...props} />;
}

const Template = React.memo(_Template);

(Template as DocumentedComponent).metadata = {
    description: "Parses and renders the contents of the referenced `jsx` file.  Any additional props passed in will be made available to the bindings within this template.",
    attributes: [
        { name: "path", type: "string", description: "The relative path to the template file to render.  `{path}.jsx`, `{path}.css` and `{path}.json` are used for the JSX, CSS and documentation respectively." },
        { name: "className", type: "string", description: "The (optional) class name(s) to apply to the container div." }
    ],
    isSelfClosing: true // Even though the Template component can take in children (to act as a wrapper), this is probably the exception rather than the rule...
};

export { Template };
