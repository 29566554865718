import "./Group.scss";
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeInputProps } from "./common";

function Group(props: NodeInputProps) {
    return (
        <div className="FlowChart-node FlowChart-node-group">
            <Handle type="source" id="source-top" position={Position.Top} />
            <Handle type="source" id="source-left" position={Position.Left} />
            <Handle type="source" id="source-bottom" position={Position.Bottom} />
            <Handle type="source" id="source-right" position={Position.Right} />

            <Handle type="target" id="target-top" position={Position.Top} />
            <Handle type="target" id="target-left" position={Position.Left} />
            <Handle type="target" id="target-bottom" position={Position.Bottom} />
            <Handle type="target" id="target-right" position={Position.Right} />

            <div className="group-heading">
                <NodeContent {...props} />
            </div>
        </div>
    );
}

export { Group };