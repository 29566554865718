import { TemplateDefinition } from "../storyline/types";

export type ConnectionStatus = "OPEN" | "CLOSED" | "ERROR";

export type VsCodeState = {
    connectionStatus: ConnectionStatus;
};

export interface PageState {
    name: string;
    template: string;
};

export interface StorylineState {
    name: string;
    currentPage: PageState;
    version: string;
};

export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";

export interface SetConnectionStatusAction {
    type: typeof SET_CONNECTION_STATUS;
    status: ConnectionStatus;
}
export type VsCodeActionTypes = SetConnectionStatusAction;


export type RequestTemplateServerToClientMessage = { type: "REQUEST_TEMPLATE"; };
export type RequestTemplatesServerToClientMessage = { type: "REQUEST_TEMPLATES"; };
export type UpdateTemplateServerToClientMessage = { type: "UPDATE_TEMPLATE"; template: string; css: string; };
export type UpdateTemplatesServerToClientMessage = { type: "UPDATE_TEMPLATES"; templates: Record<string, TemplateDefinition>; };
export type SaveTemplateServerToClientMessage = { type: "SAVE_TEMPLATE", template: string, css: string };
export type SaveTemplatesServerToClientMessage = { type: "SAVE_TEMPLATES", templates: Record<string, TemplateDefinition> };
export type ServerToClientMessage = RequestTemplateServerToClientMessage | RequestTemplatesServerToClientMessage | UpdateTemplateServerToClientMessage | UpdateTemplatesServerToClientMessage | SaveTemplateServerToClientMessage | SaveTemplatesServerToClientMessage;


export type LoadTemplateClientToServerMessage = {
    type: "LOAD_TEMPLATE";
    template: string;
    css: string;
};
export type LoadComponentDocumentationClientToServerMessage = {
    type: "LOAD_COMPONENT_DOCUMENTATION",
    components: Record<string, any>
};
export type LoadCanvasDataClientToServerMessage = {
    type: "LOAD_CANVAS_DATA",
    canvasData: Record<string, any>
};
export type LoadCssClassesClientToServerMessage = {
    type: "LOAD_CSS_CLASSES",
    classNames: string[]
};
export type StorylineLoadedClientToServerMessage = {
    type: "STORYLINE_LOADED";
    storyline: StorylineState;
};
export type PageLoadedClientToServerMessage = {
    type: "PAGE_LOADED";
    page: PageState;
};
export type TemplateRenderedClientToServerMessage = {
    type: "TEMPLATE_RENDERED";
    name: string;
    inputs: Record<string, any>;
};

export type ClientToServerMessage = LoadTemplateClientToServerMessage | LoadComponentDocumentationClientToServerMessage | LoadCanvasDataClientToServerMessage | LoadCssClassesClientToServerMessage;