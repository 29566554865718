import "./ThemeShowcase.scss";
import React from "react";
import { Section } from "./Section";
import TextToSpeechPlayer from "../../viewer/components/TextToSpeechPlayer";
import Annotations from "../../viewer/components/Annotations";
import CanvasTour from "../../viewer/components/CanvasTour";
import { PrimaryButton, SecondaryButton, TertiaryButton, LinkButton, SuccessAlert, ErrorAlert, WarningAlert, InfoAlert, Avatar, LinearProgressIndicator, Badge, DialogTitleWithCloseButton, Loader, NoSearchResults, NothingYet, NoData, Slider, Checkbox, Radio, RadioGroup, Switch, Autocomplete, TextField, DatePicker, DateTimePicker, DateRangePicker, DataGrid, Accordion, AccordionGroup, TabsContainer, Tabs, Tab, PersistentTabPanel, Vdt, AvatarGroup, Dialog, DialogActions, DialogContent, DialogTitle, Popover, FormControlLabel, FormControl, GridRenderCellParams, GridValueFormatterParams } from "../../shared/components";
import { Avatar as AvatarIcon, Archive, Beaker, Calendar, Camera, Diamond, Bell, BellSlash } from "../../shared/components/icons";
import * as icons from "../../shared/components/icons";
import { showDetailedError, showError, showSuccess } from "../../store/notifications/actions";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Moment } from "moment";

function ProgressIndicators(props) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 5));
        }, 300);

        return (() => clearInterval(timer));
    }, []);

    return (
        <Section heading="Progress Indicators">
            <h4>Indeterminate</h4>
            <LinearProgressIndicator variant="indeterminate" />
            <h4>Determinate (With Label)</h4>
            <LinearProgressIndicator variant="determinate" showLabel value={progress} />
            <h4>Determinate (No Label)</h4>
            <LinearProgressIndicator variant="determinate" value={progress} />
        </Section>
    );
}

function Sliders(props) {
    const [sliderValue, setSliderValue] = React.useState(20);
    const [rangeSliderValue, setRangeSliderValue] = React.useState([5, 25]);

    return (
        <Section heading="Sliders" style={{ width: "200px" }}>
            <Slider value={sliderValue} onChange={(_, value: number) => setSliderValue(value)} />
            <Slider value={sliderValue} disabled />
            <Slider value={rangeSliderValue} onChange={(_, value: number[]) => setRangeSliderValue(value)} />
            <Slider value={rangeSliderValue} disabled />
        </Section>
    );
}

function TabsSection(props) {
    const [selectedValue, setSelectedValue] = React.useState("Section One");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedValue(newValue);
    };

    return (
        <Section heading="Tabs" style={{ width: "600px" }}>
            <TabsContainer style={{ height: "300px" }}>
                <Tabs value={selectedValue} onChange={handleChange} centered>
                    <Tab value="Section One" label="Section One" />
                    <Tab value="Section Two" label="Section Two" />
                    <Tab value="Section Three" label="Section Three" />
                </Tabs>
                <PersistentTabPanel value="Section One" selectedValue={selectedValue}>
                    <div className="vertical-form-field">Field One</div><br />
                    <TextField
                        size="small"
                        fullWidth
                    />
                </PersistentTabPanel>
                <PersistentTabPanel value="Section Two" selectedValue={selectedValue}>
                    <div className="vertical-form-field">Field Two</div><br />
                    <TextField
                        size="small"
                        fullWidth
                    />
                </PersistentTabPanel>
                <PersistentTabPanel value="Section Three" selectedValue={selectedValue}>
                    <div className="vertical-form-field">Field Three</div><br />
                    <TextField
                        size="small"
                        fullWidth
                    />
                </PersistentTabPanel>
            </TabsContainer>
        </Section>
    );
}

function VdtsSection(props) {

    return (
        <Section heading="VDT" style={{ width: "100%" }}>
            <div style={{ height: "900px" }}>
                <Vdt {...vdtData} staticPlot={false} />
            </div>
        </Section>
    );
}

function ThemeShowcase(props) {
    const { showDetailedError, showError, showSuccess } = props;
    const [runTour, setRunTour] = React.useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
    const popOverIsOpen = Boolean(popoverAnchorEl);
    const [dialogOneIsOpen, setDialogOneIsOpen] = React.useState(false);
    const [dialogTwoIsOpen, setDialogTwoIsOpen] = React.useState(false);

    const [checkboxValue, setCheckboxValue] = React.useState(true);
    const [radioValue, setRadioValue] = React.useState("disabled");
    const [switchValue, setSwitchValue] = React.useState(false);
    const [dateValue, setDateValue] = React.useState(null);
    const [dateTimeValue, setDateTimeValue] = React.useState(null);
    const [dateRangeValue, setDateRangeValue] = React.useState([null, null] as DateRange<Moment>);
    const [columns] = React.useState(gridColumns);

    return (
        <div className="theme-showcase">
            <Section heading="Buttons">
                <PrimaryButton>Primary</PrimaryButton>
                <SecondaryButton>Secondary</SecondaryButton>
                <TertiaryButton>Tertiary</TertiaryButton>
                <LinkButton>Link</LinkButton>
            </Section>

            <Section heading="Text-to-speech">
                <TextToSpeechPlayer text="The Text-to-speech player allows the user to control the playback of the narration for arbitrary text. It displays a single icon button that can be used to start the narration. Once started, the narration can be paused and resumed again using the same button." />
            </Section>

            <Annotations input={["Total sales increased by <strong class='fg-primary'>12.5%</strong>", "This led to a <strong class='fg-primary'>R12.5m</strong> increase in revenue."]} />

            <Section heading="Canvas Tour">
                <PrimaryButton onClick={() => setRunTour(true)}>Start Tour</PrimaryButton>
                <CanvasTour run={runTour} steps={[
                    {
                        "title": "Step One",
                        "content": "The First Step of the Tour.",
                        "target": ".theme-showcase > *:nth-child(1)",
                        "disableBeacon": true
                    },
                    {
                        "title": "Step Two",
                        "content": "If you close the tour at this point, you'll have to manually resume it via the beacon.  This is because the next step doesn't have disableBeacon set to true.",
                        "target": ".theme-showcase > *:nth-child(2)",
                        "disableBeacon": true
                    },
                    {
                        "title": "Step Three",
                        "content": "My position is forced to the bottom.",
                        "target": ".theme-showcase > *:nth-child(3)",
                        "placement": "bottom"
                    },
                    {
                        "title": "Step Four",
                        "content": "My position is forced to the left.",
                        "target": ".theme-showcase > *:nth-child(4)",
                        "placement": "left",
                        "disableBeacon": true
                    }
                ]} />
            </Section>

            <Section heading="Alerts">
                <ErrorAlert onClose={(e) => false}>This is an error message!</ErrorAlert>
                <WarningAlert onClose={(e) => false}>This is a warning message!</WarningAlert>
                <InfoAlert onClose={(e) => false}>This is an information message!</InfoAlert>
                <SuccessAlert onClose={(e) => false}>This is a success message!</SuccessAlert>
            </Section>

            <Section heading="Snackbars">

                <PrimaryButton onClick={() => showSuccess("This is a success message")}>Show Success</PrimaryButton>
                <SecondaryButton onClick={() => showError("This is an error message")}>Show Error</SecondaryButton>
                <TertiaryButton onClick={() => showDetailedError("This is an error message", "More details about the error.")}>Show Detailed Error</TertiaryButton>

            </Section>

            <Section heading="Avatars">
                <h4>Image Avatars</h4>
                <div className="d-flex flex-row space-between">
                    <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/2.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/3.jpg" />
                </div>

                <h4>Icon Avatars</h4>
                <div className="d-flex flex-row space-between">
                    <Avatar color="red"><AvatarIcon color="white" /></Avatar>
                    <Avatar color="orange"><Archive color="white" /></Avatar>
                    <Avatar color="yellow"><Beaker color="white" /></Avatar>
                    <Avatar color="green"><Calendar color="white" /></Avatar>
                    <Avatar color="blue"><Camera color="white" /></Avatar>
                    <Avatar color="darkblue"><Diamond color="white" /></Avatar>
                </div>

                <h4>Letter Avatars</h4>
                <div className="d-flex flex-row space-between">
                    <Avatar color="red">B</Avatar>
                    <Avatar color="orange">B</Avatar>
                    <Avatar color="yellow">B</Avatar>
                    <Avatar color="green">B</Avatar>
                    <Avatar color="blue">B</Avatar>
                    <Avatar color="darkblue">B</Avatar>
                </div>
                <div className="d-flex flex-row space-between">
                    <Avatar color="red">DS</Avatar>
                    <Avatar color="orange">DS</Avatar>
                    <Avatar color="yellow">DS</Avatar>
                    <Avatar color="green">DS</Avatar>
                    <Avatar color="blue">DS</Avatar>
                    <Avatar color="darkblue">DS</Avatar>
                </div>

                <h4>Grouped Avatars</h4>
                <AvatarGroup max={5}>
                    <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/2.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/3.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/4.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/5.jpg" />
                    <Avatar src="https://mui.com/static/images/avatar/6.jpg" />
                </AvatarGroup>
            </Section>

            <ProgressIndicators />

            <Section heading="Badges">
                <h4>Avatar Badges</h4>
                <div className="d-flex flex-row space-between">
                    <Badge
                        color="green"
                        variant="dot"
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Avatar src="https://mui.com/static/images/avatar/3.jpg" />
                    </Badge>
                    <Badge
                        color="red"
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent="10"
                    >
                        <Avatar color="blue">B</Avatar>
                    </Badge>
                    <Badge
                        color="blue"
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent="99+"
                    >
                        <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
                    </Badge>
                </div>
                <h4>Standalone Badges</h4>
                <div className="badge-grid grid-7-5">
                    <div><Badge color="red" variant="dot" /></div>
                    <div><Badge color="orange" variant="dot" /></div>
                    <div><Badge color="yellow" variant="dot" /></div>
                    <div><Badge color="green" variant="dot" /></div>
                    <div><Badge color="blue" variant="dot" /></div>
                    <div><Badge color="darkblue" variant="dot" /></div>
                    <div><Badge color="grey" variant="dot" /></div>

                    <div><Badge color="red" badgeContent={10} /></div>
                    <div><Badge color="orange" badgeContent={10} /></div>
                    <div><Badge color="yellow" badgeContent={10} /></div>
                    <div><Badge color="green" badgeContent={10} /></div>
                    <div><Badge color="blue" badgeContent={10} /></div>
                    <div><Badge color="darkblue" badgeContent={10} /></div>
                    <div><Badge color="grey" badgeContent={10} /></div>

                    <div><Badge color="red" badgeContent={100} max={99} /></div>
                    <div><Badge color="orange" badgeContent={100} max={99} /></div>
                    <div><Badge color="yellow" badgeContent={100} max={99} /></div>
                    <div><Badge color="green" badgeContent={100} max={99} /></div>
                    <div><Badge color="blue" badgeContent={100} max={99} /></div>
                    <div><Badge color="darkblue" badgeContent={100} max={99} /></div>
                    <div><Badge color="grey" badgeContent={100} max={99} /></div>

                    <div><Badge modifier="square" color="red" badgeContent="Updated" /></div>
                    <div><Badge modifier="square" color="orange" badgeContent="Updated" /></div>
                    <div><Badge modifier="square" color="yellow" badgeContent="Updated" /></div>
                    <div><Badge modifier="square" color="green" badgeContent="Updated" /></div>
                    <div><Badge modifier="square" color="blue" badgeContent="Updated" /></div>
                    <div><Badge modifier="square" color="darkblue" badgeContent="Updated" /></div>
                    <div><Badge modifier="square" color="grey" badgeContent="Updated" /></div>

                    <div><Badge modifier="outlined" color="red" badgeContent="Outlined" /></div>
                    <div><Badge modifier="outlined" color="orange" badgeContent="Outlined" /></div>
                    <div><Badge modifier="outlined" color="yellow" badgeContent="Outlined" /></div>
                    <div><Badge modifier="outlined" color="green" badgeContent="Outlined" /></div>
                    <div><Badge modifier="outlined" color="blue" badgeContent="Outlined" /></div>
                    <div><Badge modifier="outlined" color="darkblue" badgeContent="Outlined" /></div>
                    <div><Badge modifier="outlined" color="grey" badgeContent="Outlined" /></div>
                </div>
            </Section>

            <Section heading="Popovers/Dialogs">
                <PrimaryButton onClick={(e) => setPopoverAnchorEl(e.currentTarget)}>Basic Popover</PrimaryButton>
                <Popover
                    open={popOverIsOpen}
                    anchorEl={popoverAnchorEl}
                    onClose={() => setPopoverAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        "vertical": "bottom",
                        "horizontal": "center"
                    }}
                >
                    <div className="plush">The content of the Popover.</div>
                </Popover>

                <SecondaryButton onClick={() => setDialogOneIsOpen(true)}>Simple Dialog</SecondaryButton>
                <Dialog onClose={() => setDialogOneIsOpen(false)} open={dialogOneIsOpen}>
                    <DialogTitle>Dialog Title</DialogTitle>
                    <DialogContent>
                        Dialog body text...
                    </DialogContent>
                    <DialogActions>
                        <LinkButton onClick={() => setDialogOneIsOpen(false)}>Cancel</LinkButton>
                        <LinkButton onClick={() => setDialogOneIsOpen(false)}>Accept</LinkButton>
                    </DialogActions>
                </Dialog>

                <TertiaryButton onClick={() => setDialogTwoIsOpen(true)}>Large Dialog</TertiaryButton>
                <Dialog onClose={() => setDialogTwoIsOpen(false)} open={dialogTwoIsOpen}>
                    <DialogTitleWithCloseButton onClose={() => setDialogTwoIsOpen(false)}>Dialog Title</DialogTitleWithCloseButton>
                    <DialogContent>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        <br /><br />
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br /><br />
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </DialogContent>
                    <DialogActions>
                        <LinkButton onClick={() => setDialogTwoIsOpen(false)}>Cancel</LinkButton>
                        <LinkButton onClick={() => setDialogTwoIsOpen(false)}>Accept</LinkButton>
                    </DialogActions>
                </Dialog>
            </Section>

            <Section heading="Loader">
                <Loader title="Place your title here" description="Please, fill this text box with your loader description text." />
            </Section>

            <Section heading="No Search Results">
                <NoSearchResults title="Place your title here" description="Please fill this text box with your empty state description text." />
            </Section>

            <Section heading="Nothing Yet">
                <NothingYet title="Place your title here" description="Please fill this text box with your empty state description text." />
            </Section>

            <Section heading="No Data">
                <NoData title="Place your title here" description="Please fill this text box with your empty state description text." />
            </Section>

            <hr style={{ opacity: 0 }} />

            <Sliders />

            <Section heading="Checkboxes" style={{ maxWidth: "260px" }}>
                <div className="d-flex flex-row flex-wrap">
                    <Checkbox
                        color="default"
                        className="margin-right-2"
                        checked={checkboxValue}
                        onChange={(e) => setCheckboxValue(e.target.checked)} />
                    <FormControlLabel
                        control={<Checkbox
                            color="default"
                            checked={!checkboxValue}
                            onChange={(e) => setCheckboxValue(!e.target.checked)} />}
                        label="Default"
                    />

                    <Checkbox
                        className="margin-right-2"
                        checked={checkboxValue}
                        onChange={(e) => setCheckboxValue(e.target.checked)} />
                    <FormControlLabel
                        control={<Checkbox
                            checked={!checkboxValue}
                            onChange={(e) => setCheckboxValue(!e.target.checked)} />}
                        label="Primary"
                    />

                    <Checkbox
                        color="secondary"
                        className="margin-right-2"
                        checked={checkboxValue}
                        onChange={(e) => setCheckboxValue(e.target.checked)} />
                    <FormControlLabel
                        control={<Checkbox
                            color="secondary"
                            checked={!checkboxValue}
                            onChange={(e) => setCheckboxValue(!e.target.checked)} />}
                        label="Secondary"
                    />

                    <Checkbox
                        disabled
                        className="margin-right-2"
                        indeterminate={checkboxValue}
                        onChange={(e) => setCheckboxValue(e.target.checked)} />
                    <FormControlLabel
                        control={<Checkbox
                            disabled
                            indeterminate={!checkboxValue}
                            onChange={(e) => setCheckboxValue(!e.target.checked)} />}
                        label="Disabled"
                    />

                    <Checkbox
                        color="primary"
                        className="margin-right-2"
                        indeterminate={checkboxValue}
                        onChange={(e) => setCheckboxValue(e.target.checked)} />
                    <FormControlLabel
                        control={<Checkbox
                            color="primary"
                            indeterminate={!checkboxValue}
                            onChange={(e) => setCheckboxValue(!e.target.checked)} />}
                        label="Indeterminate"
                    />
                </div>
            </Section>

            <Section heading="Radio Buttons">
                <FormControl>
                    <RadioGroup value={radioValue} onChange={(e) => setRadioValue(e.target.value)} name="radio-group-1">
                        <FormControlLabel
                            control={<Radio color="default" />}
                            value="default"
                            label="Default" />
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            value="primary"
                            label="Primary" />
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            value="secondary"
                            label="Secondary" />
                        <FormControlLabel
                            control={<Radio disabled />}
                            value="disabled"
                            label="Disabled" />
                    </RadioGroup>
                </FormControl>
            </Section>

            <Section heading="Switch">
                <div className="d-flex flex-column">
                    <FormControlLabel
                        control={<Switch color="default" checked={switchValue} onChange={(e) => setSwitchValue(e.target.checked)} />}
                        label="Default" />
                    <FormControlLabel
                        control={<Switch color="primary" checked={switchValue} onChange={(e) => setSwitchValue(e.target.checked)} />}
                        label="Primary" />
                    <FormControlLabel
                        control={<Switch color="secondary" checked={switchValue} onChange={(e) => setSwitchValue(e.target.checked)} />}
                        label="Secondary" />
                    <FormControlLabel
                        control={<Switch disabled checked={switchValue} />}
                        label="Disabled" />
                    <FormControlLabel
                        control={<Switch color="default" checked={switchValue} icon={<BellSlash size="small" />} checkedIcon={<Bell size="small" />} onChange={(e) => setSwitchValue(e.target.checked)} />}
                        label="Default + Icon" />
                    <FormControlLabel
                        control={<Switch color="primary" checked={switchValue} icon={<BellSlash size="small" />} checkedIcon={<Bell size="small" />} onChange={(e) => setSwitchValue(e.target.checked)} />}
                        label="Primary + Icon" />
                    <FormControlLabel
                        control={<Switch color="secondary" checked={switchValue} icon={<BellSlash size="small" />} checkedIcon={<Bell size="small" />} onChange={(e) => setSwitchValue(e.target.checked)} />}
                        label="Secondary + Icon" />
                </div>
            </Section>

            <Section heading="Icons" style={{ width: "100%" }}>
                <ul className="icons">
                    {
                        [...Object.keys(icons)].map(key => {
                            const Icon = icons[key];
                            return <li key={key}>
                                <Icon size="medium" />
                                <h6>{key}</h6>
                            </li>;
                        })
                    }
                </ul>
            </Section>

            <Section heading="Autocomplete" style={{ width: "400px" }}>
                <div className="vertical-form-field">Multi-Select</div>
                <Autocomplete
                    size="small"
                    multiple={true}
                    options={autocompleteOptions}
                    fullWidth
                />

                <div className="vertical-form-field">Single-Select</div>
                <Autocomplete
                    size="small"
                    multiple={false}
                    options={autocompleteOptions}
                    fullWidth
                />

                <div className="vertical-form-field">Error State</div>
                <Autocomplete
                    size="small"
                    error={true}
                    helperText="This field has an error."
                    multiple={false}
                    options={autocompleteOptions}
                    fullWidth
                />

                <div className="vertical-form-field">With Placeholder Text</div>
                <Autocomplete
                    size="small"
                    placeholder="Please Select an Option"
                    multiple={false}
                    options={autocompleteOptions}
                    fullWidth
                />
            </Section>

            <Section heading="TextField" style={{ width: "400px" }}>
                <div className="vertical-form-field">Standard</div>
                <TextField
                    size="small"
                    fullWidth
                />

                <div className="vertical-form-field">With Placeholder Text</div>
                <TextField
                    size="small"
                    placeholder="Please Enter a Value"
                    fullWidth
                />

                <div className="vertical-form-field">Error State</div>
                <TextField
                    size="small"
                    error
                    helperText="This field has an error."
                    fullWidth
                />
            </Section>

            <Section heading="Date Picker" style={{ width: "400px" }}>
                <div className="vertical-form-field">Standard Date</div>
                <DatePicker value={dateValue} onChange={setDateValue} />
                <div className="vertical-form-field">Date with Custom Formatting</div>
                <DatePicker value={dateValue} onChange={setDateValue} inputFormat="DD MMM yyyy" mask="__ ___ ____" disableMaskedInput={true} />

                <hr />

                <div className="vertical-form-field">Standard DateTime</div>
                <DateTimePicker value={dateTimeValue} onChange={setDateTimeValue} />
                <div className="vertical-form-field">DateTime with Custom Formatting</div>
                <DateTimePicker value={dateTimeValue} onChange={setDateTimeValue} inputFormat="DD MMM yyyy @ HH:mm" mask="__ ___ ____ @ __:__" disableMaskedInput={true} />

                <hr />

                <div className="vertical-form-field">Standard Date Range</div>
                <DateRangePicker value={dateRangeValue} onChange={setDateRangeValue} />
                <div className="vertical-form-field">Date Range with Custom Formatting</div>
                <DateRangePicker value={dateRangeValue} onChange={setDateRangeValue} inputFormat="DD MMM yyyy" mask="__ ___ ____" disableMaskedInput={true} />
            </Section>

            <Section heading="Data Grid" style={{ width: "calc(50% - 16px)" }} contentClass="flush">
                <div style={{ height: "500px" }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        disableSelectionOnClick
                        checkboxSelection
                    />
                </div>
            </Section>

            <Section heading="Empty Data Grid" style={{ width: "calc(50% - 16px)" }} contentClass="flush">
                <div style={{ height: "500px" }}>
                    <DataGrid
                        rows={[]}
                        columns={columns}
                    />
                </div>
            </Section>

            <Section heading="Accordions" style={{ width: "600px" }}>
                <Accordion expandedGroup="Panel 1">
                    <AccordionGroup title="Panel 1">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                        <br />
                        <br />
                        <PrimaryButton>Action</PrimaryButton>
                    </AccordionGroup>
                    <AccordionGroup title="Panel 2">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                        <br />
                        <br />
                        <PrimaryButton>Action</PrimaryButton>
                    </AccordionGroup>
                    <AccordionGroup title="Panel 3">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                        <br />
                        <br />
                        <PrimaryButton>Action</PrimaryButton>
                    </AccordionGroup>
                </Accordion>
            </Section>

            <TabsSection />

            <VdtsSection />
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        app: state.app
    }),
    { showDetailedError, showError, showSuccess })(ThemeShowcase);

const gridColumns = [
    {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: true,
        type: "string"
    }, {
        field: "company",
        headerName: "Company",
        width: 200,
        editable: true,
        type: "string"
    }, {
        field: "email",
        headerName: "Email",
        width: 200,
        editable: true,
        type: "string"
    }, {
        field: "phone",
        headerName: "Phone No",
        width: 200,
        editable: true,
        type: "string"
    }, {
        field: "picture",
        headerName: "Picture",
        width: 100,
        renderCell: (params: GridRenderCellParams<string>) => (
            <img src={params.value} width="32" height="32" alt="" />
        ),
        cellClassName: "justify-content-center"
    }, {
        field: "age",
        headerName: "Age",
        width: 100,
        editable: true,
        type: "number"
    }, {
        field: "balance",
        headerName: "Balance",
        valueFormatter: (params: GridValueFormatterParams) => {
            const formatter = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            } as Intl.NumberFormatOptions);

            return formatter.format(params.value as number);
        },
        width: 200,
        editable: true,
        type: "number"
    }, {
        field: "isActive",
        headerName: "Is Active?",
        width: 100,
        editable: true,
        type: "boolean"
    }
];


const autocompleteOptions = [
    {
        "value": "61f3de68e894a57ab4495ef7",
        "label": "Shaw Mullins"
    },
    {
        "value": "61f3de68a0dfbd279d3c547f",
        "label": "Jordan Landry"
    },
    {
        "value": "61f3de683a07d9eb2b0ebc91",
        "label": "Marks Shepherd"
    },
    {
        "value": "61f3de685096c451edb323da",
        "label": "Johns Vance"
    },
    {
        "value": "61f3de687a86cf574bcabd08",
        "label": "Myra French"
    },
    {
        "value": "61f3de68589d1ee2f9fb65dd",
        "label": "Puckett Stein"
    },
    {
        "value": "61f3de68e98f0a2dfcfc1007",
        "label": "Young Park"
    },
    {
        "value": "61f3de6824cac0d897e11b85",
        "label": "Solis Gutierrez"
    },
    {
        "value": "61f3de68dbe30bbf3beb2f43",
        "label": "Peterson Santos"
    },
    {
        "value": "61f3de68a4eedd94ff69ce44",
        "label": "Pauline Stafford"
    },
    {
        "value": "61f3de68248cc472e35fad21",
        "label": "Callahan Warner"
    },
    {
        "value": "61f3de68f20b088fd93bf4ba",
        "label": "Snider Mcclure"
    },
    {
        "value": "61f3de68cfff85b94a2d4792",
        "label": "Mcfadden Ruiz"
    },
    {
        "value": "61f3de687ed830e0c149aa51",
        "label": "Karina Battle"
    },
    {
        "value": "61f3de687255d6ecd91bc27d",
        "label": "Pickett Burgess"
    },
    {
        "value": "61f3de68ceaeef46836c5959",
        "label": "Bianca Bonner"
    },
    {
        "value": "61f3de68e72e5b1667051d02",
        "label": "Margie Terrell"
    },
    {
        "value": "61f3de68447b9b057303d2bd",
        "label": "Frances Dodson"
    },
    {
        "value": "61f3de68d3b1f388aa8cb0f4",
        "label": "Beasley Weiss"
    }
];

const gridData = [
    {
        "id": "61f8cbcc497e3c14b2af2c6a",
        "guid": "ecd325d2-3093-4030-916f-96dc2db9aa70",
        "isActive": true,
        "balance": 1121.64,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 28,
        "eyeColor": "brown",
        "name": "Reeves Farrell",
        "company": "INEAR",
        "email": "reevesfarrell@inear.com",
        "phone": "+1 (951) 525-3541"
    },
    {
        "id": "61f8cbcc08f77ddbd47a039e",
        "guid": "30569449-336f-4ae8-9ca3-6b99aa545a88",
        "isActive": false,
        "balance": 1923.99,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 39,
        "eyeColor": "green",
        "name": "Janna Randall",
        "company": "CANDECOR",
        "email": "jannarandall@candecor.com",
        "phone": "+1 (830) 424-3030"
    },
    {
        "id": "61f8cbccf6e77dc964ddad2b",
        "guid": "a2908bf3-565c-4827-8c9e-a8441d9bb537",
        "isActive": false,
        "balance": 2634.21,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 33,
        "eyeColor": "blue",
        "name": "Ginger Bass",
        "company": "CINASTER",
        "email": "gingerbass@cinaster.com",
        "phone": "+1 (828) 512-3888"
    },
    {
        "id": "61f8cbcc816df13db2af6e7d",
        "guid": "9b8c7b8a-b4c9-4f16-8375-e41c0a120640",
        "isActive": false,
        "balance": 2187.63,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 34,
        "eyeColor": "blue",
        "name": "Bridgett Prince",
        "company": "ZENTILITY",
        "email": "bridgettprince@zentility.com",
        "phone": "+1 (829) 527-2608"
    },
    {
        "id": "61f8cbccdf8d7bacf0c3aa25",
        "guid": "d1ef60eb-13f7-48bb-8d5f-30d0d6da583b",
        "isActive": true,
        "balance": 1010.96,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 28,
        "eyeColor": "blue",
        "name": "Sylvia Rogers",
        "company": "BUZZWORKS",
        "email": "sylviarogers@buzzworks.com",
        "phone": "+1 (839) 551-2732"
    },
    {
        "id": "61f8cbccedcc23daa310c2eb",
        "guid": "43d63fce-0e57-485a-b36a-0a1fbb663774",
        "isActive": false,
        "balance": 1909.06,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 36,
        "eyeColor": "brown",
        "name": "Alyson Norton",
        "company": "UTARIAN",
        "email": "alysonnorton@utarian.com",
        "phone": "+1 (867) 588-3764"
    },
    {
        "id": "61f8cbccf28b4ebd53355bdf",
        "guid": "0f29cb7f-2341-4ef0-9ad7-57050c03976c",
        "isActive": true,
        "balance": 1989.83,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 24,
        "eyeColor": "brown",
        "name": "Amy Kerr",
        "company": "KOFFEE",
        "email": "amykerr@koffee.com",
        "phone": "+1 (936) 422-2509"
    },
    {
        "id": "61f8cbccd01b7012eb4422c9",
        "guid": "96c7ed0b-7512-4ca2-a10c-43e6f1f38394",
        "isActive": false,
        "balance": 2201.15,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 38,
        "eyeColor": "blue",
        "name": "Houston Roberson",
        "company": "MEDCOM",
        "email": "houstonroberson@medcom.com",
        "phone": "+1 (815) 479-3881"
    },
    {
        "id": "61f8cbcc527f381d33734ed3",
        "guid": "84a9eec6-bc0a-44ae-bee2-771dedad565c",
        "isActive": false,
        "balance": 2589.74,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 24,
        "eyeColor": "blue",
        "name": "Russell Kidd",
        "company": "ZIORE",
        "email": "russellkidd@ziore.com",
        "phone": "+1 (964) 551-2405"
    },
    {
        "id": "61f8cbcc54efe09222f6c9d5",
        "guid": "a8e1777e-9887-4410-8703-282fe9065203",
        "isActive": false,
        "balance": 3217.15,
        "picture": `https://picsum.photos/seed/${Math.floor(Math.random() * 1000)}/32/32`,
        "age": 40,
        "eyeColor": "blue",
        "name": "Felecia Gillespie",
        "company": "ESSENSIA",
        "email": "feleciagillespie@essensia.com",
        "phone": "+1 (838) 576-3717"
    }
];

const vdtData: any = {
    "root": {
        "title": "Total Kms",
        "class": "positive",
        "progressBarValue": 0.8768762235885358,
        "progressBarText": "987.6k",
        "id": 1,
        "fields": [
            {
                "name": "November 2020",
                "value": "9.14m km"
            },
            {
                "name": "December 2020",
                "value": "10.1m km",
                "class": "positive"
            },
            {
                "name": "Delta",
                "value": "987.6k km (10.8%)",
                "class": "positive"
            }
        ],
        "nodes": [
            {
                "title": "# of Vehicles Active During the Period",
                "class": "positive",
                "progressBarValue": 1,
                "progressBarText": "1.13m",
                "id": 2,
                "fields": [
                    {
                        "name": "November 2020",
                        "value": "349"
                    },
                    {
                        "name": "December 2020",
                        "value": "392",
                        "class": "positive"
                    },
                    {
                        "name": "Delta",
                        "value": "43 (12.3%)",
                        "class": "positive"
                    }
                ],
                "nodes": [],
                "tooltip": "Input",
                "collapsed": true,
                "previousProgressBarValue": 1
            },
            {
                "title": "Kms Travelled During the Period",
                "class": "negative",
                "progressBarValue": 0.12312377641146423,
                "progressBarText": "-138.7k",
                "id": 3,
                "fields": [
                    {
                        "name": "November 2020",
                        "value": "26.2k"
                    },
                    {
                        "name": "December 2020",
                        "value": "25.8k",
                        "class": "negative"
                    },
                    {
                        "name": "Delta",
                        "value": "-353.77 (-1.35%)",
                        "class": "negative"
                    }
                ],
                "nodes": [
                    {
                        "title": "Travelling Hours",
                        "class": "negative",
                        "progressBarValue": 0.2679839479415101,
                        "progressBarText": "-301.8k",
                        "id": 4,
                        "fields": [
                            {
                                "name": "November 2020",
                                "value": "432.55"
                            },
                            {
                                "name": "December 2020",
                                "value": "419.83",
                                "class": "negative"
                            },
                            {
                                "name": "Delta",
                                "value": "-12.72 (-2.94%)",
                                "class": "negative"
                            }
                        ],
                        "nodes": [
                            {
                                "title": "Operating Hours",
                                "class": "positive",
                                "progressBarValue": 0.47597488471794686,
                                "progressBarText": "536.1k",
                                "id": 5,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "552.34"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "574.92",
                                        "class": "positive"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "22.58 (4.09%)",
                                        "class": "positive"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "Calendar Hours",
                                        "class": "positive",
                                        "progressBarValue": 0.505819096600816,
                                        "progressBarText": "569.7k",
                                        "id": 6,
                                        "fields": [
                                            {
                                                "name": "November 2020",
                                                "value": "720"
                                            },
                                            {
                                                "name": "December 2020",
                                                "value": "744",
                                                "class": "positive"
                                            },
                                            {
                                                "name": "Delta",
                                                "value": "24 (3.33%)",
                                                "class": "positive"
                                            }
                                        ],
                                        "nodes": [
                                            {
                                                "title": "Hours in Day",
                                                "class": "positive",
                                                "progressBarValue": 0,
                                                "progressBarText": "0",
                                                "id": 7,
                                                "fields": [
                                                    {
                                                        "name": "November 2020",
                                                        "value": "24"
                                                    },
                                                    {
                                                        "name": "December 2020",
                                                        "value": "24",
                                                        "class": "positive"
                                                    },
                                                    {
                                                        "name": "Delta",
                                                        "value": "0 (0.00%)",
                                                        "class": "positive"
                                                    }
                                                ],
                                                "nodes": [],
                                                "tooltip": "Input",
                                                "collapsed": true,
                                                "previousProgressBarValue": 0
                                            },
                                            {
                                                "title": "Days in Analysis",
                                                "class": "positive",
                                                "progressBarValue": 0.505819096600816,
                                                "progressBarText": "569.7k",
                                                "id": 8,
                                                "fields": [
                                                    {
                                                        "name": "November 2020",
                                                        "value": "30"
                                                    },
                                                    {
                                                        "name": "December 2020",
                                                        "value": "31",
                                                        "class": "positive"
                                                    },
                                                    {
                                                        "name": "Delta",
                                                        "value": "1 (3.33%)",
                                                        "class": "positive"
                                                    }
                                                ],
                                                "nodes": [],
                                                "tooltip": "Input",
                                                "collapsed": true,
                                                "previousProgressBarValue": 0.505819096600816
                                            }
                                        ],
                                        "tooltip": null,
                                        "collapsed": false,
                                        "previousProgressBarValue": 0.505819096600816
                                    },
                                    {
                                        "title": "Home Base Hours",
                                        "class": "negative",
                                        "progressBarValue": 0.02984421188286911,
                                        "progressBarText": "-33.6k",
                                        "id": 9,
                                        "fields": [
                                            {
                                                "name": "November 2020",
                                                "value": "167.66"
                                            },
                                            {
                                                "name": "December 2020",
                                                "value": "169.08",
                                                "class": "negative"
                                            },
                                            {
                                                "name": "Delta",
                                                "value": "1.42 (0.84%)",
                                                "class": "negative"
                                            }
                                        ],
                                        "nodes": [
                                            {
                                                "title": "HomeBase Chart",
                                                "type": "chart",
                                                "hideTitle": true,
                                                "nodes": [],
                                                "model": {
                                                    "data": [
                                                        {
                                                            "marker": {
                                                                "color": "rgb(55,119,188)",
                                                                "line": {
                                                                    "color": "black",
                                                                    "width": 3
                                                                }
                                                            },
                                                            "name": "November 2020",
                                                            "x": [
                                                                "0 - 15 Min",
                                                                "15 - 30 Min",
                                                                "30 Min - 1 Hr",
                                                                "1 - 2 Hrs",
                                                                "2 - 4 Hrs",
                                                                "4 - 8 Hrs",
                                                                "> 8 Hrs"
                                                            ],
                                                            "y": [
                                                                16891,
                                                                1332,
                                                                2229,
                                                                3217,
                                                                2862,
                                                                3046,
                                                                1720
                                                            ],
                                                            "type": "bar"
                                                        },
                                                        {
                                                            "marker": {
                                                                "color": "rgb(141,198,63)",
                                                                "line": {
                                                                    "color": "black",
                                                                    "width": 3
                                                                }
                                                            },
                                                            "name": "December 2020",
                                                            "x": [
                                                                "0 - 15 Min",
                                                                "15 - 30 Min",
                                                                "30 Min - 1 Hr",
                                                                "1 - 2 Hrs",
                                                                "2 - 4 Hrs",
                                                                "4 - 8 Hrs",
                                                                "> 8 Hrs"
                                                            ],
                                                            "y": [
                                                                17489,
                                                                1445,
                                                                2502,
                                                                3646,
                                                                3503,
                                                                3109,
                                                                2012
                                                            ],
                                                            "type": "bar"
                                                        }
                                                    ],
                                                    "layout": {
                                                        "template": {
                                                            "data": {
                                                                "barpolar": [
                                                                    {
                                                                        "marker": {
                                                                            "line": {
                                                                                "color": "white",
                                                                                "width": 0.5
                                                                            }
                                                                        },
                                                                        "type": "barpolar"
                                                                    }
                                                                ],
                                                                "bar": [
                                                                    {
                                                                        "error_x": {
                                                                            "color": "#2a3f5f"
                                                                        },
                                                                        "error_y": {
                                                                            "color": "#2a3f5f"
                                                                        },
                                                                        "marker": {
                                                                            "line": {
                                                                                "color": "white",
                                                                                "width": 0.5
                                                                            }
                                                                        },
                                                                        "type": "bar"
                                                                    }
                                                                ],
                                                                "carpet": [
                                                                    {
                                                                        "aaxis": {
                                                                            "endlinecolor": "#2a3f5f",
                                                                            "gridcolor": "#C8D4E3",
                                                                            "linecolor": "#C8D4E3",
                                                                            "minorgridcolor": "#C8D4E3",
                                                                            "startlinecolor": "#2a3f5f"
                                                                        },
                                                                        "baxis": {
                                                                            "endlinecolor": "#2a3f5f",
                                                                            "gridcolor": "#C8D4E3",
                                                                            "linecolor": "#C8D4E3",
                                                                            "minorgridcolor": "#C8D4E3",
                                                                            "startlinecolor": "#2a3f5f"
                                                                        },
                                                                        "type": "carpet"
                                                                    }
                                                                ],
                                                                "choropleth": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "type": "choropleth"
                                                                    }
                                                                ],
                                                                "contourcarpet": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "type": "contourcarpet"
                                                                    }
                                                                ],
                                                                "contour": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "colorscale": [
                                                                            [
                                                                                0,
                                                                                "#0d0887"
                                                                            ],
                                                                            [
                                                                                0.1111111111111111,
                                                                                "#46039f"
                                                                            ],
                                                                            [
                                                                                0.2222222222222222,
                                                                                "#7201a8"
                                                                            ],
                                                                            [
                                                                                0.3333333333333333,
                                                                                "#9c179e"
                                                                            ],
                                                                            [
                                                                                0.4444444444444444,
                                                                                "#bd3786"
                                                                            ],
                                                                            [
                                                                                0.5555555555555556,
                                                                                "#d8576b"
                                                                            ],
                                                                            [
                                                                                0.6666666666666666,
                                                                                "#ed7953"
                                                                            ],
                                                                            [
                                                                                0.7777777777777778,
                                                                                "#fb9f3a"
                                                                            ],
                                                                            [
                                                                                0.8888888888888888,
                                                                                "#fdca26"
                                                                            ],
                                                                            [
                                                                                1,
                                                                                "#f0f921"
                                                                            ]
                                                                        ],
                                                                        "type": "contour"
                                                                    }
                                                                ],
                                                                "heatmapgl": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "colorscale": [
                                                                            [
                                                                                0,
                                                                                "#0d0887"
                                                                            ],
                                                                            [
                                                                                0.1111111111111111,
                                                                                "#46039f"
                                                                            ],
                                                                            [
                                                                                0.2222222222222222,
                                                                                "#7201a8"
                                                                            ],
                                                                            [
                                                                                0.3333333333333333,
                                                                                "#9c179e"
                                                                            ],
                                                                            [
                                                                                0.4444444444444444,
                                                                                "#bd3786"
                                                                            ],
                                                                            [
                                                                                0.5555555555555556,
                                                                                "#d8576b"
                                                                            ],
                                                                            [
                                                                                0.6666666666666666,
                                                                                "#ed7953"
                                                                            ],
                                                                            [
                                                                                0.7777777777777778,
                                                                                "#fb9f3a"
                                                                            ],
                                                                            [
                                                                                0.8888888888888888,
                                                                                "#fdca26"
                                                                            ],
                                                                            [
                                                                                1,
                                                                                "#f0f921"
                                                                            ]
                                                                        ],
                                                                        "type": "heatmapgl"
                                                                    }
                                                                ],
                                                                "heatmap": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "colorscale": [
                                                                            [
                                                                                0,
                                                                                "#0d0887"
                                                                            ],
                                                                            [
                                                                                0.1111111111111111,
                                                                                "#46039f"
                                                                            ],
                                                                            [
                                                                                0.2222222222222222,
                                                                                "#7201a8"
                                                                            ],
                                                                            [
                                                                                0.3333333333333333,
                                                                                "#9c179e"
                                                                            ],
                                                                            [
                                                                                0.4444444444444444,
                                                                                "#bd3786"
                                                                            ],
                                                                            [
                                                                                0.5555555555555556,
                                                                                "#d8576b"
                                                                            ],
                                                                            [
                                                                                0.6666666666666666,
                                                                                "#ed7953"
                                                                            ],
                                                                            [
                                                                                0.7777777777777778,
                                                                                "#fb9f3a"
                                                                            ],
                                                                            [
                                                                                0.8888888888888888,
                                                                                "#fdca26"
                                                                            ],
                                                                            [
                                                                                1,
                                                                                "#f0f921"
                                                                            ]
                                                                        ],
                                                                        "type": "heatmap"
                                                                    }
                                                                ],
                                                                "histogram2dcontour": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "colorscale": [
                                                                            [
                                                                                0,
                                                                                "#0d0887"
                                                                            ],
                                                                            [
                                                                                0.1111111111111111,
                                                                                "#46039f"
                                                                            ],
                                                                            [
                                                                                0.2222222222222222,
                                                                                "#7201a8"
                                                                            ],
                                                                            [
                                                                                0.3333333333333333,
                                                                                "#9c179e"
                                                                            ],
                                                                            [
                                                                                0.4444444444444444,
                                                                                "#bd3786"
                                                                            ],
                                                                            [
                                                                                0.5555555555555556,
                                                                                "#d8576b"
                                                                            ],
                                                                            [
                                                                                0.6666666666666666,
                                                                                "#ed7953"
                                                                            ],
                                                                            [
                                                                                0.7777777777777778,
                                                                                "#fb9f3a"
                                                                            ],
                                                                            [
                                                                                0.8888888888888888,
                                                                                "#fdca26"
                                                                            ],
                                                                            [
                                                                                1,
                                                                                "#f0f921"
                                                                            ]
                                                                        ],
                                                                        "type": "histogram2dcontour"
                                                                    }
                                                                ],
                                                                "histogram2d": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "colorscale": [
                                                                            [
                                                                                0,
                                                                                "#0d0887"
                                                                            ],
                                                                            [
                                                                                0.1111111111111111,
                                                                                "#46039f"
                                                                            ],
                                                                            [
                                                                                0.2222222222222222,
                                                                                "#7201a8"
                                                                            ],
                                                                            [
                                                                                0.3333333333333333,
                                                                                "#9c179e"
                                                                            ],
                                                                            [
                                                                                0.4444444444444444,
                                                                                "#bd3786"
                                                                            ],
                                                                            [
                                                                                0.5555555555555556,
                                                                                "#d8576b"
                                                                            ],
                                                                            [
                                                                                0.6666666666666666,
                                                                                "#ed7953"
                                                                            ],
                                                                            [
                                                                                0.7777777777777778,
                                                                                "#fb9f3a"
                                                                            ],
                                                                            [
                                                                                0.8888888888888888,
                                                                                "#fdca26"
                                                                            ],
                                                                            [
                                                                                1,
                                                                                "#f0f921"
                                                                            ]
                                                                        ],
                                                                        "type": "histogram2d"
                                                                    }
                                                                ],
                                                                "histogram": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "histogram"
                                                                    }
                                                                ],
                                                                "mesh3d": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "type": "mesh3d"
                                                                    }
                                                                ],
                                                                "parcoords": [
                                                                    {
                                                                        "line": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "parcoords"
                                                                    }
                                                                ],
                                                                "pie": [
                                                                    {
                                                                        "automargin": true,
                                                                        "type": "pie"
                                                                    }
                                                                ],
                                                                "scatter3d": [
                                                                    {
                                                                        "line": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scatter3d"
                                                                    }
                                                                ],
                                                                "scattercarpet": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scattercarpet"
                                                                    }
                                                                ],
                                                                "scattergeo": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scattergeo"
                                                                    }
                                                                ],
                                                                "scattergl": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scattergl"
                                                                    }
                                                                ],
                                                                "scattermapbox": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scattermapbox"
                                                                    }
                                                                ],
                                                                "scatterpolargl": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scatterpolargl"
                                                                    }
                                                                ],
                                                                "scatterpolar": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scatterpolar"
                                                                    }
                                                                ],
                                                                "scatter": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scatter"
                                                                    }
                                                                ],
                                                                "scatterternary": [
                                                                    {
                                                                        "marker": {
                                                                            "colorbar": {
                                                                                "outlinewidth": 0,
                                                                                "ticks": ""
                                                                            }
                                                                        },
                                                                        "type": "scatterternary"
                                                                    }
                                                                ],
                                                                "surface": [
                                                                    {
                                                                        "colorbar": {
                                                                            "outlinewidth": 0,
                                                                            "ticks": ""
                                                                        },
                                                                        "colorscale": [
                                                                            [
                                                                                0,
                                                                                "#0d0887"
                                                                            ],
                                                                            [
                                                                                0.1111111111111111,
                                                                                "#46039f"
                                                                            ],
                                                                            [
                                                                                0.2222222222222222,
                                                                                "#7201a8"
                                                                            ],
                                                                            [
                                                                                0.3333333333333333,
                                                                                "#9c179e"
                                                                            ],
                                                                            [
                                                                                0.4444444444444444,
                                                                                "#bd3786"
                                                                            ],
                                                                            [
                                                                                0.5555555555555556,
                                                                                "#d8576b"
                                                                            ],
                                                                            [
                                                                                0.6666666666666666,
                                                                                "#ed7953"
                                                                            ],
                                                                            [
                                                                                0.7777777777777778,
                                                                                "#fb9f3a"
                                                                            ],
                                                                            [
                                                                                0.8888888888888888,
                                                                                "#fdca26"
                                                                            ],
                                                                            [
                                                                                1,
                                                                                "#f0f921"
                                                                            ]
                                                                        ],
                                                                        "type": "surface"
                                                                    }
                                                                ],
                                                                "table": [
                                                                    {
                                                                        "cells": {
                                                                            "fill": {
                                                                                "color": "#EBF0F8"
                                                                            },
                                                                            "line": {
                                                                                "color": "white"
                                                                            }
                                                                        },
                                                                        "header": {
                                                                            "fill": {
                                                                                "color": "#C8D4E3"
                                                                            },
                                                                            "line": {
                                                                                "color": "white"
                                                                            }
                                                                        },
                                                                        "type": "table"
                                                                    }
                                                                ]
                                                            },
                                                            "layout": {
                                                                "annotationdefaults": {
                                                                    "arrowcolor": "#2a3f5f",
                                                                    "arrowhead": 0,
                                                                    "arrowwidth": 1
                                                                },
                                                                "coloraxis": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "colorscale": {
                                                                    "diverging": [
                                                                        [
                                                                            0,
                                                                            "#8e0152"
                                                                        ],
                                                                        [
                                                                            0.1,
                                                                            "#c51b7d"
                                                                        ],
                                                                        [
                                                                            0.2,
                                                                            "#de77ae"
                                                                        ],
                                                                        [
                                                                            0.3,
                                                                            "#f1b6da"
                                                                        ],
                                                                        [
                                                                            0.4,
                                                                            "#fde0ef"
                                                                        ],
                                                                        [
                                                                            0.5,
                                                                            "#f7f7f7"
                                                                        ],
                                                                        [
                                                                            0.6,
                                                                            "#e6f5d0"
                                                                        ],
                                                                        [
                                                                            0.7,
                                                                            "#b8e186"
                                                                        ],
                                                                        [
                                                                            0.8,
                                                                            "#7fbc41"
                                                                        ],
                                                                        [
                                                                            0.9,
                                                                            "#4d9221"
                                                                        ],
                                                                        [
                                                                            1,
                                                                            "#276419"
                                                                        ]
                                                                    ],
                                                                    "sequential": [
                                                                        [
                                                                            0,
                                                                            "#0d0887"
                                                                        ],
                                                                        [
                                                                            0.1111111111111111,
                                                                            "#46039f"
                                                                        ],
                                                                        [
                                                                            0.2222222222222222,
                                                                            "#7201a8"
                                                                        ],
                                                                        [
                                                                            0.3333333333333333,
                                                                            "#9c179e"
                                                                        ],
                                                                        [
                                                                            0.4444444444444444,
                                                                            "#bd3786"
                                                                        ],
                                                                        [
                                                                            0.5555555555555556,
                                                                            "#d8576b"
                                                                        ],
                                                                        [
                                                                            0.6666666666666666,
                                                                            "#ed7953"
                                                                        ],
                                                                        [
                                                                            0.7777777777777778,
                                                                            "#fb9f3a"
                                                                        ],
                                                                        [
                                                                            0.8888888888888888,
                                                                            "#fdca26"
                                                                        ],
                                                                        [
                                                                            1,
                                                                            "#f0f921"
                                                                        ]
                                                                    ],
                                                                    "sequentialminus": [
                                                                        [
                                                                            0,
                                                                            "#0d0887"
                                                                        ],
                                                                        [
                                                                            0.1111111111111111,
                                                                            "#46039f"
                                                                        ],
                                                                        [
                                                                            0.2222222222222222,
                                                                            "#7201a8"
                                                                        ],
                                                                        [
                                                                            0.3333333333333333,
                                                                            "#9c179e"
                                                                        ],
                                                                        [
                                                                            0.4444444444444444,
                                                                            "#bd3786"
                                                                        ],
                                                                        [
                                                                            0.5555555555555556,
                                                                            "#d8576b"
                                                                        ],
                                                                        [
                                                                            0.6666666666666666,
                                                                            "#ed7953"
                                                                        ],
                                                                        [
                                                                            0.7777777777777778,
                                                                            "#fb9f3a"
                                                                        ],
                                                                        [
                                                                            0.8888888888888888,
                                                                            "#fdca26"
                                                                        ],
                                                                        [
                                                                            1,
                                                                            "#f0f921"
                                                                        ]
                                                                    ]
                                                                },
                                                                "colorway": [
                                                                    "#636efa",
                                                                    "#EF553B",
                                                                    "#00cc96",
                                                                    "#ab63fa",
                                                                    "#FFA15A",
                                                                    "#19d3f3",
                                                                    "#FF6692",
                                                                    "#B6E880",
                                                                    "#FF97FF",
                                                                    "#FECB52"
                                                                ],
                                                                "font": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "geo": {
                                                                    "bgcolor": "white",
                                                                    "lakecolor": "white",
                                                                    "landcolor": "white",
                                                                    "showlakes": true,
                                                                    "showland": true,
                                                                    "subunitcolor": "#C8D4E3"
                                                                },
                                                                "hoverlabel": {
                                                                    "align": "left"
                                                                },
                                                                "hovermode": "closest",
                                                                "mapbox": {
                                                                    "style": "light"
                                                                },
                                                                "paper_bgcolor": "white",
                                                                "plot_bgcolor": "white",
                                                                "polar": {
                                                                    "angularaxis": {
                                                                        "gridcolor": "#EBF0F8",
                                                                        "linecolor": "#EBF0F8",
                                                                        "ticks": ""
                                                                    },
                                                                    "bgcolor": "white",
                                                                    "radialaxis": {
                                                                        "gridcolor": "#EBF0F8",
                                                                        "linecolor": "#EBF0F8",
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "scene": {
                                                                    "xaxis": {
                                                                        "backgroundcolor": "white",
                                                                        "gridcolor": "#DFE8F3",
                                                                        "gridwidth": 2,
                                                                        "linecolor": "#EBF0F8",
                                                                        "showbackground": true,
                                                                        "ticks": "",
                                                                        "zerolinecolor": "#EBF0F8"
                                                                    },
                                                                    "yaxis": {
                                                                        "backgroundcolor": "white",
                                                                        "gridcolor": "#DFE8F3",
                                                                        "gridwidth": 2,
                                                                        "linecolor": "#EBF0F8",
                                                                        "showbackground": true,
                                                                        "ticks": "",
                                                                        "zerolinecolor": "#EBF0F8"
                                                                    },
                                                                    "zaxis": {
                                                                        "backgroundcolor": "white",
                                                                        "gridcolor": "#DFE8F3",
                                                                        "gridwidth": 2,
                                                                        "linecolor": "#EBF0F8",
                                                                        "showbackground": true,
                                                                        "ticks": "",
                                                                        "zerolinecolor": "#EBF0F8"
                                                                    }
                                                                },
                                                                "shapedefaults": {
                                                                    "line": {
                                                                        "color": "#2a3f5f"
                                                                    }
                                                                },
                                                                "ternary": {
                                                                    "aaxis": {
                                                                        "gridcolor": "#DFE8F3",
                                                                        "linecolor": "#A2B1C6",
                                                                        "ticks": ""
                                                                    },
                                                                    "baxis": {
                                                                        "gridcolor": "#DFE8F3",
                                                                        "linecolor": "#A2B1C6",
                                                                        "ticks": ""
                                                                    },
                                                                    "bgcolor": "white",
                                                                    "caxis": {
                                                                        "gridcolor": "#DFE8F3",
                                                                        "linecolor": "#A2B1C6",
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "title": {
                                                                    "x": 0.05
                                                                },
                                                                "xaxis": {
                                                                    "automargin": true,
                                                                    "gridcolor": "#EBF0F8",
                                                                    "linecolor": "#EBF0F8",
                                                                    "ticks": "",
                                                                    "title": {
                                                                        "standoff": 15
                                                                    },
                                                                    "zerolinecolor": "#EBF0F8",
                                                                    "zerolinewidth": 2
                                                                },
                                                                "yaxis": {
                                                                    "automargin": true,
                                                                    "gridcolor": "#EBF0F8",
                                                                    "linecolor": "#EBF0F8",
                                                                    "ticks": "",
                                                                    "title": {
                                                                        "standoff": 15
                                                                    },
                                                                    "zerolinecolor": "#EBF0F8",
                                                                    "zerolinewidth": 2
                                                                }
                                                            }
                                                        },
                                                        "margin": {
                                                            "l": 20,
                                                            "r": 20,
                                                            "t": 20,
                                                            "b": 20
                                                        },
                                                        "font": {
                                                            "size": 20
                                                        },
                                                        "barmode": "group",
                                                        "showlegend": false
                                                    }
                                                },
                                                "id": "chart_HomeBase"
                                            }
                                        ],
                                        "tooltip": "Input",
                                        "collapsed": false,
                                        "previousProgressBarValue": 0.02984421188286911
                                    }
                                ],
                                "tooltip": null,
                                "collapsed": false,
                                "previousProgressBarValue": 0.47597488471794686
                            },
                            {
                                "title": "On-Road Refuelling (Hrs)",
                                "class": "negative",
                                "progressBarValue": 0.07473028237828877,
                                "progressBarText": "-84.2k",
                                "id": 10,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "15.02"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "18.57",
                                        "class": "negative"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "3.55 (23.6%)",
                                        "class": "negative"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "OnRoadRefuelling Chart",
                                        "type": "chart",
                                        "hideTitle": true,
                                        "nodes": [],
                                        "model": {
                                            "data": [
                                                {
                                                    "marker": {
                                                        "color": "rgb(55,119,188)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "November 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        3464,
                                                        1590,
                                                        1822,
                                                        1108,
                                                        261,
                                                        102,
                                                        16
                                                    ],
                                                    "type": "bar"
                                                },
                                                {
                                                    "marker": {
                                                        "color": "rgb(141,198,63)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "December 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        4364,
                                                        1874,
                                                        2009,
                                                        1331,
                                                        458,
                                                        166,
                                                        53
                                                    ],
                                                    "type": "bar"
                                                }
                                            ],
                                            "layout": {
                                                "template": {
                                                    "data": {
                                                        "barpolar": [
                                                            {
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "barpolar"
                                                            }
                                                        ],
                                                        "bar": [
                                                            {
                                                                "error_x": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "error_y": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "bar"
                                                            }
                                                        ],
                                                        "carpet": [
                                                            {
                                                                "aaxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "baxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "type": "carpet"
                                                            }
                                                        ],
                                                        "choropleth": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "choropleth"
                                                            }
                                                        ],
                                                        "contourcarpet": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "contourcarpet"
                                                            }
                                                        ],
                                                        "contour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "contour"
                                                            }
                                                        ],
                                                        "heatmapgl": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmapgl"
                                                            }
                                                        ],
                                                        "heatmap": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmap"
                                                            }
                                                        ],
                                                        "histogram2dcontour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2dcontour"
                                                            }
                                                        ],
                                                        "histogram2d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2d"
                                                            }
                                                        ],
                                                        "histogram": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "histogram"
                                                            }
                                                        ],
                                                        "mesh3d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "mesh3d"
                                                            }
                                                        ],
                                                        "parcoords": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "parcoords"
                                                            }
                                                        ],
                                                        "pie": [
                                                            {
                                                                "automargin": true,
                                                                "type": "pie"
                                                            }
                                                        ],
                                                        "scatter3d": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter3d"
                                                            }
                                                        ],
                                                        "scattercarpet": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattercarpet"
                                                            }
                                                        ],
                                                        "scattergeo": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergeo"
                                                            }
                                                        ],
                                                        "scattergl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergl"
                                                            }
                                                        ],
                                                        "scattermapbox": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattermapbox"
                                                            }
                                                        ],
                                                        "scatterpolargl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolargl"
                                                            }
                                                        ],
                                                        "scatterpolar": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolar"
                                                            }
                                                        ],
                                                        "scatter": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter"
                                                            }
                                                        ],
                                                        "scatterternary": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterternary"
                                                            }
                                                        ],
                                                        "surface": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "surface"
                                                            }
                                                        ],
                                                        "table": [
                                                            {
                                                                "cells": {
                                                                    "fill": {
                                                                        "color": "#EBF0F8"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "header": {
                                                                    "fill": {
                                                                        "color": "#C8D4E3"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "type": "table"
                                                            }
                                                        ]
                                                    },
                                                    "layout": {
                                                        "annotationdefaults": {
                                                            "arrowcolor": "#2a3f5f",
                                                            "arrowhead": 0,
                                                            "arrowwidth": 1
                                                        },
                                                        "coloraxis": {
                                                            "colorbar": {
                                                                "outlinewidth": 0,
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "colorscale": {
                                                            "diverging": [
                                                                [
                                                                    0,
                                                                    "#8e0152"
                                                                ],
                                                                [
                                                                    0.1,
                                                                    "#c51b7d"
                                                                ],
                                                                [
                                                                    0.2,
                                                                    "#de77ae"
                                                                ],
                                                                [
                                                                    0.3,
                                                                    "#f1b6da"
                                                                ],
                                                                [
                                                                    0.4,
                                                                    "#fde0ef"
                                                                ],
                                                                [
                                                                    0.5,
                                                                    "#f7f7f7"
                                                                ],
                                                                [
                                                                    0.6,
                                                                    "#e6f5d0"
                                                                ],
                                                                [
                                                                    0.7,
                                                                    "#b8e186"
                                                                ],
                                                                [
                                                                    0.8,
                                                                    "#7fbc41"
                                                                ],
                                                                [
                                                                    0.9,
                                                                    "#4d9221"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#276419"
                                                                ]
                                                            ],
                                                            "sequential": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ],
                                                            "sequentialminus": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ]
                                                        },
                                                        "colorway": [
                                                            "#636efa",
                                                            "#EF553B",
                                                            "#00cc96",
                                                            "#ab63fa",
                                                            "#FFA15A",
                                                            "#19d3f3",
                                                            "#FF6692",
                                                            "#B6E880",
                                                            "#FF97FF",
                                                            "#FECB52"
                                                        ],
                                                        "font": {
                                                            "color": "#2a3f5f"
                                                        },
                                                        "geo": {
                                                            "bgcolor": "white",
                                                            "lakecolor": "white",
                                                            "landcolor": "white",
                                                            "showlakes": true,
                                                            "showland": true,
                                                            "subunitcolor": "#C8D4E3"
                                                        },
                                                        "hoverlabel": {
                                                            "align": "left"
                                                        },
                                                        "hovermode": "closest",
                                                        "mapbox": {
                                                            "style": "light"
                                                        },
                                                        "paper_bgcolor": "white",
                                                        "plot_bgcolor": "white",
                                                        "polar": {
                                                            "angularaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "radialaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "scene": {
                                                            "xaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "yaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "zaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            }
                                                        },
                                                        "shapedefaults": {
                                                            "line": {
                                                                "color": "#2a3f5f"
                                                            }
                                                        },
                                                        "ternary": {
                                                            "aaxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "baxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "caxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "title": {
                                                            "x": 0.05
                                                        },
                                                        "xaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        },
                                                        "yaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        }
                                                    }
                                                },
                                                "margin": {
                                                    "l": 20,
                                                    "r": 20,
                                                    "t": 20,
                                                    "b": 20
                                                },
                                                "font": {
                                                    "size": 20
                                                },
                                                "barmode": "group",
                                                "showlegend": false
                                            }
                                        },
                                        "id": "chart_OnRoadRefuelling"
                                    }
                                ],
                                "tooltip": "Input",
                                "collapsed": false,
                                "previousProgressBarValue": 0.07473028237828877
                            },
                            {
                                "title": "Border Control Stops (Hrs)",
                                "class": "positive",
                                "progressBarValue": 0.007075924418145779,
                                "progressBarText": "7.97k",
                                "id": 11,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "2.27"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "1.93",
                                        "class": "positive"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "-0.34 (-14.8%)",
                                        "class": "positive"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "BorderControl Chart",
                                        "type": "chart",
                                        "hideTitle": true,
                                        "nodes": [],
                                        "model": {
                                            "data": [
                                                {
                                                    "marker": {
                                                        "color": "rgb(55,119,188)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "November 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        139,
                                                        104,
                                                        55,
                                                        83,
                                                        57,
                                                        50,
                                                        13
                                                    ],
                                                    "type": "bar"
                                                },
                                                {
                                                    "marker": {
                                                        "color": "rgb(141,198,63)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "December 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        153,
                                                        109,
                                                        69,
                                                        86,
                                                        77,
                                                        39,
                                                        8
                                                    ],
                                                    "type": "bar"
                                                }
                                            ],
                                            "layout": {
                                                "template": {
                                                    "data": {
                                                        "barpolar": [
                                                            {
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "barpolar"
                                                            }
                                                        ],
                                                        "bar": [
                                                            {
                                                                "error_x": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "error_y": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "bar"
                                                            }
                                                        ],
                                                        "carpet": [
                                                            {
                                                                "aaxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "baxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "type": "carpet"
                                                            }
                                                        ],
                                                        "choropleth": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "choropleth"
                                                            }
                                                        ],
                                                        "contourcarpet": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "contourcarpet"
                                                            }
                                                        ],
                                                        "contour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "contour"
                                                            }
                                                        ],
                                                        "heatmapgl": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmapgl"
                                                            }
                                                        ],
                                                        "heatmap": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmap"
                                                            }
                                                        ],
                                                        "histogram2dcontour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2dcontour"
                                                            }
                                                        ],
                                                        "histogram2d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2d"
                                                            }
                                                        ],
                                                        "histogram": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "histogram"
                                                            }
                                                        ],
                                                        "mesh3d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "mesh3d"
                                                            }
                                                        ],
                                                        "parcoords": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "parcoords"
                                                            }
                                                        ],
                                                        "pie": [
                                                            {
                                                                "automargin": true,
                                                                "type": "pie"
                                                            }
                                                        ],
                                                        "scatter3d": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter3d"
                                                            }
                                                        ],
                                                        "scattercarpet": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattercarpet"
                                                            }
                                                        ],
                                                        "scattergeo": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergeo"
                                                            }
                                                        ],
                                                        "scattergl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergl"
                                                            }
                                                        ],
                                                        "scattermapbox": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattermapbox"
                                                            }
                                                        ],
                                                        "scatterpolargl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolargl"
                                                            }
                                                        ],
                                                        "scatterpolar": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolar"
                                                            }
                                                        ],
                                                        "scatter": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter"
                                                            }
                                                        ],
                                                        "scatterternary": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterternary"
                                                            }
                                                        ],
                                                        "surface": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "surface"
                                                            }
                                                        ],
                                                        "table": [
                                                            {
                                                                "cells": {
                                                                    "fill": {
                                                                        "color": "#EBF0F8"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "header": {
                                                                    "fill": {
                                                                        "color": "#C8D4E3"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "type": "table"
                                                            }
                                                        ]
                                                    },
                                                    "layout": {
                                                        "annotationdefaults": {
                                                            "arrowcolor": "#2a3f5f",
                                                            "arrowhead": 0,
                                                            "arrowwidth": 1
                                                        },
                                                        "coloraxis": {
                                                            "colorbar": {
                                                                "outlinewidth": 0,
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "colorscale": {
                                                            "diverging": [
                                                                [
                                                                    0,
                                                                    "#8e0152"
                                                                ],
                                                                [
                                                                    0.1,
                                                                    "#c51b7d"
                                                                ],
                                                                [
                                                                    0.2,
                                                                    "#de77ae"
                                                                ],
                                                                [
                                                                    0.3,
                                                                    "#f1b6da"
                                                                ],
                                                                [
                                                                    0.4,
                                                                    "#fde0ef"
                                                                ],
                                                                [
                                                                    0.5,
                                                                    "#f7f7f7"
                                                                ],
                                                                [
                                                                    0.6,
                                                                    "#e6f5d0"
                                                                ],
                                                                [
                                                                    0.7,
                                                                    "#b8e186"
                                                                ],
                                                                [
                                                                    0.8,
                                                                    "#7fbc41"
                                                                ],
                                                                [
                                                                    0.9,
                                                                    "#4d9221"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#276419"
                                                                ]
                                                            ],
                                                            "sequential": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ],
                                                            "sequentialminus": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ]
                                                        },
                                                        "colorway": [
                                                            "#636efa",
                                                            "#EF553B",
                                                            "#00cc96",
                                                            "#ab63fa",
                                                            "#FFA15A",
                                                            "#19d3f3",
                                                            "#FF6692",
                                                            "#B6E880",
                                                            "#FF97FF",
                                                            "#FECB52"
                                                        ],
                                                        "font": {
                                                            "color": "#2a3f5f"
                                                        },
                                                        "geo": {
                                                            "bgcolor": "white",
                                                            "lakecolor": "white",
                                                            "landcolor": "white",
                                                            "showlakes": true,
                                                            "showland": true,
                                                            "subunitcolor": "#C8D4E3"
                                                        },
                                                        "hoverlabel": {
                                                            "align": "left"
                                                        },
                                                        "hovermode": "closest",
                                                        "mapbox": {
                                                            "style": "light"
                                                        },
                                                        "paper_bgcolor": "white",
                                                        "plot_bgcolor": "white",
                                                        "polar": {
                                                            "angularaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "radialaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "scene": {
                                                            "xaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "yaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "zaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            }
                                                        },
                                                        "shapedefaults": {
                                                            "line": {
                                                                "color": "#2a3f5f"
                                                            }
                                                        },
                                                        "ternary": {
                                                            "aaxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "baxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "caxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "title": {
                                                            "x": 0.05
                                                        },
                                                        "xaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        },
                                                        "yaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        }
                                                    }
                                                },
                                                "margin": {
                                                    "l": 20,
                                                    "r": 20,
                                                    "t": 20,
                                                    "b": 20
                                                },
                                                "font": {
                                                    "size": 20
                                                },
                                                "barmode": "group",
                                                "showlegend": false
                                            }
                                        },
                                        "id": "chart_BorderControl"
                                    }
                                ],
                                "tooltip": "Input",
                                "collapsed": false,
                                "previousProgressBarValue": 0.007075924418145779
                            },
                            {
                                "title": "Customer Stops (Hrs)",
                                "class": "positive",
                                "progressBarValue": 0.07732271062734464,
                                "progressBarText": "87.1k",
                                "id": 12,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "83.95"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "80.28",
                                        "class": "positive"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "-3.67 (-4.37%)",
                                        "class": "positive"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "Customer Chart",
                                        "type": "chart",
                                        "hideTitle": true,
                                        "nodes": [],
                                        "model": {
                                            "data": [
                                                {
                                                    "marker": {
                                                        "color": "rgb(55,119,188)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "November 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        3302,
                                                        2550,
                                                        6913,
                                                        6404,
                                                        1965,
                                                        774,
                                                        293
                                                    ],
                                                    "type": "bar"
                                                },
                                                {
                                                    "marker": {
                                                        "color": "rgb(141,198,63)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "December 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        3889,
                                                        2901,
                                                        7114,
                                                        6909,
                                                        2268,
                                                        856,
                                                        287
                                                    ],
                                                    "type": "bar"
                                                }
                                            ],
                                            "layout": {
                                                "template": {
                                                    "data": {
                                                        "barpolar": [
                                                            {
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "barpolar"
                                                            }
                                                        ],
                                                        "bar": [
                                                            {
                                                                "error_x": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "error_y": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "bar"
                                                            }
                                                        ],
                                                        "carpet": [
                                                            {
                                                                "aaxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "baxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "type": "carpet"
                                                            }
                                                        ],
                                                        "choropleth": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "choropleth"
                                                            }
                                                        ],
                                                        "contourcarpet": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "contourcarpet"
                                                            }
                                                        ],
                                                        "contour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "contour"
                                                            }
                                                        ],
                                                        "heatmapgl": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmapgl"
                                                            }
                                                        ],
                                                        "heatmap": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmap"
                                                            }
                                                        ],
                                                        "histogram2dcontour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2dcontour"
                                                            }
                                                        ],
                                                        "histogram2d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2d"
                                                            }
                                                        ],
                                                        "histogram": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "histogram"
                                                            }
                                                        ],
                                                        "mesh3d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "mesh3d"
                                                            }
                                                        ],
                                                        "parcoords": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "parcoords"
                                                            }
                                                        ],
                                                        "pie": [
                                                            {
                                                                "automargin": true,
                                                                "type": "pie"
                                                            }
                                                        ],
                                                        "scatter3d": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter3d"
                                                            }
                                                        ],
                                                        "scattercarpet": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattercarpet"
                                                            }
                                                        ],
                                                        "scattergeo": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergeo"
                                                            }
                                                        ],
                                                        "scattergl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergl"
                                                            }
                                                        ],
                                                        "scattermapbox": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattermapbox"
                                                            }
                                                        ],
                                                        "scatterpolargl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolargl"
                                                            }
                                                        ],
                                                        "scatterpolar": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolar"
                                                            }
                                                        ],
                                                        "scatter": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter"
                                                            }
                                                        ],
                                                        "scatterternary": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterternary"
                                                            }
                                                        ],
                                                        "surface": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "surface"
                                                            }
                                                        ],
                                                        "table": [
                                                            {
                                                                "cells": {
                                                                    "fill": {
                                                                        "color": "#EBF0F8"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "header": {
                                                                    "fill": {
                                                                        "color": "#C8D4E3"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "type": "table"
                                                            }
                                                        ]
                                                    },
                                                    "layout": {
                                                        "annotationdefaults": {
                                                            "arrowcolor": "#2a3f5f",
                                                            "arrowhead": 0,
                                                            "arrowwidth": 1
                                                        },
                                                        "coloraxis": {
                                                            "colorbar": {
                                                                "outlinewidth": 0,
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "colorscale": {
                                                            "diverging": [
                                                                [
                                                                    0,
                                                                    "#8e0152"
                                                                ],
                                                                [
                                                                    0.1,
                                                                    "#c51b7d"
                                                                ],
                                                                [
                                                                    0.2,
                                                                    "#de77ae"
                                                                ],
                                                                [
                                                                    0.3,
                                                                    "#f1b6da"
                                                                ],
                                                                [
                                                                    0.4,
                                                                    "#fde0ef"
                                                                ],
                                                                [
                                                                    0.5,
                                                                    "#f7f7f7"
                                                                ],
                                                                [
                                                                    0.6,
                                                                    "#e6f5d0"
                                                                ],
                                                                [
                                                                    0.7,
                                                                    "#b8e186"
                                                                ],
                                                                [
                                                                    0.8,
                                                                    "#7fbc41"
                                                                ],
                                                                [
                                                                    0.9,
                                                                    "#4d9221"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#276419"
                                                                ]
                                                            ],
                                                            "sequential": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ],
                                                            "sequentialminus": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ]
                                                        },
                                                        "colorway": [
                                                            "#636efa",
                                                            "#EF553B",
                                                            "#00cc96",
                                                            "#ab63fa",
                                                            "#FFA15A",
                                                            "#19d3f3",
                                                            "#FF6692",
                                                            "#B6E880",
                                                            "#FF97FF",
                                                            "#FECB52"
                                                        ],
                                                        "font": {
                                                            "color": "#2a3f5f"
                                                        },
                                                        "geo": {
                                                            "bgcolor": "white",
                                                            "lakecolor": "white",
                                                            "landcolor": "white",
                                                            "showlakes": true,
                                                            "showland": true,
                                                            "subunitcolor": "#C8D4E3"
                                                        },
                                                        "hoverlabel": {
                                                            "align": "left"
                                                        },
                                                        "hovermode": "closest",
                                                        "mapbox": {
                                                            "style": "light"
                                                        },
                                                        "paper_bgcolor": "white",
                                                        "plot_bgcolor": "white",
                                                        "polar": {
                                                            "angularaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "radialaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "scene": {
                                                            "xaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "yaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "zaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            }
                                                        },
                                                        "shapedefaults": {
                                                            "line": {
                                                                "color": "#2a3f5f"
                                                            }
                                                        },
                                                        "ternary": {
                                                            "aaxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "baxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "caxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "title": {
                                                            "x": 0.05
                                                        },
                                                        "xaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        },
                                                        "yaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        }
                                                    }
                                                },
                                                "margin": {
                                                    "l": 20,
                                                    "r": 20,
                                                    "t": 20,
                                                    "b": 20
                                                },
                                                "font": {
                                                    "size": 20
                                                },
                                                "barmode": "group",
                                                "showlegend": false
                                            }
                                        },
                                        "id": "chart_Customer"
                                    }
                                ],
                                "tooltip": "Input",
                                "collapsed": false,
                                "previousProgressBarValue": 0.07732271062734464
                            },
                            {
                                "title": "Resting Point Stops (Hrs)",
                                "class": "negative",
                                "progressBarValue": 0.01808067221436239,
                                "progressBarText": "-20.4k",
                                "id": 13,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "4.10"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "4.96",
                                        "class": "negative"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "0.86 (20.9%)",
                                        "class": "negative"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "RestingPoint Chart",
                                        "type": "chart",
                                        "hideTitle": true,
                                        "nodes": [],
                                        "model": {
                                            "data": [
                                                {
                                                    "marker": {
                                                        "color": "rgb(55,119,188)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "November 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        1697,
                                                        398,
                                                        217,
                                                        92,
                                                        99,
                                                        91,
                                                        5
                                                    ],
                                                    "type": "bar"
                                                },
                                                {
                                                    "marker": {
                                                        "color": "rgb(141,198,63)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "December 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        1992,
                                                        417,
                                                        212,
                                                        115,
                                                        161,
                                                        117,
                                                        19
                                                    ],
                                                    "type": "bar"
                                                }
                                            ],
                                            "layout": {
                                                "template": {
                                                    "data": {
                                                        "barpolar": [
                                                            {
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "barpolar"
                                                            }
                                                        ],
                                                        "bar": [
                                                            {
                                                                "error_x": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "error_y": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "bar"
                                                            }
                                                        ],
                                                        "carpet": [
                                                            {
                                                                "aaxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "baxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "type": "carpet"
                                                            }
                                                        ],
                                                        "choropleth": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "choropleth"
                                                            }
                                                        ],
                                                        "contourcarpet": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "contourcarpet"
                                                            }
                                                        ],
                                                        "contour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "contour"
                                                            }
                                                        ],
                                                        "heatmapgl": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmapgl"
                                                            }
                                                        ],
                                                        "heatmap": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmap"
                                                            }
                                                        ],
                                                        "histogram2dcontour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2dcontour"
                                                            }
                                                        ],
                                                        "histogram2d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2d"
                                                            }
                                                        ],
                                                        "histogram": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "histogram"
                                                            }
                                                        ],
                                                        "mesh3d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "mesh3d"
                                                            }
                                                        ],
                                                        "parcoords": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "parcoords"
                                                            }
                                                        ],
                                                        "pie": [
                                                            {
                                                                "automargin": true,
                                                                "type": "pie"
                                                            }
                                                        ],
                                                        "scatter3d": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter3d"
                                                            }
                                                        ],
                                                        "scattercarpet": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattercarpet"
                                                            }
                                                        ],
                                                        "scattergeo": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergeo"
                                                            }
                                                        ],
                                                        "scattergl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergl"
                                                            }
                                                        ],
                                                        "scattermapbox": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattermapbox"
                                                            }
                                                        ],
                                                        "scatterpolargl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolargl"
                                                            }
                                                        ],
                                                        "scatterpolar": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolar"
                                                            }
                                                        ],
                                                        "scatter": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter"
                                                            }
                                                        ],
                                                        "scatterternary": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterternary"
                                                            }
                                                        ],
                                                        "surface": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "surface"
                                                            }
                                                        ],
                                                        "table": [
                                                            {
                                                                "cells": {
                                                                    "fill": {
                                                                        "color": "#EBF0F8"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "header": {
                                                                    "fill": {
                                                                        "color": "#C8D4E3"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "type": "table"
                                                            }
                                                        ]
                                                    },
                                                    "layout": {
                                                        "annotationdefaults": {
                                                            "arrowcolor": "#2a3f5f",
                                                            "arrowhead": 0,
                                                            "arrowwidth": 1
                                                        },
                                                        "coloraxis": {
                                                            "colorbar": {
                                                                "outlinewidth": 0,
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "colorscale": {
                                                            "diverging": [
                                                                [
                                                                    0,
                                                                    "#8e0152"
                                                                ],
                                                                [
                                                                    0.1,
                                                                    "#c51b7d"
                                                                ],
                                                                [
                                                                    0.2,
                                                                    "#de77ae"
                                                                ],
                                                                [
                                                                    0.3,
                                                                    "#f1b6da"
                                                                ],
                                                                [
                                                                    0.4,
                                                                    "#fde0ef"
                                                                ],
                                                                [
                                                                    0.5,
                                                                    "#f7f7f7"
                                                                ],
                                                                [
                                                                    0.6,
                                                                    "#e6f5d0"
                                                                ],
                                                                [
                                                                    0.7,
                                                                    "#b8e186"
                                                                ],
                                                                [
                                                                    0.8,
                                                                    "#7fbc41"
                                                                ],
                                                                [
                                                                    0.9,
                                                                    "#4d9221"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#276419"
                                                                ]
                                                            ],
                                                            "sequential": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ],
                                                            "sequentialminus": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ]
                                                        },
                                                        "colorway": [
                                                            "#636efa",
                                                            "#EF553B",
                                                            "#00cc96",
                                                            "#ab63fa",
                                                            "#FFA15A",
                                                            "#19d3f3",
                                                            "#FF6692",
                                                            "#B6E880",
                                                            "#FF97FF",
                                                            "#FECB52"
                                                        ],
                                                        "font": {
                                                            "color": "#2a3f5f"
                                                        },
                                                        "geo": {
                                                            "bgcolor": "white",
                                                            "lakecolor": "white",
                                                            "landcolor": "white",
                                                            "showlakes": true,
                                                            "showland": true,
                                                            "subunitcolor": "#C8D4E3"
                                                        },
                                                        "hoverlabel": {
                                                            "align": "left"
                                                        },
                                                        "hovermode": "closest",
                                                        "mapbox": {
                                                            "style": "light"
                                                        },
                                                        "paper_bgcolor": "white",
                                                        "plot_bgcolor": "white",
                                                        "polar": {
                                                            "angularaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "radialaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "scene": {
                                                            "xaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "yaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "zaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            }
                                                        },
                                                        "shapedefaults": {
                                                            "line": {
                                                                "color": "#2a3f5f"
                                                            }
                                                        },
                                                        "ternary": {
                                                            "aaxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "baxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "caxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "title": {
                                                            "x": 0.05
                                                        },
                                                        "xaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        },
                                                        "yaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        }
                                                    }
                                                },
                                                "margin": {
                                                    "l": 20,
                                                    "r": 20,
                                                    "t": 20,
                                                    "b": 20
                                                },
                                                "font": {
                                                    "size": 20
                                                },
                                                "barmode": "group",
                                                "showlegend": false
                                            }
                                        },
                                        "id": "chart_RestingPoint"
                                    }
                                ],
                                "tooltip": "Input",
                                "collapsed": false,
                                "previousProgressBarValue": 0.01808067221436239
                            },
                            {
                                "title": "Toll Gate Stops (Hrs)",
                                "class": "negative",
                                "progressBarValue": 0.023484316547550184,
                                "progressBarText": "-26.5k",
                                "id": 14,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "0.54"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "1.65",
                                        "class": "negative"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "1.11 (207%)",
                                        "class": "negative"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "TollGate Chart",
                                        "type": "chart",
                                        "hideTitle": true,
                                        "nodes": [],
                                        "model": {
                                            "data": [
                                                {
                                                    "marker": {
                                                        "color": "rgb(55,119,188)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "November 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        908,
                                                        37,
                                                        17,
                                                        17,
                                                        19,
                                                        4,
                                                        0
                                                    ],
                                                    "type": "bar"
                                                },
                                                {
                                                    "marker": {
                                                        "color": "rgb(141,198,63)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "December 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        1023,
                                                        53,
                                                        21,
                                                        33,
                                                        35,
                                                        14,
                                                        14
                                                    ],
                                                    "type": "bar"
                                                }
                                            ],
                                            "layout": {
                                                "template": {
                                                    "data": {
                                                        "barpolar": [
                                                            {
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "barpolar"
                                                            }
                                                        ],
                                                        "bar": [
                                                            {
                                                                "error_x": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "error_y": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "bar"
                                                            }
                                                        ],
                                                        "carpet": [
                                                            {
                                                                "aaxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "baxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "type": "carpet"
                                                            }
                                                        ],
                                                        "choropleth": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "choropleth"
                                                            }
                                                        ],
                                                        "contourcarpet": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "contourcarpet"
                                                            }
                                                        ],
                                                        "contour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "contour"
                                                            }
                                                        ],
                                                        "heatmapgl": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmapgl"
                                                            }
                                                        ],
                                                        "heatmap": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmap"
                                                            }
                                                        ],
                                                        "histogram2dcontour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2dcontour"
                                                            }
                                                        ],
                                                        "histogram2d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2d"
                                                            }
                                                        ],
                                                        "histogram": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "histogram"
                                                            }
                                                        ],
                                                        "mesh3d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "mesh3d"
                                                            }
                                                        ],
                                                        "parcoords": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "parcoords"
                                                            }
                                                        ],
                                                        "pie": [
                                                            {
                                                                "automargin": true,
                                                                "type": "pie"
                                                            }
                                                        ],
                                                        "scatter3d": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter3d"
                                                            }
                                                        ],
                                                        "scattercarpet": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattercarpet"
                                                            }
                                                        ],
                                                        "scattergeo": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergeo"
                                                            }
                                                        ],
                                                        "scattergl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergl"
                                                            }
                                                        ],
                                                        "scattermapbox": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattermapbox"
                                                            }
                                                        ],
                                                        "scatterpolargl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolargl"
                                                            }
                                                        ],
                                                        "scatterpolar": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolar"
                                                            }
                                                        ],
                                                        "scatter": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter"
                                                            }
                                                        ],
                                                        "scatterternary": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterternary"
                                                            }
                                                        ],
                                                        "surface": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "surface"
                                                            }
                                                        ],
                                                        "table": [
                                                            {
                                                                "cells": {
                                                                    "fill": {
                                                                        "color": "#EBF0F8"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "header": {
                                                                    "fill": {
                                                                        "color": "#C8D4E3"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "type": "table"
                                                            }
                                                        ]
                                                    },
                                                    "layout": {
                                                        "annotationdefaults": {
                                                            "arrowcolor": "#2a3f5f",
                                                            "arrowhead": 0,
                                                            "arrowwidth": 1
                                                        },
                                                        "coloraxis": {
                                                            "colorbar": {
                                                                "outlinewidth": 0,
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "colorscale": {
                                                            "diverging": [
                                                                [
                                                                    0,
                                                                    "#8e0152"
                                                                ],
                                                                [
                                                                    0.1,
                                                                    "#c51b7d"
                                                                ],
                                                                [
                                                                    0.2,
                                                                    "#de77ae"
                                                                ],
                                                                [
                                                                    0.3,
                                                                    "#f1b6da"
                                                                ],
                                                                [
                                                                    0.4,
                                                                    "#fde0ef"
                                                                ],
                                                                [
                                                                    0.5,
                                                                    "#f7f7f7"
                                                                ],
                                                                [
                                                                    0.6,
                                                                    "#e6f5d0"
                                                                ],
                                                                [
                                                                    0.7,
                                                                    "#b8e186"
                                                                ],
                                                                [
                                                                    0.8,
                                                                    "#7fbc41"
                                                                ],
                                                                [
                                                                    0.9,
                                                                    "#4d9221"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#276419"
                                                                ]
                                                            ],
                                                            "sequential": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ],
                                                            "sequentialminus": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ]
                                                        },
                                                        "colorway": [
                                                            "#636efa",
                                                            "#EF553B",
                                                            "#00cc96",
                                                            "#ab63fa",
                                                            "#FFA15A",
                                                            "#19d3f3",
                                                            "#FF6692",
                                                            "#B6E880",
                                                            "#FF97FF",
                                                            "#FECB52"
                                                        ],
                                                        "font": {
                                                            "color": "#2a3f5f"
                                                        },
                                                        "geo": {
                                                            "bgcolor": "white",
                                                            "lakecolor": "white",
                                                            "landcolor": "white",
                                                            "showlakes": true,
                                                            "showland": true,
                                                            "subunitcolor": "#C8D4E3"
                                                        },
                                                        "hoverlabel": {
                                                            "align": "left"
                                                        },
                                                        "hovermode": "closest",
                                                        "mapbox": {
                                                            "style": "light"
                                                        },
                                                        "paper_bgcolor": "white",
                                                        "plot_bgcolor": "white",
                                                        "polar": {
                                                            "angularaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "radialaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "scene": {
                                                            "xaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "yaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "zaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            }
                                                        },
                                                        "shapedefaults": {
                                                            "line": {
                                                                "color": "#2a3f5f"
                                                            }
                                                        },
                                                        "ternary": {
                                                            "aaxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "baxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "caxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "title": {
                                                            "x": 0.05
                                                        },
                                                        "xaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        },
                                                        "yaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        }
                                                    }
                                                },
                                                "margin": {
                                                    "l": 20,
                                                    "r": 20,
                                                    "t": 20,
                                                    "b": 20
                                                },
                                                "font": {
                                                    "size": 20
                                                },
                                                "barmode": "group",
                                                "showlegend": false
                                            }
                                        },
                                        "id": "chart_TollGate"
                                    }
                                ],
                                "tooltip": "Input",
                                "collapsed": false,
                                "previousProgressBarValue": 0.023484316547550184
                            },
                            {
                                "title": "Unaccounted",
                                "class": "negative",
                                "progressBarValue": 0.712062196564746,
                                "progressBarText": "-802.0k",
                                "id": 15,
                                "fields": [
                                    {
                                        "name": "November 2020",
                                        "value": "13.91"
                                    },
                                    {
                                        "name": "December 2020",
                                        "value": "47.69",
                                        "class": "negative"
                                    },
                                    {
                                        "name": "Delta",
                                        "value": "33.79 (243%)",
                                        "class": "negative"
                                    }
                                ],
                                "nodes": [
                                    {
                                        "title": "Unknown Chart",
                                        "type": "chart",
                                        "hideTitle": true,
                                        "nodes": [],
                                        "model": {
                                            "data": [
                                                {
                                                    "marker": {
                                                        "color": "rgb(55,119,188)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "November 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        13119,
                                                        4164,
                                                        7845,
                                                        9633,
                                                        5408,
                                                        2719,
                                                        2252
                                                    ],
                                                    "type": "bar"
                                                },
                                                {
                                                    "marker": {
                                                        "color": "rgb(141,198,63)",
                                                        "line": {
                                                            "color": "black",
                                                            "width": 3
                                                        }
                                                    },
                                                    "name": "December 2020",
                                                    "x": [
                                                        "0 - 15 Min",
                                                        "15 - 30 Min",
                                                        "30 Min - 1 Hr",
                                                        "1 - 2 Hrs",
                                                        "2 - 4 Hrs",
                                                        "4 - 8 Hrs",
                                                        "> 8 Hrs"
                                                    ],
                                                    "y": [
                                                        15008,
                                                        4665,
                                                        8278,
                                                        9954,
                                                        5985,
                                                        2948,
                                                        2380
                                                    ],
                                                    "type": "bar"
                                                }
                                            ],
                                            "layout": {
                                                "template": {
                                                    "data": {
                                                        "barpolar": [
                                                            {
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "barpolar"
                                                            }
                                                        ],
                                                        "bar": [
                                                            {
                                                                "error_x": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "error_y": {
                                                                    "color": "#2a3f5f"
                                                                },
                                                                "marker": {
                                                                    "line": {
                                                                        "color": "white",
                                                                        "width": 0.5
                                                                    }
                                                                },
                                                                "type": "bar"
                                                            }
                                                        ],
                                                        "carpet": [
                                                            {
                                                                "aaxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "baxis": {
                                                                    "endlinecolor": "#2a3f5f",
                                                                    "gridcolor": "#C8D4E3",
                                                                    "linecolor": "#C8D4E3",
                                                                    "minorgridcolor": "#C8D4E3",
                                                                    "startlinecolor": "#2a3f5f"
                                                                },
                                                                "type": "carpet"
                                                            }
                                                        ],
                                                        "choropleth": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "choropleth"
                                                            }
                                                        ],
                                                        "contourcarpet": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "contourcarpet"
                                                            }
                                                        ],
                                                        "contour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "contour"
                                                            }
                                                        ],
                                                        "heatmapgl": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmapgl"
                                                            }
                                                        ],
                                                        "heatmap": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "heatmap"
                                                            }
                                                        ],
                                                        "histogram2dcontour": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2dcontour"
                                                            }
                                                        ],
                                                        "histogram2d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "histogram2d"
                                                            }
                                                        ],
                                                        "histogram": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "histogram"
                                                            }
                                                        ],
                                                        "mesh3d": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "type": "mesh3d"
                                                            }
                                                        ],
                                                        "parcoords": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "parcoords"
                                                            }
                                                        ],
                                                        "pie": [
                                                            {
                                                                "automargin": true,
                                                                "type": "pie"
                                                            }
                                                        ],
                                                        "scatter3d": [
                                                            {
                                                                "line": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter3d"
                                                            }
                                                        ],
                                                        "scattercarpet": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattercarpet"
                                                            }
                                                        ],
                                                        "scattergeo": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergeo"
                                                            }
                                                        ],
                                                        "scattergl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattergl"
                                                            }
                                                        ],
                                                        "scattermapbox": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scattermapbox"
                                                            }
                                                        ],
                                                        "scatterpolargl": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolargl"
                                                            }
                                                        ],
                                                        "scatterpolar": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterpolar"
                                                            }
                                                        ],
                                                        "scatter": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatter"
                                                            }
                                                        ],
                                                        "scatterternary": [
                                                            {
                                                                "marker": {
                                                                    "colorbar": {
                                                                        "outlinewidth": 0,
                                                                        "ticks": ""
                                                                    }
                                                                },
                                                                "type": "scatterternary"
                                                            }
                                                        ],
                                                        "surface": [
                                                            {
                                                                "colorbar": {
                                                                    "outlinewidth": 0,
                                                                    "ticks": ""
                                                                },
                                                                "colorscale": [
                                                                    [
                                                                        0,
                                                                        "#0d0887"
                                                                    ],
                                                                    [
                                                                        0.1111111111111111,
                                                                        "#46039f"
                                                                    ],
                                                                    [
                                                                        0.2222222222222222,
                                                                        "#7201a8"
                                                                    ],
                                                                    [
                                                                        0.3333333333333333,
                                                                        "#9c179e"
                                                                    ],
                                                                    [
                                                                        0.4444444444444444,
                                                                        "#bd3786"
                                                                    ],
                                                                    [
                                                                        0.5555555555555556,
                                                                        "#d8576b"
                                                                    ],
                                                                    [
                                                                        0.6666666666666666,
                                                                        "#ed7953"
                                                                    ],
                                                                    [
                                                                        0.7777777777777778,
                                                                        "#fb9f3a"
                                                                    ],
                                                                    [
                                                                        0.8888888888888888,
                                                                        "#fdca26"
                                                                    ],
                                                                    [
                                                                        1,
                                                                        "#f0f921"
                                                                    ]
                                                                ],
                                                                "type": "surface"
                                                            }
                                                        ],
                                                        "table": [
                                                            {
                                                                "cells": {
                                                                    "fill": {
                                                                        "color": "#EBF0F8"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "header": {
                                                                    "fill": {
                                                                        "color": "#C8D4E3"
                                                                    },
                                                                    "line": {
                                                                        "color": "white"
                                                                    }
                                                                },
                                                                "type": "table"
                                                            }
                                                        ]
                                                    },
                                                    "layout": {
                                                        "annotationdefaults": {
                                                            "arrowcolor": "#2a3f5f",
                                                            "arrowhead": 0,
                                                            "arrowwidth": 1
                                                        },
                                                        "coloraxis": {
                                                            "colorbar": {
                                                                "outlinewidth": 0,
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "colorscale": {
                                                            "diverging": [
                                                                [
                                                                    0,
                                                                    "#8e0152"
                                                                ],
                                                                [
                                                                    0.1,
                                                                    "#c51b7d"
                                                                ],
                                                                [
                                                                    0.2,
                                                                    "#de77ae"
                                                                ],
                                                                [
                                                                    0.3,
                                                                    "#f1b6da"
                                                                ],
                                                                [
                                                                    0.4,
                                                                    "#fde0ef"
                                                                ],
                                                                [
                                                                    0.5,
                                                                    "#f7f7f7"
                                                                ],
                                                                [
                                                                    0.6,
                                                                    "#e6f5d0"
                                                                ],
                                                                [
                                                                    0.7,
                                                                    "#b8e186"
                                                                ],
                                                                [
                                                                    0.8,
                                                                    "#7fbc41"
                                                                ],
                                                                [
                                                                    0.9,
                                                                    "#4d9221"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#276419"
                                                                ]
                                                            ],
                                                            "sequential": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ],
                                                            "sequentialminus": [
                                                                [
                                                                    0,
                                                                    "#0d0887"
                                                                ],
                                                                [
                                                                    0.1111111111111111,
                                                                    "#46039f"
                                                                ],
                                                                [
                                                                    0.2222222222222222,
                                                                    "#7201a8"
                                                                ],
                                                                [
                                                                    0.3333333333333333,
                                                                    "#9c179e"
                                                                ],
                                                                [
                                                                    0.4444444444444444,
                                                                    "#bd3786"
                                                                ],
                                                                [
                                                                    0.5555555555555556,
                                                                    "#d8576b"
                                                                ],
                                                                [
                                                                    0.6666666666666666,
                                                                    "#ed7953"
                                                                ],
                                                                [
                                                                    0.7777777777777778,
                                                                    "#fb9f3a"
                                                                ],
                                                                [
                                                                    0.8888888888888888,
                                                                    "#fdca26"
                                                                ],
                                                                [
                                                                    1,
                                                                    "#f0f921"
                                                                ]
                                                            ]
                                                        },
                                                        "colorway": [
                                                            "#636efa",
                                                            "#EF553B",
                                                            "#00cc96",
                                                            "#ab63fa",
                                                            "#FFA15A",
                                                            "#19d3f3",
                                                            "#FF6692",
                                                            "#B6E880",
                                                            "#FF97FF",
                                                            "#FECB52"
                                                        ],
                                                        "font": {
                                                            "color": "#2a3f5f"
                                                        },
                                                        "geo": {
                                                            "bgcolor": "white",
                                                            "lakecolor": "white",
                                                            "landcolor": "white",
                                                            "showlakes": true,
                                                            "showland": true,
                                                            "subunitcolor": "#C8D4E3"
                                                        },
                                                        "hoverlabel": {
                                                            "align": "left"
                                                        },
                                                        "hovermode": "closest",
                                                        "mapbox": {
                                                            "style": "light"
                                                        },
                                                        "paper_bgcolor": "white",
                                                        "plot_bgcolor": "white",
                                                        "polar": {
                                                            "angularaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "radialaxis": {
                                                                "gridcolor": "#EBF0F8",
                                                                "linecolor": "#EBF0F8",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "scene": {
                                                            "xaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "yaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            },
                                                            "zaxis": {
                                                                "backgroundcolor": "white",
                                                                "gridcolor": "#DFE8F3",
                                                                "gridwidth": 2,
                                                                "linecolor": "#EBF0F8",
                                                                "showbackground": true,
                                                                "ticks": "",
                                                                "zerolinecolor": "#EBF0F8"
                                                            }
                                                        },
                                                        "shapedefaults": {
                                                            "line": {
                                                                "color": "#2a3f5f"
                                                            }
                                                        },
                                                        "ternary": {
                                                            "aaxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "baxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            },
                                                            "bgcolor": "white",
                                                            "caxis": {
                                                                "gridcolor": "#DFE8F3",
                                                                "linecolor": "#A2B1C6",
                                                                "ticks": ""
                                                            }
                                                        },
                                                        "title": {
                                                            "x": 0.05
                                                        },
                                                        "xaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        },
                                                        "yaxis": {
                                                            "automargin": true,
                                                            "gridcolor": "#EBF0F8",
                                                            "linecolor": "#EBF0F8",
                                                            "ticks": "",
                                                            "title": {
                                                                "standoff": 15
                                                            },
                                                            "zerolinecolor": "#EBF0F8",
                                                            "zerolinewidth": 2
                                                        }
                                                    }
                                                },
                                                "margin": {
                                                    "l": 20,
                                                    "r": 20,
                                                    "t": 20,
                                                    "b": 20
                                                },
                                                "font": {
                                                    "size": 20
                                                },
                                                "barmode": "group",
                                                "showlegend": false
                                            }
                                        },
                                        "id": "chart_Unknown"
                                    }
                                ],
                                "tooltip": "Input",
                                "collapsed": false,
                                "previousProgressBarValue": 0.712062196564746
                            }
                        ],
                        "tooltip": null,
                        "collapsed": false,
                        "previousProgressBarValue": 0.2679839479415101
                    },
                    {
                        "title": "Avg Speed",
                        "class": "positive",
                        "progressBarValue": 0.14486017153004588,
                        "progressBarText": "163.2k",
                        "id": 16,
                        "fields": [
                            {
                                "name": "November 2020",
                                "value": "60.56"
                            },
                            {
                                "name": "December 2020",
                                "value": "61.55",
                                "class": "positive"
                            },
                            {
                                "name": "Delta",
                                "value": "0.99 (1.64%)",
                                "class": "positive"
                            }
                        ],
                        "nodes": [],
                        "tooltip": "Input",
                        "collapsed": true,
                        "previousProgressBarValue": 0.14486017153004588
                    }
                ],
                "tooltip": null,
                "collapsed": false,
                "previousProgressBarValue": 0.12312377641146423
            }
        ],
        "tooltip": null,
        "collapsed": false,
        "previousProgressBarValue": 0.8768762235885358
    },
    "nodeHeight": 100,
    "nodeWidth": 160,
    "nodeTitleHeight": 28,
    "siblingSpacing": 16,
    "parentSpacing": 30,
    "neighbourSpacingFactor": 0.1
};