import React from 'react';
import { IconButton, DialogTitle, DialogTitleProps } from "@mui/material";
import { X } from "./icons";

export interface ExtendedDialogTitleProps extends DialogTitleProps {
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitleWithCloseButton = (props: ExtendedDialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle {...other}>
            {children}
            {onClose ?
                (
                    <IconButton aria-label="close" onClick={onClose}>
                        <X size="small" />
                    </IconButton>
                ) : null}
        </DialogTitle>
    );
};

export default DialogTitleWithCloseButton;