import React, { useContext } from "react";
import { ClientAppSettings as ClientAppSettings1 } from "../api-client";
import { ClientAppSettings as ClientAppSettings2 } from "../core-api-client";

export type ClientAppSettings =
    ClientAppSettings1 &
    ClientAppSettings2 &
    {
        domainName: string;
    };

export const SettingsContext: React.Context<ClientAppSettings> = React.createContext(null);
export const useSettings: () => ClientAppSettings = () => useContext(SettingsContext);
export const SettingsProvider = ({
    settings,
    children
}: { settings: ClientAppSettings, children: any }) => (
    <SettingsContext.Provider value={settings}>
        {children}
    </SettingsContext.Provider >
);