import * as MuiComponents from "@mui/material";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import DateRangePicker from "./DateRangePicker";
import DateTimePicker from "./DateTimePicker";
import { Radio, RadioGroup } from "./Radio";
import Switch from "./Switch";
import TextField from "./TextField";
import { DocumentedComponent } from "./DocumentedComponent";

const StyledComponents = { Checkbox, DatePicker, DateRangePicker, DateTimePicker, Radio, RadioGroup, Switch, TextField };
const Mui = { ...MuiComponents, ...StyledComponents }; // Override some base components with their styled counterparts

(Mui as DocumentedComponent).metadata = {
    description: "Namespace for accessing all the base Material UI Components.  Only used in exceptional circumstances where the wrapped components are undesirable.",
    attributes: [],
    namespaceMembers: Object.keys(Mui)
};

export { Mui };
