import Split from 'react-split';
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

(Split as DocumentedComponent).metadata = {
    description: 
        `The Split component serves as a container for other components. It adds a splitter between two or more content areas, allowing the user to resize them.  Can be used for horizontal- or vertical splits.

This is a third-party component that is used directly.  Only the CSS for this component was altered slightly in order to match the rest of the application.  See [the component's documentation](https://github.com/nathancahill/split/tree/master/packages/splitjs#documentation) for more details.`,
    isSelfClosing: false,
    attributes: [
        { name: `sizes`, type: `object`, template: "sizes={[25, 75]}", description: "The initial sizes for the child components in percent values.  E.g., `[25, 75]` would indicate that the first component should take up 25% of the width/height (depending on layout direction) and the second component should take up the remaining 75% of the width/height." },
        { name: `minSize`, type: `object`, template: "minSize={[200, 200]}", description: "The minimum sizes for the components, in pixels.  The user would not be able to decrease the size of the respective components below these values using the resize handler.  Optional - defaults to `100` for all children." },
        { name: `direction`, type: `string`, options: ["horizontal", "vertical"], description: "The direction to split in.  Can be `\"horizontal\"` or `\"vertical\"`.  Optional - defaults to `\"horizontal\"`." },
    ]
};

export default Split;