import * as React from "react";
import "./Loader.scss";
import backdrop from "../../assets/nothing-yet-backdrop.png";

interface NothingYetProps {
    title?: string;
    description?: string;
}

const NothingYet: React.FunctionComponent<NothingYetProps> = (props: NothingYetProps) => {
    const { title, description } = props;

    return (
        <div className="data-placeholders">
            <img src={backdrop} alt="" />
            <h3 className="spaced-bottom">{title}</h3>
            <span>{description}</span>
        </div>
    );
};

export default NothingYet;