import * as React from "react";
import { TreeItem, TreeItemProps } from "@mui/lab";

interface ConditionalTreeItemProps extends TreeItemProps {
    show: boolean;
}

function ConditionalTreeItem(props: ConditionalTreeItemProps) {
    const { show, children, ...rest } = props;

    return show ? <TreeItem {...rest}>{children}</TreeItem> : children as JSX.Element;
}

export { ConditionalTreeItem };