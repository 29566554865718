import React from "react";
import "./Roles.scss";
import { Card, Container, Button, NavBar, ConfirmationDialog, DataGrid, Icon, CardHeader } from "../../shared/components";
import * as coreApi from "../../shared/core-api-client";
import * as _ from "lodash";
import EditRoleDialog from "./EditRole";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { useSettings } from "../../shared/providers/SettingsProvider";
import RequiresTags from "../../shared/components/RequiresTags";

function Roles(props) {
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showEdit, setShowEdit] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const settings = useSettings();

    React.useEffect(() => {
        setIsLoading(true);
        new coreApi.RolesClient(settings.coreApiUrl).search(undefined, settings.domainName)
            .then(result => setData(result))
            .finally(() => setIsLoading(false));
    }, [refreshCounter]);

    const onRoleCreatedOrUpdated = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const createOrEditRole = (model) => {
        setActiveItem(model);
        setShowEdit(true);
    }

    const deleteRole = (model) => {
        setActiveItem(model);
        setConfirmOpen(true);
    }

    const onDeleteConfirmed = () => {
        new coreApi.RolesClient(settings.coreApiUrl).delete(activeItem.id)
            .then(() => {
                props.showSuccess("Role deleted successfully.");
                setRefreshCounter(refreshCounter + 1);
            })
            .catch(error => {
                props.showError("Unable to delete role.  Please try again.");
            });
    }

    return (
        <>
            <NavBar
                title="Manage Roles">
            </NavBar>

            <Container className="plush roles-list" maxWidth="lg">
                <Card className="col-fill">
                    <CardHeader title="Role List" action={
                        <RequiresTags tags={[["roles::create"]]}>
                            <Button className="align-self-center" size="small" variant="contained" color="success" onClick={() => createOrEditRole(null)}>
                                <Icon fontSize="small">add</Icon>
                                Add Role
                            </Button>
                        </RequiresTags>
                    } />
                    <DataGrid
                        disableSelectionOnClick
                        loading={isLoading}
                        columns={[
                            { headerName: 'Name', field: 'name', flex: 1 },
                            {
                                field: "actions", width: 150, type: "actions", getActions: (r) => [
                                    <RequiresTags tags={[["roles::update"]]}>
                                        <Button size="small" variant="contained" color="primary" onClick={(e) => { createOrEditRole(r.row); e.preventDefault(); }} title="Edit Role"><Icon fontSize="small">edit</Icon></Button>
                                    </RequiresTags>,
                                    <RequiresTags tags={[["roles::delete"]]}>
                                        <Button size="small" variant="contained" color="error" onClick={(e) => { deleteRole(r.row); e.preventDefault(); }} title="Delete Role"><Icon fontSize="small">delete</Icon></Button>
                                    </RequiresTags>
                                ]
                            }
                        ]}
                        rows={data}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'name', sort: 'asc' }
                                ]
                            }
                        }}
                    />
                </Card>
            </Container>

            <EditRoleDialog key={activeItem?.id} open={showEdit} setOpen={setShowEdit} role={activeItem} onSuccess={onRoleCreatedOrUpdated} />

            <ConfirmationDialog
                title="Delete Role?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirmed}
            >
                {`Are you sure you want to delete ${activeItem?.name}?`}
            </ConfirmationDialog>

        </>
    );
}

export default connect(
    null,
    { showSuccess, showError })(Roles);