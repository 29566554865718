import * as React from "react";
import { Checkbox as MaterialCheckbox, CheckboxProps } from "@mui/material";
import { Tasklist, Box, CheckboxMinus } from "./icons";

const Checkbox: React.FunctionComponent<CheckboxProps> = React.forwardRef((props: CheckboxProps, ref) => {
    const { size: _size, color: _color } = props;
    const size = _size ?? "medium";
    const color = _color ?? "primary";

    return (
        <MaterialCheckbox
            ref={ref}
            checkedIcon={<span className="check-container"><Tasklist size={size} /></span>}
            indeterminateIcon={<span className="check-container"><CheckboxMinus size={size} /></span>}
            icon={<span className="check-container"><Box size={size} /></span>}
            color={color}
            {...props}
        />
    );
});

export default Checkbox;