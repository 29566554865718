import * as React from "react";
import { Alert, AlertProps } from "@mui/material";
import { CircleCheck, CircleSlash, Alert as AlertIcon, Info } from "./icons";
import { DocumentedComponent } from "./DocumentedComponent";

const SuccessAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="success" icon={<CircleCheck />} {...props} />);

(SuccessAlert as DocumentedComponent).metadata = {
    description: `Displays a success alert.  This is a preconfigured Alert with a green background and a checkmark icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

const ErrorAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="error" icon={<CircleSlash />} {...props} />);

(ErrorAlert as DocumentedComponent).metadata = {
    description: `Displays an error alert.  This is a preconfigured Alert with a red background and a cross icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

const WarningAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="warning" icon={<AlertIcon />} {...props} />);

(WarningAlert as DocumentedComponent).metadata = {
    description: `Displays a warning alert.  This is a preconfigured Alert with a yellow background and an exclamation mark icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

const InfoAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="info" icon={<Info />} {...props} />);

(InfoAlert as DocumentedComponent).metadata = {
    description: `Displays an info alert.  This is a preconfigured Alert with a blue background and an information icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

export { SuccessAlert, ErrorAlert, WarningAlert, InfoAlert };