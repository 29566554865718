export enum GanttTaskType {
    Summary = "project",
    MileStone = "milestone",
    Regular = "task",
    Placeholder = "placeholder",
    Stage = "stage"
}

export enum ScaleType {
    Weeks = "Weeks",
    Months = "Months",
    Years = "Years",
    Quarters = "Quarters"
}


export enum GanttLinkType {
    FinishToStart = "0",
    StartToStart = "1",
    FinishToFinish = "2",
    StartToFinish = "3",
}


export type GanttDataSource = {
    data: CoreGanttItem[],
    links: GanttLink[],
}

//All the fields required or available by DHTMLX since they dont provide a type definition
export type DHTMLXGanttItem = {
    type: GanttTaskType,
    id: string,
    parent?: string,
    text: string,
    open?: boolean,
    start_date: Date,
    end_date?: Date,
    readonly?: boolean,
    color?: string,
    textColor?: string,
    unscheduled?: boolean;
    duration?: number;
    $new?: boolean;
    $open?: boolean;
}

//All the fields required by our core Gantt implementation for it to work
export type CoreGanttItem = DHTMLXGanttItem & {
    order?: number,
    exclude?: boolean,
    dragTo?: {
        parent: string,
        order: number,
    },
    metadata?: Object;
}

export type GanttLink = {
    id?: string,
    source: string,
    target: string,
    type: GanttLinkType
}

export type ZoomConfig = {
    levels: Array<ZoomLevelConfig>
}

type ZoomLevelConfig = {
    name: string;
    scale_height: number;
    min_column_width: number;
    scales: Array<ZoomScale>;
}

type ZoomScale = {
    unit: string;
    step?: number;
    format?: string;
}