import "./NavBar.scss";
import { AppBar, Toolbar, Select, MenuItem } from "@mui/material";
import UserProfileBadge from "./UserProfileBadge";
import { ChevronDown } from "../../shared/components/icons";
import { Link } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { toggleMenu } from "../../store/app/actions";
import { Helmet } from "react-helmet";
import { BreadcrumbItem } from "../../store/app/types";
import { RootState } from "../../store";
import { AjaxLoadingIndicator } from "./AjaxLoadingIndicator";
import { BranchSelector } from "./BranchSelector";
import _ from "lodash";

const connector = connect(
    (state: RootState) => ({
        breadcrumbStackFromAppState: state.app.breadcrumbStack
    }),
    { toggleMenu },
    (stateProps, dispatchProps, ownProps) => ({ ...stateProps, ...dispatchProps, ...ownProps })
);
type PropsFromRedux = ConnectedProps<typeof connector>;

function NavBarBreadcrumbs(props) {
    const { breadcrumbStack } = props;

    return (
        <ul className="breadcrumb">
            {breadcrumbStack?.map((bc, depth) => {
                if (depth === breadcrumbStack.length - 1) {
                    const lastParent = breadcrumbStack?.[breadcrumbStack.length - 2];
                    const dropdownOptions = lastParent?.children?.filter(mi => !!mi?.url);

                    if (!dropdownOptions || dropdownOptions.length < 2) {
                        return <li key={bc.title}>{bc.title}</li>
                    }

                    return (
                        <li key={bc.title}>
                            <Select size="small" defaultValue={bc.url} IconComponent={(p) => <div {...p}><ChevronDown size="small" /></div>} MenuProps={{ MenuListProps: { className: "breadcrumb-dropdown" } }}>
                                {
                                    dropdownOptions.map(ddo => <MenuItem key={ddo.title} value={ddo.url}><Link to={ddo.url}>{ddo.title}</Link></MenuItem>)
                                }
                            </Select>
                        </li>
                    );
                }
                else return <li key={bc.title}>{bc.title}</li>;
            })}
        </ul>
    );
}

type NavBarProps = PropsFromRedux & {
    title: string;
    actions?: any;
    children?: any;
    breadcrumbStack?: BreadcrumbItem[];
}

function NavBar(props: NavBarProps) {
    const { title: _title, actions, children, breadcrumbStack: breadcrumbStackFromProps, breadcrumbStackFromAppState } = props;
    const breadcrumbStack = breadcrumbStackFromProps || breadcrumbStackFromAppState;

    // Ensure that title is a string here.  Any other data type will blow up the Helmet component...
    const title = _.isString(_title) ? _title : "";

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <AppBar position="sticky" className="navbar">
                <Toolbar>
                    <NavBarBreadcrumbs breadcrumbStack={breadcrumbStack} />
                    <div className="page-title">
                        {title}
                    </div>
                    <div className="actions">
                        {actions}
                        <BranchSelector />
                        <UserProfileBadge />
                    </div>
                    <div className="custom-content">
                        <AjaxLoadingIndicator />
                        {children}
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default connector(NavBar);