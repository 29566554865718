import * as React from "react";
import { Button } from "../../Button";
import { object, string } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import _ from "lodash";
import { Autocomplete, Option, Dialog, DialogActions, DialogContent, DialogTitleWithCloseButton, TextField } from "../../../../shared/components";

interface CloneDialogProps {
    entityName: string;
    allEntities: Option[];
    availableEntities: Option[];
    selectedValue?: Option;
    acceptCallback: (args: Object) => void;
    closeCallback: () => void;
}

function CloneDialog(props: CloneDialogProps) {
    const { entityName, allEntities, availableEntities, selectedValue: _selectedValue, acceptCallback, closeCallback } = props;
    const schema = object().shape({
        entity: object()
            .nullable()
            .required(`Please select a ${entityName} to clone.`),
        name: string()
            .trim()
            .required(`Please supply a name for the new ${entityName}.`)
    });
    const selectedValue = availableEntities.find(e => _.isEqual(e?.value, _selectedValue?.value));
    const { register, handleSubmit, errors, setError, control } = useForm({ resolver: yupResolver(schema), defaultValues: { entity: (selectedValue as any), name: "" } });

    const save = React.useCallback((formModel: { name: string, entity: Option }) => {
        if (_.find(allEntities, e => e.label === formModel.name)) {
            setError("name", { message: `'${formModel.name}' is an existing ${entityName} name!  Please choose a different name.`, type: "validation_error" });
            return;
        }

        acceptCallback({
            entity_id: formModel.entity.value,
            entity_label: formModel.entity.label,
            name: formModel.name
        });
    }, [allEntities, acceptCallback]);

    return (
        <Dialog
            open={true}
            onClose={(_event, reason) => {
                if (reason !== 'backdropClick') {
                    closeCallback();
                }
            }}
            disablePortal={true}
            aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeCallback} id="form-dialog-title">
                Clone {entityName}
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)} autoComplete="off">
                <DialogContent>
                    <div className="vertical-form-field">
                        Select {entityName} to clone:
                    </div>
                    <Controller
                        control={control}
                        name="entity"
                        render={({
                            onChange,
                            value
                        }) => (
                            <Autocomplete
                                onChange={(_event, option, _reason) => onChange((option as Option))}
                                isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
                                getOptionLabel={(option: any) => option?.label || ""}
                                value={value}
                                size="small"
                                fullWidth
                                options={availableEntities}
                                error={!!errors.entity}
                                helperText={errors.entity?.message}
                            />
                        )}
                    />

                    <div className="vertical-form-field">
                        Please supply a name for the new {entityName}:
                    </div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />

                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="text" color="warning">
                        Clone
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export { CloneDialog };