import * as React from "react";
import "./Loader.scss";
import backdrop from "../../assets/no-search-results-backdrop.png";

interface NoSearchResultsProps {
    title?: string;
    description?: string;
}

const NoSearchResults: React.FunctionComponent<NoSearchResultsProps> = (props: NoSearchResultsProps) => {
    const { title, description } = props;

    return (
        <div className="data-placeholders">
            <img src={backdrop} alt="" />
            <h3 className="spaced-bottom">{title}</h3>
            <span>{description}</span>
        </div>
    );
};

export default NoSearchResults;