/* eslint-disable eqeqeq */
import React from 'react';
import "./Autocomplete.scss";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { CircularProgress, InputAdornment, Autocomplete as BaseAutocomplete, TextField, Paper, TreeView, TreeViewNode } from "../../../shared/components";
import { BehaviorSubject } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map, filter, switchMap, debounceTime, tap } from 'rxjs/operators';
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

export interface Option {
    value: any;
    label: string;
    children?: Option[];
}

interface Props {
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    options?: Option[];
    label?: string;
    multiple?: boolean;
    hierarchical?: boolean;
    fetchUrl?: string;
    highlightMatchPattern?: "prefix" | "wildcard";
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLDivElement>, value: Option | Option[]) => void;
    helperText?: string;
    error?: boolean;
    value?: any;
    footerContent?: JSX.Element;
    getOptionTooltip?: (option: Option) => any;
    getOptionDisabled?: (option: Option) => boolean;
    maxSelectionCount?: number;
}

const subject$ = new BehaviorSubject("");

export function getFullHierarchy(option: Option): Option[] {
    const allChildren = _.flatMap(option.children, getFullHierarchy);
    return [option, ...allChildren];
}

function mapTreeNodeToOption(node: TreeViewNode): Option {
    return {
        value: node.value,
        label: node.label
    };
}

export function _Autocomplete(props: Props) {
    const { parameterValues, multiple, options, name, onChange, fetchUrl, updateParameterValue, label, placeholder, hierarchical, error, helperText, footerContent, getOptionTooltip: _getOptionTooltip, getOptionDisabled: _getOptionDisabled, maxSelectionCount, ...other } = props;

    const inputRef = React.useRef<HTMLDivElement>(null);

    const [availableOptions, setAvailableOptions] = React.useState<Option[]>(options || []);
    const treeNodes = React.useMemo(() => (options || []), [options]);
    const [treeViewContentBeacon, setTreeViewPopperBeacon] = React.useState(0);

    const [value, setValue] = React.useState<Option | Option[]>(
        multiple ?
            parameterValues?.get(name)?.map(pv => options?.find(o => _.isEqual(o?.value, pv)))?.filter(v => v != null) ?? props.value ?? [] :
            options?.find(o => _.isEqual(o?.value, parameterValues?.get(name))) ?? props.value ?? {}
    );
    const [inputValue, setInputValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [originalValue, setOriginalValue] = React.useState(value);

    const getOptionDisabled = (option: Option) => {
        if (multiple && maxSelectionCount && (value as Option[]).length >= maxSelectionCount && !(value as Option[]).find(v => v.value === option.value)) {
            return true;
        }
        return _getOptionDisabled?.(option);
    };

    const getOptionTooltip = (option: Option) => {
        if (multiple && maxSelectionCount && (value as Option[]).length >= maxSelectionCount && !(value as Option[]).find(v => v.value === option.value)) {
            return `A maximum of ${maxSelectionCount} items can be selected.`;
        }
        return _getOptionTooltip?.(option);
    }

    const handleChange = (event: any, newValue: Option | Option[]) => {
        setValue(newValue);

        // Batch multi-select changes into a single update when the popper closes...
        if (multiple && open) return;

        if (name) {
            const newParameterValue = newValue instanceof Array ? newValue.map(v => v.value) : newValue?.value;
            updateParameterValue(name, newParameterValue);
        }

        onChange && onChange(event, newValue);
    };

    const handleClose = (event) => {
        setOpen(false);

        // Single-select changes were already handled in the `handleChange` handler...
        if (!multiple) return;

        if (name) {
            const newParameterValue = value instanceof Array ? value.map(v => v.value) : value?.value;
            updateParameterValue(name, newParameterValue);
        }

        if (!_.isEqual(originalValue, value)) {
            onChange && onChange(event, value);
        }
    };

    React.useEffect(() => {
        setAvailableOptions(options || []);
    }, [options]);

    React.useEffect(() => {
        if (parameterValues.has(name)) {
            const newValue = parameterValues.get(name);
            if (options) {
                const availableOptions: Option[] = hierarchical ? options.flatMap(getFullHierarchy) : options;
                if (multiple) {
                    const mappedValues = (newValue ?? [])?.map?.(v => availableOptions?.find(o => _.isEqual(v, o?.value))).filter(v => v != null);
                    setValue(mappedValues);
                }
                else if (!_.isEqual(newValue, (value as Option)?.value)) {
                    const selectedOption = _.find(availableOptions, option => _.isEqual(option?.value, newValue));
                    setValue(selectedOption);
                }
            } else {
                if (multiple) {
                    const newOptions = _.map(newValue, v => ({ value: v, label: `${v}` }))
                    setAvailableOptions(newOptions);
                    setValue(newOptions);
                }
                else {
                    if (newValue !== null && newValue !== undefined) {
                        const newOption = { value: newValue, label: `${newValue}` };
                        setAvailableOptions([newOption]);
                        setValue(newOption);
                    }
                    else {
                        setAvailableOptions([]);
                        setValue(null);
                    }
                }
            }
        }
    }, [parameterValues, options]);

    const getSuggestions = (subject: BehaviorSubject<string>) => {
        return subject.pipe(
            debounceTime(100), // wait until user stops typing
            filter((text: string) => text.length > 0), // send request only if search string is not empty
            map((text: string) => fetchUrl.replace("{searchTerm}", encodeURIComponent(text))), // form url for the API call
            tap(() => setLoading(true)), // show loading indicator
            switchMap((url: string) => ajax(url)), // call HTTP endpoint and cancel previous requests
            map(({ response }: AjaxResponse<any[]>) => response), // change response shape for autocomplete consumption
            tap(() => setLoading(false)) // hide loading indicator
        );
    };

    React.useEffect(() => {
        const subscription = getSuggestions(subject$).subscribe(
            suggestions => {
                setAvailableOptions(suggestions);
            },
            error => {
                // Ignore the error for now - perhaps show an icon in the text field going forward?
            }
        );

        return () => subscription.unsubscribe();
    }, [fetchUrl]);

    React.useEffect(() => {
        if (fetchUrl && inputValue !== "" && inputValue != (value as Option)?.value) {
            subject$.next(inputValue);
        }
    }, [inputValue]);

    const selectedNodeIds = React.useMemo(() => _.isArray(value) ? value?.map(v => v.value) : value?.value, [value]);

    const TreeViewContent = React.useCallback((props) => {
        const { children, ...rest } = props;

        return <Paper {...rest} className="col-fill" onMouseDown={(event) => {
            // Prevent input blur when interacting with the TreeView content
            event.preventDefault();
        }}>
            <ul className="MuiAutocomplete-listbox">
                <TreeView
                    nodes={treeNodes}
                    defaultSearchTerm={inputValue}
                    defaultSelected={selectedNodeIds}
                    multiSelect={multiple}
                    multiSelectWithoutCtrl={true}
                    onSelectionChanged={(selectedNodes) => {
                        if (multiple) {
                            handleChange({}, selectedNodes?.map(mapTreeNodeToOption));
                        } else {
                            handleChange({}, mapTreeNodeToOption(selectedNodes?.[0]));
                            handleClose({});
                            setTimeout(() => Array.from(inputRef?.current?.getElementsByTagName("input")).forEach(e => e?.blur()));
                        }
                    }}
                    getNodeTooltip={_getOptionTooltip}
                    getNodeSelectionDisabled={_getOptionDisabled}
                    maxSelectionCount={maxSelectionCount}
                />
            </ul>
            { footerContent &&
                <>
                    <hr />
                    {footerContent}
                </>
            }
        </Paper>;
    }, [treeViewContentBeacon, footerContent]);

    const StandardContent = React.useCallback((props) => {
        const { children, ...rest } = props;

        return <Paper {...rest} className="col-fill" onMouseDown={(event) => {
            // Prevent input blur when interacting with the TreeView content
            event.preventDefault();
        }}>
            {children}
            { footerContent &&
                <>
                    <hr />
                    {footerContent}
                </>
            }
        </Paper>;
    }, [footerContent]);

    return (
        <BaseAutocomplete
            size="small"
            {...other}
            getOptionTooltip={getOptionTooltip}
            getOptionDisabled={getOptionDisabled}
            multiple={!!multiple}
            options={availableOptions}
            noOptionsText={(fetchUrl && !inputValue) ? "Enter text to search for options." : "No options available."}
            value={value}
            onChange={handleChange}
            onOpen={() => {
                setOriginalValue(value);
                setOpen(true);
                if (hierarchical) {
                    setTreeViewPopperBeacon(v => v + 1);
                    !multiple && setInputValue("");
                }
            }}
            open={open}
            onClose={handleClose}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                // Prevent selection from clearing out the existing search text...
                if (event) {
                    hierarchical && setTreeViewPopperBeacon(v => v + 1);
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    ref={inputRef}
                    label={label}
                    variant="outlined"
                    placeholder={multiple && (value as Option[])?.length > 0 ? "" : placeholder}
                    error={error}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </InputAdornment>
                        )
                    }}
                />
            )}
            PaperComponent={hierarchical ? TreeViewContent : StandardContent}
        />
    );
}

const Autocomplete = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(_Autocomplete);

(Autocomplete as DocumentedComponent).metadata = {
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the variable that the selected value(s) will be read from and persisted to." },
        { name: `label`, type: `string`, description: "The placeholder text / label to display alongside this control." },
        { name: `placeholder`, type: `string`, description: "Placeholder text to display when no input has been provided yet." },
        { name: `multiple`, type: `boolean`, description: "If `true`, allows for selecting multiple values from the list.  If `false`, only a single value can be selected.  Optional - defaults to `false`." },
        { name: `hierarchical`, type: `boolean`, description: "If `true`, renders a `TreeView` in the item selection pane.  Use in conjunction with the `children` field of `Option`.  Optional - defaults to`false`." },
        {
            name: `options`, type: `object`, description: `The list of options that will be displayed in the Autocomplete control.  See the structure of \`Option\` below:

### Option Fields:

| Name | Type | Description |
|------|------|-------------|
| \`value\` | \`any\` | The value to bind to the specified storyline parameter when this option is selected. |
| \`label\` | \`string\` | The text to display for this option. |
| \`preventSelection\` | \`boolean\` | If \`true\`, this option cannot be selected.  Optional - defaults to \`false\`. |
| \`children\` | \`Option[]\` | The (optional) children of this node.  The \`hierarchical\` prop above must be set to \`true\` in order to utilize this feature. |
` },
        { name: `fetchUrl`, type: `string`, description: "The URL to use for dynamic options.  The placeholder `{searchTerm}` is replaced with the currently entered text." },
        { name: `highlightMatchPattern`, type: `string`, options: ["prefix", "wildcard"], description: "The matching pattern to use for highlighting the current text in the available options.  'wildcard' matches text anywhere inside the results, whereas 'prefix' only matches the beginning of words.  Optional - defaults to 'wildcard'." },
        { name: `onChange`, type: `object`, template: `onChange={(event, value) => {$1}}`, description: "Optional event handler, called when the selected value has changed." },
        { name: `footerContent`, type: `object`, description: "Optional content to display in the footer of the pop-up dialog.  Supports any JSX content." },
        { name: `getOptionTooltip`, type: "function", template: `getOptionTooltip={(option) => {$1}}`, description: "The (optional) callback function used to provide tooltip content for each option.  If this function returns a `falsey` value, no tooltip will be shown for that particular option.  Optional - defaults to `null`." },
        { name: `getOptionDisabled`, type: "function", template: `getOptionDisabled={(option) => {$1}}`, description: "The (optional) callback function used to determine if an option should be disabled.  If this function returns `true`, the option will be disabled.  Optional - defaults to `null`." },
        { name: `maxSelectionCount`, type: `number`, description: "The maximum number of items that can be selected.  Only applies when `multiple` is `true`.  Optional - defaults to unlimited." },
    ]
};

export { Autocomplete, BaseAutocomplete };