import "./ErrorContainer.scss";
import type { AppError } from "../../store/app/types"
import { Icon, Markdown } from "../../viewer/components";
import { NavBar } from "../components";

export type ErrorContainerProps = {
    error?: AppError;
    includeNavBar?: boolean;
    children?: React.ReactNode;
}

export function ErrorContainer(props: ErrorContainerProps) {
    const { error, includeNavBar, children } = props;

    if (!error) {
        return <>{children}</>;
    }

    const icon =
        error.type === "NotFound" ? "search_off" :
            error.type === "Connectivity" ? "cloud_off" :
                "error";

    return (
        <>
            {
                includeNavBar && <NavBar title="Error" />
            }

            <div className="error-page">
                <div className="error-container">
                    <div className="image">
                        <Icon>{icon}</Icon>
                    </div>
                    <div className="heading">
                        {error.message}
                    </div>
                    <div className="details">
                        <Markdown children={error.details} />
                    </div>
                </div>
            </div>
        </>
    );
}