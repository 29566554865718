import * as React from "react";
import { Radio as MaterialRadio, RadioProps, RadioGroup } from "@mui/material";

const Radio: React.FunctionComponent<RadioProps> = (props: RadioProps) =>
    <MaterialRadio
        icon={<span className="radio-button-container" />}
        checkedIcon={<span className="radio-button-container checked"><span className="radio-button-checked" /></span>}
        {...props}
    />;

export { Radio, RadioGroup };
