import React from "react";
import { connect } from "react-redux";
import { Button, InfoAlert, Card } from "../../shared/components";
import { RootState } from "../../store/index";
import { closeWsConnection, openWsConnection, sendCurrentTemplateToVsCode } from "../../store/vscode/actions";
import { VsCodeState, } from "../../store/vscode/types";

interface VsCodeProps {
    state: VsCodeState;
    openWsConnection: typeof openWsConnection;
    closeWsConnection: typeof closeWsConnection;
    sendCurrentTemplateToVsCode: typeof sendCurrentTemplateToVsCode;
}

function VsCode(props: VsCodeProps) {
    const { state, openWsConnection, closeWsConnection, sendCurrentTemplateToVsCode } = props;

    return (
        <div className='table-container'>
            <Card className="vscode-layout plush">
                <InfoAlert>
                    In order to utilize this functionality, please ensure that the <a href="https://marketplace.visualstudio.com/items?itemName=qerent.sc-canvas-toolkit" target="_blank" rel="noreferrer">VS Code Extension</a> is installed and running locally.
                </InfoAlert>

                <span className="connection-status">
                    <span className="label">Connection Status : </span>
                    <span className="value">{state.connectionStatus}</span>
                </span>

                <Button variant="outlined" color="primary" onClick={() => state.connectionStatus === "OPEN" ? closeWsConnection() : openWsConnection()}>{state.connectionStatus === "OPEN" ? "Disconnect" : "Connect"}</Button>
                <Button variant="outlined" color="secondary" onClick={() => sendCurrentTemplateToVsCode()}>Send Template to VS Code</Button>
            </Card>
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        state: state.vsCode
    }),
    { openWsConnection: openWsConnection as any, closeWsConnection: closeWsConnection as any, sendCurrentTemplateToVsCode: sendCurrentTemplateToVsCode as any })(VsCode);