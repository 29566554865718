import React from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../store/index";
import _ from "lodash";
import { jsonParseReviver } from "../../shared/api-client";
import { renderValue } from "./shared";
import Style from 'style-it';
import { setDatasourceData } from "../../store/storyline/actions";
import { DataSource, DataSourceParameter } from "../../store/storyline/types";
import { Button } from "../../shared/components";

interface DatasourcesProps {
    datasources: Map<string, DataSource>;
    datasourceValues: Map<string, any[]>;
    parameterValues: Map<string, any>;
}

function renderParameterValueSection(dsp: DataSourceParameter, value: any) {
    return (<>
        <td>
            {dsp.name}{!dsp.isRequired ? "*" : ""}
        </td>
        <td>
            {renderValue(value, null, { readOnly: true })}
        </td>
    </>);
}

function Datasources(props: DatasourcesProps) {
    const { datasources, datasourceValues, parameterValues } = props;

    return (
        <div className="table-container">
            <table className="table bordered key-value">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>API Path</th>
                        <th>Current Value</th>
                        <th>Parameter Name</th>
                        <th>Parameter Value</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.from(datasources.entries()).map(([name, datasource], idx) => <Datasource key={idx} datasource={datasource} value={datasourceValues.get(name)} parameterValues={parameterValues} />)
                    }
                </tbody>
            </table>
        </div>
    );
}

function Datasource(props: { datasource: DataSource, value: any, parameterValues: Map<string, any> }) {
    const { datasource, value, parameterValues } = props;
    const { name } = datasource;

    const dispatch = useDispatch();
    const [modifiedValue, setModifiedValue] = React.useState(JSON.stringify(value, null, 2));

    React.useEffect(() => {
        setModifiedValue(JSON.stringify(value, null, 2));
    }, [value]);

    const applyChanges = React.useCallback(() => {
        try {
            const parsedValue = JSON.parse(modifiedValue, jsonParseReviver);
            dispatch(setDatasourceData(datasource.name, parsedValue));
        }
        catch (e) {

        }
    }, [datasource.name, modifiedValue]);

    let normalizedParameters = datasource.parameters.length > 0 ? _.orderBy(datasource.parameters, ["isOptional", "name"], [false, true]) : [null];

    return (
        <>
            {
                normalizedParameters.map((dsp, dspId) => <tr key={`dsp-${dspId}`}>
                    {dspId === 0 && <td className="text-field" rowSpan={normalizedParameters.length}>{name}</td>}
                    {dspId === 0 && <td className="text-field" rowSpan={normalizedParameters.length}>{(datasource.apiEndpoint ?? []).join("/")}</td>}

                    {
                        dspId === 0 &&
                        <td rowSpan={normalizedParameters.length}>
                            {
                                // The CodeMirror element's max height can only be set via a CSS rule targeting a nested element, so we use a styled component for this...
                                Style.it(`.CodeMirror .CodeMirror-scroll { max-height: ${Math.max(250, normalizedParameters.length * 50)}px !important; }`, renderValue(value, setModifiedValue))
                            }
                        </td>
                    }
                    {
                        dsp === null ?
                            <td colSpan={2}>No parameters.</td> :
                            renderParameterValueSection(dsp, parameterValues.get(dsp.name))
                    }
                    {
                        dspId === 0 &&
                        <td rowSpan={normalizedParameters.length}>
                            <Button variant="contained" color="secondary" size="small" onClick={applyChanges}>Apply Changes</Button>
                        </td>
                    }
                </tr>
                )
            }
        </>
    );
}

export default connect(
    (state: RootState) => ({
        datasources: state.storyline.datasources,
        datasourceValues: state.storyline.datasourceValues,
        parameterValues: state.storyline.parameterValues
    }),
    null)(Datasources);