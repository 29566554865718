import "./TemplateRenderer.scss";
import * as React from "react";
import JsxParser from "@recursive-robot/react-jsx-parser";
import Style from 'style-it';
import { useCanvasBindings } from "../../../shared/providers/CanvasBindingsProvider";
import { components, blacklistedAttrs } from "../../Canvas/Canvas";
import clsx from "clsx";
import { useSelector } from "react-redux";
import type { RootState } from "../../../store";
import { sendTemplateBindingsToVsCode } from "../../../store/vscode/actions";

const renderError = ({ error }) => <span className="error-message">{`Template error: ${error}`}</span>;

export type TemplateRendererProps = {
    // Pass in the path to the template object in the storyline...
    path?: string;
    // OR pass in the JSX and CSS directly...
    jsx?: string;
    css?: string;
    // Addtional props are used as (scoped) bindings for the template...
    [key: string]: any;
}

function _TemplateRenderer(props: TemplateRendererProps) {
    const { path, jsx, css, children, className, ...scopedInputs } = props;
    const _bindings = useCanvasBindings();
    const templates = useSelector((state: RootState) => state.storyline.storyline.templates);
    const template = templates[path] ?? { jsx, css };

    const defineFunction = React.useCallback((...args) => {
        return new Function(...args).bind({ ..._bindings, ...scopedInputs }); // eslint-disable-line no-new-func
    }, [_bindings]);

    const bindings = { ..._bindings, ...scopedInputs, defineFunction, children };

    const result = (
        <JsxParser
            bindings={bindings}
            components={components}
            jsx={template.jsx}
            disableKeyGeneration={true}
            blacklistedAttrs={blacklistedAttrs}
            renderError={renderError}
            renderInWrapper={false}
            key={path}
        />
    );

    // Send the bindings to the VS Code extension, to provide Intellisense for scoped input expressions...
    sendTemplateBindingsToVsCode(path, scopedInputs);

    return template.css ?
        Style.it(template.css, <div className={clsx(className, "template-fragment")}>{result}</div>) :
        result;
}

const TemplateRenderer = React.memo(_TemplateRenderer);

export { TemplateRenderer };