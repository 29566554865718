import clsx from "clsx";
import { ValueDriverTreeNode, VdtNode } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

function VdtNodeCard(props?: ValueDriverTreeNode<any>) {
    const node = { ...props };
    const className = props.class || props["className"]; // The JSX parser normalizes the "class" property if passsed in directly, so we need to look for both forms...

    return (
        <div className={clsx("vdtNode", "full", className)}>
            <div className="vdtNodeContainer">
                <VdtNode
                    node={node}
                    zoomLevel="full"
                    staticPlot={false}
                />
            </div>
        </div>
    );
}

(VdtNodeCard as DocumentedComponent).metadata = {
    description: "The `VdtNode` component allows for the use of the VDT body template outside of a VDT.  The input properties are identical to those of the `node` values inside a VDT, except that the standalone VDT Node does not support child nodes.",
    isSelfClosing: true,
    attributes: [
        { name: `title`, type: `string`, description: "The title of the node." },
        { name: `type`, type: "string", options: ['fields', 'chart', 'custom'], description: "The type of the node. `fields` nodes display a list of fields.  `chart` nodes display a Plotly chart inside the node body, with the `data` and `layout` fields populated in the `model`.  `custom` nodes allow the user to render a completely custom VDT body, driven by the `template` specified.  Optional - defaults to `fields`." },
        { name: `class`, type: `string`, description: "The CSS class to apply to this node.  Determines the default background color for the \"simple\" node body and the background color for the progress bar.  Predefined classes are: `positive` (green), `negative` (red), `warning` (orange) and `deemphasized` (gray)." },
        {
            name: `fields`,
            type: `object`,
            description: `The fields to display in the node body.  Each field is rendered as a row in the node body.  Only visible in the "full" view, when the node \`type\` is \`fields\` or empty.  See below for the structure of the \`VDTField\` object.

### VDTField Fields:

| Name | Type | Description |
|------|------|-------------|
| \`name\` | \`string\` | The title of the field.  Left-aligned text value displayed in the field row. |
| \`value\` | \`string\` | The value of the field.  Right-aligned text value displayed in the field row.  |
| \`class\` | \`string\` | The CSS class to apply to this field row.  Useful for color-coding or highlighting rows. |`
        },
        { name: `template`, type: `string`, description: "The custom template used to render the body of the node.  Only applicable if the node type is set to `custom`.  All the fields inside `model`, as well as the node fields (`title`, `progressBarValue` and `progressBarText`) and canvas values are available to the template.  In the case of a naming conflict, the `model` fields will override the canvas-level fields." },
        { name: `model`, type: `object`, description: "Data required for rendering non-standard nodes (those with a type other than `fields`) is passed in here.  In the case of `chart` nodes, this contains the `data` and `layout` objects required for rendering a Plotly chart." },
        { name: `progressBarValue`, type: `number`, description: "A value between 0 and 1 to indicate what percentage of the progress bar should be filled in." },
        { name: `progressBarText`, type: `string`, description: "The text to overlay on top of the progress bar.  Usually contains the human-friendly value of the impact for this node." },
        { name: `progressBarColor`, type: `string`, description: "The fill color to apply to the shaded portion of the progress bar.  Overrides the value from the CSS class applied to the node.  Optional - default value is already driven by the node's `class` property." },
        { name: `hideTitle`, type: `boolean`, description: "Hides node title in order to free up space.  Only applies to certain node types - `chart` only at the moment.  Optional - defaults to `false`." },
        { name: `hideProgressBar`, type: `boolean`, description: "Hides progress bar.  Optional - defaults to `false`." },
    ]
};

export default VdtNodeCard;