import * as React from "react";
import { Dialog, DialogTitleWithCloseButton, DialogContent, DialogActions, LinkButton, Button } from "../../../shared/components";
import type { GanttLink } from "./types";

interface DeleteLinkProps {
    link: GanttLink;
    showDialog: boolean;
    setShowDialog: (showDialog: boolean) => void;
    onDeleteAccepted: () => void;
}

function DeleteLinkDialog(props: DeleteLinkProps) {
    const { link, showDialog, setShowDialog, onDeleteAccepted } = props;

    const onCancelClicked = () => {
        setShowDialog(false);
    };

    const onAcceptClicked = () => {
        onDeleteAccepted();
        setShowDialog(false);
    };

    return (
        <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
            <DialogTitleWithCloseButton onClose={() => setShowDialog(false)}>Delete Link</DialogTitleWithCloseButton>
            <DialogContent>
                Are you sure you want to delete this link?
            </DialogContent>
            <DialogActions>
                <LinkButton color="secondary" onClick={onCancelClicked}>Cancel</LinkButton>
                <Button variant="contained" color="error" onClick={onAcceptClicked}>Delete</Button>
            </DialogActions>
        </Dialog>
    );
}

export { DeleteLinkDialog };