import React from "react";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RootState } from "../../../store";
import { connect } from "react-redux";

import { ConfirmationDialog as BaseConfirmationDialog } from "../../../shared/components";
import { Breakpoint } from "@mui/material";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { TooltipContainerContext } from "../../../shared/providers/TooltipContainerProvider";

interface ConfirmationDialogProps {
    title: string,
    visibilityParameter: string,
    children: any,
    onConfirm: () => void,
    onCancel?: () => void,
    maxWidth?: Breakpoint,
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue
}

function _ConfirmationDialog(props: ConfirmationDialogProps) {
    const { title, visibilityParameter, onConfirm, onCancel, maxWidth = "xs", parameterValues, updateParameterValue, children, ...rest } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const dialogRef = React.useRef(null);

    React.useEffect(() => {
        const parameterValue = parameterValues.get(visibilityParameter);
        setIsOpen(!!parameterValue);
    }, [parameterValues, visibilityParameter]);

    const setOpen = React.useCallback((isOpen) => {
        setIsOpen(isOpen);
        updateParameterValue(visibilityParameter, isOpen);
    }, [setIsOpen])

    return (
        <BaseConfirmationDialog
            open={isOpen}
            maxWidth={maxWidth}
            setOpen={setOpen}
            disablePortal={true}
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            {...rest}>
            <TooltipContainerContext.Provider value={dialogRef}>
                <div className="confirmation-dialog-content">
                    {children}
                </div>
            </TooltipContainerContext.Provider>
        </BaseConfirmationDialog>
    );
}

const ConfirmationDialog = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(_ConfirmationDialog));

(ConfirmationDialog as DocumentedComponent).metadata = {
    description: "The ConfirmationDialog component is a preconfigured Modal with default actions for confirming or cancelling an operation.  Visibility of the dialog is determined by the value of the specified visibility parameter.",
    isSelfClosing: false,
    attributes: [
        { name: `title`, type: `string`, description: "The text to display in the heading of the modal dialog." },
        { name: `visibilityParameter`, type: `string`, description: `The name of the parameter used to determine the visibility of the Modal.  Any _truthy_ value is interpreted as "visible" and any _falsey_ value is interpreted as "hidden".` },
        { name: `onConfirm`, type: `function`, template: "onConfirm={() => \n{\n\t$1\n}}", description: `The function to invoke when the user clicks the "Confirm" button.` },
        { name: `onCancel`, type: `function`, template: "onCancel={() => \n{\n\t$1\n}}", description: `The (optional) function to invoke when the user clicks the "Cancel" button.  Defaults to \`null\`.` },
        { name: `maxWidth`, type: "string", options: [`xs`, `sm`, `md`, `lg`, `xl`], description: "The maximum width of the modal.  Optional - defaults to `xs`." },
    ]
};

export default ConfirmationDialog;