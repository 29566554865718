import * as React from "react";
import { Card } from "../../../shared/components";
import { Popper } from "@mui/material";
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

interface CellWithTooltipProps extends GridRenderCellParams {
    tooltipRenderer: any;
    cellRenderer: any;
}

const CellWithTooltipRenderer = React.memo((props: CellWithTooltipProps) => {
    const { formattedValue, value, cellMode, tooltipRenderer: TooltipRenderer, cellRenderer: CellRenderer } = props;
    const cellDiv = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMouseEnter = React.useCallback(() => {
        setAnchorEl(cellDiv?.current);
    }, [setAnchorEl]);

    const handleMouseLeave = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    if (cellMode !== "view") return null;

    return (
        <>
            <div
                className="cell-tooltip-source"
                ref={cellDiv}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
            </div>

            {
                CellRenderer ?
                    <CellRenderer {...props} /> :
                    <div className="MuiDataGrid-cellContent">
                        {formattedValue ? formattedValue : value}
                    </div>
            }

            {
                anchorEl && (
                    <Popper
                        sx={{
                            zIndex: 2
                        }}
                        open={anchorEl !== null}
                        anchorEl={anchorEl}
                    >
                        <Card className="cell-tooltip-card">
                            {TooltipRenderer ? <TooltipRenderer {...props} /> : value}
                        </Card>
                    </Popper>
                )
            }
        </>
    );
});

export { CellWithTooltipRenderer };
