import "./LoadingContainer.scss";
import clsx from "clsx";
import React from "react";
import Loader from "./Loader";
import LinearProgressIndicator from "./LinearProgressIndicator";
import { CircularProgress } from "@mui/material";

type LoadingContainerProps = {
    isLoading: boolean;
    type?: "linear" | "circular" | "full";
    title?: string;
    description?: string;
    children: React.ReactNode;
    className?: string;
    [key: string]: any;
};

const LoadingContainer = (props: LoadingContainerProps) => {
    const { isLoading, type, children, className, title, description, ...rest } = props;

    const renderLoadingIndicator = () => {
        switch (type) {
            case "linear": return <div className="linear-progress-indicator"><LinearProgressIndicator /></div>;
            case "circular": return <div className="circular-progress-indicator"><CircularProgress /></div>;
            default: return <div className="full-progress-indicator"><Loader title={title} description={description} /></div>;
        }
    }

    return (
        <div className={clsx("loading-container", className, isLoading ? "lc-state-loading" : "lc-state-loaded")} {...rest }>
            {
                isLoading ?
                    renderLoadingIndicator() :
                    children
            }
        </div>
    );
};

export { LoadingContainer };