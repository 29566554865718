import React from 'react';
import "./FocusContainer.scss";
import { Dialog, IconButton, Tooltip } from "../../../shared/components";
import { SettingsOverscan, ZoomOut } from "@mui/icons-material";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';
import clsx from 'clsx';
import { TooltipContainerContext } from '../../../shared/providers/TooltipContainerProvider';

interface Props {
    className?: string;
    useModal?: boolean;
    title?: string;
    children?: any;
}

function FocusContainer(props: Props) {
    const { className, useModal, children, ...rest } = props;
    const [[isExpanded, wasExpanded], setExpansionState] = React.useState([false, false]);
    const dialogRef = React.useRef(null);

    const onClose = () => {
        setExpansionState([false, true]);
    };

    // Ensure that the modal renders inside the canvas div, in order to use the user-defined CSS...
    const getContainer = React.useCallback(() => document.querySelector(".main-canvas .jsx-parser"), []);

    const content = <div className={clsx("focus-container", isExpanded && "focused", wasExpanded && "restored", className)}>
        <div className="buttons">
            {
                !isExpanded &&
                <Tooltip arrow title="Focus On This Section">
                    <IconButton color="primary" onClick={() => setExpansionState([true, false])}>
                        <SettingsOverscan />
                    </IconButton>
                </Tooltip>
            }

            {
                isExpanded &&
                <Tooltip arrow title="Return To Report">
                    <IconButton color="primary" onClick={() => setExpansionState([false, true])}>
                        <ZoomOut />
                    </IconButton>
                </Tooltip>
            }
        </div>
        {children}
    </div>;

    if (useModal && isExpanded) {
        return <Dialog
            container={getContainer}
            ref={dialogRef}
            className="fullscreen-dialog"
            open={isExpanded}
            maxWidth={null}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            {...rest}
        >
            <TooltipContainerContext.Provider value={dialogRef}>
                {content}
            </TooltipContainerContext.Provider>
        </Dialog>;
    }

    return content;
}

(FocusContainer as DocumentedComponent).metadata = {
    description: "The FocusContainer component allows the user to expand any child components to fill the entire canvas.  It does so by showing an expand/collapse button on hover.  Any HTML/components can be embedded inside the FocusContainer.",
    isSelfClosing: false,
    attributes: [
        { name: "useModal", type: "boolean", description: "If `true`, the expanded content will be rendered in a full-screen Modal dialog.  Otherwise, the expanded content is rendered directly in the canvas, on top of the other content." }
    ]
};

export default FocusContainer;