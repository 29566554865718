import { AppState, AppActionTypes, LOAD_APP, SHOW_APP, TOGGLE_MENU, SET_BREADCRUMB_STACK, SET_MENU_IS_EXPANDED, SET_GLOBAL_ERROR, SET_PAGE_ERROR } from "./types";

const initialState: AppState = {
    loading: true,
    activeBranch: "",
    menuItems: [],
    menuIsExpanded: true,
    breadcrumbStack: [{ title: "Home" }],
    errors: {},
    user: {
        name: "",
        roles: [],
        effectiveTags: [],
        isDeveloper: false,
    },
    settings: {
        domainName: window.location.hostname
    }
}

export function appReducer(
    state = initialState,
    action: AppActionTypes
): AppState {
    switch (action.type) {
        case LOAD_APP:
            return {
                ...state,
                loading: true,
                user: action.user,
                settings: action.settings,
            }

        case SHOW_APP:
            return {
                ...state,
                loading: false,
                activeBranch: action.activeBranch,
                menuItems: action.menuItems,
                settings: action.settings,
            }

        case TOGGLE_MENU:
            return {
                ...state,
                menuIsExpanded: !state.menuIsExpanded
            }

        case SET_BREADCRUMB_STACK:
            return {
                ...state,
                breadcrumbStack: action.breadcrumbStack
            }

        case SET_MENU_IS_EXPANDED:
            return {
                ...state,
                menuIsExpanded: action.value
            }

        case SET_GLOBAL_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    global: action.error
                }
            }

        case SET_PAGE_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    page: action.error
                }
            }

        default:
            return state;
    }
}