import React from "react";
import { Auth0Context } from "../AuthContext";

export const AnonymousAuthProvider = ({
    children,
    ...rest
}) => {
    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated: true,
                user: { name: "Anonymous User" },
                roles: [],
                allowedTags: [],
                deniedTags: [],
                effectiveTags: [],
                userMetadata: {
                    EmailAddress: "anonymous@qerent.ai",
                    UserId: "anonymous@qerent.ai",
                    domain: window.location.host,
                    tenant_name: window.location.host,
                    roles: [],
                    allowedTags: [],
                    deniedTags: [],
                    effectiveTags: [],
                    user_id: "Anonymous User",
                    name: "Anonymous User",
                    email: "anonymous@qerent.ai",
                },
                loading: false,
                error: null,
                loginWithPopup: (...a) => { },
                handleRedirectPromise: (...a) => { },
                onRedirectCallback: (...a) => { },
                getIdTokenClaims: (...p) => { },
                loginWithRedirect: (...p) => { },
                getTokenSilently: (...p) => Promise.resolve(null),
                getTokenWithPopup: (...p) => { },
                logout: (...p) => Promise.resolve(null)
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};