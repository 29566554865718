import React from "react";
import "./SidenavMiniMap.scss";
import Canvas from "../Canvas/Canvas";
import { Canvas as CanvasModel, StorylineState } from "../../store/storyline/types";
import { navigateTo, goToXYZ } from "../../store/storyline/actions";
import { CircularProgress, ConditionalTreeItem } from "../../shared/components";
import { Book, Chapter, VDT } from "../../shared/components/icons";
import { TreeView, TreeItem } from "@mui/lab";
import { RootState } from "../../store";
import { connect, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as _ from "lodash";
import { TriangleDown, TriangleRight } from "../../shared/components/icons";
import { AppError } from "../../store/app/types";

const MiniMapFramePreview = React.memo((props: { slide: CanvasModel, ix: number, iy: number, fi: number }) => {
    const { slide, ix, iy, fi } = props;
    const template = slide?.template;
    const frameData = slide?.data;

    if (!template || !frameData) return null;

    return (
        <div className="minimap-hover-tooltip-content">
            <div className="hover-tooltip-slide-title">{frameData?.["title"]}</div>
            <div className="slide">
                <Canvas key={`mini-map-slide-hover-preview-${ix}-${iy}-${fi}`} template={template} frameData={frameData} staticPlot={true} />
            </div>
        </div>
    );
});

interface MiniMapProps {
    navigateTo: typeof navigateTo,
    goToXYZ: typeof goToXYZ,
    pageError: AppError
}

function SIDENAV_MINIMAP(props: MiniMapProps) {
    const { goToXYZ, pageError } = props;
    const { canvases, xIndex, yIndex, frameIndex, loading } = useSelector((state: RootState) => state.storyline ?? {});
    const [expandedNodes, setExpandedNodes] = React.useState([]);
    const hideMiniMap = canvases?.length === 1 && canvases[0]?.length === 1 && canvases[0][0]?.length === 1;

    React.useEffect(() => {
        const allIds = _.flatten(canvases.map((c, ci) => [`node-${ci}`, ...c.map((p, pi) => `node-${ci}-${pi}`)]));
        setExpandedNodes(allIds);
    }, [canvases]);

    const onNodeToggle = (event: any, nodeIds: string[]) => {
        setExpandedNodes(nodeIds);
    }

    // (Re)initialize the tooltip handler when the storyline is updated (data sources changed, etc.)...
    React.useEffect(() => {
        setTimeout(() => ReactTooltip.rebuild(), 1000);
    }, [canvases]);

    const getTooltipContent = React.useCallback((dataString) => {
        if (!dataString) return null;
        const [ix, iy, fi] = dataString.split("|").map(a => parseInt(a));
        return <MiniMapFramePreview slide={canvases?.[ix]?.[iy]?.[fi]} ix={ix} iy={iy} fi={fi} />;
    }, [canvases]);

    if (pageError == null && loading) {
        return <div className="minimap-loading"><CircularProgress /></div>;
    }

    return (
        hideMiniMap ?
            null :
            <div className="minimap">
                <ReactTooltip id="slide-preview" type="light" effect="solid" place="right" clickable={false} className="tooltip-primary minimap-hover-tooltip" getContent={getTooltipContent} />
                <TreeView
                    expanded={expandedNodes}
                    onNodeToggle={onNodeToggle}
                    disableSelection={true}
                    defaultCollapseIcon={<TriangleDown size="small" />}
                    defaultExpandIcon={<TriangleRight size="small" />}
                >
                    {
                        canvases.map((chapter, chapterIndex) => <ConditionalTreeItem key={`node-${chapterIndex}`} show={chapter.length > 1} nodeId={`node-${chapterIndex}`} className={chapterIndex === chapterIndex ? "active" : ""} label={
                            <div className="menu-item-label">
                                <Book size="small" />
                                <span>
                                    {
                                        `Chapter ${chapterIndex + 1}`
                                    }
                                </span>
                            </div>
                        }>
                            {chapter.map((page, pageIndex) => <ConditionalTreeItem key={`node-${chapterIndex}-${pageIndex}`} show={page.length > 1} nodeId={`node-${chapterIndex}-${pageIndex}`} className={chapterIndex === chapterIndex && pageIndex === pageIndex ? "active" : ""} label={
                                <div className="menu-item-label">
                                    <Chapter size="small" />
                                    <span>
                                        {
                                            `Page ${pageIndex + 1}`
                                        }
                                    </span>
                                </div>
                            }>
                                {
                                    page.map((paragraph, paragraphIndex) =>
                                        <TreeItem key={`node-${chapterIndex}-${pageIndex}-${paragraphIndex}`} nodeId={`node-${chapterIndex}-${pageIndex}-${paragraphIndex}`} className={chapterIndex === chapterIndex && pageIndex === pageIndex && paragraphIndex === paragraphIndex ? "active tooltip-target" : "tooltip-target"} label={
                                            <div className="menu-item-label">
                                                <VDT size="small" />
                                                <span>{paragraph["title"]}</span>
                                            </div>
                                        }
                                            data-for="slide-preview"
                                            data-tip={`${chapterIndex}|${pageIndex}|${paragraphIndex}`}
                                            onClick={() => goToXYZ(chapterIndex, pageIndex, paragraphIndex)}
                                        >
                                        </TreeItem>)}
                            </ConditionalTreeItem>)}
                        </ConditionalTreeItem>)
                    }
                </TreeView>
            </div>
    );
}

const SidenavMiniMap = React.memo(SIDENAV_MINIMAP);

export default connect(
    (state: RootState) => ({
        pageError: state.app.errors.page
    }),
    { navigateTo, goToXYZ })(SidenavMiniMap);