export const white = "#FFFFFF";
export const black = "#000000";

// Primary color pallette...
export const red_1 = "#D9032B";
export const red_2 = "#C32233";
export const red_3 = "#DF4253";
export const red_4 = "#FFE2E0";

export const orange_1 = "#F08B39";
export const orange_2 = "#EA832F";
export const orange_3 = "#FF9F51";
export const orange_4 = "#FFE3CD";

export const yellow_1 = "#FFD400";
export const yellow_2 = "#EEC601";
export const yellow_3 = "#FFEA81";
export const yellow_4 = "#FFF7CF";

export const green_1 = "#8BC53F";
export const green_2 = "#77AD2F";
export const green_3 = "#A6DC5E";
export const green_4 = "#E9F3DC";

export const blue_1 = "#3777BB";
export const blue_2 = "#2B6AAD";
export const blue_3 = "#89BAED";
export const blue_4 = "#D3E7FC";

export const dark_blue_1 = "#132A58";
export const dark_blue_2 = "#0E2044";
export const dark_blue_3 = "#32508C";
export const dark_blue_4 = "#DFE6F4";

// Utility colors...
export const error_1 = "#D9032B";
export const error_2 = "#C32233";
export const error_3 = "#DF4253";
export const error_4 = "#FFE2E0";

export const warning_1 = "#F08B39";
export const warning_2 = "#EA832F";
export const warning_3 = "#FF9F51";
export const warning_4 = "#FFE3CD";

export const highlight_1 = "#FFD400";
export const highlight_2 = "#EEC601";
export const highlight_3 = "#FFEA81";
export const highlight_4 = "#FFF7CF";

export const success_1 = "#8BC53F";
export const success_2 = "#77AD2F";
export const success_3 = "#A6DC5E";
export const success_4 = "#E9F3DC";

export const grey_1 = "#111111";
export const grey_2 = "#222222";
export const grey_3 = "#565656";
export const grey_4 = "#7D7D7D";
export const grey_5 = "#AFAFAF";
export const grey_6 = "#E5E5E5";
export const grey_7 = "#F3F3F3";
export const grey_8 = "#FCFCFC";

// Chart colors...
export const chart_red_1 = "#FFC3CF";
export const chart_red_2 = "#E95D77";
export const chart_red_3 = "#E12649";
export const chart_red_4 = "#D9032B";
export const chart_red_5 = "#C20428";
export const chart_red_6 = "#AB0625";
export const chart_red_7 = "#8D041E";
export const chart_red_8 = "#670417";

export const chart_orange_1 = "#FFDFC6";
export const chart_orange_2 = "#FFC494";
export const chart_orange_3 = "#FC9F53";
export const chart_orange_4 = "#F08B39";
export const chart_orange_5 = "#DB7A2C";
export const chart_orange_6 = "#C36920";
export const chart_orange_7 = "#A65615";
export const chart_orange_8 = "#80410E";

export const chart_yellow_1 = "#FFF7D1";
export const chart_yellow_2 = "#FFEB88";
export const chart_yellow_3 = "#FFDE3E";
export const chart_yellow_4 = "#FFD400";
export const chart_yellow_5 = "#F3CA01";
export const chart_yellow_6 = "#DDBB14";
export const chart_yellow_7 = "#C1A411";
export const chart_yellow_8 = "#A1890F";

export const chart_green_1 = "#D0EBAC";
export const chart_green_2 = "#B8E27F";
export const chart_green_3 = "#A0D35C";
export const chart_green_4 = "#8BC53F";
export const chart_green_5 = "#7FB539";
export const chart_green_6 = "#70A032";
export const chart_green_7 = "#5E8728";
export const chart_green_8 = "#507125";

export const chart_lightblue_1 = "#9FCEFF";
export const chart_lightblue_2 = "#69A6E5";
export const chart_lightblue_3 = "#4E90D6";
export const chart_lightblue_4 = "#3777BB";
export const chart_lightblue_5 = "#30669F";
export const chart_lightblue_6 = "#2C6096";
export const chart_lightblue_7 = "#26517D";
export const chart_lightblue_8 = "#21476F";

export const chart_darkblue_1 = "#9BBBFC";
export const chart_darkblue_2 = "#6A91DF";
export const chart_darkblue_3 = "#30559F";
export const chart_darkblue_4 = "#1D3970";
export const chart_darkblue_5 = "#132A58";
export const chart_darkblue_6 = "#0D1E41";
export const chart_darkblue_7 = "#09162F";
export const chart_darkblue_8 = "#050C1B";

export const COLOR_NAMES = [
    "red-1",
    "red-2",
    "red-3",
    "red-4",
    "orange-1", 
    "orange-2", 
    "orange-3", 
    "orange-4", 
    "yellow-1", 
    "yellow-2", 
    "yellow-3", 
    "yellow-4", 
    "green-1",
    "green-2",
    "green-3",
    "green-4",
    "blue-1",
    "blue-2",
    "blue-3",
    "blue-4",
    "dark-blue-1",
    "dark-blue-2",
    "dark-blue-3",
    "dark-blue-4",
    "grey-1",
    "grey-2",
    "grey-3",
    "grey-4",
    "grey-5",
    "grey-6",
    "grey-7",
    "grey-8",
    "chart-red-1",
    "chart-red-2",
    "chart-red-3",
    "chart-red-4",
    "chart-red-5",
    "chart-red-6",
    "chart-red-7",
    "chart-red-8",
    "chart-orange-1",
    "chart-orange-2",
    "chart-orange-3",
    "chart-orange-4",
    "chart-orange-5",
    "chart-orange-6",
    "chart-orange-7",
    "chart-orange-8",
    "chart-yellow-1",
    "chart-yellow-2",
    "chart-yellow-3",
    "chart-yellow-4",
    "chart-yellow-5",
    "chart-yellow-6",
    "chart-yellow-7",
    "chart-yellow-8",
    "chart-green-1",
    "chart-green-2",
    "chart-green-3",
    "chart-green-4",
    "chart-green-5",
    "chart-green-6",
    "chart-green-7",
    "chart-green-8",
    "chart-lightblue-1",
    "chart-lightblue-2",
    "chart-lightblue-3",
    "chart-lightblue-4",
    "chart-lightblue-5",
    "chart-lightblue-6",
    "chart-lightblue-7",
    "chart-lightblue-8",
    "chart-darkblue-1",
    "chart-darkblue-2",
    "chart-darkblue-3",
    "chart-darkblue-4",
    "chart-darkblue-5",
    "chart-darkblue-6",
    "chart-darkblue-7",
    "chart-darkblue-8",
];