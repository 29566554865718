import * as React from "react";
import { Box, TextFieldProps } from "@mui/material";
import { TextField } from "./";
import { DateRangePicker as MaterialDateRangePicker, DateRangePickerProps as MaterialDateRangePickerProps } from "@mui/x-date-pickers-pro";
import { Moment } from "moment";

interface DateRangePickerProps extends Omit<MaterialDateRangePickerProps<Moment, Moment>, 'renderInput'> {
    renderInput?: (startProps: TextFieldProps, endProps: TextFieldProps) => React.ReactElement;
    startLabel?: string;
    endLabel?: string;
    startError?: boolean;
    endError?: boolean;
    startHelperText?: string;
    endHelperText?: string;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = React.forwardRef((props: DateRangePickerProps, ref: any) => {
    const { startLabel, endLabel, startError, endError, startHelperText, endHelperText, ...rest } = props;

    return <MaterialDateRangePicker 
        ref={ref}
        inputFormat="DD/MM/yyyy"
        mask="__/__/____"
        renderInput={(startProps, endProps) => (
            <React.Fragment>
                <TextField {...startProps} size="small" label={startLabel} error={startError} helperText={startHelperText} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} size="small" label={endLabel} error={endError} helperText={endHelperText} />
            </React.Fragment>
        )}
        {...rest}
    />;
});

export default DateRangePicker;