import * as _ from "lodash";

export function jsonParseReviver(key, value) {
    var dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    if (typeof value === 'string' && dateRegex.exec(value)) {
        // We don't want to treat naive datetime objects as UTC just yet, since most clients are returning local times naively...
        //if (!_.includes(value, '+') && !_.endsWith(value, 'Z')) {
        //    value = value + 'Z';
        //}

        const parsedDate = new Date(value);
        // Fall back to raw string value if parsed date is invalid...
        return (parsedDate instanceof Date && !isNaN(parsedDate.valueOf())) ?
            parsedDate :
            value;
    }
    return value;
}

export class BaseClient {
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor() {
        this.jsonParseReviver = jsonParseReviver;
    }
}