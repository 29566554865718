import * as React from "react";
import "./Tabs.scss";
import { Tabs as MuiTabs, Tab as MuiTab, TabsProps, TabProps } from "@mui/material";


interface TabsContainerProps extends React.ComponentProps<any> {
}

const TabsContainer: React.FunctionComponent<TabsContainerProps> = (props: TabsContainerProps) => {
    const { children, ...rest } = props;

    return (
        <div className="tabs-container" {...rest}>
            {children}
        </div>
    );
}

interface TabPanelProps {
    value?: any;
    selectedValue?: any;
    className?: string;
    children?: any;
}

const TabPanel: React.FunctionComponent<TabPanelProps> = (props: TabPanelProps) => {
    const { value, selectedValue, children } = props;
    const isVisible = value === selectedValue;

    return (
        isVisible &&
        <div className="tabpanel">
            {children}
        </div>
    );
}

const PersistentTabPanel: React.FunctionComponent<TabPanelProps> = (props: TabPanelProps) => {
    const { value, selectedValue, className, children, ...other } = props;
    const isVisible = value === selectedValue;

    return (
        <div {...other} className={`tabpanel ${isVisible ? "" : "tabpanel-hidden"}`}>
            {children}
        </div>
    );
}

const Tabs: React.FunctionComponent<TabsProps> = (props: TabsProps) => {
    return (
        <MuiTabs {...props} />
    );
}

const Tab: React.FunctionComponent<TabProps> = (props: TabProps) => {
    return (
        <MuiTab {...props} />
    );
}

export { TabsContainer, TabPanel, PersistentTabPanel, Tabs, Tab };
export type { TabPanelProps, TabsProps, TabProps };