import React from "react";
import { useAuth0 } from "./AuthContext";
import LoadingScreen from "../shared/pages/LoadingScreen";

interface RequireAuthenticationProps {
    isInitialized?: boolean;
    children: any;
}

const RequireAuthentication = (props: RequireAuthenticationProps) => {
    const { isInitialized, children } = props;
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

    React.useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                state: JSON.stringify({ targetUrl: window.location.href })
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect]);

    return !loading && isAuthenticated && isInitialized ? (
        <React.Fragment>{children}</React.Fragment>
    ) : (
            <LoadingScreen />
        );
};

export default RequireAuthentication;