import clsx from "clsx";
import { Button } from "../../../shared/components";
import { Icons } from "../Icons";
import { KpiMosaicGraphic } from "./KpiMosaicGraphic";
import type { Dimension } from "./types";

type KpiMosaicHeaderCellProps = {
    coordinates: string[],
    gridArea: string,
    className: string,
    headerData: Dimension,
    collapsible?: boolean,
    isExpanded?: boolean,
    onCollapseExpand?: (boolean, headerData) => void,
}

export function KpiMosaicHeaderCell(props: KpiMosaicHeaderCellProps) {
    const { coordinates, gridArea, className, headerData, collapsible, isExpanded, onCollapseExpand } = props;
    if (!headerData) return null;
    const { text, graphics } = headerData.display ?? { text: null, graphics: [] };

    return <div key={gridArea} className={className} style={{ gridArea }}>
        <div className="header-content">
            {
                graphics && graphics.map(graphic => <KpiMosaicGraphic {...graphic} />)
            }

            {text}
        </div>

        {
            collapsible &&
            <Button
                variant="outlined"
                color="primary"
                className={clsx("collapse-expand-button", isExpanded ? "expanded" : "collapsed")}
                onClick={e => onCollapseExpand(!isExpanded, coordinates)}
            >
                <Icons.ChevronDown />
            </Button>
        }
    </div>;
}