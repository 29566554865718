import "dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css";
import "./Gantt.scss";
import React from "react";
import { Gantt, GanttConfigOptions, GanttPlugins, GanttStatic, GanttTemplates } from "dhtmlx-gantt";
import { CoreGanttItem, GanttLink, GanttTaskType, ZoomConfig } from "./types";
import { EditTaskDialog } from "./EditTaskDialog";
import { DeleteLinkDialog } from "./DeleteLinkDialog";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "../../../store";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";

type GanttInternals = {
    _sort: { name: string; direction: string }
}

type GanttInputItem = {
    id: string;
    name: string;
    start: Date;
    end: Date;
    metadata?: Object;
    color?: string;
    textColor?: string;
    parent?: string;
    type?: string;
    open?: boolean;
}

type ZoomLevel = "year" | "month" | "week" | "day";

type Marker = {
    start_date: Date;
    end_date?: Date;
    css?: string;
    text?: string;
    title?: string;
}

type GanttDragMode = "resize" | "progress" | "move" | "ignore";

type GanttProps = {
    input?: GanttInputItem[]; // Backwards compatibility...
    data?: GanttInputItem[];
    links?: GanttLink[];
    markers?: Marker[];
    itemHeight?: number;
    mode?: ZoomLevel;
    readOnly?: boolean;
    onItemUpdated?: (item: GanttInputItem, allItems: GanttInputItem[], ganttInstance: GanttStatic) => void;
    onItemClick?: (item: GanttInputItem, ganttInstance: GanttStatic, event: Event) => boolean;
    onItemDblClick?: (item: GanttInputItem, ganttInstance: GanttStatic, event: Event) => boolean;
    onItemDrag?: (item: GanttInputItem, ganttInstance: GanttStatic, mode: GanttDragMode, task: CoreGanttItem, original: CoreGanttItem) => void;
    onLinkCreated?: (link: GanttLink, ganttInstance: GanttStatic) => boolean;
    onLinkDeleted?: (link: GanttLink, ganttInstance: GanttStatic) => void;
    onLinkDblClick?: (link: GanttLink, ganttInstance: GanttStatic, event: Event) => boolean;
    onGanttRender?: (ganttInstance: GanttStatic) => void;
    onGanttInit?: (ganttInstance: GanttStatic) => void;
    config?: GanttConfigOptions;
    plugins?: GanttPlugins;
    templates?: GanttTemplates;
    zoomConfig?: ZoomConfig;
    onBeforeShowEdit?: (task: CoreGanttItem) => boolean;
    onBeforeEditAccepted?: (task: CoreGanttItem) => boolean;
    additionalEditDialogContent?: any;
    customFields?: string[];
    manuallyMonitorConfigChanges?: boolean;
}

const augmentConfig = (config: GanttConfigOptions, itemHeight?: number, readOnly?: boolean, userConfig?: GanttConfigOptions, gantt?: GanttStatic & GanttInternals) => {
    const rowHeight = itemHeight || 36;
    config.root_id = "dhtmlx-gantt-container";
    config.date_grid = "%d %M %Y"
    config.round_dnd_dates = true;
    config.auto_scheduling = false;
    config.show_progress = false;
    config.drag_links = false;
    config.row_height = rowHeight;
    config.bar_height = rowHeight - 10; // Add vertical spacing...
    config.fit_tasks = true;
    config.readonly = readOnly;
    config.external_render = {
        // checks the element is a React element
        isElement: (element) => {
            return React.isValidElement(element);
        },
        // renders the React element into the DOM
        renderElement: (element, container) => {
            createRoot(container).render(
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {element}
                    </LocalizationProvider>
                </Provider>
            );
        }
    };

    // Workaround for bug in component where scroll sensitivity is way too high in Firefox...
    if (gantt.env.isFF && parseInt(navigator.userAgent.split("Firefox/")[1]) > 87) {
        config.wheel_scroll_sensitivity = 0.05
    }

    Object.assign(config, userConfig); // Values from config object takes precedence over all default values...
}

const setTemplates = (gantt: GanttStatic & GanttInternals, templates?: GanttTemplates) => {
    Object.assign(gantt.templates, templates);
}

const setColumns = (config: GanttConfigOptions) => {
    config.columns = [
        {
            name: "text",
            label: "Name",
            tree: true,
            width: 200,
            resize: true
        }, {
            name: "start_date",
            label: "Start",
            align: "left",
            fixed: true,
            min_width: 100,
            width: 100,
            max_width: 100
        }, {
            name: "end_date",
            label: "End",
            align: "left",
            fixed: true,
            min_width: 100,
            width: 100,
            max_width: 100
        }
    ];
}

const defaultZoomConfig: ZoomConfig = {
    levels: [
        {
            name: "day",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "month", step: 1, format: "%M %Y" },
                { unit: "day", step: 1, format: "%d" }
            ]
        },
        {
            name: "week",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "week", step: 1 },
                { unit: "day", step: 1, format: "%j %D" }
            ]
        },
        {
            name: "month",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "year", step: 1, format: "%Y" },
                { unit: "month", format: "%F" }
            ]
        },
        {
            name: "quarter",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "quarter", step: 1, format: "%M %Y" },
                { unit: "month", step: 1, format: "%M" }

            ]
        },
        {
            name: "year",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "year", step: 1, format: "%Y" }
            ]
        }
    ]
};

const mapGanttItem = (item: GanttInputItem, customFields: string[] = []): CoreGanttItem => {
    const result = {
        type: (item.type as GanttTaskType) ?? GanttTaskType.Regular,
        id: item.id,
        text: item.name,
        start_date: new Date(item.start),
        end_date: new Date(item.end),
        metadata: item.metadata,
        color: item.color,
        textColor: item.textColor,
        parent: item.parent,
        open: item.open ?? true
    };

    for (let fieldName of customFields) {
        result[fieldName] = item[fieldName];
    }

    return result;
}

const reverseMapGanttItem = (item: CoreGanttItem, customFields: string[] = []): GanttInputItem => {
    const result = {
        id: item.id,
        name: item.text,
        start: item.start_date,
        end: item.end_date,
        metadata: item.metadata,
        color: item.color,
        textColor: item.textColor,
        parent: item.parent
    };

    for (let fieldName of customFields) {
        result[fieldName] = item[fieldName];
    }

    return result;
}

function GanttWrapper(props: GanttProps) {
    const { itemHeight, mode, onItemUpdated, onItemClick, onItemDblClick, onItemDrag, onLinkCreated, onLinkDeleted, onLinkDblClick, onGanttRender, onGanttInit, readOnly, config: _config, plugins, templates, zoomConfig, onBeforeShowEdit, onBeforeEditAccepted, additionalEditDialogContent, customFields, manuallyMonitorConfigChanges } = props;
    const [showEditDialog, setShowEditDialog] = React.useState(false);
    const [editedTask, setEditedTask] = React.useState(null);
    const [showDeleteLinkDialog, setShowDeleteLinkDialog] = React.useState(false);
    const [linkToDelete, setLinkToDelete] = React.useState(null);

    const ganttContainer = React.useRef<HTMLDivElement>();
    const ganttInstance = React.useRef<GanttStatic & GanttInternals>();
    const [ganttInstanceVersion, setGanttInstanceVersion] = React.useState(0);
    const clickEventTimeout = React.useRef<number>();

    const config = Object.fromEntries(Object.entries({
        ..._config,
        columns: _config?.columns?.map(c => ({
            ...c,
            // Wrap the original onrender function to pass the Gantt instance to the user...
            onrender: c.onrender ? (task, node) => c.onrender(task, node, ganttInstance.current) : undefined
        }))
    }).filter(([_k, v]) => v !== undefined)) as GanttConfigOptions;

    React.useEffect(() => {
        let currentGanttInstance = Gantt.getGanttInstance();
        ganttInstance.current = currentGanttInstance as GanttStatic & GanttInternals;

        setColumns(ganttInstance.current.config);
        plugins && ganttInstance.current.plugins(plugins);
        augmentConfig(ganttInstance.current.config, itemHeight, readOnly, config, ganttInstance.current);
        setTemplates(ganttInstance.current, templates);
        ganttInstance.current.init(ganttContainer.current);
        onGanttInit?.(ganttInstance.current);

        return () => {
            ganttInstance.current.destructor();
            ganttInstance.current = null;
        };
    }, [ganttInstanceVersion]);

    React.useEffect(() => {
        if (manuallyMonitorConfigChanges) return;

        // Initial render, no need to rebuild the existing Gantt..
        if (!ganttInstance.current) return;

        if (
            !_.isMatch(ganttInstance.current.config, config) ||
            ganttInstance.current.config.readonly !== readOnly
        ) {
            setGanttInstanceVersion(v => v + 1);
        }
    }, [readOnly, config]);

    const onTaskEdited = React.useCallback((task: CoreGanttItem) => {
        ganttInstance.current.updateTask(task.id, task);
    }, [ganttInstanceVersion]);

    const onAfterTaskUpdate = React.useCallback((_id: string | number, task: CoreGanttItem) => {
        const updatedItem = reverseMapGanttItem(task, customFields ?? []);
        const allItems: GanttInputItem[] = [];
        ganttInstance.current.eachTask(t => allItems.push(reverseMapGanttItem(t, customFields ?? [])));

        onItemUpdated(updatedItem, allItems, ganttInstance.current);
    }, [ganttInstanceVersion, onItemUpdated]);

    React.useEffect(() => {
        if (ganttInstance.current && onItemUpdated) {
            const handlerId = ganttInstance.current.attachEvent("onAfterTaskUpdate", onAfterTaskUpdate, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [ganttInstanceVersion, onAfterTaskUpdate]);

    const onTaskClick = React.useCallback((id: string | number, e: Event) => {
        const task = ganttInstance.current.getTask(id);
        const item = reverseMapGanttItem(task, customFields ?? []);

        if (clickEventTimeout.current == null) {
            clickEventTimeout.current = window.setTimeout(() => {
                onItemClick?.(item, ganttInstance.current, e);
                clickEventTimeout.current = null;
            }, 300);
        }
        
        return true;
    }, [ganttInstanceVersion, onItemClick]);

    const onTaskDblClick = React.useCallback((id: string | number, e: Event) => {
        const task = ganttInstance.current.getTask(id);
        const item = reverseMapGanttItem(task, customFields ?? []);

        if (clickEventTimeout.current) {
            window.clearTimeout(clickEventTimeout.current);
            clickEventTimeout.current = null;
        }

        return onItemDblClick == null ?
            true :
            onItemDblClick(item, ganttInstance.current, e);
    }, [ganttInstanceVersion, onItemDblClick]);

    React.useEffect(() => {
        if (ganttInstance.current && onItemClick) {
            const handlerId = ganttInstance.current.attachEvent("onTaskClick", onTaskClick, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [onTaskClick]);

    React.useEffect(() => {
        if (ganttInstance.current && onTaskDblClick) {
            const handlerId = ganttInstance.current.attachEvent("onTaskDblClick", onTaskDblClick, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [onTaskDblClick]);

    const onTaskDrag = React.useCallback((_id: string | number, mode: GanttDragMode, task: CoreGanttItem, original: CoreGanttItem) => {
        const item = reverseMapGanttItem(task, customFields ?? []);
        onItemDrag(item, ganttInstance.current, mode, task, original);
    }, [ganttInstanceVersion, onItemDrag]);

    React.useEffect(() => {
        if (ganttInstance.current && onItemDrag) {
            const handlerId = ganttInstance.current.attachEvent("onTaskDrag", onTaskDrag, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [ganttInstanceVersion, onTaskDrag]);

    React.useEffect(() => {
        if (ganttInstance.current && onLinkCreated) {
            // If the user doesn't explicitly return a result, we assume they want to allow the link to be created...
            const handlerWrapper = (link) => {
                const result = onLinkCreated(link, ganttInstance.current);
                if (result === null || result === undefined) {
                    return true;
                }

                return result;
            }

            const handlerId = ganttInstance.current.attachEvent("onLinkCreated", handlerWrapper, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [ganttInstanceVersion, onLinkCreated]);

    React.useEffect(() => {
        if (ganttInstance.current && onLinkDeleted) {
            const handlerWrapper = (_id, link) => {
                onLinkDeleted(link, ganttInstance.current);
            };

            const handlerId = ganttInstance.current.attachEvent("onAfterLinkDelete", handlerWrapper, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [ganttInstanceVersion, onLinkDeleted]);

    React.useEffect(() => {
        if (ganttInstance.current) {
            const handlerWrapper = (id, event) => {
                const link = ganttInstance.current.getLink(id);
                const result = onLinkDblClick ? onLinkDblClick(link, ganttInstance.current, event) : true;

                // User either explicitly opted out of showing the delete dialog or the Gantt is read-only...
                if (result === false || readOnly) {
                    return;
                }

                setLinkToDelete(link);
                setShowDeleteLinkDialog(true);
            };

            const handlerId = ganttInstance.current.attachEvent("onLinkDblClick", handlerWrapper, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [ganttInstanceVersion, onLinkDblClick]);

    const onBeforeLightbox = React.useCallback((id) => {
        const task = ganttInstance.current.getTask(id);

        if (onBeforeShowEdit) {
            const shouldShowModal = onBeforeShowEdit(task);
            if (shouldShowModal === false) return false;
        }

        setEditedTask(task);
        setShowEditDialog(true);
        return false; // Don't show the default edit dialog
    }, [ganttInstanceVersion, onBeforeShowEdit]);

    React.useEffect(() => {
        if (ganttInstance.current) {
            const handlerId = ganttInstance.current.attachEvent("onBeforeLightbox", onBeforeLightbox, null);
            return (() => ganttInstance.current?.detachEvent?.(handlerId));
        }
    }, [ganttInstanceVersion, onBeforeLightbox]);

    const [ganttData, setGanttData] = React.useState({ data: props?.data || props?.input || [], links: props?.links || [], markers: props?.markers || [] });
    React.useEffect(() => {
        const newData = { data: props?.data || props?.input || [], links: props?.links || [], markers: props?.markers || [] };
        if (!_.isEqual(ganttData, newData)) {
            setGanttData(newData);
        }
    }, [props.data, props.input, props.links, props.markers]);

    React.useEffect(() => {
        if (!ganttInstance.current) return;

        const scroll: { x: number, y: number } = ganttInstance.current.getScrollState();

        const { data, links, markers } = ganttData;

        ganttInstance.current.clearAll();
        ganttInstance.current.parse({ data: data.map(d => mapGanttItem(d, customFields ?? [])), links: links });
        ganttInstance.current.scrollTo(scroll.x, scroll.y);
        ganttInstance.current.ext.zoom.init(zoomConfig || defaultZoomConfig);
        ganttInstance.current.ext.zoom.setLevel(mode || "year");

        if (markers && markers.length) {
            markers.forEach(marker => ganttInstance.current.addMarker(marker));
        }

        //re-sort because after parsing the gantt doesn"t reapply its current sort state
        const sortSettings = ganttInstance.current._sort;
        if (sortSettings && sortSettings.name !== "" && sortSettings.name !== null && sortSettings.name !== undefined) {
            const sortColumn = ganttInstance.current.getGridColumn(sortSettings.name);
            if (sortColumn && sortColumn.sort) {
                if (typeof sortColumn.sort === "boolean") {
                    ganttInstance.current.sort(sortSettings.name, sortSettings.direction === "desc");
                }
                if (typeof sortColumn.sort !== "boolean") {
                    const sortFn = sortColumn.sort as (a: CoreGanttItem, b: CoreGanttItem) => number;
                    ganttInstance.current.sort(sortFn, sortSettings.direction === "desc");
                }
            }
        }

        onGanttRender?.(ganttInstance.current);
    }, [ganttInstanceVersion, ganttData]);

    const onDeleteAccepted = React.useCallback(() => {
        if (linkToDelete) {
            ganttInstance.current?.deleteLink(linkToDelete.id);
        }
    }, [linkToDelete, ganttInstanceVersion]);

    return (
        <div className="fill" style={{ position: "relative" }}>
            <div id="dhtmlx-gantt-container" ref={ganttContainer} className="dhtmlx-gantt-container" ></div>
            <EditTaskDialog task={editedTask} onTaskEdited={onTaskEdited} showDialog={showEditDialog} setShowDialog={setShowEditDialog} additionalContent={additionalEditDialogContent} onBeforeEditAccepted={onBeforeEditAccepted} />
            <DeleteLinkDialog link={linkToDelete} onDeleteAccepted={onDeleteAccepted} showDialog={showDeleteLinkDialog} setShowDialog={setShowDeleteLinkDialog} />
        </div>
    );
}

(GanttWrapper as DocumentedComponent).metadata = {
    description: "The Gantt component displays work items in a timeline, with the ability to edit these.  This component is a wrapper around the [dhtmlxGantt](https://docs.dhtmlx.com/gantt/) library.",
    isSelfClosing: true,
    attributes: [
        {
            name: `input`, type: `object`, description:
                `The list of items to show in the Gantt.  See below for the structure of this type.

| Name | Type | Description |
|------|------|-------------|
| \`id\` | \`string\` | The unique id for this task.  Must be unique across the current list of items. |
| \`parent\` | \`string?\` | The optional parent id for this task.  If populated, this task will display as a subtask of the specified parent. |
| \`type\` | \`'project' \\| 'milestone' \\| 'task' \\| 'placeholder' \\| 'stage'\` | The optional type of the task - defaults to \`'task'\`.  Determines how the item is displayed and the behavior when a child task is moved/resized.  Parent items should be set to \`'project'\` in order to automatically size them based on the child task durations.  See [the component documentation page](https://docs.dhtmlx.com/gantt/desktop__task_types.html) for more details on the various task types. |
| \`name\` | \`string\` | The title/name for this task.  Used for display purposes. |
| \`start\` | \`date\` | The start date for this task.  **Note:** _Javascript \`date\` objects include the time._ |
| \`end\` | \`date\` | The end date for this task.  **Note:** _Javascript \`date\` objects include the time._ |
| \`color\` | \`string?\` | The optional color to use for the task bar.  Can be a named color, hex- or RGB string.  E.g., \`red\`, \`#FF0000\` or \`rgb(255, 0, 0)\`. |
| \`textColor\` | \`string?\` | The optional color to use for the text in the task bar.  Can be a named color, hex- or RGB string.  E.g., \`red\`, \`#FF0000\` or \`rgb(255, 0, 0)\`. |
| \`metadata\` | \`any?\` | Any additional data that should be included alongside this item.  Available to the event handlers and any other points where the items are exposed. |
| \`open\` | \`boolean?\` | Only relevant to parent tasks.  Controls the default expanded state of the item.  Optional - defaults to \`true\`. |` },
        {
            name: `links`, type: `object`, description:
                `The (optional) links to display between items in the Gantt.  See below for the structure of this type.

| Name | Type | Description |
|------|------|-------------|
| \`id\` | \`string\` | The unique id for this link.  Must be unique across the current list of links. |
| \`source\` | \`string\` | The id of the source task.  The link will be drawn from this task to the \`target\` task. |
| \`target\` | \`string\` | The id of the target task.  The link will be drawn from the \`source\` task to this task. |
| \`type\` | \`number\` | The optional type of the link - defaults to \`0\`.  Determines how the link is drawn and the scheduling rules to apply between the linked tasks.  See [the component documentation page](https://docs.dhtmlx.com/gantt/api__gantt_links_config.html) for the available options. |` },
        {
            name: `markers`, type: `object`, description: `
The (optional) list of markers to display in the Gantt.  Note that the \`markers\` plugin need to be enabled in order to use this functionality - please ensure that the \`plugins\` object contains \`markers: true\`.  See below for the structure of this type.

| Name | Type | Description |
|------|------|-------------|
| \`start_date\` | \`Date\` | The date where the marker will be displayed from. |
| \`end_date\` | \`Date?\` | The (optional) date where the marker will be displayed to. |
| \`css\` | \`string?\` | The (optional) CSS class to apply to the marker. |
| \`text\` | \`string?\` | The (optional) text to display alongside the marker. |
| \`title\` | \`string?\` | The (optional) tooltip text to display when the user hovers over the marker. | ` },
        { name: `readOnly`, type: `boolean`, description: "If `true`, the items in the Gantt cannot be edited.  This includes renaming them, dragging them and resizing them." },
        { name: `itemHeight`, type: `number`, description: "The optional height of each row.  Defaults to `36` pixels." },
        { name: `mode`, type: `string`, description: "The zoom level of the Gantt.  Valid options are: `\"year\"`, `\"month\"`, `\"week\"` and `\"day\"`.  Defaults to `\"year\"`." },
        { name: `onItemUpdated`, type: "function", template: `onItemUpdated={(updatedItem, allItems, gantt) => {$1}}`, description: "The callback function to be invoked when an item is modified.  The first parameter is the new item (after update is applied).  The second parameter is the new item list (after update is applied).  The third parameter is the underlying Gantt component.  This function is responsible for diffing/persisting the changes made to this item and/or persisting the item list." },
        { name: `onItemClick`, type: "function", template: `onItemClick={(item, gantt, event) => {$1}}`, description: "The callback function to be invoked when an item is clicked.  The first parameter is the item that was clicked.  The second parameter is the underlying Gantt component.  The third parameter is the source DOM event." },
        { name: `onItemDblClick`, type: "function", template: `onItemDblClick={(item, gantt, event) => {$1}}`, description: "The callback function to be invoked when an item is double-clicked.  The first parameter is the item that was double-clicked.  The second parameter is the underlying Gantt component.  The third parameter is the source DOM event.  If this function returns a falsey value, the event will not propagate any further (this cancels the opening of the edit dialog, etc.)." },
        { name: `onItemDrag`, type: "function", template: `onItemDrag={(item, gantt, mode, task, originalTask) => {$1}}`, description: "The callback function to be invoked when an item is dragged.  The first parameter is the item that was dragged.  The second parameter is the underlying Gantt component." },
        { name: `onLinkCreated`, type: "function", template: `onLinkCreated={(link, gantt) => {$1}}`, description: "The callback function to be invoked when a link is about to be created.  The first parameter is the link that was added.  The second parameter is the underlying Gantt component.  If this function returns a `falsey` value, the link will not be added to the Gantt." },
        { name: `onLinkDeleted`, type: "function", template: `onLinkDeleted={(link, gantt) => {$1}}`, description: "The callback function to be invoked when a link has been deleted.  The first parameter is the link that was deleted.  The second parameter is the underlying Gantt component." },
        { name: `onLinkDblClick`, type: "function", template: `onLinkDblClick={(link, gantt, event) => {$1}}`, description: "The callback function to be invoked when a link is double-clicked.  This would most commonly be used to delete a link or to open an edit modal.  The first parameter is the link that was double-clicked.  The second parameter is the underlying Gantt component.  The third parameter is the source DOM event." },
        { name: `onGanttRender`, type: "function", template: `onGanttRender={(gantt) => {$1}}`, description: "The callback function to be invoked when the Gantt has finished rendering.  The first parameter is the underlying Gantt component, which can be used to toggle layouts or manipulate tasks, if required." },
        { name: `onGanttInit`, type: "function", template: `onGanttInit={(gantt) => {$1}}`, description: "The callback function to be invoked when the Gantt is being initialized.  This can be used to override config, enable extensions, add task layers, etc." },
        { name: `config`, type: `object`, description: "Optional config object - allows for customization of most aspects of the component.  See [the component documentation page](https://docs.dhtmlx.com/gantt/api__refs__gantt_props.html) for the full list of fields/options available." },
        { name: `templates`, type: `object`, description: "Optional template override object - allows for customization of Gantt elements and their content.  See [the component documentation page](https://docs.dhtmlx.com/gantt/desktop__templates.html) for the full list of overridable templates." },
        { name: `plugins`, type: `object`, description: "Optional configuration object used to enable plugins for the component.  See [the component documentation page](https://docs.dhtmlx.com/gantt/desktop__extensions_list.html) for the full list of available plugins." },
        { name: `onBeforeShowEdit`, type: "function", template: `onBeforeShowEdit={(task) => {$1}}`, description: "Optional callback function to invoke just before the task edit dialog is shown.  If this function returns a `false` value, the dialog is not displayed." },
        { name: `onBeforeEditAccepted`, type: "function", template: `onBeforeEditAccepted={(task) => {$1}}`, description: "Optional callback function to invoke when the user clicks the \"Accept\" button on the Task Edit dialog.  If this function returns a `false` value, the changes are not applied and the dialog remains open." },
        { name: `additionalEditDialogContent`, type: "object", description: "Additional content to display in the Task Edit dialog.  This content is rendered directly beneath the standard task fields." },
        { name: `manuallyMonitorConfigChanges`, type: "boolean", description: "By default, the component automatically re-initializes itself when changes to the `config` prop are detected.  This (optional) prop allows for disabling this behaviour.  This can be used to prevent unnecessary re-initialization when the `config` prop contains callback functions (such as custom column renderers).  If `true`, re-initialization of the component can be forced via the standard `key` prop.  Defaults to `false`." },
    ]
};

export default GanttWrapper;