import * as React from "react";
import "./FileUpload.scss";
import { Button } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import clsx from "clsx";

interface FileUploadProps {
    onFilesSelected?: (files: File[]) => void;
    showButton?: boolean;
    buttonContent?: JSX.Element;
    showDragDropArea?: boolean;
    dragDropAreaContent?: JSX.Element;
    className?: string;
}

function FileUpload(props: FileUploadProps) {
    const { onFilesSelected, showButton = true, buttonContent, showDragDropArea, dragDropAreaContent, className, ...rest } = props;
    const inputRef = React.useRef<HTMLInputElement>();
    const [dragActive, setDragActive] = React.useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files.length < 1) return;

        onFilesSelected?.([...files]);
    }

    const handleDragEnter = React.useCallback((e: React.DragEvent<any>) => {
        e.preventDefault();
        setDragActive(true);
    }, [setDragActive]);

    const handleDragExit = React.useCallback((e: React.DragEvent<any>) => {
        e.preventDefault();
        setDragActive(false);
    }, [setDragActive]);

    const handleDragOver = React.useCallback((e: React.DragEvent<any>) => {
        e.preventDefault();
    }, []);

    const handleDrop = React.useCallback((e: React.DragEvent<any>) => {
        e.preventDefault();
        setDragActive(false);

        const files = e.dataTransfer.files;
        if (files?.length < 1) return;

        onFilesSelected?.([...files]);
    }, [setDragActive, onFilesSelected]);

    return (
        <form className={clsx("file-upload-container", className)}>
            {
                showDragDropArea &&
                <div
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragExit}
                    onDragOver={handleDragOver}
                    className={clsx("file-upload-drag-drop-area", dragActive && "drag-active")}
                >
                    <div className="file-upload-drag-drop-area-text">
                        {dragDropAreaContent ?? (showButton ? "Drag and drop files here\nor click to upload." : "Drag and drop files\nhere to upload.") }
                    </div>
                </div>
            }

            {
                showButton &&
                <>
                    <input type="file" ref={inputRef} onChange={handleChange} {...rest} />
                    <Button className="file-upload-button" variant="outlined" onClick={() => { inputRef.current.click(); }}  {...rest}>
                        {buttonContent ?? "Select File"}
                    </Button>
                </>
            }
        </form>
    );
}

(FileUpload as DocumentedComponent).metadata = {
    description: "The `FileUpload` control allows the user to select files for upload.  Selected file(s) must be processed via the `onFilesSelected` event handler, in order to manipulate and persist the contents to parameter value(s).",
    isSelfClosing: true,
    attributes: [
        { name: `multiple`, type: `boolean`, description: "If `true`, multiple files can be selected." },
        { name: `accept`, type: `string`, description: "A comma-separated list of file types that the control will accept.  Comma-separated list of File Types which can be uploaded by the user.  See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept) for more details on this attribute." },
        { name: `onFilesSelected`, type: `function`, description: "The callback to be invoked when the user has selected the files to upload.  Passes in a list of [File](https://developer.mozilla.org/en-US/docs/Web/API/File) objects to be processed.  Reading and processing the file contents must be done here and any required parameter values persisted as required." },
        { name: `showButton`, type: `boolean`, description: "If `true`, a button is shown which the user can click to select files for upload.  Optional - defaults to `true`." },
        { name: `buttonContent`, type: `string`, description: "The content to render inside the button.  Optional - defaults to `\"Select File\"`" },
        { name: `showDragDropArea`, type: `boolean`, description: "If `true`, a drag-and-drop area is shown above the upload button.  Dragging files onto this area will have the same effect as using the upload button.  Optional - defaults to `false`." },
        { name: `dragDropAreaContent`, type: `object`, description: "The content to render inside the drag-and-drop area.  Optional - defaults to `\"Drag and drop files here or click to upload.\"`." },
    ]
};

export { FileUpload }