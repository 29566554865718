import React from "react";
import { logger, SeverityLevel } from "../services/loggingService";

export default class AppInsightsErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error: error, errorInfo: errorInfo });
        logger.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: { ...errorInfo }
        });
    }

    render() {
        if (this.state.error) {
            const { onError } = this.props;
            return typeof onError === "function"
                ? onError(this.state)
                : React.createElement(onError, this.state);
        }

        return this.props.children;
    }
}

