import "./MultiTriangle.scss";
import { useId } from "react";
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeInputProps, VerticalFillDefinition } from "./common";

function MultiTriangle(props: NodeInputProps) {
    const id = useId();
    const verticalFillId = `vertical-fill-${id}`;
    const fillPercentage = props.data?.fillPercentage;
    const fill = fillPercentage !== undefined && fillPercentage !== null ? `url("#${verticalFillId}")` : null;

    return (
        <div className="FlowChart-node FlowChart-node-multi-triangle">
            <Handle type="source" id="source-top" position={Position.Top} style={{ top: "7%" }} />
            <Handle type="source" id="source-left" position={Position.Left} style={{ left: "12%" }} />
            <Handle type="source" id="source-bottom" position={Position.Bottom} />
            <Handle type="source" id="source-right" position={Position.Right} style={{ right: "12%" }} />

            <Handle type="target" id="target-top" position={Position.Top} style={{ top: "7%" }} />
            <Handle type="target" id="target-left" position={Position.Left} style={{ right: "12%" }} />
            <Handle type="target" id="target-bottom" position={Position.Bottom} />
            <Handle type="target" id="target-right" position={Position.Right} style={{ right: "12%" }} />

            <svg className="node-shape" viewBox="0 0 140 90" preserveAspectRatio="none" width="100%">
                <defs>
                    <VerticalFillDefinition fillPercentage={fillPercentage} id={verticalFillId} />
                </defs>
                <polygon points="50 1 1 80 100 80" strokeWidth="2" style={{ fill, stroke: fill }}></polygon>
                <polygon points="90 1 40 80 139 80" strokeWidth="2" style={{ fill, stroke: fill }}></polygon>
                <polygon points="70 10 20 89 120 89" strokeWidth="2" style={{ fill, stroke: fill }}></polygon>
            </svg>

            <div className="node-content">
                <NodeContent {...props} />
            </div>
        </div>
    );
}

export { MultiTriangle };