import React from "react";
import { connect } from "react-redux";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { RootState } from "../../../store";
import { updateCurrentCanvasState } from "../../../store/storyline/actions";

interface CanvasStateProps {
    updateCurrentCanvasState: typeof updateCurrentCanvasState;
    parameterValues: any;
    currentFrame: any;
    children?: any;
    [key: string]: any;
}

function _CanvasState(props: CanvasStateProps) {
    const { updateCurrentCanvasState, parameterValues, currentFrame, children, ...fields } = props;

    React.useLayoutEffect(() => {
        updateCurrentCanvasState(fields);
    }, [parameterValues, currentFrame]);
    
    return null;
}

const CanvasState = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        currentFrame: state.storyline.currentParagraph?.data
    }),
    { updateCurrentCanvasState: updateCurrentCanvasState as any })(_CanvasState);

(CanvasState as DocumentedComponent).metadata = {
    description: `The CanvasState component can be used to expose constants, calculated values, function definitions, etc., to the Canvas.  These can be referenced directly by name in any of the canvas bindings and facilitate re-use and additional abstractions.

In the case of a naming conflict between the canvas state, frame data and/or parameter values, the following prioritization is used:

1. Parameter Values
2. Frame Data
3. Canvas State`,
    isSelfClosing: true,
    attributes: []
};

export { CanvasState };