import React from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../store/index";
import _ from "lodash";
import { renderValue } from "./shared";
import { Button } from "../../shared/components";
import { updateParameterValue } from "../../store/storyline/actions";
import { jsonParseReviver } from "../../shared/api-client";

interface ParameterValuesProps {
    parameterValues: Map<string, any>
}

function ParameterValues(props: ParameterValuesProps) {
    const { parameterValues } = props;

    return (
        <div className="table-container">
            <table className="table bordered no-header key-value">
                <tbody>
                    {
                        _.map(Array.from(parameterValues.entries()), entry => <ParameterValueRow key={entry[0]} entry={entry} />)
                    }
                </tbody>
            </table>
        </div>
    );
}

interface ParameterValueRowProps {
    entry: [string, any]
}

function ParameterValueRow(props: ParameterValueRowProps) {
    const { entry } = props;
    const [name, value] = entry;

    const dispatch = useDispatch();
    const [modifiedValue, setModifiedValue] = React.useState(JSON.stringify(value, null, 2));

    React.useEffect(() => {
        setModifiedValue(JSON.stringify(value, null, 2));
    }, [value]);

    const applyChanges = React.useCallback(() => {
        try {
            const parsedValue = JSON.parse(modifiedValue, jsonParseReviver);
            dispatch(updateParameterValue(name, parsedValue));
        }
        catch (e) {

        }
    }, [name, modifiedValue]);

    return (
        <tr>
            <td className="heading-cell">
                {name}
            </td>
            <td>
                {renderValue(value, setModifiedValue)}
            </td>
            <td>
                <Button variant="contained" color="secondary" size="small" onClick={applyChanges}>Apply Changes</Button>
            </td>
        </tr>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    null)(ParameterValues);