import "./Vdt.scss";
import React, { useState, useRef, useEffect } from "react";
import { ValueDriverTree, ValueDriverTreeOptions } from "./valueDriverTree";
import { ValueDriverTreeNode, VdtNode } from "./VdtNode";
import { useResizeDetector } from 'react-resize-detector';
import Slider from "../Slider";
import { IconButton, Tooltip } from "@mui/material";
import { ZoomIn, ZoomOut, Tune, Visibility, VisibilityOff } from "@mui/icons-material";
import { formatNumber } from "../../utilities";
import clsx from "clsx";
import _ from "lodash";
import { createPortal } from "react-dom";


export interface VdtProps extends ValueDriverTreeOptions {
    className?: string;
    root: ValueDriverTreeNode<any>;
    hideControls?: boolean;
    staticPlot: boolean;
    nodeStatusMap?: Object;
    defaultCustomNodeTemplate?: string;
    getCustomNodeContent?: (node: ValueDriverTreeNode<any>) => any;
    getNodeTooltip?: (node: ValueDriverTreeNode<any>) => any;
}

function getAllNodes(node: ValueDriverTreeNode<any>): ValueDriverTreeNode<any>[] {
    const allChildren = _.flatMap(node.nodes, getAllNodes);
    return [node, ...allChildren];
}

function Vdt(props: VdtProps) {
    const { className, root, onExpand, onCollapse, onFieldValueEdited, nodeStatusMap, onNodeClick, onNodeTitleClick, defaultCustomNodeTemplate, getCustomNodeContent, staticPlot, getNodeTooltip } = props;
    const vdtRef = useRef(null);
    const [tree, setTree] = useState<ValueDriverTree<any>>();
    const { width, height, ref } = useResizeDetector();
    const [zoomLevel, setZoomLevel] = React.useState(1);
    const [showControls, setShowControls] = React.useState(false);
    const [_version, setVersion] = React.useState(0); // Used to force a re-render when new tree nodes are rendered...

    const renderTree = () => {
        if (!root) {
            return;
        }

        const vdt = new ValueDriverTree(vdtRef.current, {
            ...props,
            width: vdtRef.current?.clientWidth,
            height: vdtRef.current?.clientHeight,
            onZoomLevelChanged: setZoomLevel
        });
        vdt.root = JSON.parse(JSON.stringify(root));
        vdt.duration = 0; // Disable animation for initial render...
        nodeStatusMap && vdt.updateNodeStatuses(nodeStatusMap, true);
        vdt.draw(vdt.root as any);
        vdt.zoomToFit();
        vdt.duration = 750; // Re-enable animation for user-initiated events...
        setTree(vdt);
    };

    // Prop changes which require a full reinitialization...
    useEffect(() => { setTimeout(renderTree, 0) }, [root]);

    // Prop changes which require a redraw...
    React.useEffect(() => {
        if (tree) {
            tree.width = vdtRef.current?.clientWidth;
            tree.height = vdtRef.current?.clientHeight;
            tree.duration = 0; // Disable animation for initial render...
            tree.zoomToFit();
            tree.duration = 750; // Re-enable animation for user-initiated events...
        }
    }, [width, height]);

    // Prop changes which simply update existing tree fields...
    React.useEffect(() => {
        if (!tree) return;

        tree.onExpand = onExpand;
        tree.onCollapse = onCollapse;
        tree.onFieldValueEdited = onFieldValueEdited;
    }, [tree, onExpand, onCollapse, onFieldValueEdited]);

    // Selectively collapse/expand nodes based on the status map...
    React.useEffect(() => {
        if (!tree) return;

        tree.updateNodeStatuses(nodeStatusMap);
    }, [nodeStatusMap]);

    const handleZoomSliderChange = (_e: Event, newValue: number) => {
        if (!tree) return;

        tree.zoomTo(newValue);
    }

    const formatSliderValue = React.useCallback((v) => formatNumber(v, 1), []);

    const allNodes = React.useMemo(() => getAllNodes(root), [root]);
    const nodeExpandCollapseCallback = React.useCallback((node) => {
        Reflect.apply(tree.toggleNodeById, tree, [node.id]);
        setVersion(v => v + 1);
    }, [tree]);

    return (
        <div className="vdt-and-controls-container">
            <div className={clsx("vdt-container", className)} ref={ref}>
                <div className="vdt-inner" ref={vdtRef}>
                    {
                        !props?.root ? <div className="col-fill centered error">
                            <h3 className="error-message">"root" property is empty/missing.  Please check the data source and template bindings.</h3>
                        </div> :
                            <>
                                {
                                    tree &&
                                    allNodes.map(node => {
                                        const target = vdtRef.current.querySelector(`#node-${CSS.escape(node.id)}`);
                                        if (!target)
                                            return null;

                                        return createPortal(
                                            <VdtNode
                                                node={node}
                                                expandCollapseCallback={nodeExpandCollapseCallback}
                                                zoomLevel={tree.mode}
                                                staticPlot={staticPlot}
                                                onFieldValueEdited={onFieldValueEdited}
                                                onNodeClick={onNodeClick}
                                                onNodeTitleClick={onNodeTitleClick}
                                                defaultCustomNodeTemplate={defaultCustomNodeTemplate}
                                                getCustomNodeContent={getCustomNodeContent}
                                                getNodeTooltip={getNodeTooltip}
                                            />,
                                            target
                                        )
                                    })
                                }
                            </>
                    }
                </div>
            </div>

            <div className="controls-container">
                {
                    showControls &&
                    <div className="zoom-controls">
                        <IconButton color="primary" onClick={() => tree.zoomTo(zoomLevel + 0.1)}>
                            <ZoomIn />
                        </IconButton>
                        <Slider min={0.1} max={8} step={0.1} value={zoomLevel} valueLabelFormat={formatSliderValue} onChange={handleZoomSliderChange} orientation="vertical" />
                        <IconButton color="primary" onClick={() => tree.zoomTo(zoomLevel - 0.1)}>
                            <ZoomOut />
                        </IconButton>
                    </div>
                }
                {
                    !props.hideControls && (
                        <div className="vdt-controls">
                            <Tooltip arrow title={showControls ? "Hide Zoom Controls" : "Show Zoom Controls"}>
                                <IconButton color="primary" onClick={() => setShowControls(!showControls)}>
                                    <Tune />
                                </IconButton>
                            </Tooltip>

                            <Tooltip arrow title="Expand all Nodes">
                                <IconButton color="primary" onClick={() => tree.toggleAllNodes("expand")}>
                                    <Visibility />
                                </IconButton>
                            </Tooltip>

                            <Tooltip arrow title="Collapse all Nodes">
                                <IconButton color="primary" onClick={() => tree.toggleAllNodes("collapse")}>
                                    <VisibilityOff />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export { Vdt };