import * as React from "react";
import { Fab, ProgressBar, TextField } from "../";
import { Collapse, ArrowRight } from "../icons";
import { ContentRenderer } from "../../../viewer/components/ContentRenderer";
import Plot from "react-plotly.js";
import clsx from "clsx";
import { JsxRenderer } from "../../../viewer/components/ContentRenderer/JsxRenderer";
import { Tooltip } from "../../../viewer/components/Tooltip";

export interface ValueDriverTreeNode<TModel> {
    id: any;
    title: string;
    class?: string;
    type?: "fields" | "chart" | "custom";
    fields?: ValueDriverTreeNodeField[];
    backgroundColor?: string;
    foregroundColor?: string;
    progressBarValue?: number;
    previousProgressBarValue?: number;
    progressBarText?: string;
    progressBarColor?: string;
    isExpanded: boolean;
    model: TModel;
    nodes: ValueDriverTreeNode<TModel>[];
    formula?: string;
    collapsed: boolean;
    tooltip?: string;
    hideTitle?: boolean;
    hideProgressBar?: boolean;
    template?: string;
}

export interface ValueDriverTreeNodeField {
    name: string;
    value: string;
    class: string;
    isEditable?: boolean;
}

export interface ChartNodeModel {
    data: object[]
    layout: object
}

interface VdtNodeProps {
    node: ValueDriverTreeNode<any>;
    zoomLevel: "full" | "simple";
    expandCollapseCallback?: (node: any) => void;
    staticPlot: boolean;
    onFieldValueEdited?: ({ node, field }: { node: ValueDriverTreeNode<any>, field: ValueDriverTreeNodeField }) => void;
    onNodeClick?: (node: ValueDriverTreeNode<any>) => void;
    onNodeTitleClick?: (node: ValueDriverTreeNode<any>) => void;
    defaultCustomNodeTemplate?: string;
    getCustomNodeContent?: (node: ValueDriverTreeNode<any>) => any;
    getNodeTooltip?: (node: ValueDriverTreeNode<any>) => any;
}

const VdtNodeBody: React.FunctionComponent<VdtNodeProps> = (props: VdtNodeProps) => {
    const { node } = props;
    const { type } = node;

    switch (type) {
        case "chart": return <ChartVdtNode {...props} />;
        case "custom": return <CustomVdtNode {...props} />;
        default: return <FieldsVdtNode {...props} />;
    }
};

const VdtNode: React.FunctionComponent<VdtNodeProps> = (props: VdtNodeProps) => {
    const { node, expandCollapseCallback, onNodeClick, getNodeTooltip } = props;
    const zoomLevel = props?.zoomLevel || "full";
    const hasChildren = node?.nodes?.length > 0;
    const backgroundOpacity = zoomLevel === "full" ?
        0 :
        node.progressBarValue || 0;

    return (
        <>
            <div className="vdtNodeBackground" style={{ opacity: backgroundOpacity }}></div>
            <VdtNodeTooltip node={node} getNodeTooltip={getNodeTooltip}>
                <div className={clsx("vdtNodeBody", { "clickable-element": onNodeClick })} onClick={() => onNodeClick?.(node)}>
                    <VdtNodeBody {...props} />
                    {
                        hasChildren &&
                        <div className="vdtNodeExpandCollapseButton">
                            <Fab size="small" onClick={(e) => { expandCollapseCallback(node); e.stopPropagation(); }}>
                                <Collapse size="small" />
                            </Fab>
                        </div>
                    }
                </div>
            </VdtNodeTooltip>
        </>
    );
};

const VdtNodeTooltip: React.FunctionComponent<any> = (props: any) => {
    const { node, getNodeTooltip, children } = props;
    const tooltip = getNodeTooltip ? getNodeTooltip(node) : node.tooltip;

    if (!tooltip)
        return children;

    return (
        <Tooltip title={tooltip} arrow placement="top" disableInteractive>
            {children}
        </Tooltip>
    );
};


interface FieldValueProps {
    node: ValueDriverTreeNode<any>;
    field: ValueDriverTreeNodeField;
    onFieldValueEdited?: ({ node, field }: { node: ValueDriverTreeNode<any>, field: ValueDriverTreeNodeField }) => void;
}

const EditableFieldValue: React.FunctionComponent<FieldValueProps> = (props: FieldValueProps) => {
    const { node, field, onFieldValueEdited } = props;
    const [value, setValue] = React.useState(field?.value);

    const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(e.target.value);
        field.value = e.target.value;
    }, [setValue]);

    const onBlur = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onFieldValueEdited && onFieldValueEdited({ node, field });
    }, [onFieldValueEdited]);

    return (
        <TextField fullWidth value={value} onChange={onChange} onBlur={onBlur} />
    );
};

const FieldValue: React.FunctionComponent<FieldValueProps> = (props: FieldValueProps) => {
    const { node, field, onFieldValueEdited } = props;

    return (
        <>
            {
                field.isEditable ?
                    <EditableFieldValue node={node} field={field} onFieldValueEdited={onFieldValueEdited} /> :
                    <ContentRenderer input={field.value} />
            }
        </>
    );
};

const FieldsVdtNode: React.FunctionComponent<VdtNodeProps> = (props: VdtNodeProps) => {
    const { node, onFieldValueEdited, onNodeTitleClick: _onNodeTitleClick } = props;
    const onNodeTitleClick = React.useCallback((e) => {
        if (_onNodeTitleClick) {
            _onNodeTitleClick(node);
            e.stopPropagation();
        }
    }, [_onNodeTitleClick]);

    return (
        <div className="vdtFieldsContainer">
            {
                !node.hideTitle &&
                <>
                    <div className={clsx("vdtNodeHeader", { "clickable-element": _onNodeTitleClick })} onClick={onNodeTitleClick}>
                        {node.title}
                    </div>
                    <hr className="vdtNodeHeaderSeparator" />
                </>
            }

            <div className={`vdtValues ${node.fields?.length === 3 ? "from-to" : "fields"}`}>
                {
                    node.fields == null ?
                        null :
                        node.fields?.length === 3 ?
                            <div className="fromToValues">
                                <div className="field">
                                    <span className="name">
                                        <ContentRenderer input={node.fields[0].name} />
                                    </span>
                                    <span className={clsx("value", node.fields[0].class)}>
                                        <FieldValue node={node} field={node.fields[0]} onFieldValueEdited={onFieldValueEdited} />
                                    </span>
                                </div>
                                <ArrowRight size="small" />
                                <div className="field">
                                    <span className="name to-value">
                                        <ContentRenderer input={node.fields[1].name} />
                                    </span>
                                    <span className={clsx("value", "to-value", node.fields[1].class)}>
                                        <FieldValue node={node} field={node.fields[1]} onFieldValueEdited={onFieldValueEdited} />
                                    </span>
                                </div>
                                <div className="field">
                                    <span className="name">
                                        <ContentRenderer input={node.fields[2].name} />
                                    </span>
                                    <span className={clsx("value", "delta-value", node.fields[2].class)}>
                                        <FieldValue node={node} field={node.fields[2]} onFieldValueEdited={onFieldValueEdited} />
                                    </span>
                                </div>
                            </div> :
                            <div className="valuesList">
                                {node.fields.map(field =>
                                    <>
                                        <div className="label">
                                            <ContentRenderer input={field.name} />
                                        </div>
                                        <div className={`value ${field.class || ""}`}>
                                            <FieldValue node={node} field={field} onFieldValueEdited={onFieldValueEdited} />
                                        </div>
                                    </>
                                )}
                            </div>
                }
            </div>
            <div className="vdtProgressBar">
                {
                    !node?.hideProgressBar &&
                    <ProgressBar value={node.progressBarValue} text={node.progressBarText} />
                }
            </div>
        </div>
    );
};

const style = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

const ChartVdtNode: React.FunctionComponent<VdtNodeProps> = (props: VdtNodeProps) => {
    const { node, staticPlot } = props;
    const { model } = node;
    const { data, layout } = model;

    return (
        <div className="vdtChartContainer">
            {
                !node.hideTitle &&
                <>
                    <div className="vdtNodeHeader">
                        {node.title}
                    </div>
                </>
            }
            <div className="vdtChart">
                <Plot
                    data={data}
                    layout={layout}
                    config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: staticPlot, displayModeBar: false }}
                    style={style}
                />
            </div>
        </div>
    );
};

const CustomVdtNode: React.FunctionComponent<VdtNodeProps> = (props: VdtNodeProps) => {
    const { node, defaultCustomNodeTemplate: defaultTemplate, getCustomNodeContent } = props;
    const { model, template } = node;
    const data = { ...node, ...model };

    return (
        <div className="vdtCustomContainer">
            {
                getCustomNodeContent ?
                    getCustomNodeContent(node) :
                    <JsxRenderer template={template || defaultTemplate} data={data} />
            }
        </div>
    );
};

export { VdtNode };
export type { VdtNodeProps };