import { LoadingContainer } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

(LoadingContainer as DocumentedComponent).metadata = {
    description: "",
    attributes: [
        { name: "isLoading", type: "boolean", description: "Determines whether to show the loading indicator or the actual content.  If `true`, the loading indicator is shown, otherwise the content is rendered.  Optional - defaults to `false`." },
        { name: "type", type: "string", options: ["circular", "full", "linear"], description: "The type of loading indicator to display.  Optional - defaults to `full`." },
        { name: "title", type: "string", description: "The (optional) title to display in the loading indicator." },
        { name: "description", type: "string", description: "The (optional) description to display in the loading indicator." },
        { name: "className", type: "string", description: "The (optional) class name(s) to apply to the container div." }
    ]
};

export { LoadingContainer };