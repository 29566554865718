import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import "./CanvasDebugger.scss";
import { showError } from "../../store/notifications/actions";
import { Tabs, Tab, AppBar, PersistentTabPanel } from "../../shared/components";
import TemplatesTab from "./Templates";
import FrameDataTab from "./FrameData";
import ParameterValuesTab from "./ParameterValues";
import { Canvas, StorylineState } from "../../store/storyline/types";
import Traces from "./Traces";
import VsCode from "./VsCode";
import * as _ from "lodash";
import { RootState } from "../../store";
import Datasources from "./Datasources";
import { usePopoutWindowContext } from "../../shared/components/PopoutWindow";

interface CanvasDebuggerProps {
    slide: Canvas,
    frameData: object;
    storyline: StorylineState;
    style?: CSSProperties;
}

function CanvasDebugger(props: CanvasDebuggerProps) {
    const { slide, frameData, storyline, style } = props;
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    // The editor controls do not enjoy being used in a semi-headless state, so we add a small artificial delay between initial pop-up window render
    // and the time the editor is shown...
    const [loading, setLoading] = React.useState(true);
    const popoutWindowContext = usePopoutWindowContext();

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            // After all the controls are rendered, pull the new dynamic styles from the parent window...
            setTimeout(() => popoutWindowContext.pullStylesFromParentWindow());
        }, 100);
    }, []);

    const handleTabChange = (event: React.ChangeEvent<{}>, newSelectedIndex: number) => {
        setSelectedTabIndex(newSelectedIndex);
    };

    if (!slide || !storyline) return null;

    return (
        <div className="canvas-debugger" style={style}>
            <Tabs
                value={selectedTabIndex}
                onChange={handleTabChange}
                centered
            >
                <Tab label="Metadata" />
                <Tab label="Templates" />
                <Tab label="Frame Data" />
                <Tab label="Parameter Values" />
                <Tab label="Datasources" />
                <Tab label="Event Log" />
                <Tab label="VS Code" />
            </Tabs>

            <PersistentTabPanel key="tab-0" selectedValue={selectedTabIndex} value={0}>
                {
                    loading ?
                        <div>Loading</div> :
                        <div className="table-container">
                            <table className="table bordered no-header key-value">
                                <tbody>
                                    <tr>
                                        <td className="heading-cell">
                                            Storyline
                                        </td>
                                        <td>
                                            {storyline.id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="heading-cell">
                                            Template
                                        </td>
                                        <td>
                                            {slide.templateName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="heading-cell">
                                            Chapter
                                        </td>
                                        <td>
                                            {slide.chapterName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="heading-cell">
                                            Page
                                        </td>
                                        <td>
                                            {slide.pageName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="heading-cell">
                                            Paragraph
                                        </td>
                                        <td>
                                            {slide.name}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                }
            </PersistentTabPanel>

            <PersistentTabPanel key="tab-1" selectedValue={selectedTabIndex} value={1}>
                {
                    loading ?
                        <div>Loading</div> :
                        <TemplatesTab currentPageTemplate={slide.templateName} />
                }
            </PersistentTabPanel>

            <PersistentTabPanel key="tab-2" selectedValue={selectedTabIndex} value={2}>
                {
                    loading ?
                        <div>Loading</div> :
                        <FrameDataTab frameData={frameData} />
                }
            </PersistentTabPanel>

            <PersistentTabPanel key="tab-3" selectedValue={selectedTabIndex} value={3}>
                {
                    loading ?
                        <div>Loading</div> :
                        <ParameterValuesTab />
                }
            </PersistentTabPanel>

            <PersistentTabPanel key="tab-4" selectedValue={selectedTabIndex} value={4}>
                {
                    loading ?
                        <div>Loading</div> :
                        <Datasources />
                }
            </PersistentTabPanel>

            <PersistentTabPanel key="tab-5" selectedValue={selectedTabIndex} value={5}>
                {
                    loading ?
                        <div>Loading</div> :
                        <Traces />
                }
            </PersistentTabPanel>

            <PersistentTabPanel key="tab-6" selectedValue={selectedTabIndex} value={6}>
                {
                    loading ?
                        <div>Loading</div> :
                        <VsCode />
                }
            </PersistentTabPanel>
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { showError })(CanvasDebugger);