/* eslint-disable eqeqeq */
import "./POIEditorMap.scss";
import React from "react";
import { useResizeDetector } from 'react-resize-detector';
import { connect } from "react-redux";
import { updateParameterValue, applyParameterValueChanges } from "../../../../../store/storyline/actions";
import { RootState } from "../../../../../store";
import Map from "../../../Map"
import { Autocomplete, Option, Button, Dialog, DialogContent, TextField, DialogActions, Select, MenuItem, DialogTitleWithCloseButton } from "../../../../../shared/components";
import { useForm, Controller } from "react-hook-form";
import { object, string, number } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import * as _ from "lodash";


interface POIModel {
    name: string;
    poiTypeId: number;
    contractId: number;
    geofenceRadius: string;
}

function EditPOIDialog(props: any) {
    const { poiTypes, contracts, updateParameterValue, applyParameterValueChanges, allowEdit } = props;
    const poi = props.poi || {};
    const isEditable = poi.id && allowEdit;

    const schema = object().shape({
        name: string().required("POI Name is required."),
        poiTypeId: number().required("POI Type is required."),
        contractId: number(),
        geofenceRadius: number()
            .min(10, "Radius must be at least 10 meters.")
            .max(10000, "Radius must be at most 10 kilometers.")
            .required("Geofence Radius is required.")
    });
    const { register, handleSubmit, errors, reset, control } = useForm({ resolver: yupResolver(schema) });

    React.useEffect(() => {
        if (!props.isOpen) return;

        reset(poi);
    }, [props.isOpen]);

    const closeDialog = () => {
        props.setIsOpen(false);
    }

    const save = (formModel: POIModel) => {
        updateParameterValue("poi_id", poi.id);
        updateParameterValue("poi_name", formModel.name);
        updateParameterValue("poi_type_id", formModel.poiTypeId);
        updateParameterValue("poi_contract_id", formModel.contractId);
        updateParameterValue("poi_geofence_radius", parseInt(formModel.geofenceRadius));
        applyParameterValueChanges(true);

        closeDialog();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="md">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                { isEditable ? "Edit POI" : "Location Information" }
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)}>
                <DialogContent>
                    <div className="poi-info">
                        <div className="label">
                            Name
                        </div>
                        <div className="value">
                            {
                                isEditable ?
                                    <TextField
                                        name="name"
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                        inputRef={register}
                                        variant="outlined"
                                        fullWidth
                                        autoFocus
                                    /> :
                                    <span>{poi.name}</span>
                            }
                        </div>

                        <div className="label">
                            POI Type
                        </div>
                        <div className="value">
                            {
                                isEditable ?
                                    <Controller
                                        as={
                                            <Select
                                                variant="outlined"
                                                error={!!errors.poiTypeId}
                                                fullWidth>
                                                {
                                                    poiTypes?.map(poit => <MenuItem value={poit.value}>{poit.label}</MenuItem>)
                                                }
                                            </Select>
                                        }
                                        control={control}
                                        name="poiTypeId"
                                    /> :
                                    <span>{_.find(poiTypes || [], pt => pt?.value == poi.poiTypeId)?.label || "Unknown"}</span>
                            }
                        </div>

                        <div className="label">
                            Contract
                        </div>
                        <div className="value">
                            {
                                isEditable ?
                                    <Controller
                                        as={({ onChange }) => (
                                          <Autocomplete
                                            className="form-item"
                                            options={contracts}
                                            onChange={(_, data: Option) => onChange(data?.value)}
                                            getOptionLabel={(option: Option) => option?.label}
                                          />
                                        )}
                                        name="contractId"
                                        control={control}
                                      />
                                   :
                                    <span>{_.find(contracts || [], c => c?.value == poi.contractId)?.label || "Unknown"}</span>
                            }
                        </div>

                        <div className="label">
                            Geofence Radius (m)
                        </div>
                        <div className="value">
                            {
                                isEditable ?
                                    <TextField
                                        name="geofenceRadius"
                                        error={!!errors.geofenceRadius}
                                        helperText={errors.geofenceRadius?.message}
                                        inputRef={register}
                                        variant="outlined"
                                        fullWidth
                                        autoFocus
                                    /> :
                                    <span>{poi.geofenceRadius !== undefined && poi.geofenceRadius !== null ? poi.geofenceRadius : "Unknown"}</span>
                            }
                        </div>

                        <div className="label">
                            Latitude
                        </div>
                        <div className="value">
                            {poi.lat}
                        </div>

                        <div className="label">
                            Longitude
                        </div>
                        <div className="value">
                            {poi.lon}
                        </div>

                        <div className="label">
                            &nbsp;
                        </div>
                        <div className="value">
                            <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${poi.lat}, ${poi.lon}`}>Open in Google Street View</a>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {
                        isEditable &&
                        <Button type="submit" variant="contained" color="secondary">
                            Save
                        </Button>
                    }
                    
                </DialogActions>
            </form>
        </Dialog>
    );
}

function POIEditorMap(props: any) {
    // Just attaching a resize listener to the parent of the chart causes the component to re-render when the size changes.  No need to explicitly call resize on the Plotly element...
    const { width: _width, height: _height, ref } = useResizeDetector();
    const { poiTypes, contracts, updateParameterValue, applyParameterValueChanges, allowEdit, ...rest } = props;
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [editDialogIsOpen, setEditDialogIsOpen] = React.useState(false);

    const dataPointClicked = React.useCallback(
        (data) => {
            if (data?.points?.[0]?.customdata) {
                setSelectedItem({
                    "id": data.points[0].customdata.id,
                    "name": data.points[0].customdata.name,
                    "poiTypeId": data.points[0].customdata.poiTypeId,
                    "contractId": data.points[0].customdata.contractId,
                    "geofenceRadius": data.points[0].customdata.geofenceRadius,
                    "lat": data.points[0].lat,
                    "lon": data.points[0].lon
                });
                setEditDialogIsOpen(true);
            }
            else {
                setSelectedItem({
                    "id": null,
                    "name": "Unknown Location",
                    "lat": data.points[0].lat,
                    "lon": data.points[0].lon
                });
                setEditDialogIsOpen(true);
            }
        }, []);

    return (
        <div className="fill" style={{ position: "relative" }} ref={ref}>
            <Map
                {...rest}
                input={props.input}
                onClick={dataPointClicked}
            />

            <EditPOIDialog
                allowEdit={allowEdit}
                poi={selectedItem}
                poiTypes={poiTypes}
                contracts={contracts}
                isOpen={editDialogIsOpen}
                setIsOpen={setEditDialogIsOpen}
                updateParameterValue={updateParameterValue}
                applyParameterValueChanges={applyParameterValueChanges}
            />
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue, applyParameterValueChanges })(React.memo(POIEditorMap));