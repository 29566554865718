import { createStore, combineReducers, applyMiddleware, AnyAction } from "redux";
import thunkMiddleware, { ThunkDispatch } from "redux-thunk";
import { composeWithDevTools, EnhancerOptions } from "redux-devtools-extension/logOnlyInProduction";

import { storylineReducer } from "./storyline/reducers";
import { appReducer } from "./app/reducers";
import { notificationsReducer } from "./notifications/reducers";
import { tracingReducer } from "./tracing/reducers";
import { vsCodeReducer } from "./vscode/reducers";
import { useDispatch } from "react-redux";

/**
 * a utility to check if a value is a Promise or not
 * @param value
 */
const isPromise = value => value !== null && typeof value === 'object' && typeof value.then === 'function';


const rootReducer = combineReducers({
    app: appReducer,
    storyline: storylineReducer,
    notifications: notificationsReducer,
    traces: tracingReducer,
    vsCode: vsCodeReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const middlewares = [];

    // Exception-handling middleware...
    middlewares.push(() => next => action => {

        if (!isPromise(action.payload)) {
            try {
                return next(action);
            }
            catch (error) {
                return error;
            }
        }

        if (!action.meta || !action.meta.localError) {
            // Dispatch initial pending promise, but catch any errors...
            return next(action).catch(error => {
                console.log(`${action.type} unhandled rejection caught at middleware with reason: ${JSON.stringify(error.message)}.`);
                return next();
            });
        }

        return next(action);
    });

    middlewares.push(thunkMiddleware);
    const composeEnhancers = composeWithDevTools({
        serialize: {
            options: {
                map: true
            }
        }
    } as EnhancerOptions);
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const store = createStore(
        rootReducer,
        composeEnhancers(middlewareEnhancer)
    );

    return store;
}

export const store = configureStore();

export const useThunkDispatch = () => useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();