import "./KpiProfile.scss";
import clsx from "clsx";
import { ContentRenderer } from "../ContentRenderer";
import Plot from "react-plotly.js";
import { useResizeDetector } from 'react-resize-detector';
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

const chartStyle = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

interface KpiProfileProps {
    variant?: "compact" | "full";
    title?: string;
    delta?: string;
    value?: string;
    chart?: { data: Plotly.Data[], layout: Plotly.Layout };
    className?: string;
    children?: any;
}

const defaultProps: KpiProfileProps = {
    variant: "full"
}

function KpiProfile(props: KpiProfileProps) {
    const { variant, title, delta, value, chart, className, children, ...rest } = props;
    const { width: _width, height: _height, ref } = useResizeDetector();

    return (
        <div {...rest} className={clsx("kpi-profile", variant, className)}>
            <div className="title">{<ContentRenderer input={title} />}</div>
            <div className="delta">{<ContentRenderer input={delta} />}</div>
            <div className="value">{<ContentRenderer input={value} />}</div>
            <div className="chart" ref={ref}>
                {
                    children ||
                    <Plot
                        data={chart?.data}
                        layout={chart?.layout}
                        config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: false, displayModeBar: false }}
                        style={chartStyle}
                    />
                }
            </div>
        </div>
    );
}

KpiProfile.defaultProps = defaultProps;

(KpiProfile as DocumentedComponent).metadata = {
    description: "The KpiProfile component displays the current KPI value, delta and a sparkline (or other chart) of historical values in a grid-based format.",
    isSelfClosing: false,
    attributes: [
        { name: `variant`, type: "string", options: ['compact', 'full'], description: "The layout to use for the component.  `compact` is more suited to scenarios where vertical space is limited, whereas `full` requires more vertical space, but affords more horizontal space for the chart." },
        { name: `title`, type: `string`, description: "The name/label for the KPI.  Supports HTML or JSX via the relevant prefix." },
        { name: `delta`, type: `string`, description: "The delta value for the KPI.  Supports HTML or JSX via the relevant prefix." },
        { name: `value`, type: `string`, description: "The current value for the KPI.  Supports HTML or JSX via the relevant prefix." },
        { name: `chart`, type: "object", description: "The Plotly chart containing the historical KPI values.  Optional - any content can be rendered in place of the chart by passing those in as `children` instead." },
    ]
};

export default KpiProfile;