import * as React from "react";
import { Icon } from "./";

export interface MenuItemIconProps {
    type: "URL" | "BuiltIn" | "Binary";
    content: string;
}

function MenuItemIcon(props) {
    const { type, content } = props;

    return (
        type === "URL" ? <img className="menu-item-icon" src={content} /> :
            type === "BuiltIn" ? <Icon>{content}</Icon> :
                type === "Binary" ? <img className="menu-item-icon" src={`data:image/png;base64,${content}`} /> :
                    null
    );
}

export { MenuItemIcon };