import { FC } from 'react';
import { EdgeProps, EdgeLabelRenderer, BaseEdge, getStraightPath } from 'reactflow';
import { NodeContent } from '../nodeTypes/common';
import clsx from 'clsx';

const Straight: FC<EdgeProps> = (props: EdgeProps) => {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        data,
        ...rest
    } = props;

    const [edgePath, labelX, labelY] = getStraightPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const edgeWidth = Math.abs(targetX - sourceX);
    const maxContentWidth = edgeWidth >= 64 ? edgeWidth - 32 : 120; // Horizontal connection: width - 32px padding, Vertical connection: 120px (average node width)

    return (
        <>
            <BaseEdge id={id} path={edgePath} {...rest} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        maxWidth: maxContentWidth,
                        ...rest.style,
                    }}
                    className={clsx("FlowChart-edge-label-content", "nodrag", "nopan", data?.className)}
                >
                    <NodeContent {...props} />
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export { Straight };
