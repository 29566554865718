import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import ChartV1, { PlotlyFigure } from "./ChartV1";
import ChartV2 from "./ChartV2";

type ChartProps = {
    key: string;
    input: PlotlyFigure | Highcharts.Options;
    staticPlot?: boolean;
    tooltipTemplate?: string;
    getTooltipContent?: (e: any) => React.ReactNode;
    onClick?: (event: Record<string, any>, ...args: any[]) => void;
    onHover?: (event: Record<string, any>, ...args: any[]) => boolean;
    onUnhover?: (event: Record<string, any>, ...args: any[]) => void;
    onSelected?: (event: Record<string, any>, ...args: any[]) => void;
    onDeselect?: (...args: any[]) => void;
    [key: string]: any;
}

const Chart = (props: ChartProps) => {
    const { input, options, ...rest } = props;

    if (!input) {
        return "No input provided.";
    }

    return (
        "layout" in input ?
            <ChartV1 input={input} {...rest} /> :
            <ChartV2 options={input} {...rest} />
    );
}

(Chart as DocumentedComponent).metadata = {
    description: `
    The \`Chart\` component is a wrapper around either the \`Plot\` component from the [\`react-plotly.js\`](https://plotly.com/javascript/react/) library or the \`HighChartsReact\` component from the [\`highcharts-react\`](https://github.com/highcharts/highcharts-react) library.

The format of the input data will determine which of these underlying components to use.  If the input data is a \`Plotly\` figure, the \`Plot\` component will be used.  Otherwise, the \`HighChartsReact\` component will be used.

Please see the following links for more information on the relevant data structures:

	* [Plotly figure structure](https://plotly.com/python/figure-structure/)
	* [HighCharts options](https://api.highcharts.com/highcharts/)
    `,
    isSelfClosing: true,
    attributes: [
        { name: `input`, type: `object`, description: "The input data provided to the underlying library.  Please consult the documentation for the respective charting library - see links above." },
        { name: `staticPlot`, type: `boolean`, description: "This optional property indicates whether the plot should be interactive or static.  Defaults to `true` when the plot is rendered on the Minimap/Tooltip and to `false` when rendered on the main canvas. Can be overridden to always be `true` if a static plot is desired.  For `Highcharts`, this disables animations for the chart." },
        { name: `disableResizeListener`, type: `boolean`, description: "If the chart is placed within a non-resizable container, this optional flag can be used to improve performance.  Especially useful for table cell renders, where the virtual scrolling behaviour can trip up the default resize handler." },
        { name: `tooltipTemplate`, type: `string`, description: "The optional custom template to use for chart tooltips.  Can contain any valid JSX.  The underlying hover event is exposed as a binding named `hoverEvent`.  `hoverEvent.points[0]` would most likely be used here to access the data for the point." },
        { name: `getTooltipContent`, type: `function`, template: `getTooltipContent={(hoverEvent) => $1}`, description: "The optional callback function to use for generating the tooltip content.  This is the non-serializable counterpart to `tooltipTemplate`, which provides the same functionality.  If both `tooltipTemplate` and `getTooltipContent` is provided, `tooltipTemplate` will take precedence." },
        { name: `tooltipIsInteractive`, type: `boolean`, description: "**Plotly only** - Optional - defaults to `false`.  If `true`, the custom tooltip will remain open while the user's mouse pointer is within its bounds." },
        { name: "onHover", type: "function", template: "onHover={(event) => {$1}}", description: "If provided, this callback function will be invoked whenever the user hovers over a point in the chart.  The first argument contains the source event (including the point(s) hovered over). **Plotly only** - `plotData` and `updatePlotData` can be used to manipulate the plot data directly.  This is useful for adding adornments (outlines, color changes, etc.) to the hovered points or its neighbors." },
        { name: "onUnhover", type: "function", template: "onUnhover={(event) => {$1}}", description: "If provided, this callback function will be invoked whenever the user unhovers from a point in the chart.  The first argument contains the source event (including the point(s) previously hovered over).  **Plotly only** - `plotData` and `updatePlotData` can be used to manipulate the plot data directly.  This is useful for removing adornments added by the `onHover` event." },
        { name: "onSelected", type: "function", template: "onSelected={(event, plotData, updatePlotData) => {$1}}", description: "If provided, this callback function will be invoked whenever the user selects a point in the chart.  The first argument contains the source event (including the point(s) selected).  **Plotly only** - `plotData` and `updatePlotData` can be used to manipulate the plot data directly.  This is useful for adding adornments (outlines, color changes, etc.) to the selected points or its neighbors." },
        { name: "onDeselect", type: "function", template: "onDeselect={(event) => {$1}}", description: "If provided, this callback function will be invoked whenever the user deselects a point in the chart.  The first argument contains the source event (including the point(s) deselected).  **Plotly only** - `plotData` and `updatePlotData` can be used to manipulate the plot data directly.  This is useful for removing adornments added by the `onSelected` event. " },
    ]
};

export default Chart;
