import * as React from "react";
import { FC, useCallback } from "react";
import NewWindow, { INewWindowProps } from "react-new-window";

interface IPopoutWindowContext {
    pullStylesFromParentWindow: () => void;
    closeWindow: () => void;
}

export const PopoutWindowContext: React.Context<IPopoutWindowContext> = React.createContext(null);
export const usePopoutWindowContext: () => IPopoutWindowContext = () => React.useContext(PopoutWindowContext);

/**
 * Wrapper for react-new-window that works with MUI (and Emotion).
 *
 * Same interface as react-new-window.
 */
const PopoutWindow: FC<INewWindowProps> = ({
    onOpen,
    onUnload,
    ...props
}) => {
    const [context, setContext] = React.useState<IPopoutWindowContext>(null);

    const compatOnOpen = useCallback(
        (childWindow: Window) => {
            const pullStylesFromParentWindow = () => {
                const childHead = childWindow.document.head;

                Array.from(childHead.childNodes)
                    .filter(node => node.nodeName === "STYLE")
                    .forEach(node => childHead.removeChild(node));

                const allParentStyles = Array.from(document.styleSheets)
                    .flatMap(s => {
                        try {
                            return Array.from(s.rules).map(r => r.cssText);
                        } catch (e) {
                            console.error(e);
                            return [];
                        }
                    });

                const styleElement = childWindow.document.createElement("style");
                styleElement.innerHTML = allParentStyles.join("\n");
                childHead.appendChild(styleElement);
            };

            const _context = {
                pullStylesFromParentWindow,
                closeWindow: () => {
                    childWindow.close();
                }
            };

            // Immediately pull the current styles from the parent, with the ability to update these later via the Context...
            pullStylesFromParentWindow();
            setContext(_context);

            onOpen?.(childWindow);
        },
        [onOpen]
    );

    const compatOnUnload = useCallback(() => {
        onUnload?.();
    }, [onUnload]);

    return (
        <PopoutWindowContext.Provider value={context}>
            <NewWindow onOpen={compatOnOpen} onUnload={compatOnUnload} {...props} />
        </PopoutWindowContext.Provider>
    );
};

export default PopoutWindow;