import "./Annotations.scss";
import * as _ from "lodash";
import { Card, CardHeader, CardContent } from "../../../shared/components";
import TextToSpeechPlayer from "../TextToSpeechPlayer";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface AnnotationProps {
    input: string[];
}

function Annotations(props: AnnotationProps) {
    const { input } = props;
    let items = input || [];
    return items && items.length && _.find(items, i => !!i) ? (
        <Card className="annotations col-fixed">
            <CardHeader title="Comments" action={<TextToSpeechPlayer text={items} />} />
            <CardContent>
                {_.map(items, (text, i) => <div className="item" dangerouslySetInnerHTML={{ __html: text }} key={i} ></div>)}
            </CardContent>
        </Card>
    ) : <></>;
}

(Annotations as DocumentedComponent).metadata = {
    description: "The Annotations component is used to display an unordered list of commentary.  This commentary is primarily regarding the reason for the values displayed on the current canvas.",
    attributes: [
        { name: "input", type: "object", description: "The list of comments.  Items can be either plain-text or HTML formatted.  Comments are stacked vertically." }
    ]
};

export default Annotations;