import * as React from "react";
import { Avatar as MaterialAvatar, AvatarProps } from "@mui/material";
import { DocumentedComponent } from "./DocumentedComponent";

interface ExtendedAvatarProps extends AvatarProps {
    color?: "red" | "orange" | "yellow" | "green" | "blue" | "darkblue";
}

const Avatar = (props: ExtendedAvatarProps) => <MaterialAvatar className={`${props?.color}`} {...props} />;

(Avatar as DocumentedComponent).metadata = {
    description: `Displays an avatar.  This is a preconfigured Avatar with a background color.`,
    attributes: [
        { name: "color", description: "The color of the avatar.  Optional.", type: "string", options: ["red", "orange", "yellow", "green", "blue", "darkblue"] },
        { name: "variant", description: "Determines how the Avatar is displayed.  Optional - defaults to 'circular'.", type: "string", options: ["circular", "rounded", "square"] }
    ]
};

export default Avatar;