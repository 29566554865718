import {
    StorylineState,
    StorylineActionTypes,
    DataSource,
    LOAD_STORYLINE,
    SHOW_STORYLINE,
    GO_TO_X_Y_Z,
    GO_TO_ID,
    PARAMETER_VALUE_UPDATED,
    DATASOURCE_UPDATED,
    UPDATE_CURRENT_SLIDE_TEMPLATE,
    UPDATE_SLIDE_TEMPLATES,
    UPDATE_CURRENT_FRAME_DATA,
    UPDATE_CANVAS_STATE,
    ADD_GLOBAL_DATASOURCE,
    UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
    Canvas
} from "./types";
import * as _ from "lodash";
import { MapWithPathSupport } from "../../shared/utilities";

const initialState: StorylineState = {
    id: "",
    name: "",
    storyline: { name: "", chapters: [], dataSources: {}, templates: {} },
    datasources: new Map<string, DataSource>(),
    templates: {},
    datasourceValues: new Map<string, object>(),
    datasourcesInFlight: new Set<string>(),
    parameterValues: new MapWithPathSupport<any>(),
    canvases: [[[]]],
    chapterIndex: 0,
    pageIndex: 0,
    paragraphIndex: 0,
    currentParagraph: undefined,
    loading: true,
    canNavigateBackwards: false,
    canNavigateForward: false,
    canvasState: {}
}

export function storylineReducer(
    state = initialState,
    action: StorylineActionTypes
): StorylineState {
    switch (action.type) {
        case LOAD_STORYLINE:
            return {
                ...initialState,
                id: action.id
            };

        case SHOW_STORYLINE:
            const storylineCanvases = action.storyline.chapters.map(chapter =>
                chapter.pages.map(page =>
                    page.paragraphs.map(paragraph => {
                        const paragraphData = Object.fromEntries(paragraph.slots.map(slot => {
                            return [slot.name, action.dataSourceValues.get(slot.dataSource) as any];
                        }));

                        return ({
                            name: paragraph.name,
                            chapterName: chapter.name,
                            pageName: page.name,
                            title: paragraphData?.["title"],
                            data: paragraphData,
                            template: action.storyline.templates[page.template],
                            templateName: page.template
                        } as Canvas);
                    })
                )
            );

            return {
                id: action.storyline.name,
                name: action.storyline.name,
                storyline: action.storyline,
                canvases: storylineCanvases,
                datasources: action.dataSources,
                templates: action.storyline.templates,
                datasourceValues: action.dataSourceValues,
                datasourcesInFlight: state.datasourcesInFlight,
                parameterValues: action.parameterValues,
                chapterIndex: 0,
                pageIndex: 0,
                paragraphIndex: 0,
                currentParagraph: getFrame(storylineCanvases, 0, 0, 0),
                loading: false,
                ...calculateNavigationProperties(storylineCanvases, 0, 0, 0),
                canvasState: {}
            };

        case PARAMETER_VALUE_UPDATED:
            state.parameterValues.set(action.parameterName, action.newValue);
            return {
                ...state,
                parameterValues: new MapWithPathSupport(state.parameterValues)
            };

        case DATASOURCE_UPDATED:
            state.datasourceValues.set(action.datasourceName, action.data);

            const postUpdateCanvases = state.storyline.chapters.map(chapter =>
                chapter.pages.map(page =>
                    page.paragraphs.map(paragraph => {
                        const paragraphData = Object.fromEntries(paragraph.slots.map(slot => {
                            return [slot.name, state.datasourceValues.get(slot.dataSource) as any];
                        }));

                        return ({
                            name: paragraph.name,
                            chapterName: chapter.name,
                            pageName: page.name,
                            title: paragraphData?.["title"],
                            data: paragraphData,
                            template: state.storyline.templates[page.template],
                            templateName: page.template
                        } as Canvas);
                    })
                )
            );

            return {
                ...state,
                canvases: postUpdateCanvases,
                datasourceValues: state.datasourceValues,
                currentParagraph: getFrame(postUpdateCanvases, state.chapterIndex, state.pageIndex, state.paragraphIndex)
            };


        case GO_TO_X_Y_Z:
            return {
                ...state,
                chapterIndex: action.chapterIndex,
                pageIndex: action.pageIndex,
                paragraphIndex: action.paragraphIndex,
                currentParagraph: getFrame(state.canvases, action.chapterIndex, action.pageIndex, action.paragraphIndex),
                ...calculateNavigationProperties(state.canvases, action.chapterIndex, action.pageIndex, action.paragraphIndex)
            };

        case GO_TO_ID:

            const getCoordinatesForName = (name: string) => {
                for (const [chapterIndex, chapter] of state.canvases.entries()) {
                    for (const [pageIndex, page] of chapter.entries()) {
                        for (const [paragraphIndex, paragraph] of page.entries()) {
                            if (paragraph.name === name) return [chapterIndex, pageIndex, paragraphIndex];
                        }
                    }
                }
            }

            const matchingCoordinates = getCoordinatesForName(action.id);

            if (matchingCoordinates) {
                const [chapterIndex, pageIndex, paragraphIndex] = matchingCoordinates;
                return {
                    ...state,
                    chapterIndex,
                    pageIndex,
                    paragraphIndex,
                    currentParagraph: getFrame(state.canvases, chapterIndex, pageIndex, paragraphIndex),
                    ...calculateNavigationProperties(state.canvases, chapterIndex, pageIndex, paragraphIndex)
                };
            }
            else {
                console.log(`No slide with Name, ID or Canvas ID = "${action.id}" found.`);
                return state;
            }

        case UPDATE_CURRENT_SLIDE_TEMPLATE:
            state.currentParagraph.template.jsx = action.newTemplate;
            state.currentParagraph.template.css = action.newCustomCss;

            return {
                ...state,
                storyline: {
                    ...state.storyline,
                    templates: {
                        ...state.storyline.templates,
                        [state.currentParagraph.templateName]: {
                            jsx: action.newTemplate,
                            css: action.newCustomCss
                        }
                    }
                },
                currentParagraph: {
                    ...state.currentParagraph,
                    template: {
                        css: action.newCustomCss,
                        jsx: action.newTemplate
                    }
                }
            };

        case UPDATE_SLIDE_TEMPLATES:
            const allTemplates = { ...state.storyline.templates, ...action.newTemplates };

            return {
                ...state,
                storyline: {
                    ...state.storyline,
                    templates: allTemplates
                },
                currentParagraph: {
                    ...state.currentParagraph,
                    template: allTemplates[state.currentParagraph.templateName]
                }
            };

        case UPDATE_CURRENT_FRAME_DATA:
            return {
                ...state,
                currentParagraph: {
                    ...state.currentParagraph,
                    data: action.newData
                }
            }

        case UPDATE_CANVAS_STATE:
            return {
                ...state,
                canvasState: action.newState
            }

        case ADD_GLOBAL_DATASOURCE:
            state.datasourceValues.set(action.datasource.name, action.datasource.defaultData);

            return {
                ...state,
                storyline: {
                    ...state.storyline,
                    dataSources: {
                        ...state.storyline.dataSources,
                        [action.datasource.name]: action.datasource
                    }
                }
            };
        
        case UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE:
            const datasourceName = action.datasource.name;

            // New in-flight request...
            if (action.inFlightRequestCount > 0 && !state.datasourcesInFlight.has(datasourceName)) {
                return {
                    ...state,
                    datasourcesInFlight: new Set<string>([...state.datasourcesInFlight.values(), datasourceName])
                };
            }

            // In-flight request completed...
            if (action.inFlightRequestCount === 0 && state.datasourcesInFlight.has(datasourceName)) {
                return {
                    ...state,
                    datasourcesInFlight: new Set<string>([...state.datasourcesInFlight.values()].filter(ds => ds !== datasourceName))
                }
            }

            // No material change...
            return state;

        default:
            return state;
    }
}

function getFrame(canvases: Canvas[][][], chapterIndex: number, pageIndex: number, paragraphIndex: number): Canvas {
    return canvases?.[chapterIndex]?.[pageIndex]?.[paragraphIndex] ?? {
        name: "",
        chapterName: "",
        pageName: "",
        title: "",
        data: {},
        template: {
            css: "",
            jsx: ""
        },
        templateName: ""
    };
}

function calculateNavigationProperties(canvases: Canvas[][][], chapterIndex: number, pageIndex: number, paragraphIndex: number) {
    return {
        canNavigateBackwards: chapterIndex > 0 || pageIndex > 0 || paragraphIndex > 0,
        canNavigateForward: canvases?.length > (chapterIndex + 1) || canvases?.[chapterIndex]?.length > (pageIndex + 1) || canvases?.[chapterIndex]?.[pageIndex]?.length > (paragraphIndex + 1)
    };
}

