import React from "react";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { updateCurrentFrameData } from "../../store/storyline/actions";
import { AppBar, Button, Toolbar } from "../../shared/components";
import { UnControlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/foldgutter.css';
import "codemirror/lib/codemirror.css";
import 'codemirror/theme/material.css';
import { jsonParseReviver } from "../../shared/api-client";

interface FrameDataProps {
    frameData: object,
    updateCurrentFrameData: typeof updateCurrentFrameData,
    showSuccess: typeof showSuccess,
    showError: typeof showError
}

function FrameData(props: FrameDataProps) {
    const [editor, setEditor] = React.useState(null);

    const applyFrameDataChange = () => {
        try {
            const newFrameData = JSON.parse(editor.doc.getValue(), jsonParseReviver);
            props.updateCurrentFrameData(newFrameData);
        }
        catch (ex) {
            props.showError(`Failed to parse JSON: ${ex}`);
        }
    }

    return (
        <>
            <div style={{ height: "calc(100% - 64px)" }}>
                <CodeMirror
                    value={JSON.stringify(props.frameData, null, 2)}
                    options={{
                        mode: { name: "javascript", json: true },
                        lineNumbers: true,
                        theme: 'material',
                        foldGutter: true,
                        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                    }}
                    editorDidMount={(editor) => {
                        setEditor(editor);
                        setTimeout(() => editor.execCommand("goDocStart"));
                    }
                    }
                />
            </div>

            <AppBar className="action-panel" position="sticky">
                <Toolbar>
                    <Button color="secondary" variant="contained" onClick={applyFrameDataChange}>Apply Changes</Button>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default connect(
    null,
    { updateCurrentFrameData, showSuccess, showError })(FrameData);