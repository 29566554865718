import * as React from "react";
import { Dialog, DialogTitleWithCloseButton, DialogContent, DialogActions, LinkButton, Button, DatePicker, TextField } from "../../../shared/components";
import type { DHTMLXGanttItem } from "./types";
import { Controller, useForm } from "react-hook-form";
import { object, string, date } from "yup";
import { yupResolver } from "@hookform/resolvers";

interface EditTaskProps {
    task: DHTMLXGanttItem;
    showDialog: boolean;
    setShowDialog: (showDialog: boolean) => void;
    onTaskEdited: (task: DHTMLXGanttItem) => void;
    onBeforeEditAccepted?: (task: DHTMLXGanttItem) => boolean;
    additionalContent?: any;
}

function EditTaskDialog(props: EditTaskProps) {
    const { task, showDialog, setShowDialog, onTaskEdited, onBeforeEditAccepted, additionalContent } = props;

    const schema = object().shape({
        text: string()
            .required("This field is required."),
        start_date: date()
            .required("This field is required."),
        end_date: date()
            .required("This field is required.")
    });

    const { register, handleSubmit, errors, reset, control } = useForm({ resolver: yupResolver(schema) });

    React.useEffect(() => {
        reset(task);
    }, [task]);

    const onAcceptClicked = React.useCallback((formModel) => {
        const updatedTask = { ...task, ...formModel };
        if (onBeforeEditAccepted) {
            const shouldAcceptEdit = onBeforeEditAccepted(updatedTask);
            if (shouldAcceptEdit === false) return;
        }
        onTaskEdited(updatedTask);
        setShowDialog(false);
    }, [task, onTaskEdited, setShowDialog]);

    return (
        <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
            <DialogTitleWithCloseButton onClose={() => setShowDialog(false)}>Edit Item</DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(onAcceptClicked)}>
                <DialogContent>
                    <div className="vertical-form-field">Name</div>
                    <TextField
                        name="text"
                        error={!!errors.text}
                        helperText={errors.text?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />

                    <div className="vertical-form-field">Start Date</div>
                    <Controller
                        name="start_date"
                        control={control}
                        error={!!errors.start_date}
                        helperText={errors.start_date?.message}
                        render={p => <DatePicker onChange={e => p.onChange(e)} value={p.value} />}
                    />

                    <div className="vertical-form-field">End Date</div>
                    <Controller
                        name="end_date"
                        control={control}
                        error={!!errors.end_date} helperText={errors.end_date?.message}
                        render={p => <DatePicker onChange={e => p.onChange(e)} value={p.value} />}
                    />

                    { additionalContent }

                </DialogContent>
                <DialogActions>
                    <LinkButton color="secondary" onClick={() => setShowDialog(false)}>Cancel</LinkButton>
                    <Button type="submit" variant="contained" color="primary">Accept</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export { EditTaskDialog };