import "./Map.scss";
import React from "react";
import Plot, { PlotParams } from "react-plotly.js";
import { useResizeDetector } from 'react-resize-detector';
import { connect } from "react-redux";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RootState } from "../../../store";
import MapControls from "./MapControls";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";
import { PlotMouseEvent } from "plotly.js";
import { Popper } from "../../../shared/components";
import clsx from "clsx";
import { useTooltipContainer } from "../../../shared/providers/TooltipContainerProvider";
import { JsxRenderer } from "../ContentRenderer/JsxRenderer";

const style = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

const defaultMapboxAccessToken = "pk.eyJ1IjoiZGJhcnJldHRic2MiLCJhIjoiY2tjeW1zMWE1MGJlcjMxb3VwdHp1ejR3diJ9.NFsoLer0ez7eDYU-SpAY3Q";

interface ZoomOverride {
    center: {
        lat: number;
        lon: number;
    };
    zoom?: number;
}

interface DataPoint {
    lat: number;
    lon: number;
    customdata: object;
    layer: Partial<Plotly.PlotData>;
}

interface DataPointHighlightOptions {
    markerSize: number;
    markerColor: string;
    dataPointFilter: (point: DataPoint) => boolean;
}

interface CalculatedLayer {
    baseLayerName: string;
    dataPointFilter: (point: DataPoint) => boolean;
    properties: Plotly.MapboxLayers;
}

interface Props extends Omit<PlotParams, "data" | "layout" | "onHover"> {
    input: {
        data: Plotly.Data[];
        layout: Partial<Plotly.Layout>;
    };
    maxSelectionCount?: number,
    parameterName?: string,
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue,
    onMaxSelected?: Function,
    onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void,
    staticPlot?: boolean,
    zoomOverride?: ZoomOverride,
    persistUserZoom?: boolean,
    dataPointHighlightOptions: DataPointHighlightOptions,
    calculatedLayers: CalculatedLayer[],
    tooltipTemplate?: string;
    getTooltipContent?: (e: PlotMouseEvent) => JSX.Element;
    tooltipIsInteractive?: boolean;
    hideTooltipArrow?: boolean;
    onHover?: (event: Readonly<Plotly.PlotMouseEvent>) => boolean;
}

function _Map(props: Props) {
    // Just attaching a resize listener to the parent of the chart causes the component to re-render when the size changes.  No need to explicitly call resize on the Plotly element...
    const { width: _width, height: _height, ref } = useResizeDetector();
    const { input, maxSelectionCount, parameterName, parameterValues, updateParameterValue, onMaxSelected, onClick, onHover, onUnhover, zoomOverride, persistUserZoom, dataPointHighlightOptions, calculatedLayers = [], staticPlot: propStaticPlot, tooltipTemplate, getTooltipContent, tooltipIsInteractive, hideTooltipArrow = false, ...rest } = props;
    const customTooltipContent = tooltipTemplate || getTooltipContent;
    const canvasStaticPlot = useStaticPlot();
    // If the user is passing in an explicit value for the `staticPlot` prop, use that, otherwise use the canvas-level value...
    const staticPlot = propStaticPlot !== undefined && propStaticPlot !== null ? propStaticPlot : canvasStaticPlot;
    const { data, layout } = input || {};
    const [revision, setRevision] = React.useState(0);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [lastZoomDetails, setLastZoomDetails] = React.useState(null);
    const [mapStyle, setMapStyle] = React.useState(layout?.mapbox?.style || "light");
    const [intermediateMapData, setIntermediateMapData] = React.useState(data);
    const [mapData, setMapData] = React.useState([{
        lat: [],
        lon: [],
        mode: "markers",
        type: "scattermapbox"
    }] as any[]);
    const [mapLayout, setMapLayout] = React.useState(null);

    const tooltipTimeoutRef = React.useRef(null);
    React.useEffect(() => {
        const sanitizedData = (data ?? []).map(d => ({ ...d, hoverinfo: customTooltipContent ? "none" : d.hoverinfo, hovertemplate: customTooltipContent ? null : d.hovertemplate }));
        if (!_.isEqual(sanitizedData, data)) {
            setIntermediateMapData(sanitizedData);
        }
    }, [input, customTooltipContent]);

    const dataPointClicked = React.useCallback(
        (data) => {
            if (!maxSelectionCount || !parameterName) return;

            if (data?.points?.[0]?.customdata) {
                const item = {
                    "id": data.points[0].customdata.id,
                    "name": data.points[0].customdata.name,
                    "lat": data.points[0].lat,
                    "lon": data.points[0].lon
                };

                const selectedItemsMinusThisPoint = selectedItems.filter(i => i.id !== item.id);

                // Remove item if it already exists in the list, otherwise add it...
                const newItems = selectedItemsMinusThisPoint.length !== selectedItems.length ?
                    selectedItemsMinusThisPoint :
                    [...selectedItems, item];

                const newItemsWithMaxLengthConstraint = newItems.slice(0, maxSelectionCount);

                setSelectedItems(newItemsWithMaxLengthConstraint);
                updateParameterValue(parameterName, newItemsWithMaxLengthConstraint);

                if (onMaxSelected && newItems.length === maxSelectionCount) {
                    onMaxSelected();
                }
            }
        }, [selectedItems, updateParameterValue, parameterName]);

    React.useEffect(() => {
        const newParameterValue = parameterValues.get(parameterName);
        if (!newParameterValue) return;
        setSelectedItems(newParameterValue);
    }, [parameterValues, parameterName]);

    const onRelayout = (e) => {
        persistUserZoom && setLastZoomDetails(e);
    };

    React.useEffect(() => {
        if (layout) {
            setMapLayout({
                ...layout,
                autosize: true,
                mapbox: {
                    ...layout?.mapbox,
                    center: lastZoomDetails?.["mapbox.center"] || layout?.mapbox?.center,
                    zoom: lastZoomDetails?.["mapbox.zoom"] || layout?.mapbox?.zoom,
                    style: mapStyle || layout?.mapbox?.style,
                    accesstoken: layout?.mapbox?.accesstoken || defaultMapboxAccessToken
                }
            });
        }
        else {
            setMapLayout({
                autosize: true,
                mapbox: {
                    center: lastZoomDetails?.["mapbox.center"] || { lon: 23.84474398368866, lat: -27.644883351235023 },
                    zoom: lastZoomDetails?.["mapbox.zoom"] || 4,
                    style: mapStyle,
                    accesstoken: defaultMapboxAccessToken
                },
                margin: {
                    r: 0,
                    t: 0,
                    l: 0,
                    b: 0
                }
            })
        }
    }, [layout, mapStyle, lastZoomDetails]);

    React.useEffect(() => {
        zoomOverride && setLastZoomDetails({
            "mapbox.center": zoomOverride.center,
            "mapbox.zoom": zoomOverride.zoom
        })
    }, [zoomOverride]);

    React.useEffect(() => {
        if (!intermediateMapData) return;

        const mapDataWithHighlighting = dataPointHighlightOptions ? intermediateMapData.map(layer => {
            const originalMarkerOptions = layer?.["originalMarker"] || layer.marker;

            const normalizedLayerData = _.zipWith(layer.lat, layer.lon, layer.customdata, (lat: number, lon: number, customdata: any) => ({ lat, lon, customdata, layer }));
            const markerOptions = originalMarkerOptions ? {
                ...originalMarkerOptions,
                "size": normalizedLayerData.map(point => dataPointHighlightOptions.dataPointFilter(point) ? (dataPointHighlightOptions.markerSize || originalMarkerOptions.size) : originalMarkerOptions.size),
                "color": normalizedLayerData.map(point => dataPointHighlightOptions.dataPointFilter(point) ? (dataPointHighlightOptions.markerColor || originalMarkerOptions.color) : originalMarkerOptions.color)
            } : undefined;

            return {
                ...layer,
                marker: markerOptions,
                originalMarker: originalMarkerOptions
            };
        }) : intermediateMapData;

        const additionalLayers =
            (calculatedLayers ?? [])
                .map(calculatedLayer => {
                    const originalLayer = intermediateMapData.find(layer => layer.name === calculatedLayer.baseLayerName);
                    if (!originalLayer) return null;

                    const allDataPoints = _.zipWith(originalLayer.lat, originalLayer.lon, originalLayer.customdata, (lat: number, lon: number, customdata: any) => ({ lat, lon, customdata, layer: originalLayer }));
                    const dataPoints = calculatedLayer.dataPointFilter ?
                        allDataPoints.filter(point => calculatedLayer.dataPointFilter(point)) :
                        allDataPoints;

                    return {
                        ...originalLayer,
                        visible: true,
                        ...calculatedLayer.properties,
                        lat: dataPoints.map(point => point.lat),
                        lon: dataPoints.map(point => point.lon),
                        customdata: dataPoints.map(point => point.customdata)
                    };
                })
                .filter(layer => layer !== null);

        setMapData([
            ...mapDataWithHighlighting,
            ...additionalLayers,
            {
                lat: selectedItems.map(i => i.lat),
                lon: selectedItems.map(i => i.lon),
                customdata: selectedItems.map(i => ({ id: i.id, name: i.name })),
                marker: {
                    color: "rebeccapurple",
                    size: 20,
                    symbol: "circle"
                },
                mode: "markers",
                name: "Selected Items",
                type: "scattermapbox",
                hoverinfo: "skip"
            }
        ]);

    }, [intermediateMapData, selectedItems, dataPointHighlightOptions, parameterValues]);

    const [hoverData, setHoverData] = React.useState(null);
    const activeHover = React.useRef(false);

    const handleHover = React.useCallback((e: PlotMouseEvent) => {
        onHover && onHover(e) && setRevision(r => r + 1);

        if (!customTooltipContent) {
            return;
        }

        const point = e.points[0];
        if (!point) return;

        const mapCanvas: HTMLElement = e.event?.["originalEvent"]?.target;
        if (!mapCanvas) return;

        activeHover.current = true;
        // Cancel the last tooltip show timeout, since we've since moved to a new point...
        tooltipTimeoutRef.current && clearTimeout(tooltipTimeoutRef.current);

        tooltipTimeoutRef.current = setTimeout(() => {
            if (activeHover.current) {
                const offsets = mapCanvas.getBoundingClientRect();
                const boundingBox = point["bbox"];
                const getBoundingClientRect = () =>
                ({
                    width: boundingBox.x1 - boundingBox.x0,
                    height: boundingBox.y1 - boundingBox.y0,
                    left: boundingBox.x0 + offsets.x,
                    right: boundingBox.x1 + offsets.x,
                    top: boundingBox.y0 + offsets.y,
                    bottom: boundingBox.y1 + offsets.y,
                });

                setHoverData({
                    hoverEvent: e,
                    anchorEl: {
                        getBoundingClientRect,
                        contextElement: mapCanvas
                    }
                });
            }
        }, tooltipIsInteractive ? 200 : 0);

    }, [customTooltipContent, onHover]);

    const handleUnhover = React.useCallback((e) => {
        onUnhover && onUnhover(e);

        if (!customTooltipContent) {
            return;
        }

        activeHover.current = false;

        setTimeout(() => {
            if (!activeHover.current) {
                setHoverData(null);
            }
        }, tooltipIsInteractive ? 100 : 0);
    }, [customTooltipContent, onUnhover]);

    return (
        <div className="fill map" style={{ position: "relative" }} ref={ref}>
            <Plot
                {...rest}
                data={mapData}
                layout={mapLayout}
                config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: staticPlot, displayModeBar: false, doubleClick: false }}
                style={style}
                onClick={onClick || dataPointClicked}
                onRelayout={onRelayout}
                onHover={handleHover}
                onUnhover={handleUnhover}
                revision={revision}
            />

            <MapControls mapStyle={mapStyle} setMapStyle={setMapStyle} />
            {
                customTooltipContent &&
                <ChartTooltip
                    content={customTooltipContent}
                    hoverData={hoverData}
                    onMouseEnter={() => { activeHover.current = true; }}
                    onMouseLeave={handleUnhover}
                    isInteractive={tooltipIsInteractive}
                    arrow={!hideTooltipArrow}
                />
            }
        </div>
    );
}

interface HoverData {
    anchorEl?: any;
    hoverEvent: PlotMouseEvent;
    [key: string]: any;
}

interface ChartTooltipProps {
    content?: string | ((e: PlotMouseEvent) => JSX.Element);
    hoverData?: HoverData;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    isInteractive?: boolean;
    arrow?: boolean;
}


function ChartTooltip(props: ChartTooltipProps) {
    const { content, hoverData, onMouseEnter, onMouseLeave, isInteractive, arrow = true } = props;

    const tooltipContainerRef = useTooltipContainer();

    const TooltipContent = () => {
        if (typeof content === "string") {
            return <JsxRenderer template={content} data={hoverData} />;
        }

        return content(hoverData.hoverEvent);
    };

    return (
        <Popper
            arrow={arrow}
            className={clsx("chart-tooltip", { "non-interactive-element": !isInteractive })}
            open={hoverData != null}
            container={tooltipContainerRef.current}
            anchorEl={hoverData?.anchorEl}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <TooltipContent />
        </Popper>
    );
}

const Map = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(_Map));

(Map as DocumentedComponent).metadata = {
    description:
        `The Map component is a thin wrapper around the \`Plot\` component from the [\`react-plotly.js\`](https://plotly.com/javascript/react/) library.  All additional props are passed directly to the underlying component - please consult the documentation for that component for all the available options.  

This is a specialized version of the standard \`Chart\` component - with a few enhancements specific to maps:
        
* Allows for toggling between the different values available for \`layout.mapbox.style\`
* Persists zoom/pan state between data rebinds
* Shows a default map when no data is bound
* Allows for selecting POIs/data points`,
    isSelfClosing: true,
    attributes: [
        { name: `input`, type: "object", description: "The figure to render.  Traces contained within must be of type `Scattermapbox` or `Scattergeo` in order for the map-specific enhancements to function correctly.  For other trace types use a standard `Chart` component.  See the [Plotly figure documentation](https://plotly.com/python/figure-structure/) for the structure of this object." },
        { name: `staticPlot`, type: `boolean`, description: "This optional property indicates whether the plot should be interactive or static.  Defaults to `true` when the plot is rendered on the Minimap/Tooltip and to `false` when rendered on the main canvas. Can be overridden to always be `true` if a static plot is desired." },
        { name: `maxSelectionCount`, type: `number`, description: "The maximum number of data points that can be selected.  Selected points are persisted against the storyline parameter specified via `parameterName`.  Defaults to `0`, which disables point selection." },
        { name: `parameterName`, type: `string`, description: "The name of the parameter to persist selected data points against.  If no value is specified, selection is disabled." },
        { name: `onMaxSelected`, type: "function", template: "onMaxSelected={() => {$1}}", description: "An optional parameterless function to invoke when the maximum number of points have been selected.  Useful for refreshing data sources or closing a modal once a sufficient number of points have been selected." },
        {
            name: `zoomOverride`,
            type: "object",
            description:
                `The center point and zoom level to force for this map.  Used for programmatic control of pan/zoom from other components or events.  See below for the structure of the \`ZoomOverride\` object.

### ZoomOverride Props:

| Name | Type | Description |
|------|------|-------------|
| \`center\` | \`object\` | The desired center point of the map.  Contains 2 fields - \`lat\` and \`lon\`. |
| \`zoom\` | \`number\` | The desired zoom level. See the [Mapbox documentation](https://docs.mapbox.com/help/glossary/zoom-level/#zoom-levels-and-geographical-distance) for the available zoom levels. |`
        },
        {
            name: `dataPointHighlightOptions`,
            type: "object",
            description:
                `The optional rule used to highlight data points based on the filter function provided.  Applies to all layers in the map.  See below for the structure of the \`DataPointHighlightOptions\` object.
        
### DataPointHighlightOptions Props:

| Name | Type | Description |
|------|------|-------------|
| \`markerSize\` | \`number\` | The size of the marker to use for the highlighted data points. |
| \`markerColor\` | \`string\` | The color of the marker to use for the highlighted data points. |
| \`dataPointFilter\` | \`(point: { lat, lon, customdata, layer }) => boolean\` | The function used to determine whether a data point should be highlighted. |`
        },
        {
            name: `calculatedLayers`,
            type: "object",
            description:
                `The addition layers to create based on data contained within other layers.  See below for the structure of the \`CalculatedLayer\` object.
        
### CalculatedLayer Props:

| Name | Type | Description |
|------|------|-------------|
| \`baseLayerName\` | \`string\` | The layer that should be used as the basis for the new layer.  Data from this layer is used to construct the new layer (lat + lon + customdata). |
| \`dataPointFilter\` | \`(point: { lat, lon, customdata, layer }) => boolean\` | The function used to determine whether a data point is copied over to the new layer. |
| \`properties\` | \`object\` | The metadata for the new map layer.  Overrides the base layer properties, where applicable.  See the [Plotly documentation](https://plotly.com/python/reference/scattermapbox/#scattermapbox) for the available fields. |`
        },
        { name: `persistUserZoom`, type: `boolean`, description: "This property indicates whether to persist the last interactive zoom level/center point across data rebinds.  If `true`, the calculated midpoint/zoom level passed in via the map data is ignored once the user has started interacting with the map." },
        { name: `tooltipTemplate`, type: `string`, description: "The optional custom template to use for chart tooltips.  Can contain any valid JSX.  The underlying `plotly` hover event is exposed as a binding named `hoverEvent`.  `hoverEvent.points[0]` would most likely be used here to access the data for the point." },
        { name: `getTooltipContent`, type: `function`, template: `getTooltipContent={(hoverEvent) => $1}`, description: "The optional callback function to use for generating the tooltip content.  This is the non-serializable counterpart to `tooltipTemplate`, which provides the same functionality.  If both `tooltipTemplate` and `getTooltipContent` is provided, `tooltipTemplate` will take precedence." },
        { name: `tooltipIsInteractive`, type: `boolean`, description: "Optional - defaults to `false`.  If `true`, the custom tooltip will remain open while the user's mouse pointer is within its bounds." },
    ]

};

export default Map;