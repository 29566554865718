import "./Sparkline.scss";
import _ from "lodash";
import { useResizeDetector } from 'react-resize-detector';
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import React from "react";
import clsx from "clsx";

type SparklineSection = {
    values: number[],
    color: string,
    variant: "dashed" | "filled" | "gradient" | "line"
}

type SparklineProps = {
    className?: string;
    sections: SparklineSection[];
};

function Sparkline(props: SparklineProps) {
    const { className, sections } = props;
    const { width, height, ref } = useResizeDetector();
    const [id] = React.useState(_.uniqueId("sparkline_"));

    if (_.isEmpty(sections)) { return null; }

    const allValues = sections.flatMap(s => s.values.filter(v => v != null));
    const xScale = width / (allValues.length - sections.length);
    const yScale = height;
    const minValue = _.min(allValues) ?? 0;
    const maxValue = _.max(allValues) ?? 0;
    const normalizationFactor = (maxValue - minValue) === 0 ? 1 : maxValue - minValue;


    const sectionsWithOffsets = sections.map((section, sectionIndex) => {
        const predecessors = sections.slice(0, sectionIndex).flatMap(s => s.values);
        return [
            {
                ...section,
                values: section.values,
            },
            predecessors.length ? predecessors.length - sectionIndex : 0
        ] as [SparklineSection, number];
    });

    const definitions = <defs>
        {sections.map((section, sectionIndex) => 
            <linearGradient key={`${id}-gradient-${sectionIndex}`} id={`${id}-gradient-${sectionIndex}`} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor={section.color} stopOpacity="1" />
                {section.variant !== "gradient" && <stop offset="50%" stopColor={section.color} stopOpacity="1" />}
                <stop offset="100%" stopColor={section.color} stopOpacity={ section.variant === "gradient" ? 0 : 1 } />
            </linearGradient>
        )}
    </defs>;

    const paths = sectionsWithOffsets.map(([section, offset], sectionIndex) => {
        const { values, color, variant: _variant } = section;
        const variant = _variant ?? "filled";
        if (!_.isArray(values)) return null;

        const normalizedValues = values.filter(v => v != null).map(v => (v - minValue) / normalizationFactor);
        const coords = normalizedValues.map((y, x) => ({
            x: (offset + x) * xScale,
            y: (1 - y) * yScale
        }));

        if (coords?.[0]?.x === undefined || isNaN(coords?.[0]?.x) || coords?.[0]?.y === undefined || isNaN(coords?.[0]?.y)) {
            return null;
        }

        const lines = coords.map(({ x, y }) => `L ${x} ${y}`);

        if (variant === "filled" || variant === "gradient") {
            return <>
                <path key={`fill-${sectionIndex}`} d={`M ${coords[0].x} ${height} ${lines.join(" ")} L ${coords.at(-1).x} ${height} Z`} fill={`url(#${id}-gradient-${sectionIndex})`} strokeLinecap="round" strokeLinejoin="round" />
                <path key={`path-${sectionIndex}`} d={`M ${coords[0].x} ${coords[0].y} ${lines.join(" ")}`} fill="none" stroke={color} strokeWidth={ variant === "filled" ? 0 : 2 } strokeLinecap="round" strokeLinejoin="round" />
            </>
        }
        else {
            return <path key={`path-${sectionIndex}`} d={`M ${coords[0].x} ${coords[0].y} ${lines.join(" ")}`} fill="none" stroke={`url(#${id}-gradient-${sectionIndex})`} strokeWidth="3" strokeDasharray={variant === "dashed" ? "5,5" : null} strokeLinecap="round" strokeLinejoin="round" />;
        }
    });

    return (
        <div ref={ref} className={clsx("sparkline-container", className)}>
            {
                width !== undefined && !isNaN(width) && height !== undefined && !isNaN(height) ?
                    <div className="sparkline">
                        <svg viewBox={`0 0 ${width} ${height}`}>
                            {definitions}
                            {paths}
                        </svg>
                    </div> :
                    null
            }
        </div>
    );
}

(Sparkline as DocumentedComponent).metadata = {
    description: "The Sparkline component displays a list of numeric values as a sparkline chart, providing a simple mechanism for visualizing trends in data.  A single Sparkline can consist of an arbitrary mix of different sections, each displaying values in a different style.  For most use cases, only a single section will be used though.",
    attributes: [
        {
            name: "sections", type: "object", description: `An array of sections to display in the sparkline.  Each section is an object with the following properties:

### \`Section\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`values\` | \`number[]\` | The values to render in this section.  Values can be within any range - the point is to show the relationship between these values rather than the raw values. |
| \`color\` | \`string\` | The fill/line color to use for the section.  Any valid CSS color value can be used here, including variable lookups.  If the "dashed" variant is used, this sets the line color.  For the "filled" variant, this sets the background color of the section. |
| \`variant\` | \`"filled" \| "dashed" \| "line"\` | Determines how to render the section.  "filled" renders the section as a filled area.  "dashed" renders a dashed line, where "line" uses a solid line instead. |` }
    ],
    isSelfClosing: true
};

export { Sparkline };