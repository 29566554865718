import "./Chevron.scss";
import { useId } from "react";
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeInputProps, VerticalFillDefinition } from "./common";

function Chevron(props: NodeInputProps) {
    const id = useId();
    const verticalFillId = `vertical-fill-${id}`;
    const fillPercentage = props.data?.fillPercentage;
    const fill = fillPercentage !== undefined && fillPercentage !== null ? `url("#${verticalFillId}")` : null;

    return (
        <div className="FlowChart-node FlowChart-node-chevron">
            <Handle type="source" id="source-top" position={Position.Top} />
            <Handle type="source" id="source-left" position={Position.Left} style={{ left: "16%" }} />
            <Handle type="source" id="source-bottom" position={Position.Bottom} />
            <Handle type="source" id="source-right" position={Position.Right} />

            <Handle type="target" id="target-top" position={Position.Top} />
            <Handle type="target" id="target-left" position={Position.Left} style={{ left: "16%" }} />
            <Handle type="target" id="target-bottom" position={Position.Bottom} />
            <Handle type="target" id="target-right" position={Position.Right} />

            <svg className="node-shape" viewBox="0 0 100 60" preserveAspectRatio="none" width="100%">
                <defs>
                    <VerticalFillDefinition fillPercentage={fillPercentage} id={verticalFillId} />
                </defs>
                <path d="M1,1 L20,30 L1,59 L80,59 L99,30 L80,1 Z" strokeWidth="2" style={{ fill, stroke: fill }}></path>
            </svg>

            <div className="node-content">
                <NodeContent {...props} />
            </div>
        </div>
    );
}

export { Chevron };