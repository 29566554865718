import React from "react";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { updateCurrentFrameData } from "../../store/storyline/actions";
import 'codemirror/mode/javascript/javascript';
import "codemirror/lib/codemirror.css";
import { RootState } from "../../store/index";
import { Trace } from "../../store/tracing/types";
import { formatDate, shouldRecordTraces, setShouldRecordTraces } from "../../shared/utilities";
import { FormControlLabel, Switch } from "../../shared/components";
import { renderValue } from "./shared";

interface TracesProps {
    traces: Trace[];
}

function renderTrace(trace: Trace) {
    return (
        <tr>
            <td>{formatDate(trace.timestamp, "HH:mm:ss")}</td>
            <td>{trace.event.kind}</td>
            <td>{trace.event.name}</td>
            <td>{renderValue(trace.event.oldValue)}</td>
            <td>{renderValue(trace.event.newValue)}</td>
        </tr>
    );
}

function Traces(props: TracesProps) {
    const { traces } = props;
    const [recordTraces, setRecordTraces] = React.useState(shouldRecordTraces());

    const onRecordTracesChanged = React.useCallback((_event, newValue) => {
        setShouldRecordTraces(newValue);
        setRecordTraces(newValue);
    }, [recordTraces]);

    return (
        <div className="traces-layout">
            <FormControlLabel
                className="plush"
                control={<Switch color="primary" checked={recordTraces} onChange={onRecordTracesChanged} />}
                label="Record Events : " />
            
            <div className="table-container traces">
                <table className="table bordered striped">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Event Type</th>
                            <th>Name</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {traces.map(renderTrace)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        traces: state.traces.traces
    }),
    { updateCurrentFrameData, showSuccess, showError })(Traces);