import React from 'react';
import "./DatePicker.scss";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DatePicker as BaseDatePicker } from "../../../shared/components";
import { Moment, default as moment } from 'moment';
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';
import _ from 'lodash';

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    format?: string;
    minDate?: string | Date | Moment;
    maxDate?: string | Date | Moment;
    onChange?: (value: Moment, originalValue: any) => void
}

function _DatePicker(props: Props) {
    const { storyline, updateParameterValue, name, format, minDate, maxDate, onChange, ...other } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || null);
    const valueRef = React.useRef(value);
    const [open, setOpen] = React.useState(false);
    const [originalValue, setOriginalValue] = React.useState(value);

    const handleChange = (newValue: Moment) => {
        setValue(newValue);
        valueRef.current = newValue; // The handleClose event handler will read this value in order to an update + execute in the same render cycle.

        if (!open) {
            updateParameterValue(name, newValue);
            onChange?.(newValue, originalValue);
        }
    };

    const handleOpen = () => {
        setOpen(true);
        setOriginalValue(value);
    }

    const handleClose = () => {
        setOpen(false);

        if (!_.isEqual(originalValue, valueRef.current)) {
            updateParameterValue(name, valueRef.current);
            onChange?.(valueRef.current, originalValue);
        }
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return (
        <BaseDatePicker
            className="date-picker"
            {...other}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            minDate={minDate ? moment(minDate) : undefined}
            maxDate={maxDate ? moment(maxDate) : undefined}
            value={value}
            onChange={handleChange}
            inputFormat={format || "YYYY/MM/DD"}
        />
    );
}

const DatePicker = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(_DatePicker);

(DatePicker as DocumentedComponent).metadata = {
    description: `The DatePicker component allows the user to select a date value for a storyline parameter.  It does this via a calendar control inside a pop-up.

Note that this control does not allow for the selection of the time.  The selected date will be a Moment object with the time set to local midnight.
    
_Use the \`DateTimePicker\` component in order to support the selection of the date + time._`,
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the parameter that the selected value will be read from and persisted to." },
        { name: `format`, type: `string`, description: "The format of the date to be displayed in the input field.  This is a string that is passed to the Moment library.  See https://momentjs.com/docs/#/displaying/format/ for more information." },
        { name: `minDate`, type: `object`, description: "The minimum date that can be selected.  This can be a string, a Date object, or a Moment object." },
        { name: `maxDate`, type: `object`, description: "The maximum date that can be selected.  This can be a string, a Date object, or a Moment object." },
        { name: `onChange`, type: `function`, template: "onChange={(newValue, _originalValue) => {$1}}", description: "An optional callback function that will be invoked when the selected date changes.  The updated value and the original value are passed in as arguments." }
    ]
};

export default DatePicker;