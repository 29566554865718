import { lazy, Suspense } from "react";
import type { JsxRendererProps } from "./JsxRendererImpl";

// Dynamic import, in order to avoid circular references...
const JsxRendererImpl = lazy(() => import("./JsxRendererImpl"));

function JsxRenderer(props: JsxRendererProps) {
    return (
        <Suspense fallback={null}>
            <JsxRendererImpl {...props} />
        </Suspense>
    );
}

export { JsxRenderer };