import React from 'react';
import "./ConfirmationDialog.scss";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const ConfirmationDialog = (props) => {
    const { title, children, open, setOpen, onConfirm, onCancel, ...rest } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirmation-dialog"
            className="confirmation-dialog"
            {...rest}
        >
            <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    onClick={() => {
                        setOpen(false);
                        onCancel?.();
                    }}
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="success"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;