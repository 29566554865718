import { useAuth0 } from "../../auth/AuthContext";
import { evaluateTagGroups } from "../utilities";

interface RequiresTagsProps {
    tags: string[][];
    children: any;
}

function RequiresTags(props: RequiresTagsProps) {
    const { tags, children } = props;
    const { effectiveTags } = useAuth0();

    const isAllowed = evaluateTagGroups(tags, effectiveTags);

    return (
        isAllowed && children
    );
}

export default RequiresTags;