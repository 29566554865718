import "./KpiDelta.scss";
import clsx from "clsx";
import { ContentRenderer } from "../ContentRenderer";
import { ProgressBar } from "../../../shared/components";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface KpiDeltaProps {
    title?: string;
    value?: string;
    delta?: string;
    progressBarValues?: [number, number];
    maxProgressBarValue?: number,
    className?: string;
}

function KpiProfile(props: KpiDeltaProps) {
    const { title, delta, value, progressBarValues, maxProgressBarValue, className, ...rest } = props;

    return (
        <div {...rest} className={clsx("kpi-delta", className)}>
            <div className="title">{<ContentRenderer input={title} />}</div>
            <div className="delta">{<ContentRenderer input={delta} />}</div>
            <div className="value">{<ContentRenderer input={value} />}</div>
            <div className="progressbar secondary">
                <ProgressBar value={_.max(progressBarValues)} maxValue={maxProgressBarValue} text=" " />
            </div>
            <div className="progressbar primary">
                <ProgressBar value={_.min(progressBarValues)} maxValue={maxProgressBarValue} text=" " />
            </div>
        </div>
    );
}

KpiProfile.defaultProps = {
    maxProgressBarValue: 1
};

(KpiProfile as DocumentedComponent).metadata = {
    description: "The KpiDelta component displays the current KPI value, delta and a progress bar in a row-based format.",
    isSelfClosing: true,
    attributes: [
        { name: `title`, type: `string`, description: "The name/label for the KPI.  Supports HTML or JSX via the relevant prefix." },
        { name: `className`, type: "string", options: ['positive', 'negative', 'neutral'], description: "The CSS class to apply to the component.  The provided defaults populate the fill color of the progress bars and the delta text/icon colors.  Any other values can also be used, but custom CSS will need to be provided in the template accordingly." },
        { name: `value`, type: `string`, description: "The current value for the KPI.  Supports HTML or JSX via the relevant prefix." },
        { name: `delta`, type: `string`, description: "The delta value for the KPI.  Supports HTML or JSX via the relevant prefix." },
        { name: `progressBarValues`, type: "object", description: "The from/to values to display on the progress bar.  If `maxProgressBarValue` is not 1, the filled portion is calcuted as a fraction of that value." },
        { name: `maxProgressBarValue`, type: `number`, description: "The maximum value that the progress bar can represent.  The filled portions are calculated as a fraction of this value.  Optional - defaults to `1`." },
    ]
};

export default KpiProfile;