import * as React from "react";
import { Card, CardContent, CardHeader } from "../../shared/components";

interface SectionProps extends React.ComponentPropsWithoutRef<any> {
    heading: string;
    contentClass?: string;
    children: any;
}

export function Section(props: SectionProps) {
    const { heading, children, contentClass, ...rest } = props;

    return (
        <Card {...rest}>
            <CardHeader title={heading} />
            <CardContent className={`section ${contentClass}`}>
                {children}
            </CardContent>
        </Card>
    );
}