import {
    CellTemplate,
    Cell,
    Compatible,
    Uncertain,
    UncertainCompatible
} from "@silevis/reactgrid";

export interface JsxCell extends Cell {
    type: "jsx",
    content: JSX.Element
}

export class JsxCellTemplate implements CellTemplate<JsxCell> {
    getCompatibleCell(uncertainCell: Uncertain<JsxCell>): Compatible<JsxCell> {
        const content = uncertainCell.content ?? null;
        const text = null;
        const value = NaN;
        return { ...uncertainCell, content, text, value, nonEditable: true };
    }

    handleKeyDown(
        cell: Compatible<JsxCell>,
        _keyCode: number,
        _ctrl: boolean,
        _shift: boolean,
        _alt: boolean
    ): { cell: Compatible<JsxCell>; enableEditMode: boolean } {
            return { cell, enableEditMode: false };
    }

    update(
        cell: Compatible<JsxCell>,
        _cellToMerge: UncertainCompatible<JsxCell>
    ): Compatible<JsxCell> {
        return cell;
    }

    isFocusable(_cell: Compatible<JsxCell>): boolean {
        return false;
    }

    getClassName(cell: Compatible<JsxCell>, _isInEditMode: boolean): string {
        return cell.className ?? "";
    }

    render(
        cell: Compatible<JsxCell>
    ): React.ReactNode {
        return cell.content;
    }
}