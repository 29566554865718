import { BaseClient, jsonParseReviver } from "./api-client-extensions";
import { RequestInitWithRetries } from "./monkey-patches";

const urlPrefix = "api/web/v1";

interface IHttpClient {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
}

class BaseModel {
    name: string;
    schemaVersion?: string;
    object?: string;
    createdOnMs?: string;
    storedOnMs?: string;
    version?: string;
}

export class MenuItemModel extends BaseModel {
    displayOrder: number;
    title: string;
    icon?: {
        type: "URL" | "BuiltIn" | "Binary";
        content: string;
    };
    description?: string;
    storyline?: string;
    get url() {
        return this.storyline ? `/storylines/${this.storyline}` : null;
    };
    children?: MenuItemModel[];
    get menuLocationId() {
        return 1;
    }
}

export class MenuItemsClient extends BaseClient {
    private http: IHttpClient;
    private baseUrl: string;
    private activeBranch: string;

    constructor(baseUrl: string, activeBranch: string, http?: IHttpClient) {
        super();
        this.http = http ? http : window as any;
        this.activeBranch = activeBranch;
        this.baseUrl = baseUrl;
    }

    static mapToMenuItemModel(item: MenuItemModel) {
        if (!item) return null;

        const result = Object.assign(new MenuItemModel(), item);
        result.children = result.children?.map(MenuItemsClient.mapToMenuItemModel);
        return result;
    }

    getAll(): Promise<MenuItemModel[]> {
        let url_ = `${this.baseUrl}/${urlPrefix}/menu-items`;
        let options_: RequestInitWithRetries = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1",
            },
            retries: 1,
            signal: AbortSignal.timeout(8000)
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<MenuItemModel[]>(response).then(result => {
                return result.map(MenuItemsClient.mapToMenuItemModel);
            });
        });
    }

    getById(id: string): Promise<MenuItemModel> {
        let url_ = `${this.baseUrl}/${urlPrefix}/menu-items/${id}`;
        let options_: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1",
            }
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<MenuItemModel>(response);
        });
    }
}

export interface Parameter {
    name: string;
    type?: string;
    default?: any;
}

export class Datasource extends BaseModel {
    requiredParameters?: Parameter[];
    optionalParameters?: Parameter[];
    defaultData?: string;
    autoRefresh?: boolean;
    apiEndpoint?: string[];
}

export class StorylineObjectModel extends BaseModel {
    dataSources?: {
        [key: string]: Datasource;
    };
    isHideable?: boolean;
}

export class Storyline extends StorylineObjectModel {
    friendlyUrl?: string;
    chapters: Chapter[];
    templates?: Record<string, TemplateContent>; // Nullable for backwards-compatibility.  @todo: Remove nullability once all metastore instances are updated.
}

export class Chapter extends StorylineObjectModel {
    pages: Page[];
}

export class Page extends StorylineObjectModel {
    template: Template | string; // Union type for backwards-compatibility.  Will be string in the latest version of the metastore.  @todo: Remove once all metastore instances are updated.
    paragraphs: Paragraph[];
}

export class Paragraph extends StorylineObjectModel {
    slots: Slot[];
}

export class Slot extends BaseModel {
    dataSource?: string;
}

export class TemplateContent {
    jsx: string;
    css: string;
}

export class Template extends BaseModel {
    root: TemplateContent;
}

export class TemplateInputModel {
    jsx: string;
    css: string;
}

export class StorylinesClient extends BaseClient {
    private http: IHttpClient;
    private baseUrl: string;
    private activeBranch: string;

    constructor(baseUrl: string, activeBranch: string, http?: IHttpClient) {
        super();
        this.http = http ? http : window as any;
        this.activeBranch = activeBranch;
        this.baseUrl = baseUrl;
    }

    getAll(): Promise<Storyline[]> {
        let url_ = `${this.baseUrl}/${urlPrefix}/storylines`;
        let options_: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1",
            }
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<Storyline[]>(response);
        });
    }

    getById(id: string): Promise<Storyline> {
        let url_ = `${this.baseUrl}/${urlPrefix}/storylines/${id}`;
        let options_: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1",
            }
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<Storyline>(response);
        });
    }

    updateTemplate(storyline_id: string, template_id: string, jsx: string, css: string): Promise<string> {
        let url_ = `${this.baseUrl}/${urlPrefix}/storylines/${storyline_id}/template`;
        let options_: RequestInit = {
            method: "PUT",
            body: JSON.stringify({
                id: template_id,
                jsx,
                css
            }),
            headers: {
                "Content-Type": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1"
            }
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<string>(response);
        });
    }
}

export class DatasourcesClient extends BaseClient {
    private http: IHttpClient;
    private baseUrl: string;
    private activeBranch: string;
    private maxDatasourceRetryCount: number | null;

    constructor(baseUrl: string, activeBranch: string, maxDatasourceRetryCount?: number, http?: IHttpClient) {
        super();
        this.http = http ? http : window as any;
        this.activeBranch = activeBranch;
        this.baseUrl = baseUrl;
        this.maxDatasourceRetryCount = maxDatasourceRetryCount;
    }

    fetchData(apiUrl: string, apiEndpoint: string, parameterValues: object): Promise<any> {
        let url_ = `${this.baseUrl}/${urlPrefix}/data-sources/data?url=${apiUrl}&endpoint=${apiEndpoint}`;
        let options_: RequestInitWithRetries = {
            method: "POST",
            body: JSON.stringify(parameterValues),
            headers: {
                "Accept": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1"
            },
            retries: this.maxDatasourceRetryCount
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<object>(response);
        });
    }
}

function parseResponse<TModel>(response: Response): Promise<TModel> {
    const status = response.status;
    let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
    if (status === 200) {
        return response.text().then((_responseText) => {
            let result200: TModel = null;
            result200 = _responseText === "" ? null : JSON.parse(_responseText, jsonParseReviver) as TModel;
            return result200;
        });
    } else if (status !== 200 && status !== 204) {
        return response.text().then((_responseText) => {
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        });
    }
}

export class TemplatesClient extends BaseClient {
    private http: IHttpClient;
    private baseUrl: string;
    private activeBranch: string;

    constructor(baseUrl: string, activeBranch: string, http?: IHttpClient) {
        super();
        this.http = http ? http : window as any;
        this.activeBranch = activeBranch;
        this.baseUrl = baseUrl;
    }

    getById(id: string): Promise<Template> {
        let url_ = `${this.baseUrl}/${urlPrefix}/templates/${id}`;
        let options_: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Branch": this.activeBranch,
                "BranchState": "clean",
                "QSC-Data-Model": "0.0.1"
            }
        };
        return this.http.fetch(url_, options_).then((response: Response) => {
            return parseResponse<Template>(response);
        });
    }
}

export class ApiException extends Error {
    override message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}