import * as React from "react";
import { Badge as MaterialBadge, BadgeProps } from "@mui/material";
import * as _ from "lodash";

interface ExtendedBadgeProps extends BadgeProps {
    color?: "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning" | "red" | "orange" | "yellow" | "green" | "blue" | "darkblue" | "grey" | any;
    modifier?: "square" | "outlined";
}

const baseColors = ["primary", "secondary", "default", "error", "info", "success", "warning"];

const Badge = (props: ExtendedBadgeProps) => {
    const { color, modifier, ...rest } = props;

    return (
        _.find(baseColors, c => c === color) ?
            <MaterialBadge color={color} className={modifier} {...rest} /> :
            <MaterialBadge color="default" className={`${color} ${modifier}`} {...rest} />
    );
};

export default Badge;