export interface FetchInterceptorResponse extends Response {
    request: Request;
}

export interface FetchInterceptor {
    request?(url: string, config: any): Promise<any[]> | any[];
    requestError?(error: any): Promise<any>;
    response?(response: FetchInterceptorResponse): FetchInterceptorResponse;
    responseError?(error: any): Promise<any>;
}

let interceptors: FetchInterceptor[] = [];

function interceptor(fetch, ...args) {
    const reversedInterceptors = interceptors.reduce((array, interceptor) => [interceptor].concat(array), []);
    let promise = Promise.resolve(args);

    // Register request interceptors
    reversedInterceptors.forEach(({ request, requestError }) => {
        if (request || requestError) {
            promise = promise.then(args => request(...args), requestError);
        }
    });

    // Register fetch call
    promise = promise.then(args => fetch(...args));

    // Register response interceptors
    reversedInterceptors.forEach(({ response, responseError }) => {
        if (response || responseError) {
            promise = promise.then(response, responseError);
        }
    });

    return promise;
}

export function attachToEnvironment(env) {
    env.fetch = (function (fetch) {
        return function (...args) {
            return interceptor(fetch, ...args);
        };
    })(env.fetch);

    return {
        register: function (interceptor: FetchInterceptor) {
            interceptors.push(interceptor);
            return () => {
                const index = interceptors.indexOf(interceptor);
                if (index >= 0) {
                    interceptors.splice(index, 1);
                }
            };
        },
        clear: function () {
            interceptors = [];
        }
    };
}