import { AppBar, AvatarGroup, Card, CardHeader, Chip, CircularProgress, DialogActions, DialogContent, Drawer, Fab, IconButton, Input, LinearProgressIndicator, Toolbar } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

(AppBar as DocumentedComponent).metadata = {
    description: "The AppBar component renders a top-level navigation bar.",
    attributes: [
        { name: "color", description: "The background to use for this element.", type: "string", options: ['default', 'inherit', 'primary', 'secondary', 'transparent'] },
        { name: "position", description: "The position of the app bar.", type: "string", options: ["fixed", "absolute", "sticky", "static", "relative"] }
    ]
};
export { AppBar };

(AvatarGroup as DocumentedComponent).metadata = {
    description: `The AvatarGroup component renders a group of Avatars.

NOTE: Newline characters often result in components being wrapped in `,
    attributes: [
        { name: "max", description: "The maximum number of avatars to display.  If the actual avatar count exceeds this value, only the first (`max`-1) avatars will be rendered, with a counter displayed in the final position.", type: "number" },
        { name: "spacing", description: "The spacing between avatars.", type: "string", options: ["small", "medium", "large"] }
    ]
};
export { AvatarGroup };

(Card as DocumentedComponent).metadata = {
    description: "Cards contain content and actions about a single subject.",
    attributes: [
        { name: "variant", description: "The type of outline to apply to the card.  Defaults to 'elevation'.", type: "string", options: ["elevation", "outlined"] }
    ]
};
export { Card };

(CardHeader as DocumentedComponent).metadata = {
    description: "Renders a header inside a card.  Contains a title and optional actions.",
    isSelfClosing: true,
    attributes: [
        { name: "title", description: "The text to display within this header.", type: "string" },
        { name: "action", description: "The elements to render inside the 'action' section of the header.  This is typically a button or a menu.", type: "object" },
    ]
};
export { CardHeader };

(Chip as DocumentedComponent).metadata = {
    description: "Chips represent complex entities in small blocks, such as a contact.",
    attributes: [
        { name: "color", description: "The color of the chip.  Defaults to 'default'.", type: "string", options: ["default", "primary", "secondary", "success", "error", "warning", "info"] },
        { name: "variant", description: "The variant to use.  Defaults to 'filled'.", type: "string", options: ["filled", "outlined"] },
        { name: "size", description: "The size of the chip.  Defaults to 'medium'.", type: "string", options: ["small", "medium"] },
        { name: "icon", description: "The icon to display inside the chip.", type: "object" },
        { name: "label", description: "The text to display inside the chip.", type: "string" },
        { name: "onClick", description: "The function to invoke when the chip is clicked.", type: "function", template: "onClick={(e) => {$1}}" },
    ]
};
export { Chip };

(CircularProgress as DocumentedComponent).metadata = {
    description: "Circular progress indicators display the length of a process or express an unspecified wait time.",
    isSelfClosing: true,
    attributes: [
        { name: "color", description: "The color of the progress indicator.  Defaults to 'primary'.", type: "string", options: ["primary", "secondary", "success", "error", "warning", "info"] },
        { name: "size", description: "The size of the component.  If using a number, the pixel unit is assumed.  If using a string, you need to provide the CSS unit, e.g '3rem', '80px', etc.", type: "string" },
        { name: "value", description: "The value of the progress indicator for the determinate variant.  Value between 0 and 100.", type: "number" },
        { name: "variant", description: "The variant to use.  Defaults to 'indeterminate'.", type: "string", options: ["determinate", "indeterminate"] },
    ]
};

(DialogActions as DocumentedComponent).metadata = {
    description: "The DialogActions component renders a set of buttons at the bottom of a dialog.",
    attributes: [
        { name: "disableSpacing", description: "If true, the actions do not have additional margin.", type: "boolean" }
    ]
};
export { DialogActions };

(DialogContent as DocumentedComponent).metadata = {
    description: "The DialogContent component renders the content of a dialog.",
    attributes: [
        { name: "dividers", description: "If true, renders dividers above and below the content.", type: "boolean" }
    ]
};
export { DialogContent };

(Drawer as DocumentedComponent).metadata = {
    description: "The Drawer component renders a panel that slides in from the side of the screen.",
    attributes: [
        { name: "anchor", description: "The anchor of the drawer.  Defaults to 'left'.", type: "string", options: ["left", "right", "top", "bottom"] },
        { name: "open", description: "If true, the drawer is open.", type: "boolean" },
        { name: "variant", description: "The variant to use.  Defaults to 'temporary'.", type: "string", options: ["permanent", "persistent", "temporary"] },
        { name: "onClose", description: "The function to invoke when the drawer is closed.", type: "function", template: "onClose={(e) => {$1}}" }
    ]
};
export { Drawer };


(Fab as DocumentedComponent).metadata = {
    description: "The Fab component renders a floating action button.",
    attributes: [
        { name: "color", description: "The color of the button.  Defaults to 'default'.", type: "string", options: ["default", "inherit", "primary", "secondary", "success", "error", "warning", "info"] },
        { name: "size", description: "The size of the button.  Defaults to 'medium'.", type: "string", options: ["small", "medium", "large"] },
        { name: "variant", description: "The variant to use.  Defaults to 'circular'.", type: "string", options: ["circular", "extended"] },
        { name: "onClick", description: "The function to invoke when the button is clicked.", type: "function", template: "onClick={(e) => {$1}}" }
    ]
};
export { Fab };

(IconButton as DocumentedComponent).metadata = {
    description: "The IconButton component renders a button with an icon.",
    attributes: [
        { name: "color", description: "The color of the button.  Defaults to 'default'.", type: "string", options: ["default", "inherit", "primary", "secondary", "success", "error", "warning", "info"] },
        { name: "size", description: "The size of the button.  Defaults to 'medium'.", type: "string", options: ["small", "medium"] },
        { name: "onClick", description: "The function to invoke when the button is clicked.", type: "function", template: "onClick={(e) => {$1}}" }
    ]
};
export { IconButton };

(Input as DocumentedComponent).metadata = {
    description: "The Input component renders a text input field.",
    attributes: [
        { name: "disabled", description: "If true, the input is disabled.", type: "boolean" },
        { name: "error", description: "If true, the input is in an error state.", type: "boolean" },
        { name: "fullWidth", description: "If true, the input will take up the full width of its container.", type: "boolean" },
        { name: "helperText", description: "The helper text to display below the input.", type: "string" },
        { name: "label", description: "The label to display above the input.", type: "string" },
        { name: "multiline", description: "If true, the input will allow multiple lines of text.", type: "boolean" },
        { name: "required", description: "If true, the input will be required.", type: "boolean" },
        { name: "rows", description: "The number of rows to display when multiline is true.", type: "number" },
        { name: "value", description: "The value of the input.", type: "string" },
        { name: "variant", description: "The variant to use.  Defaults to 'standard'.", type: "string", options: ["standard", "outlined", "filled"] },
        { name: "onChange", description: "The function to invoke when the input value changes.", type: "function", template: "onChange={(e) => { const newValue = e.target?.value; $1 }}" }
    ]
};
export { Input };

(LinearProgressIndicator as DocumentedComponent).metadata = {
    description: "Linear progress indicators display the length of a process or express an unspecified wait time.",
    isSelfClosing: true,
    attributes: [
        { name: "color", description: "The color of the progress indicator.  Defaults to 'primary'.", type: "string", options: ["primary", "secondary", "success", "error", "warning", "info"] },
        { name: "showLabel", description: "Determines whether to display a label alongside the progress indicator, containing the current value.", type: "boolean" },
        { name: "value", description: "The value of the progress indicator for the determinate variant.  Value between 0 and 100.", type: "number" },
        { name: "valueBuffer", description: "The value for the buffer variant.  Value between 0 and 100.", type: "number" },
        { name: "variant", description: "The variant to use.  Defaults to 'indeterminate'.", type: "string", options: ["determinate", "indeterminate", "buffer", "query"] },
    ]
};
export { LinearProgressIndicator, LinearProgressIndicator as LinearProgress };

(Toolbar as DocumentedComponent).metadata = {
    description: "The Toolbar component is used as a layout container for the contents of an AppBar.",
    attributes: [
        { name: "variant", description: "The variant to use.  Defaults to 'regular'.", type: "string", options: ["dense", "regular"] },
        { name: "disableGutters", description: "If true, the toolbar will not have gutters.", type: "boolean" }
    ]
};
export { Toolbar };