import * as React from "react";
import clsx from "clsx";
import "./filters.scss";
import { DocumentedComponent } from "./DocumentedComponent";

interface FilterRowProps {
    className?: string;
    children?: any;
}

function FilterRow(props: FilterRowProps) {
    const { className, children, ...rest } = props;

    return (
        <div className={clsx("canvas-filter-row", className)} {...rest}>
            {children}
        </div>
    );
}

(FilterRow as DocumentedComponent).metadata = {
    description: `The FilterRow component is used to display a row of filters.  It is used by the Filter component.`,
    isSelfClosing: false,
    attributes: []
};

interface FilterProps {
    title?: string;
    className?: string;
    children?: any;
}

function Filter(props: FilterProps) {
    const { title, children, className, ...rest } = props;

    return (
        <div className={clsx("canvas-filter", className)} {...rest}>
            <span className="filter-title">{title}</span>
            <div className="controls-list">
                {children}
            </div>
        </div>
    );
}

(Filter as DocumentedComponent).metadata = {
    description: "A single input component that is displayed alongside an optional label.",
    isSelfClosing: false,
    attributes: [
        { name: `title`, type: `string`, description: "The text to display in the filter's label." }
    ]
};

export { FilterRow, Filter };