import React from "react";
import FullCalendar from "@fullcalendar/react";
import { config } from "@fullcalendar/core/internal";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import _ from "lodash";
import { CalendarOptions, EventContentArg, EventInput, SlotLabelContentArg } from "@fullcalendar/core";
import moment from "moment";
import "./Calendar.scss";
import { ResourceInput } from "@fullcalendar/resource";

config.MAX_TIMELINE_SLOTS = 1_000_000;

type CalendarProps = CalendarOptions & {
    resources: ResourceInput[];
    events: EventInput[];
};

const Calendar = (props: CalendarProps) => {
    const { events, resources, eventContent: _eventContent, ...rest } = props;

    const fromDate = moment(_.min((events ?? []).map(e => e.start))).startOf("month").toDate();
    const toDate = moment(_.max((events ?? []).map(e => e.end))).endOf("month").toDate();

    const calendarRef = React.useRef<FullCalendar>(null);

    const defaultEventContent = React.useCallback((arg: EventContentArg) => {
        const { title } = arg.event;

        return (
            <div className="fc-event-main-frame">
                <div className="fc-event-title-container">
                    <div className="fc-event-title fc-sticky">
                        <b>{title}</b>
                    </div>
                </div>
            </div>
        );
    }, []);

    const eventContent = _eventContent ?? defaultEventContent;

    const getAdditionalSlotClassNames = React.useCallback((arg: SlotLabelContentArg) => {
        const result = [];

        const { date } = arg;
        const day = moment(date);

        if (day.date() === 1) {
            result.push("fc-first-day-of-month");
        }

        if (day.date() === day.daysInMonth()) {
            result.push("fc-last-day-of-month");
        }

        return result;
    }, []);

    return (
        <FullCalendar
            ref={calendarRef}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            plugins={[resourceTimelinePlugin, interactionPlugin]}

            events={events}
            resources={resources}

            slotLaneClassNames={getAdditionalSlotClassNames}
            visibleRange={{ start: fromDate, end: toDate }}
            height="100%"

            resourceOrder={null} // Retain original resource order - don't sort by title
            editable={true}
            scrollTimeReset={false} // Don't reset the scroll position each time the calendar input is updated
            eventMinWidth={1} // Events can be rendered arbitrarily small by default

            eventContent={eventContent}

            {...rest}
        />
    );
};

export { Calendar };