import React from "react";
import "./EditGroup.scss";
import * as coreApi from "../../shared/core-api-client";
import * as _ from "lodash";
import { Button, Dialog, DialogContent, TextField, DialogActions, Select, MenuItem, Chip, DialogTitleWithCloseButton, InlineInfoTooltip, InfoAlert, KeyValueEditor, Checkbox, LoadingContainer } from "../../shared/components";
import { Controller, useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { useSettings } from "../../shared/providers/SettingsProvider";

interface EditGroupProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    roles: coreApi.RoleLookup[],
    group?: coreApi.GroupListModel,
    showSuccess: typeof showSuccess,
    showError: typeof showError,
    onSuccess: () => void
}

function EditGroup(props: EditGroupProps) {
    const schema = object().shape({
        name: string()
            .required("This field is required."),
        description: string()
            .required("This field is required."),
        roles: array().of(string()),
        userMetadata: array(),
    });
    const { open, setOpen, roles, group: _group, showSuccess, showError, onSuccess } = props;
    const { register, handleSubmit, errors, reset, setError, control } = useForm({ resolver: yupResolver(schema) });
    const [formErrors, setFormErrors] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const settings = useSettings();

    React.useEffect(() => {
        if (_group) {
            new coreApi.GroupsClient(settings.coreApiUrl).getById(_group.id)
                .then(result => {
                    setGroup(result);
                    reset(result);
                });
        }
        else {
            reset({ explicitTags: { allowed: [], denied: [] } });
        }
    }, [_group]);

    const closeDialog = () => {
        setOpen(false);
    }

    const attachFormErrors = (errorDictionary: object) => {
        _.forOwn(errorDictionary, (value, key) => {
            if (key === "non_field_errors") {
                setFormErrors(value[0]);
            }
            else {
                setError(_.camelCase(key), { type: "server", message: value[0] });
            }
        });
    }

    const save = (formModel) => {
        const model = {
            domainName: settings.domainName,
            ...group,
            ...formModel,
        };

        const apiCall = group ?
            new coreApi.GroupsClient(settings.coreApiUrl).update(group.id, model) :
            new coreApi.GroupsClient(settings.coreApiUrl).create(model);

        (apiCall as Promise<any>)
            .then(() => {
                closeDialog();
                showSuccess(`Group has been ${group ? "updated" : "created"} successfully.`);
                onSuccess();
            })
            .catch((ex: coreApi.ApiException) => {
                if (ex.status === 400) {
                    attachFormErrors((ex.result as coreApi.ValidationErrorResponse).errors ?? {});
                }
                else {
                    showError(ex.response);
                }
            });
    };

    const getRole = (id) => {
        return roles.find(r => r.id === id);
    };

    return (
        <Dialog
            id="edit-group-dialog"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(save)}>
                <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                    {`${group ? "Edit " + group.name : "Create Group"}`}
                </DialogTitleWithCloseButton>
                <DialogContent>
                    <div className="field-header">Name</div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        title={group != null ? "This field cannot be changed once created." : null }
                        disabled={group != null}
                    />

                    <div className="field-header">Description</div>
                    <TextField
                        name="description"
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />

                    <div className="field-header">Roles</div>
                    <Controller
                        name="roles"
                        control={control}
                        defaultValue={[]}
                        render={({ onChange, value }) => (
                            <Select
                                className="margin-bottom-2"
                                multiple
                                value={value}
                                onChange={onChange}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    id: 'roles',
                                }}
                                renderValue={(selected) => (
                                    <div>
                                        {(selected as string[] ?? []).map((value) => (
                                            <Chip key={value} label={getRole(value)?.name} color="primary" className="multiselect-chip" />
                                        ))}
                                    </div>
                                )}
                            >
                                {
                                    roles
                                        .filter(role => role.isAssignable)
                                        .map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))
                                }
                            </Select>
                        )}
                    />

                    <div className="field-header">Metadata
                        <InlineInfoTooltip
                            text="Any additional information about the users assigned to this group can be captured here.  This will be available to the application and can be utilized for custom logic/bindings in storylines."
                        />
                    </div>
                    <Controller
                        name="userMetadata"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="field"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />
                    
                    {
                        formErrors &&
                        <div className="form-errors">
                            {formErrors}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {`${group?.id ? "Update" : "Create"} Group`}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default connect(
    null,
    { showSuccess, showError })(EditGroup);