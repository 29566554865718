import { ContentRenderer } from "../ContentRenderer";
import type { Graphic } from "./types";

export function KpiMosaicGraphic(props: Graphic) {
    const { type, content } = props ?? {};
    if (!type || !content) return null;

    const jsxString =
        type === "img" ? `html:<img src="${content}" />` :
            type === "Qerent-Bespoke" ? `jsx:<Icons.${content} />` :
                type === "MaterialUI" ? `jsx:<Icon>${content}</Icon>` :
                    type === "FontAwesome" ? `html:<i class="fa ${content}"></i>` : "";

    return <ContentRenderer input={jsxString} />;
}