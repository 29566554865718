import axios, { AxiosResponse } from 'axios';
import { PolygonFeatureCollection, GeoGpsDataModel } from './types';

export class GeoClient {
    public getGeoFeatures(coords: number[][]): Promise<AxiosResponse<PolygonFeatureCollection>> {
        const formatCoords = JSON.stringify(coords);
        const params = { bounding_coords: formatCoords };
        return axios.get('/api/getgeofeatures', { params });
    }

    public getGeoById(id: any): Promise<AxiosResponse<PolygonFeatureCollection>> {
        const formatId = JSON.stringify(id);
        const params = { ID: formatId.replace(/"/g, '') };
        return axios.get('/api/getgeobyid', { params });
    }

    public getGpsData(coords: number[][], max: number): Promise<AxiosResponse<GeoGpsDataModel[]>> {
        const formatCoords = JSON.stringify(coords);
        const params = { bounding_coords: formatCoords, max };
        return axios.get('/api/getgpsdata', { params });
    }
 
    public upsertGeo(model: PolygonFeatureCollection): Promise<AxiosResponse> {
        return axios.post('/api/upsertgeofeature', model);
    }

    public deleteGeo(id: any): Promise<AxiosResponse> {
        const params = { ID: id };
        return axios.post('/api/deletegeobyid', null, { params });
    }
}