import React from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "../../auth/AuthContext";
import { PERMISSIONS } from "../../auth/types";
import { RootState, useThunkDispatch } from "../../store";
import { switchBranch } from "../../store/app/actions";
import TextField from "./TextField";

function BranchSelector(props) {
    const defaultBranch = useSelector((store: RootState) => store.app.settings.activeBranch);
    const activeBranch = useSelector((store: RootState) => store.app.activeBranch);
    const [value, setValue] = React.useState(activeBranch);
    const dispatch = useThunkDispatch();
    const { effectiveTags } = useAuth0();

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBranchChanged = (e) => {
        const input = e.target.value ?? "";

        const newBranch =
            input
                ?.toLowerCase() // Azure only allows lowercase characters in blob path
                ?.replace(/[^a-z0-9-\.]+/g, "") // Only allow alphanumeric, dot and hyphen
                ?.replace(/(--)+/g, "-") // Double hyphens are out
                ?.match(/[a-z0-9]+.*[a-z0-9]+/g)?.[0] // Remove leading and trailing dots and hyphens
                ?.substring(0, 63); // Limit length to 63

        setValue(newBranch ?? defaultBranch);

        if (newBranch === activeBranch) return;

        dispatch(switchBranch(newBranch));
    }

    const isDeveloper = effectiveTags?.includes?.(PERMISSIONS.DEVELOPER_TOOLS_ACCESS);

    return isDeveloper && <TextField value={value} onChange={onChange} onBlur={onBranchChanged} />;
}

export { BranchSelector };