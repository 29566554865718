import { TracingState, TracingActionTypes, ADD_TRACE_EVENT } from "./types";

const initialState: TracingState = {
    traces: []
}

export function tracingReducer(
    state = initialState,
    action: TracingActionTypes
): TracingState {
    switch (action.type) {
        case ADD_TRACE_EVENT:
            return {
                traces: [action.trace, ...state.traces].slice(0, 100) // Only keep 100 entries...
            };

        default:
            return state;
    }
}