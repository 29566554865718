import React from 'react';
import "./Table.scss";
import GridFooter from "./GridFooter";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue, goToID, applyParameterValueChanges } from "../../../store/storyline/actions";
import { GridCellParams, GridCellEditCommitParams, GridCellValue, GridColDef, GridRenderCellParams, GridRowData, GridRowParams, GridValueFormatterParams, useGridApiRef, MuiBaseEvent, GridRenderEditCellParams, renderEditInputCell } from '@mui/x-data-grid-pro';
import { DataGrid } from "../../../shared/components";
import * as _ from "lodash";
import clsx from "clsx";
import { getColumnDateTimeFormatter, getColumnNumberFormatter } from '../../../shared/utilities';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { CellWithTooltipRenderer } from "./CellWithTooltip";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';
import { JsxRenderer } from '../ContentRenderer/JsxRenderer';

interface ParameterMap {
    fieldName: string;
    parameterName: string;
}

interface Column extends Omit<GridColDef, 'valueFormatter'> {
    index?: number;
    valueFormatter?: (params: GridValueFormatterParams) => GridCellValue | Array<string>;
    cellTemplate?: string;
    cellEditTemplate?: string;
    cellEditTemplateFieldName?: string;
    tooltipTemplate?: string;
}

interface TableProps {
    storyline: StorylineState;
    parameterMap?: ParameterMap[];
    rows: GridRowData[];
    columns: Column[];
    additionalColumns: Column[];
    navigateTo?: string;
    selectedRowParameter?: string;
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
    className?: string;
    onRowClick?: (param: GridRowParams) => void;
    hideExportToExcel?: boolean;
    allRowsParameter?: string;
    editedRowsParameter?: string;
    onRowEdited: (row: any, originalRow: any) => void;
    additionalFooterItems?: React.ReactNode;
}

function mapValueFormatter(valueFormatter: any, columnType: string = "number"): (params: GridValueFormatterParams) => GridCellValue {
    if (_.isFunction(valueFormatter)) {
        return valueFormatter;
    }
    else if (_.isArray(valueFormatter)) {
        switch (columnType) {
            case "number": return getColumnNumberFormatter(...valueFormatter);
            case "date":
            case "dateTime": return getColumnDateTimeFormatter(...valueFormatter);
        }
    }
}

function mapCellClassName(cellClassName?: string | Function | Array<string>): (GridCellParams) => string {
    if (_.isFunction(cellClassName)) {
        return cellClassName;
    }
    if (_.isArray(cellClassName)) {
        return new Function(...cellClassName) as (GridCellParams) => string; // eslint-disable-line no-new-func
    }
    if (_.isString(cellClassName)) {
        return (_) => cellClassName;
    }

    return (_) => "";
}

function cellEditHighlighter(params: GridCellParams) {
    return clsx({ "is-dirty": _.find(params.row?.editedFields, f => f === params.field) });
}

function getFieldValueSetterForRow(gridApi: GridApiPro, row: any) {
    return (field: string, value: any) => {
        gridApi.setEditRowsModel({ [row.id]: { [field]: { value: value } } });
        gridApi.commitCellChange({ id: row.id, field: field }, new Event("selectionchange"));
        gridApi.setCellMode(row.id, field, "view");
    }
}

function mapColumn(column: Column): GridColDef {
    // Dynamically load the TemplateRenderer component in order to work around the cyclic dependency...
    const { valueFormatter, type, renderCell, cellTemplate, tooltipTemplate, cellClassName, cellEditTemplate, cellEditTemplateFieldName } = column;
    const RenderCellTemplate = (params: GridRenderCellParams) => params.cellMode === "view" ? <JsxRenderer data={{ ...params.row, row: params.row, gridApi: params.api, setFieldValue: getFieldValueSetterForRow(params.api, params.row), params }} template={cellTemplate} /> : null;
    const RenderCellEditTemplate = (params: GridRenderEditCellParams) =>
        cellEditTemplate ? <JsxRenderer data={{ ...params.row, row: params.row, gridApi: params.api, params }} template={cellEditTemplate ?? ""} /> :
            cellEditTemplateFieldName && params?.row?.[cellEditTemplateFieldName] ? <JsxRenderer data={{ ...params.row, row: params.row, gridApi: params.api, params }} template={params?.row?.[cellEditTemplateFieldName] ?? ""} /> :
                renderEditInputCell(params);
    const RenderTooltipTemplate = (params: GridRenderCellParams) => params.cellMode === "view" ? <JsxRenderer data={{ ...params.row, row: params.row, params }} template={tooltipTemplate} /> : null;
    const cellRenderer = renderCell ? renderCell : cellTemplate ? RenderCellTemplate : null;
    const tooltipRenderer = tooltipTemplate ? (params: GridRenderCellParams) => <CellWithTooltipRenderer tooltipRenderer={RenderTooltipTemplate} cellRenderer={cellRenderer} {...params} /> : null;
    const cellEditRenderer = (cellEditTemplate || cellEditTemplateFieldName) ? RenderCellEditTemplate : undefined;
    const classNameHandler = mapCellClassName(cellClassName);
    const combinedCellClassName = (params: GridCellParams) => clsx(cellEditHighlighter(params), classNameHandler(params));

    let result = {
        ...column,
        valueFormatter: mapValueFormatter(valueFormatter, type),
        renderCell: tooltipRenderer ? tooltipRenderer : cellRenderer,
        cellClassName: combinedCellClassName
    };

    // For some reason setting `renderEditCell` to null/undefined breaks editing for all fields, so we need to omit the field altogether instead...
    if (cellEditRenderer) result.renderEditCell = cellEditRenderer;

    return result;
}

function _Table(props: TableProps) {
    const { storyline, parameterMap, selectedRowParameter, parameterValues, updateParameterValue, navigateTo, rows, columns, additionalColumns, className, allRowsParameter, editedRowsParameter, onRowEdited, additionalFooterItems, ...other } = props;
    const [allColumns, setAllColumns] = React.useState(columns);
    const [currentRows, setCurrentRows] = React.useState(rows);
    const apiRef = useGridApiRef();

    React.useEffect(() => {
        const result = columns?.map(c => mapColumn(c));
        _.forEach(additionalColumns, additionalColumn => {
            result.splice(additionalColumn.index, 0, mapColumn(additionalColumn));
        });
        setAllColumns(result);

    }, [columns, additionalColumns]);

    React.useEffect(() => {
        if (currentRows?.length === rows?.length && _.isMatch(currentRows, rows)) return;
        setCurrentRows(rows);

        // Clear out the edited rows parameter when the grid data is refreshed...
        if (editedRowsParameter) {
            updateParameterValue(editedRowsParameter, []);
        }
    }, [rows]);

    const onRowClick = React.useCallback(
        (e: GridRowParams) => {
            const data = e.row;

            if (parameterMap?.length && navigateTo) {
                const parameters = _.map(parameterMap, map => `${encodeURIComponent(map.parameterName)}=${encodeURIComponent(data[map.fieldName])}`);

                window.open(`${navigateTo}?${parameters.join("&")}`, "_blank");
            }
            else if (selectedRowParameter) {
                updateParameterValue(selectedRowParameter, data);
            }

            return () => null;
        },
        [parameterMap, updateParameterValue]
    );

    const onCellEditCommit = React.useCallback(
        (params: GridCellEditCommitParams, event?: MuiBaseEvent) => {
            const { id, field, value } = params;
            const model = apiRef.current.getRowModels().get(id); // The current value of the row being edited...

            // If the value hasn't actually changed OR we haven't triggered this via a setFieldValue call, skip the below...
            if (_.isEqual(value, model[field]) && (event as Event)?.type !== "selectionchange") {
                return;
            }

            model.editedFields = _.uniq([...(model?.editedFields || []), field]);
            const editedRow = { ...model, [field]: value }; // The data that will be committed

            if (onRowEdited) {
                onRowEdited(editedRow, model);
            }

            if (editedRowsParameter) {
                const editedRows = parameterValues.get(editedRowsParameter) || [];
                // If this row was edited previously, exclude the old version from the changeset and only include the latest version...
                const editedRowsExcludingCurrentRow = editedRows.filter(r => r.id !== id);
                updateParameterValue(editedRowsParameter, [...editedRowsExcludingCurrentRow, editedRow]);
            }

            if (!allRowsParameter) return;
            setTimeout(() => {
                const gridRows = Array.from(apiRef.current.getRowModels().entries()).map(([_, item]) => item);
                updateParameterValue(allRowsParameter, gridRows);
            }, 50);
        },
        [apiRef, parameterValues, updateParameterValue, onRowEdited]
    );

    return (
        <DataGrid
            apiRef={apiRef}
            columnBuffer={Number.MAX_SAFE_INTEGER}
            {...other}
            className={clsx("table-component", navigateTo && "navigate-on-click", className)}
            components={{
                Footer: GridFooter
            }}
            componentsProps={{
                footer: { hideExportToExcel: props.hideExportToExcel, additionalFooterItems }
            }}
            columns={allColumns}
            rows={currentRows || []}
            onCellEditCommit={onCellEditCommit}
            onRowClick={props.onRowClick || onRowClick}
        />
    );
}

const Table = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any, goToID: goToID as any, applyParameterValueChanges: applyParameterValueChanges as any })(_Table);

(Table as DocumentedComponent).metadata = {
    description:
        `The Table component allows for the display of tabular data in a grid.  This is the preferred component for displaying tabular data.

        See [the base component's documentation](https://mui.com/components/data-grid/) for the full feature list.`,
    isSelfClosing: true,
    attributes: [
        { name: `rows`, type: `object`, description: "The data to display in the grid.  Each row entry must be an object containing a superset of the fields defined in `columns`." },
        {
            name: `columns`,
            type: `object`,
            description:
                `The columns to display in the grid.  See below for the definition of the \`Column\` type.
            
### \`Column\` Props:

| Name | Type | Description |
|------|------|-------------|
| \`field\` | \`string\` | The name of the field to display in this column.  Corresponds to a key in the row object. |
| \`headerName\` | \`string\` | The title of the column rendered in the column header cell. |
| \`description\` | \`string?\` | The tooltip to display when the user hovers over the column header. |
| \`type\` | \`'string' \\| 'number' \\| 'date' \\| 'dateTime' \\| 'boolean'\` | The type of data that is displayed in the column.  Determines how to display the data (alignment, formatting, controls) and which filters to expose in the data filter dialog. |
| \`flex\` | \`number?\` | If set, it indicates that a column has fluid width.  All columns with a \`flex\` value populated will share the available horizontal space according to this ratio.  Optional - defaults to \`null\`. |
| \`width\` | \`number?\` | The initial width of the column in \`px\`.  The \`flex\` property above takes precedence over this value, if populated.  Optional - defaults to \`100\`. |
| \`minWidth\` | \`number?\` | The minimum width of the column.  Applies to both calculated \`flex\` sizes and user interaction (column resizing).  Optional - defaults to \`50\`. |
| \`editable\` | \`boolean?\` | If \`true\`, the user can edit the cell data inline by double-clicking on the cell.  Optional - defaults to \`false\`. |
| \`cellTemplate\` | \`string?\` | The custom template to use for rendering the cell body.  All row fields are available for use in the template bindings, along with the standard frame data, parameter values, etc. |
| \`tooltipTemplate\` | \`string?\` | The custom template to use for rendering the cell tooltip.  All row fields are available for use in the template bindings, along with the standard frame data, parameter values, etc. |
| \`cellEditTemplate\` | \`string?\` | The custom template to use for rendering the contents of the cell in edit mode.  All row fields are available for use in the template bindings, along with the standard frame data, parameter values, etc.  Additionally, a field called \`params\` is exposed, which contains the binding context for the edit operation.  See [the component documentation](https://mui.com/x/api/data-grid/grid-cell-params/) for details on this object. |
| \`cellEditTemplateFieldName\` | \`string?\` | The name of the field which contains the custom template to use for rendering the contents of the cell in edit mode.  Equivalent to the \`cellEditTemplate\` field, but allows for flexibility in terms of varying the cell edit template per row. |`
        },
        {
            name: `additionalColumns`,
            type: `object`,
            description:
                `Additional columns to display in the grid.  This collection is appended to \`columns\` before rendering occurs.  This split allows for some columns to be dynamically defined in the data source and the rest to be defined in the template itself.
                
### \`Column\` Props:

| Name | Type | Description |
|------|------|-------------|
| \`field\` | \`string\` | The name of the field to display in this column.  Corresponds to a key in the row object. |
| \`headerName\` | \`string\` | The title of the column rendered in the column header cell. |
| \`description\` | \`string?\` | The tooltip to display when the user hovers over the column header. |
| \`type\` | \`'string' \\| 'number' \\| 'date' \\| 'dateTime' \\| 'boolean'\` | The type of data that is displayed in the column.  Determines how to display the data (alignment, formatting, controls) and which filters to expose in the data filter dialog. |
| \`flex\` | \`number?\` | If set, it indicates that a column has fluid width.  All columns with a \`flex\` value populated will share the available horizontal space according to this ratio.  Optional - defaults to \`null\`. |
| \`width\` | \`number?\` | The initial width of the column in \`px\`.  The \`flex\` property above takes precedence over this value, if populated.  Optional - defaults to \`100\`. |
| \`minWidth\` | \`number?\` | The minimum width of the column.  Applies to both calculated \`flex\` sizes and user interaction (column resizing).  Optional - defaults to \`50\`. |
| \`editable\` | \`boolean?\` | If \`true\`, the user can edit the cell data inline by double-clicking on the cell.  Optional - defaults to \`false\`. |
| \`cellTemplate\` | \`string?\` | The custom template to use for rendering the cell body.  All row fields are available for use in the template bindings, along with the standard frame data, parameter values, etc. |
| \`tooltipTemplate\` | \`string?\` | The custom template to use for rendering the cell tooltip.  All row fields are available for use in the template bindings, along with the standard frame data, parameter values, etc. |
| \`cellEditTemplate\` | \`string?\` | The custom template to use for rendering the contents of the cell in edit mode.  All row fields are available for use in the template bindings, along with the standard frame data, parameter values, etc.  Additionally, a field called \`params\` is exposed, which contains the binding context for the edit operation.  See [the component documentation](https://mui.com/x/api/data-grid/grid-cell-params/) for details on this object. |
| \`cellEditTemplateFieldName\` | \`string?\` | The name of the field which contains the custom template to use for rendering the contents of the cell in edit mode.  Equivalent to the \`cellEditTemplate\` field, but allows for flexibility in terms of varying the cell edit template per row. |
| \`valueFormatter\` | \`(params: GridValueFormatterParams) => string\` | The optional formatter function to use for cell contents.  \`cellTemplate\` takes precedence over this field. |`
        },
        { name: `parameterMap`, type: `object`, description: "The mapping of row fields to parameter values.  This is used when the `navigateTo` value is populated with a navigation URL and the user clicks on a row.  Query parameters are appended to the URL in the format: `<value>=rowValue[<key>]`." },
        { name: `navigateTo`, type: `string`, description: "The URL to navigate to when the user clicks on a row.  Query parameters are appended to this URL using the `parameterMap` provided.  If not specified, no navigation occurs on row click." },
        { name: `selectedRowParameter`, type: `string`, description: "The name of the parameter to bind the currently selected row to.  The entire row is bound to this parameter and can be decomposed where the parameter is used." },
        { name: `allRowsParameter`, type: `string`, description: "The name of the parameter to bind the current grid rows to.  This will include both edited (latest version) and unedited rows." },
        { name: `editedRowsParameter`, type: `string`, description: "The name of the parameter to bind the edited grid rows to.  This parameter will be populated with the latest version of all rows that were edited by the user." },
        { name: `onRowClick`, type: `function`, template: `onRowClick={(e) => {const { row } = e; $1}}`, description: "The callback function to execute when a grid row is clicked on.  The sole input parameter contains a field called `row`, which can be used to access the row data." },
        { name: `onRowEdited`, type: `function`, template: `onRowEdited={(row, originalRow) => $1}}`, description: "The callback function to execute after a grid row has been edited.  The first input parameter is the row data after the update has occurred.  The second input parameter is the row data just before the update has been applied." },
        { name: `hideFooter`, type: `boolean`, description: "Set this property to `true` in order to hide the table footer.  Defaults to `false`." },
        { name: `hideExportToExcel`, type: `boolean`, description: "Set this property to `true` in order to hide the \"Export to Excel\" button in the table footer.  Defaults to `false`." },
        { name: `density`, type: `string`, options: ["comfortable", "compact", "standard"], description: "Determines the heading and row height for the table.  Defaults to `\"standard\"``." },
        { name: `additionalFooterItems`, type: `object`, description: "Additional items to display in the table footer.  If provided, these items are displayed alonside the \"Export to Excel\" button." },
    ]
};

export default Table;