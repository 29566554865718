export interface Storyline {
    name: string;
    dataSources: { [name: string]: DataSource };
    templates: { [name: string]: TemplateDefinition };
    chapters: Chapter[];
    canHideChapter?: boolean;
    canHidePage?: boolean;
}

export interface Chapter {
    name: string;
    dataSources: { [name: string]: DataSource };
    pages: Page[];
}

export interface Page {
    name: string;
    dataSources: { [name: string]: DataSource };
    template: string;
    paragraphs: Paragraph[];
}

export interface Paragraph {
    name: string;
    dataSources: { [name: string]: Slot };
    slots: Slot[];
}

export interface Slot {
    name: string;
    dataSource?: string;
}

export interface Template {
    name: string;
    root: TemplateDefinition;
}

export interface TemplateDefinition {
    jsx: string;
    css: string;
}

export interface DataSource {
    name: string;
    parameters: DataSourceParameter[];
    defaultData?: any;
    autoRefresh: boolean;
    apiEndpoint?: string[];
}

export interface DataSourceParameter {
    name: string;
    type: string;
    isRequired: boolean;
    defaultValue?: any;
}

export interface StorylineState {
    id: string;
    name: string;
    storyline: Storyline;
    datasources: Map<string, DataSource>;
    templates: Record<string, TemplateDefinition>;
    datasourceValues: Map<string, object>;
    datasourcesInFlight: Set<string>;
    parameterValues: Map<string, any>;
    canvasState: Object;

    canvases: Canvas[][][];

    chapterIndex: number;
    pageIndex: number;
    paragraphIndex: number;

    currentParagraph: Canvas;
    loading: boolean;

    canNavigateBackwards: boolean;
    canNavigateForward: boolean;
}

export interface Canvas {
    name: string;
    chapterName: string;
    pageName: string;
    title: string;
    data: object;
    template: TemplateDefinition;
    templateName: string;
}

export interface NavigationTarget {
    chapterIndex: number,
    pageIndex: number,
    paragraphIndex: number,
    previousParagraphIndex?: number
}

export const LOAD_STORYLINE = "LOAD_STORYLINE";
export const SHOW_STORYLINE = "SHOW_STORYLINE";
export const PARAMETER_VALUE_UPDATED = "PARAMETER_VALUE_UPDATED";
export const DATASOURCE_UPDATED = "DATASOURCE_UPDATED";
export const GO_TO_X_Y_Z = "GO_TO_X_Y_Z";
export const GO_TO_ID = "GO_TO_ID";
export const UPDATE_CURRENT_SLIDE_TEMPLATE = "UPDATE_CURRENT_SLIDE_TEMPLATE";
export const UPDATE_SLIDE_TEMPLATES = "UPDATE_SLIDE_TEMPLATES";
export const UPDATE_CURRENT_FRAME_DATA = "UPDATE_CURRENT_FRAME_DATA";
export const UPDATE_CANVAS_STATE = "UPDATE_CANVAS_STATE";
export const ADD_GLOBAL_DATASOURCE = "ADD_GLOBAL_DATASOURCE";
export const UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE = "UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE";

export interface LoadStorylineAction {
    type: typeof LOAD_STORYLINE,
    id: string
}

export interface ShowStorylineAction {
    id: string,
    type: typeof SHOW_STORYLINE,
    storyline: Storyline,
    dataSources: Map<string, DataSource>,
    dataSourceValues: Map<string, any>,
    parameterValues: Map<string, any>
}

export interface ParameterValueUpdatedAction {
    type: typeof PARAMETER_VALUE_UPDATED,
    parameterName: string,
    newValue: any
}

export interface DatasourceUpdatedAction {
    type: typeof DATASOURCE_UPDATED,
    datasourceName: string,
    data: object
}

export interface GoToXYZAction {
    type: typeof GO_TO_X_Y_Z,
    chapterIndex: number,
    pageIndex: number,
    paragraphIndex: number,
    previousParagaphIndex?: number
}

export interface GoToIDAction {
    type: typeof GO_TO_ID,
    id: string
}

export interface UpdateCurrentSlideTemplate {
    type: typeof UPDATE_CURRENT_SLIDE_TEMPLATE,
    newTemplate: string,
    newCustomCss: string
}

export interface UpdateSlideTemplates {
    type: typeof UPDATE_SLIDE_TEMPLATES,
    newTemplates: Record<string, TemplateDefinition>
}

export interface UpdateCurrentFrameData {
    type: typeof UPDATE_CURRENT_FRAME_DATA,
    newData: object
}

export interface UpdateCanvasState {
    type: typeof UPDATE_CANVAS_STATE,
    newState: any
}

export interface AddGlobalDatasource {
    type: typeof ADD_GLOBAL_DATASOURCE,
    datasource: DataSource
}

export interface UpdateInFlightRequestsForDatasource {
    type: typeof UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
    datasource: DataSource,
    inFlightRequestCount: number
}

export type StorylineActionTypes = LoadStorylineAction | ShowStorylineAction | ParameterValueUpdatedAction | DatasourceUpdatedAction | GoToXYZAction | GoToIDAction | UpdateCurrentSlideTemplate | UpdateSlideTemplates |UpdateCurrentFrameData | UpdateCanvasState | AddGlobalDatasource | UpdateInFlightRequestsForDatasource;