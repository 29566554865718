import { Popper as MuiPopper, PopperProps as MuiPopperProps } from "@mui/material";
import { styled } from "@mui/material";
import React from "react";

const StyledPopper = styled(MuiPopper)(({ theme }) => ({
    zIndex: 1,
    border: "1px solid rgba(97, 97, 97, 0.92)",
    borderRadius: "4px",
    '&[data-popper-placement*="bottom"]': {
        marginTop: "8px !important"
    },
    '&[data-popper-placement*="bottom"] .MuiPopper-arrow': {
        top: 0,
        marginTop: '-1em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent rgba(97, 97, 97, 0.92) transparent`,
        },
    },
    '&[data-popper-placement*="top"]': {
        marginBottom: "8px !important"
    },
    '&[data-popper-placement*="top"] .MuiPopper-arrow': {
        bottom: 0,
        marginBottom: '-1em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `rgba(97, 97, 97, 0.92) transparent transparent transparent`,
        },
    },
    '&[data-popper-placement*="right"]': {
        marginLeft: "8px !important"
    },
    '&[data-popper-placement*="right"] .MuiPopper-arrow': {
        left: 0,
        marginLeft: '-1em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent rgba(97, 97, 97, 0.92) transparent transparent`,
        },
    },
    '&[data-popper-placement*="left"]': {
        marginRight: "8px !important"
    },
    '&[data-popper-placement*="left"] .MuiPopper-arrow': {
        right: 0,
        marginRight: '-1em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent rgba(97, 97, 97, 0.92)`,
        },
    },
}));

const Arrow = styled('div')({
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    }
});

type PopperProps = MuiPopperProps & {
    arrow?: boolean;
};

function Popper(props: PopperProps) {
    const { arrow, children, ...rest } = props;
    const [arrowRef, setArrowRef] = React.useState(null);

    const modifiers = arrow ?
        [{
            name: 'arrow',
            enabled: true,
            options: {
                element: arrowRef,
            },
        }] : [];

    return (
        <StyledPopper className="MuiPopper-root" {...rest} modifiers={modifiers}>
            {children}
            {arrow && <Arrow ref={setArrowRef} className="MuiPopper-arrow" />}
        </StyledPopper>
    );
}

export { Popper };