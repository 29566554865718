import React from 'react';
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { Checkbox as BaseCheckbox } from "../../../shared/components";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
}

export function _Checkbox(props: Props) {
    const { storyline, updateParameterValue, name, ...rest } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || false);

    const handleChange = (event: any) => {
        updateParameterValue(name, event.target.checked);
        setValue(event.target.checked);
    };

    React.useEffect(() => {

        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }

    }, [storyline.parameterValues]);

    return (
        <BaseCheckbox
            {...rest}
            checked={value}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }} />
    );
}

const Checkbox = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(_Checkbox);

(Checkbox as DocumentedComponent).metadata = {
    description: "The Checkbox component allows the user to select a `true` or `false` value for a storyline variable.",
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the variable that the selected value(s) will be read from and persisted to." },
        { name: `size`, type: `string`, options: ["small", "medium", "large"], description: `The (optional) size of the Checkbox.  Defaults to \`"medium"\`.` }
    ]
};

export default Checkbox;