import * as React from "react";
import { Option } from "../../Autocomplete";
import { Button } from "../../Button";
import { object, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import _ from "lodash";
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitleWithCloseButton, TextField } from "../../../../shared/components";

interface CreateDialogProps {
    entityName: string;
    allEntities: Option[];
    availableEntities: Option[];
    acceptCallback: (args: Object) => void;
    closeCallback: () => void;
    enableLinkedAndWipEntities?: boolean;
}

const typeOptions = [
    { value: "Value", label: "Value" },
    { value: "Linked", label: "Linked" },
    { value: "Work in Progress", label: "Work in Progress" }
];

type FormModel = {
    name: string;
    type?: Option;
    entity?: Option;
};

function CreateDialog(props: CreateDialogProps) {
    const { entityName, allEntities, availableEntities, acceptCallback, closeCallback, enableLinkedAndWipEntities } = props;
    const schema = enableLinkedAndWipEntities ?
        object().shape({
            name: string()
                .trim()
                .required(`Please supply a name for the new ${entityName}.`),
            type: object()
                .nullable()
                .required(`Please select a type for the new ${entityName}.`),
            entity: object()
                .nullable()
                .when('type', (type, schema) => {
                    return type?.value === "Linked" || type?.value === "Work in Progress" ? schema.required(`Please select a ${entityName} to use as the source.`) : schema;
                })
        }) :
        object().shape({
            name: string()
                .trim()
                .required(`Please supply a name for the new ${entityName}.`)
        });
    const { register, handleSubmit, errors, setError, control, watch } = useForm({ resolver: yupResolver(schema), defaultValues: { name: null, type: typeOptions[0] as any, entity: null } });

    const typeValue = watch("type")?.value;
    const showEntityField = typeValue === "Linked" || typeValue === "Work in Progress";

    const save = React.useCallback((formModel: FormModel) => {
        if (_.find(allEntities, e => e.label === formModel.name)) {
            setError("name", { message: `'${formModel.name}' is an existing ${entityName} Name!  Please choose a different name.`, type: "validation_error" });
            return;
        }

        acceptCallback({
            name: formModel.name,
            type: formModel.type?.value,
            entity_id: formModel.entity?.value,
            entity_label: formModel.entity?.label
        });
    }, [allEntities, acceptCallback]);

    return (
        <Dialog
            open={true}
            onClose={(_event, reason) => {
                if (reason !== 'backdropClick') {
                    closeCallback();
                }
            }}
            disablePortal={true}
            aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeCallback} id="form-dialog-title">
                Create New {entityName}
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)} autoComplete="off">
                <DialogContent>
                    <div className="vertical-form-field">
                        Please supply a name for the new {entityName}:
                    </div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                    {
                        enableLinkedAndWipEntities &&
                        <>
                            <div className="vertical-form-field">
                                {entityName} type:
                            </div>
                            <Controller
                                control={control}
                                name="type"
                                render={({
                                    onChange,
                                    value
                                }) => (
                                    <Autocomplete
                                        onChange={(_event, option, _reason) => onChange((option as Option))}
                                        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
                                        getOptionLabel={(option: any) => option?.label || ""}
                                        value={value}
                                        size="small"
                                        fullWidth
                                        options={typeOptions}
                                        error={!!errors.type}
                                        helperText={errors.type?.message}
                                    />
                                )}
                            />

                            {
                                showEntityField &&
                                <>
                                    <div className="vertical-form-field">
                                        Source {entityName}:
                                    </div>
                                    <Controller
                                        control={control}
                                        name="entity"
                                        render={({
                                            onChange,
                                            value
                                        }) => (
                                            <Autocomplete
                                                onChange={(_event, option, _reason) => onChange((option as Option))}
                                                isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
                                                getOptionLabel={(option: any) => option?.label || ""}
                                                value={value}
                                                size="small"
                                                fullWidth
                                                options={availableEntities}
                                                error={!!errors.entity}
                                                helperText={errors.entity?.message}
                                            />
                                        )}
                                    />
                                </>
                            }
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="text" color="warning">
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export { CreateDialog };