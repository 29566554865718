import React from "react";
import "./KpiIndicator.scss";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import clsx from "clsx";

type KpiDataPoint = {
    title: string;
    value: string;
    class: string;
}

type IKpiIndicator = KpiDataPoint & {
    deltas: KpiDataPoint[];
}

type KpiIndicatorProps = {
    input: IKpiIndicator;
    showLabelInHeader?: boolean;
    className?: string;
};

function KpiIndicator(props: KpiIndicatorProps) {
    const { input, showLabelInHeader, className } = props;
    if (!input)
        return null;

    return (
        <div className={clsx("kpi-indicator-container", className)}>
            <div className="kpi-indicator">

                <div className={clsx(showLabelInHeader && "label-in-header", "main-block")}>
                    <div className="value">
                        {input.value}
                    </div>
                    <div className="label">
                        {input.title}
                    </div>
                </div>

                {
                    input?.deltas?.[0] &&
                    <div className="main-delta">
                        <div className={`delta ${input.deltas[0].class}`}>
                            <div className="value">
                                {input.deltas[0].value}
                            </div>
                            <div className="label">
                                {input.deltas[0].title}
                            </div>
                        </div>
                    </div>
                }

                {
                    input?.deltas?.length > 1 &&
                    <div className="deltas">
                        {_.map(input?.deltas?.splice?.(1), d =>
                            <div className={`delta ${d.class}`}>
                                <div className="value">
                                    {d.value}
                                </div>
                                <div className="label">
                                    {d.title}
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    );
}

(KpiIndicator as DocumentedComponent).metadata = {
    description: "The KpiIndicator component displays the current value for a KPI alongside historical values.",
    isSelfClosing: true,
    attributes: [
        {
            name: `input`, type: `object`, description: `The input data for the KPI Indicator.  See below for the structure of \`KpiIndicatorInput\`.

### \`KpiIndicatorInput\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`value\` | \`string\` | The current value for the KPI. |
| \`title\` | \`string\` | The name/label for the KPI. |
| \`deltas\` | \`Delta[]\` | The delta values for the KPI.  Used to display the trends for the KPI over time.  See below for the structure of \`Delta\`. |

### \`Delta\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`value\` | \`string\` | The historical value for the KPI. |
| \`title\` | \`string\` | The time period for this historical KPI value. |
| \`class\` | \`string\` | The optional display class for this delta value.  Determines the text color used.  Predefined classes are \`positive\` and \`negative\`. |`
        },
        { name: "showLabelInHeader", type: "boolean", description: "If `true`, the KPI label is displayed above the value, in a shaded area.  Optional - defaults to `false`." },
    ]
};

export default KpiIndicator;