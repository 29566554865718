import * as React from "react";
import { NoData, NoSearchResults, Checkbox } from "./";
import { DataGridPro as MaterialDataGrid, DataGridProProps as DataGridProps, GridOverlay, GridActionsCellItem, GridRenderCellParams, GridValueFormatterParams, GridEditInputCell } from "@mui/x-data-grid-pro";

const NoDataOverlay = (props: any) => <GridOverlay {...props}><NoData title="No Data" description="No Rows To Show Here" /></GridOverlay>;
const NoSearchResultsOverlay = (props: any) => <GridOverlay {...props}><NoSearchResults title="Not Found" description="No Rows Matching Search Criteria" /></GridOverlay>;
const CheckboxControl = React.forwardRef((props: any, ref) => <Checkbox ref={ref} {...props} color="default" />);

const DataGrid: React.FunctionComponent<DataGridProps> = (props: DataGridProps) => {
    const { components, ...rest } = props;
    const allComponents = {
        NoRowsOverlay: NoDataOverlay,
        NoResultsOverlay: NoSearchResultsOverlay,
        BaseCheckbox: CheckboxControl,
        ...components
    };

    return <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
            <MaterialDataGrid
                components={allComponents}
                {...rest}
            />
        </div>
    </div>
};

export { DataGrid, GridActionsCellItem, GridEditInputCell };
export type { DataGridProps, GridRenderCellParams, GridValueFormatterParams };