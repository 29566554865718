import { useLocation } from "react-router-dom";
import { convertStringToValue, getHashCode } from "../utilities";
import * as _ from "lodash";

// A custom hook that builds on useLocation to parse
// the query string for you.
export default function useQueryParams() {
    let searchParams = new URLSearchParams(useLocation().search);

    // If the "_parameters" parameter is present, then the query string has been obfuscated.  We need to decode the base64-encoded value and parse that...
    if (searchParams.has("_parameters")) {
        try {
            const parameterValue = searchParams.get("_parameters");
            const hashValue = searchParams.get("_hash");

            searchParams = new URLSearchParams(atob(searchParams.get("_parameters")));

            // If the hash value doesn't match the hash of the parameter value, then the query string has been tampered with.  Add an error message to the query string...
            if (getHashCode(parameterValue).toString() !== hashValue) {
                searchParams.append("_hash_error", "Parameter values hash mismatch.");
            }
        }
        catch {
            // If the base64-decoding fails, then the query string has been tampered with.  Add an error message to the query string...
            searchParams.append("_parameters_error", "Parameter values decoding error.");
        }
    }  

    // Arrays are passed through as multiple entries with the same key.  We need to group these values and reconstruct the arrays, where applicable...
    const entriesMap = _
        .chain(Array.from(searchParams.entries()))
        .reduce((map, [key, value]) => {
            let newValue = null;

            const existingValue = map.get(key);
            if (existingValue) {
                newValue = _.isArray(existingValue) ?
                    [...existingValue, convertStringToValue(value)] :
                    [existingValue, convertStringToValue(value)];
            } else {
                newValue = convertStringToValue(value);
            }

            map.set(key, newValue);
            return map;
        }, new Map<string, any>())
        .value();

    return entriesMap;
}