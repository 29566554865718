import { JsxRenderer } from "../../ContentRenderer/JsxRenderer";

export type NodeInputProps = {
    data?: {
        label?: string;
        template?: string;
        content?: JSX.Element;
        getContent?: (data: any) => JSX.Element;
        fillPercentage?: number;
    }
};

export function NodeContent(props: NodeInputProps) {
    const { data, ...rest } = props;

    return (
        data?.getContent?.(data) ??
        (data?.template ? <JsxRenderer template={data?.template} data={{ ...rest, ...data }} /> : null) ??
        data?.content ??
        data?.label
    ) as JSX.Element;
};

export function VerticalFillDefinition({ fillPercentage, id }) {
    return (
        <linearGradient id={id} x1="0" x2="0" y1="1.05" y2="-0.05">
            <stop offset="0%" stop-color="var(--fill-color)" />
            <stop offset={`${fillPercentage}%`} stop-color="var(--fill-color)" />
            <stop offset={`${fillPercentage}%`} stop-color="white" />
            <stop offset="100%" stop-color="white" />
        </linearGradient>
    );
}