import { SwitchProps as BaseSwitchProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../store";
import { Switch as BaseSwitch } from "../../../shared/components";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

type SwitchProps = BaseSwitchProps & {
    name?: string
};

function Switch(props: SwitchProps) {
    const { name, checked: _checked = false, onChange, ...rest } = props;
    const parameterValues = useSelector((s: RootState) => s.storyline.parameterValues);
    const dispatch = useThunkDispatch();
    const [checked, setChecked] = React.useState((name && parameterValues?.get(name)) ?? _checked)

    React.useEffect(() => {
        if (!name || !parameterValues) { return; }

        const valueFromParameters = parameterValues.get(name);
        if (!_.isEqual(valueFromParameters, checked)) {
            setChecked(valueFromParameters);
        }
    }, [parameterValues, name]);

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
        setChecked(newValue);

        name && dispatch(updateParameterValue(name, newValue));

        onChange && onChange(e, newValue);
    }, [onChange]);

    return (
        <BaseSwitch checked={checked ?? false} onChange={handleChange} {...rest} />
    );
}

(Switch as DocumentedComponent).metadata = {
    description: "The Switch component is functionally equivalent to a `Checkbox`, with only the UI differing between the two.",
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the variable that the checked status will be read from and persisted to." }
    ]
};

export { Switch };