import * as React from "react";
import { Slider as MaterialSlider, SliderProps, SliderThumb } from "@mui/material";

interface StyledThumbComponentProps extends React.HTMLAttributes<unknown> { }

const ThumbComponent = (props: StyledThumbComponentProps) => {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="MuiSlider-thumb-middle" />
        </SliderThumb>
    );
}

const Slider: React.FunctionComponent<SliderProps> = (props: SliderProps) => <MaterialSlider components={{ Thumb: ThumbComponent }} valueLabelDisplay="on" {...props} />

export default Slider;