import "./Home.scss";
import logo from "../../assets/logo.png";
import { NavBar } from "../../shared/components";
import { ErrorContainer } from "../../shared/pages/ErrorContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function Home(props: any) {
    const pageError = useSelector((state: RootState) => state.app.errors.page);

    return (
        <>
            <NavBar title="Welcome to Qerent" breadcrumbStack={[{ title: "Home" }]} />

            <ErrorContainer error={pageError}>
                <div className="home-content">
                    <img id="home-logo" src={logo} alt="The Qerent logo" className="logo" />
                    <br />
                    <br />
                    <h2 id="home-header-1">Welcome to Qerent!</h2>
                    <br />
                    <h3 id="home-header-2">Select a Storyline/Canvas from the left menu to continue.</h3>
                </div>
            </ErrorContainer>
        </>
    );
}

export default Home;