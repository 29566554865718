import React from "react";
import VisTimeline from 'react-vis-timeline';
import "./Timeline.scss";
import moment from "moment";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import clsx from "clsx";

export interface Group {
    id: string;
    name: string;
}

export interface Activity {
    id: string;
    name: string;
    color: string;
}

export interface Event {
    id: string;
    groupId: string;
    activityId: string;
    start: Date;
    end: Date;
    message: string;
}

export interface TimelineInput {
    groups?: Group[];
    activities: Activity[];
    events: Event[];
}

export interface TimelineProps {
    className?: string;
    input: TimelineInput;
    onSelectionChanged?: (selectedItems: any[]) => void;
    enableVerticalScroll?: boolean;
    [key: string]: any;
}

const tooltipTemplate = (item, element, data) => {
    return `
<div class="timeline-tooltip">
    <div class="title">${item.title}</div>
    <div class="dates">
        <div class="date"><div class="title">From:</div> <div>${moment(item.start).format("DD MMM YYYY @ HH:mm")}</div></div>
        <div class="date"><div class="title">To:</div> <div>${moment(item.end).format("DD MMM YYYY @ HH:mm")}</div></div>
    </div>
    <div class="message">${item.message || ""}</div>
</div>`;
}

function mapTimelineGroups(groups: Group[]) {
    return groups.map(g => ({ id: g.id, content: g.name }));
}

function mapTimelineItems(events: Event[], activities: Activity[]) {
    const mapEventToItem = (event: Event) => {
        const activity = _.find(activities, a => a.id === event.activityId);

        return {
            ...event,
            group: event.groupId,
            style: `background-color: ${activity?.color}`,
            title: activity?.name,
            content: ""
        };
    };

    return events.map(mapEventToItem);
}

function _Timeline(props: TimelineProps) {
    const { className, input, enableVerticalScroll, onSelectionChanged } = props;
    const { activities, events, groups } = input;

    // The ReactVisTimeline component does not allow for updating an event handler, so a traditional closure approach doesn't work. 
    // The workaround is to use a singleton reference to the selected ids - which we can use inside the click handler...
    const selectedIdsRef = React.useRef([]);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const timelineRef = React.useRef(null);

    React.useEffect(() => {
        if (!timelineRef?.current) return;

        timelineRef.current.timeline.setData({
            groups: mapTimelineGroups(groups),
            items: []
        })

        setTimeout(() => timelineRef.current.timeline.setData({
            groups: mapTimelineGroups(groups),
            items: mapTimelineItems(events, activities)
        }));
    }, [activities, events, groups]);

    const options: any = {
        margin: 0,
        height: "100%",
        minHeight: "100%",
        stack: false,
        type: 'range',
        selectable: false, // We handle the selection manually via a click handler in order to allow for unselecting an item by clicking on it...
        tooltip: {
            template: tooltipTemplate
        },
        min: _.minBy(events, e => e.start).start,
        max: _.maxBy(events, e => e.end).end,
        showCurrentTime: false,
        autoResize: true,
        verticalScroll: enableVerticalScroll
    };


    const clickHandler = (e: { item: string, event: MouseEvent }) => {
        if (!onSelectionChanged) return;

        // Multi select
        if (e.event.ctrlKey || e.event.shiftKey) {
            if (_.find(selectedIdsRef.current, i => i === e.item)) {
                _.remove(selectedIdsRef.current, i => i === e.item)
            }
            else {
                selectedIdsRef.current.push(e.item)
            }
        }
        // Single select...
        else {
            if (_.find(selectedIdsRef.current, i => i === e.item)) {
                _.remove(selectedIdsRef.current, i => true);
            }
            else {
                _.remove(selectedIdsRef.current, i => true);
                selectedIdsRef.current.push(e.item)
            }
        }

        setSelectedIds([...selectedIdsRef.current]);

        // Invoke callback...
        onSelectionChanged(_.map(selectedIdsRef.current, id => timelineRef.current.timeline.itemSet.items[id].data));
    };

    return (
        <div className={clsx("timeline-container", className)}>
            <div className={clsx("timeline", !enableVerticalScroll && "fill-height")}>
                <VisTimeline
                    ref={timelineRef}
                    options={options}
                    clickHandler={clickHandler}
                    selection={selectedIds}
                />
            </div>
            <div className="legend">
                {activities.map(a => (
                    <>
                        <div className="color-block" style={{ backgroundColor: a.color }} />
                        <div className="title">{a.name}</div>
                    </>
                ))}
            </div>
        </div>
    );
}

const Timeline = React.memo(_Timeline);

(Timeline as DocumentedComponent).metadata = {
    description: "The Timeline component can be used to display a linear series of events as coloured blocks.  This provides an overview of the activities that a resource participated in during a time range.  The control supports an arbitrary amount of groups (trucks or other resources) and activities.  Each activity is colour-coded for display purposes. A legend is shown next to the timeline, explaining the activity associated with each colour.",
    isSelfClosing: true,
    attributes: [
        {
            name: `input`,
            type: `object`,
            description:
                `The input data for the component.  See below for the definition of \`Input\`.

                ### \`Input\` Fields:

                | Name | Type | Description |
                |------|------|-------------|
                | \`groups\` | \`Group[]\` | Each group represents a resource and becomes a row in the chart.  See below for the definition of \`Group\`. |
                | \`activities\` | \`Activity[]\` | Specifies the full list of activities that a resource can participate in.  A row is displayed in the legend for each activity.  See below for the definition of \`Activity\`. |
                | \`events\` | \`Event[]\` |  Each event consists of a start date, end date, activity id and group id. Each event is rendered as a colour-coded duration block in the relevant resource row.  See below for the definition of \`Event\`. |
                
                ### \`Group\` Fields:
                
                | Name | Type | Description |
                |------|------|-------------|
                | \`id\` | \`string\` | The unique id to identify the group.  Events are assigned to a group via this id. |
                | \`name\` | \`string\` | The name of the group.  Displayed as the row header in the chart. |
                
                ### \`Activity\` Fields:
                
                | Name | Type | Description |
                |------|------|-------------|
                | \`id\` | \`string\` | The unique id to identify the activity.  Events are assigned to an activity via this id. |
                | \`name\` | \`string\` | The name of the action/activity.  Displayed in the legend alongside the colour block to help users identify the activities. |
                | \`color\` | \`string\` | The unique color to use for this activity.  Used as fill color for events that are participating in this activity.  Also displayed in the legend alongside the activity name. |
                
                ### \`Event\` Fields:
                
                | Name | Type | Description |
                |------|------|-------------|
                | \`id\` | \`string\` | An optional unique identifier for this event.  If a natural identifier does not exist, the control will generate one. |
                | \`groupId\` | \`string\` | The id of the group that this event belongs to.  Used to determine which row this event is rendered in. |
                | \`activityId\` | \`string\` | The id of the activity that is occurring.  Used to determine the fill color for the event block. |
                | \`start\` | \`date\` | The start date (and time) for this event. |
                | \`end\` | \`date\` | The end date (and time) for this event. |
                | \`message\` | \`string\` | An optional message that will be displayed in the tooltip for this event.  Primarily used to provide a reason for a specific delay or deviation from the standard schedule. |`
        },
        {
            name: `onSelectionChanged`,
            type: `function`,
            template: "onSelectionChanged={(selectedItems) => {\n\r$1\n}}",
            description: "The (optional) callback function to invoke when a user selects item(s)."
        },
        {
            name: `enableVerticalScroll`,
            type: `boolean`,
            description: "If `true`, the rows will have a fixed height and a scrollbar will be displayed when the content overflows the available vertical space."
        }
    ]
};

export default Timeline;