import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider as BaseThemeProvider } from "@mui/material";
import * as colors from "../colors";

const cacheInstance = createCache({ key: 'css' });
const theme = createTheme({
    palette: {
        background: {
            default: colors.grey_7
        },
        primary: {
            main: colors.blue_1,
            contrastText: colors.white
        },
        secondary: {
            main: colors.blue_3,
            contrastText: colors.white
        },
        info: {
            main: colors.dark_blue_3,
            contrastText: colors.white
        },
        success: {
            main: colors.green_1,
            contrastText: colors.white
        },
        warning: {
            main: colors.orange_1,
            contrastText: colors.white
        },
        error: {
            main: colors.red_1,
            contrastText: colors.white
        },
        tonalOffset: 0.1
    },
    typography: {
        fontFamily: [
            "Helvetica Neue",
            "Arial"
        ].join(",")
    }
});

export const AppThemeProvider = ({ children }: { children: any }) =>
    <CacheProvider value={cacheInstance}>
        <BaseThemeProvider theme={theme}>
            {children}
        </BaseThemeProvider>
    </CacheProvider>;