import React from "react";
import "./Markdown.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import _ from "lodash";

const remarkPlugins = [remarkGfm];
const rehypePlugins = [rehypeRaw];
const components = {
    code: ({ node, className, children, ...props }) => {
        const match = /language-(\w+)/.exec(className || '')
        return match ? (
            <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={style}
                language={match[1]}
                PreTag="div"
                {...props}
            />
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        )
    }
};

function Markdown(props: any) {
    const { key, source, children, ...rest } = props;

    // Workaround for the fact that JSX Parser wraps whitespace-enclosed elements in a fragment by default...
    const sanitizedChildren = _.isArray(children) ?
        children.find(c => _.isString(c)) :
        children?.type?.toString()?.indexOf("fragment") > -1 ? children?.children?.[0] :
            children;

    return (
        <div className="markdown">
            <ReactMarkdown
                key={key}
                remarkPlugins={remarkPlugins}
                rehypePlugins={rehypePlugins}
                {...rest}
                components={components}
                children={source ?? sanitizedChildren}
            />
        </div>
    );
}

(Markdown as DocumentedComponent).metadata = {
    description: "The Markdown component renders the provided markdown as HTML.  See the [component's repository](https://github.com/rexxars/react-markdown) for the full documentation.",
    isSelfClosing: false,
    attributes: []
};

export default Markdown;