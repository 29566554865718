import React from 'react';
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { Tabs as BaseTabs, Tab } from "../../../shared/components";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

interface TabsProps {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    defaultValue?: string;
    children: any;
}

function isChildOfTab(element: HTMLElement) {
    if (!element) return false;
    if (element.classList.contains("MuiTab-root")) return true;

    return isChildOfTab(element.parentElement);
}

function _Tabs(props: TabsProps) {

    const { storyline, updateParameterValue, name, defaultValue, children, ...other } = props;

    const parameterName = `tabs-${name}`;

    const [value, setValue] = React.useState(defaultValue);

    React.useEffect(() => {
        if (!storyline.parameterValues.has(parameterName)) {
            setValue(defaultValue);
            updateParameterValue(parameterName, defaultValue);
        }
    }, [defaultValue]);

    const handleChange = (event: any, newValue: string) => {
        // Only treat Tab clicks as change events.
        // Allows us to embed interactive components inside Tabs without affecting the selected value...
        if (!isChildOfTab(event.target)) return;

        setValue(newValue);
        updateParameterValue(parameterName, newValue);
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(parameterName)) {
            const newValue = storyline.parameterValues.get(parameterName);

            if (newValue !== value) {
                setValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    // Workaround for the fact that JSX Parser wraps whitespace-enclosed elements in a fragment by default...
    const sanitizedChildren = _.isArray(children) ? 
        children.map(c => c?.type?.toString()?.indexOf("fragment") > -1 ? c?.children?.[0] : c) : 
        children?.type?.toString()?.indexOf("fragment") > -1 ? children?.children?.[0] : children;

    return (
        <BaseTabs
            value={value}
            onChange={handleChange}
            {...other}
        >
            {sanitizedChildren}
        </BaseTabs>
    );
}

const Tabs = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(_Tabs);

(Tabs as DocumentedComponent).metadata = {
    description: "The Tabs component can be used to display multiple panels of content in a space-efficient manner.  Only one panel can be viewed at a time, with the ability to toggle between the different panels using the tabs at the top.",
    isSelfClosing: false,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the tab control.  Must be unique across all Tabs that exist on the canvas." },
        { name: `defaultValue`, type: `string`, description: "The value of the tab that should be shown by default." },
    ]
};

(Tab as DocumentedComponent).metadata = {
    description: 
    `The Tab component is used to render a tab within a Tabs component.  Must be a direct child of a Tabs component.
    
Each \`Tab\` must have a matching \`TabPanel\` defined inside the canvas.  When the \`Tab\` is selected, the contents of the relevant \`TabPanel\` is rendered.`,
    isSelfClosing: true,
    attributes: [
        { name: `value`, type: `string`, description: "A unique value to identify this tab.  Must be unique across all tabs within the parent." },
        { name: `label`, type: `string`, description: "The text to display on the tab." },
    ]
};

export { Tabs, Tab };