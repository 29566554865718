import "./CellRenderers.scss";
import { Icon, Tooltip, IconButton, Avatar } from "../../../shared/components";

interface IconButtonCellProps {
    icon: string;
    tooltip: string;
    className: string;
    onClick: (rowData: any) => void;
}

export function getIconButtonCellRenderer(props: IconButtonCellProps) {
    const { icon, tooltip, className, onClick } = props;

    return (rowData: any) => (
        <div className="button-column" style={{ position: "relative" }}>
            <Tooltip title={tooltip}>
                <IconButton onClick={(e) => { onClick(rowData); e.stopPropagation(); }} className="button">
                    <Avatar className={className}>
                        <Icon>{icon}</Icon>
                    </Avatar>
                </IconButton>
            </Tooltip>
        </div>
    );
}
