import "./MinMaxIndicator.scss";
import clsx from "clsx";
import { ProgressBar } from "../../../shared/components";
import * as colors from "../../../shared/colors";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface MinMaxIndicatorProps {
    range?: [number, number];
    minVal?: number;
    maxVal?: number;
    negativeColor?: string;
    positiveColor?: string;
    className?: string;
}

function MinMaxIndicator(props: MinMaxIndicatorProps) {
    const { range, minVal, maxVal, negativeColor, positiveColor, className, ...rest } = props;

    return (
        <div {...rest} className={clsx("min-max-indicator", className)}>
            <div className="progressbar left">
                <ProgressBar value={Math.abs(minVal)} maxValue={Math.abs(range?.[0])} text=" " fillColor={negativeColor} dir="rtl" />
            </div>
            <div className="progressbar right">
                <ProgressBar value={Math.abs(maxVal)} maxValue={Math.abs(range?.[1])} text=" " fillColor={positiveColor} />
            </div>
        </div>
    );
}

MinMaxIndicator.defaultProps = {
    range: [-1, 1],
    negativeColor: colors.chart_red_3,
    positiveColor: colors.chart_green_3
};

(MinMaxIndicator as DocumentedComponent).metadata = {
    description: "The MinMaxIndicator component displays 2 progress bars back-to-back, as an alternative to a range chart.",
    isSelfClosing: true,
    attributes: [
        { name: `range`, type: "object", description: "The max values for the negative and positive progress bars.  The filled portion of each bar is calculated as the fraction of the respective value." },
        { name: `minVal`, type: `number`, description: "The value to display in the negative progress bar." },
        { name: `maxVal`, type: `number`, description: "The value to display in the positive progress bar." },
        { name: `negativeColor`, type: `string`, description: "The color of the negative progress bar.  Supports all CSS color formats.  Optional - defaults to <div class='bg-chart-red-3' style='display: inline-block; margin-bottom: -1px; width: 12px; height: 12px; border: 1px solid black;'></div>" },
        { name: `positiveColor`, type: `string`, description: "The color of the positive progress bar.  Supports all CSS color formats.  Optional - defaults to <div class='bg-chart-green-3' style='display: inline-block; margin-bottom: -1px; width: 12px; height: 12px; border: 1px solid black;'></div>" },
    ]
};

export default MinMaxIndicator;