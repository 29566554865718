import * as React from "react";
import { Switch as MaterialSwitch, SwitchProps } from "@mui/material";

const Switch: React.FunctionComponent<SwitchProps> = (props: SwitchProps) => {
    const { icon, checkedIcon, ...rest } = props;

    return (
        <MaterialSwitch
            checkedIcon={
                <span className="MuiSwitch-thumb">
                    {checkedIcon ? checkedIcon : <span className="switch-container-inner" />}
                </span>
            }
            icon={
                <span className="MuiSwitch-thumb">
                    {icon ? icon : <span className="switch-container-inner" />}
                </span>
            }
            {...rest}
        />
    );
};

export default Switch;