import React from "react";
import { NavBar } from "../components";
import { logger } from "../services/loggingService";
import { ErrorContainer } from "./ErrorContainer";

export default function NotFound() {
    React.useEffect(() => {
        logger.trackEvent({
            name: "404 - Not Found",
            properties: {
                path: window.location.href
            }
        });
    }, []);

    return (
        <>
            <NavBar title="Not Found" breadcrumbStack={[{ title: "Not Found" }]} />
            <ErrorContainer
                error={{
                    type: "NotFound",
                    message: "We're sorry, but the requested resource does not exist.",
                    details: "Please try a different link from the navigation menu."
                }}
            />
        </>
    );
}