/* eslint-disable no-shadow-restricted-names */
import './icons.scss';

import ALERT_ICON from '../../assets/icons/alert.svg?react';
import ARCHIVE_ICON from '../../assets/icons/archive.svg?react';
import AREA_CHART_ICON from '../../assets/icons/area-chart.svg?react';
import ARROW_BOTH_ICON from '../../assets/icons/arrow-both.svg?react';
import ARROW_DOWN_1_ICON from '../../assets/icons/arrow-down-1.svg?react';
import ARROW_DOWN_RIGHT_ICON from '../../assets/icons/arrow-down-right.svg?react';
import ARROW_DOWN_ICON from '../../assets/icons/arrow-down.svg?react';
import ARROW_LEFT_ICON from '../../assets/icons/arrow-left.svg?react';
import ARROW_RIGHT_ICON from '../../assets/icons/arrow-right.svg?react';
import ARROW_UP_LEFT_ICON from '../../assets/icons/arrow-up-left.svg?react';
import ARROW_UP_RIGHT_ICON from '../../assets/icons/arrow-up-right.svg?react';
import ARROW_UP_ICON from '../../assets/icons/arrow-up.svg?react';
import AVATAR_ICON from '../../assets/icons/avatar.svg?react';
import BACK_ICON from '../../assets/icons/back.svg?react';
import BAR_CHART_ICON from '../../assets/icons/bar-chart.svg?react';
import BEAKER_ICON from '../../assets/icons/beaker.svg?react';
import BELL_SLASH_ICON from '../../assets/icons/bell-slash.svg?react';
import BELL_ICON from '../../assets/icons/bell.svg?react';
import BLOCKED_ICON from '../../assets/icons/blocked.svg?react';
import BOLD_ICON from '../../assets/icons/bold.svg?react';
import BOOK_ICON from '../../assets/icons/book.svg?react';
import BOOKMARK_SLASH_ICON from '../../assets/icons/bookmark-slash.svg?react';
import BOOKMARK_ICON from '../../assets/icons/bookmark.svg?react';
import BOX_ICON from '../../assets/icons/box.svg?react';
import BRIEFCASE_ICON from '../../assets/icons/briefcase.svg?react';
import BROWSER_ICON from '../../assets/icons/browser.svg?react';
import BUBBLE_CHART_ICON from '../../assets/icons/bubble-chart.svg?react';
import BURGER_MENU_ICON from '../../assets/icons/burger-menu.svg?react';
import CALENDAR_ICON from '../../assets/icons/calendar.svg?react';
import CAMERA_ICON from '../../assets/icons/camera.svg?react';
import CANDLESTICK_CHART_ICON from '../../assets/icons/candlestick-chart.svg?react';
import CHAPTER_ICON from '../../assets/icons/chapter.svg?react';
import CHECK_ICON from '../../assets/icons/check.svg?react';
import CHECKBOX_MINUS_ICON from '../../assets/icons/checkbox-minus.svg?react';
import CHECKLIST_ICON from '../../assets/icons/checklist.svg?react';
import CHEVRON_DOWN_ICON from '../../assets/icons/chevron-down.svg?react';
import CHEVRON_LEFT_ICON from '../../assets/icons/chevron-left.svg?react';
import CHEVRON_RIGHT_ICON from '../../assets/icons/chevron-right.svg?react';
import CHEVRON_UP_ICON from '../../assets/icons/chevron-up.svg?react';
import CIRCLE_CHECK_ICON from '../../assets/icons/circle-check.svg?react';
import CIRCLE_PLUS_ICON from '../../assets/icons/circle-plus.svg?react';
import CIRCLE_SLASH_ICON from '../../assets/icons/circle-slash.svg?react';
import CIRCLE_ICON from '../../assets/icons/circle.svg?react';
import CLIPPY_ICON from '../../assets/icons/clippy.svg?react';
import CLOCK_ICON from '../../assets/icons/clock.svg?react';
import COLLAPSE_ICON from '../../assets/icons/collapse.svg?react';
import COLUMN_CHART_ICON from '../../assets/icons/column-chart.svg?react';
import COMBO_CHART_ICON from '../../assets/icons/combo-chart.svg?react';
import COMMENT_DISCUSSION_ICON from '../../assets/icons/comment-discussion.svg?react';
import COMMENT_ICON from '../../assets/icons/comment.svg?react';
import CREDIT_CARD_ICON from '../../assets/icons/credit-card.svg?react';
import CROSS_REFERENCE_ICON from '../../assets/icons/cross-reference.svg?react';
import DASH_ICON from '../../assets/icons/dash.svg?react';
import DASHBOARD_ICON from '../../assets/icons/dashboard.svg?react';
import DESKTOP_DEVICE_ICON from '../../assets/icons/desktop-device.svg?react';
import DESKTOP_DOWNLOAD_ICON from '../../assets/icons/desktop-download.svg?react';
import DEVICE_CAMERA_VIDEO_ICON from '../../assets/icons/device-camera-video.svg?react';
import DEVICE_MOBILE_ICON from '../../assets/icons/device-mobile.svg?react';
import DIAMOND_ICON from '../../assets/icons/diamond.svg?react';
import DOT_ICON from '../../assets/icons/dot.svg?react';
import DOUBLE_TAB_ICON from '../../assets/icons/double-tab.svg?react';
import DOWNLOAD_ICON from '../../assets/icons/download.svg?react';
import DRAG_VERTICAL_ICON from '../../assets/icons/drag-vertical.svg?react';
import DUPLICATE_ICON from '../../assets/icons/duplicate.svg?react';
import EXPAND_ICON from '../../assets/icons/expand.svg?react';
import EXPORT_ICON from '../../assets/icons/export.svg?react';
import EYE_ICON from '../../assets/icons/eye.svg?react';
import FILE_MEDIA_ICON from '../../assets/icons/file-media.svg?react';
import FILTER_ICON from '../../assets/icons/filter.svg?react';
import FIRE_ICON from '../../assets/icons/fire.svg?react';
import FLIP_ICON from '../../assets/icons/flip.svg?react';
import FOLD_DOWN_ICON from '../../assets/icons/fold-down.svg?react';
import FOLD_UP_ICON from '../../assets/icons/fold-up.svg?react';
import FOLD_ICON from '../../assets/icons/fold.svg?react';
import GEO_CHART_ICON from '../../assets/icons/geo-chart.svg?react';
import GIFT_ICON from '../../assets/icons/gift.svg?react';
import GLOBE_ICON from '../../assets/icons/globe.svg?react';
import GRABBER_ICON from '../../assets/icons/grabber.svg?react';
import GRAPH_ICON from '../../assets/icons/graph.svg?react';
import GRID_ICON from '../../assets/icons/grid.svg?react';
import GROUP_ICON from '../../assets/icons/group.svg?react';
import HASH_24_ICON from '../../assets/icons/hash-24.svg?react';
import HEADING_ICON from '../../assets/icons/heading.svg?react';
import HEADSET_ICON from '../../assets/icons/headset.svg?react';
import HEART_ICON from '../../assets/icons/heart.svg?react';
import HISTORY_ICON from '../../assets/icons/history.svg?react';
import HOME_ICON from '../../assets/icons/home.svg?react';
import HORIZONTAL_RULE_ICON from '../../assets/icons/horizontal-rule.svg?react';
import HOURGLASS_ICON from '../../assets/icons/hourglass.svg?react';
import INBOX_ICON from '../../assets/icons/inbox.svg?react';
import INFINITY_ICON from '../../assets/icons/infinity.svg?react';
import INFO_ICON from '../../assets/icons/info.svg?react';
import INSER_ABOVE_ICON from '../../assets/icons/inser-above.svg?react';
import INSER_BELOW_ICON from '../../assets/icons/inser-below.svg?react';
import INVISIBLE_ICON from '../../assets/icons/invisible.svg?react';
import ITALIC_ICON from '../../assets/icons/italic.svg?react';
import KEBAB_HORIZONTAL_ICON from '../../assets/icons/kebab-horizontal.svg?react';
import KEY_ICON from '../../assets/icons/key.svg?react';
import KEYBOARD_SHORTCUT_ICON from '../../assets/icons/keyboard-shortcut.svg?react';
import LABELS_ICON from '../../assets/icons/labels.svg?react';
import LAW_ICON from '../../assets/icons/law.svg?react';
import LIGHTBULB_ICON from '../../assets/icons/lightbulb.svg?react';
import LINK_ICON from '../../assets/icons/link.svg?react';
import LIST_ORDERED_ICON from '../../assets/icons/list-ordered.svg?react';
import LIST_UNORDERED_ICON from '../../assets/icons/list-unordered.svg?react';
import LOADING_ICON from '../../assets/icons/loading.svg?react';
import LOCATION_ICON from '../../assets/icons/location.svg?react';
import LOCK_ICON from '../../assets/icons/lock.svg?react';
import MAIL_ICON from '../../assets/icons/mail.svg?react';
import MEGAPHONE_ICON from '../../assets/icons/megaphone.svg?react';
import MENTION_ICON from '../../assets/icons/mention.svg?react';
import METER_ICON from '../../assets/icons/meter.svg?react';
import MILESTONE_ICON from '../../assets/icons/milestone.svg?react';
import MINUS_ICON from '../../assets/icons/minus.svg?react';
import MIRROR_ICON from '../../assets/icons/mirror.svg?react';
import MOON_ICON from '../../assets/icons/moon.svg?react';
import MORTAR_BOARD_ICON from '../../assets/icons/mortar-board.svg?react';
import MOUSE_ICON from '../../assets/icons/mouse.svg?react';
import MOVE_ICON from '../../assets/icons/move.svg?react';
import MULTI_SELECT_ICON from '../../assets/icons/multi-select.svg?react';
import MUTE_ICON from '../../assets/icons/mute.svg?react';
import NORTH_STAR_ICON from '../../assets/icons/north-star.svg?react';
import NOTE_ICON from '../../assets/icons/note.svg?react';
import NUMBER_ICON from '../../assets/icons/number.svg?react';
import ORGANISATIONAL_CHART_ICON from '../../assets/icons/organisational-chart.svg?react';
import PAGE_ICON from '../../assets/icons/page.svg?react';
import PAINTBRUSH_ICON from '../../assets/icons/paintbrush.svg?react';
import PAPER_AIRPLANE_ICON from '../../assets/icons/paper-airplane.svg?react';
import PAUSE_ICON from '../../assets/icons/pause.svg?react';
import PENCIL_ICON from '../../assets/icons/pencil.svg?react';
import PIE_CHART_ICON from '../../assets/icons/pie-chart.svg?react';
import PIN_ICON from '../../assets/icons/pin.svg?react';
import PLAY_ICON from '../../assets/icons/play.svg?react';
import PLOT_AREA_ICON from '../../assets/icons/plot-area.svg?react';
import PLUG_ICON from '../../assets/icons/plug.svg?react';
import PLUS_ICON from '../../assets/icons/plus.svg?react';
import PPT_ICON from '../../assets/icons/ppt.svg?react';
import PROJECT_ICON from '../../assets/icons/project.svg?react';
import PULSE_ICON from '../../assets/icons/pulse.svg?react';
import QUESTION_ICON from '../../assets/icons/question.svg?react';
import QUOTE_ICON from '../../assets/icons/quote.svg?react';
import RADAR_CHART_ICON from '../../assets/icons/radar-chart.svg?react';
import REPLY_ICON from '../../assets/icons/reply.svg?react';
import REPORT_ICON from '../../assets/icons/report.svg?react';
import ROCKET_ICON from '../../assets/icons/rocket.svg?react';
import RUBY_ICON from '../../assets/icons/ruby.svg?react';
import SAVE_ICON from '../../assets/icons/save.svg?react';
import SCATTER_CHART_ICON from '../../assets/icons/scatter-chart.svg?react';
import SCREEN_FULL_1_ICON from '../../assets/icons/screen-full-1.svg?react';
import SCREEN_FULL_ICON from '../../assets/icons/screen-full.svg?react';
import SCREEN_NORMAL_ICON from '../../assets/icons/screen-normal.svg?react';
import SEARCH_ICON from '../../assets/icons/search.svg?react';
import SERIES_ICON from '../../assets/icons/series.svg?react';
import SETTINGS_1_ICON from '../../assets/icons/settings-1.svg?react';
import SETTINGS_ICON from '../../assets/icons/settings.svg?react';
import SHARE_ANDROID_ICON from '../../assets/icons/share-android.svg?react';
import SHARE_ICON from '../../assets/icons/share.svg?react';
import SIGN_IN_ICON from '../../assets/icons/sign-in.svg?react';
import SIGN_OUT_ICON from '../../assets/icons/sign-out.svg?react';
import SQUARE_ICON from '../../assets/icons/square.svg?react';
import STACKED_BAR_CHART_ICON from '../../assets/icons/stacked-bar-chart.svg?react';
import STACKED_COLUMN_CHART_ICON from '../../assets/icons/stacked-column-chart.svg?react';
import STAR_ICON from '../../assets/icons/star.svg?react';
import STOP_ICON from '../../assets/icons/stop.svg?react';
import STOPWATCH_ICON from '../../assets/icons/stopwatch.svg?react';
import STRIKETHROUGH_ICON from '../../assets/icons/strikethrough.svg?react';
import SUN_ICON from '../../assets/icons/sun.svg?react';
import SYNC_ICON from '../../assets/icons/sync.svg?react';
import T_ICON from '../../assets/icons/t.svg?react';
import TAB_1_ICON from '../../assets/icons/tab-1.svg?react';
import TAB_ICON from '../../assets/icons/tab.svg?react';
import TABLE_ICON from '../../assets/icons/table.svg?react';
import TAG_ICON from '../../assets/icons/tag.svg?react';
import TASKLIST_ICON from '../../assets/icons/tasklist.svg?react';
import TELESCOPE_ICON from '../../assets/icons/telescope.svg?react';
import TEST_ICON from '../../assets/icons/test.svg?react';
import TOOLS_ICON from '../../assets/icons/tools.svg?react';
import TRASH_ICON from '../../assets/icons/trash.svg?react';
import TREE_CHART_ICON from '../../assets/icons/tree-chart.svg?react';
import TREND_ICON from '../../assets/icons/trend.svg?react';
import TRIANGLE_DOWN_ICON from '../../assets/icons/triangle-down.svg?react';
import TRIANGLE_LEFT_ICON from '../../assets/icons/triangle-left.svg?react';
import TRIANGLE_RIGHT_ICON from '../../assets/icons/triangle-right.svg?react';
import TRIANGLE_UP_ICON from '../../assets/icons/triangle-up.svg?react';
import TYPOGRAPHY_ICON from '../../assets/icons/typography.svg?react';
import UNFOLD_ICON from '../../assets/icons/unfold.svg?react';
import UNLOCK_ICON from '../../assets/icons/unlock.svg?react';
import UNMUTE_ICON from '../../assets/icons/unmute.svg?react';
import UNVERIFED_ICON from '../../assets/icons/unverifed.svg?react';
import UPLOAD_ICON from '../../assets/icons/upload.svg?react';
import VDT_MENU_GRAPH_ICON from '../../assets/icons/VDT-menu-graph.svg?react';
import VDT_ICON from '../../assets/icons/VDT.svg?react';
import VERIFIED_ICON from '../../assets/icons/verified.svg?react';
import VIDEO_ICON from '../../assets/icons/video.svg?react';
import VIEW_ICON from '../../assets/icons/view.svg?react';
import WATERFALL_CHART_ICON from '../../assets/icons/waterfall-chart.svg?react';
import WIZARD_PIPELINE_ICON from '../../assets/icons/wizard-pipeline.svg?react';
import X_AXIS_ICON from '../../assets/icons/x-axis.svg?react';
import X_CIRCLE_ICON from '../../assets/icons/x-circle.svg?react';
import X_ICON from '../../assets/icons/x.svg?react';
import Y_AXIS_ICON from '../../assets/icons/y-axis.svg?react';
import ZAP_ICON from '../../assets/icons/zap.svg?react';
import ZOOM_IN_ICON from '../../assets/icons/zoom-in.svg?react';
import ZOOM_OUT_ICON from '../../assets/icons/zoom-out.svg?react';

interface IconProps {
    size?: "small" | "medium" | "large";
    color?: "white" | "black" | string;
}

export const Alert = (props: IconProps) => <ALERT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Archive = (props: IconProps) => <ARCHIVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const AreaChart = (props: IconProps) => <AREA_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowBoth = (props: IconProps) => <ARROW_BOTH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowDown1 = (props: IconProps) => <ARROW_DOWN_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowDownRight = (props: IconProps) => <ARROW_DOWN_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowDown = (props: IconProps) => <ARROW_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowLeft = (props: IconProps) => <ARROW_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowRight = (props: IconProps) => <ARROW_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowUpLeft = (props: IconProps) => <ARROW_UP_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowUpRight = (props: IconProps) => <ARROW_UP_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowUp = (props: IconProps) => <ARROW_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Avatar = (props: IconProps) => <AVATAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Back = (props: IconProps) => <BACK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BarChart = (props: IconProps) => <BAR_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Beaker = (props: IconProps) => <BEAKER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BellSlash = (props: IconProps) => <BELL_SLASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Bell = (props: IconProps) => <BELL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Blocked = (props: IconProps) => <BLOCKED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Bold = (props: IconProps) => <BOLD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Book = (props: IconProps) => <BOOK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BookmarkSlash = (props: IconProps) => <BOOKMARK_SLASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Bookmark = (props: IconProps) => <BOOKMARK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Box = (props: IconProps) => <BOX_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Briefcase = (props: IconProps) => <BRIEFCASE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Browser = (props: IconProps) => <BROWSER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BubbleChart = (props: IconProps) => <BUBBLE_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BurgerMenu = (props: IconProps) => <BURGER_MENU_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Calendar = (props: IconProps) => <CALENDAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Camera = (props: IconProps) => <CAMERA_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CandlestickChart = (props: IconProps) => <CANDLESTICK_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Chapter = (props: IconProps) => <CHAPTER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Check = (props: IconProps) => <CHECK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CheckboxMinus = (props: IconProps) => <CHECKBOX_MINUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Checklist = (props: IconProps) => <CHECKLIST_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronDown = (props: IconProps) => <CHEVRON_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronLeft = (props: IconProps) => <CHEVRON_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronRight = (props: IconProps) => <CHEVRON_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronUp = (props: IconProps) => <CHEVRON_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CircleCheck = (props: IconProps) => <CIRCLE_CHECK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CirclePlus = (props: IconProps) => <CIRCLE_PLUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CircleSlash = (props: IconProps) => <CIRCLE_SLASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Circle = (props: IconProps) => <CIRCLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Clippy = (props: IconProps) => <CLIPPY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Clock = (props: IconProps) => <CLOCK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Collapse = (props: IconProps) => <COLLAPSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ColumnChart = (props: IconProps) => <COLUMN_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ComboChart = (props: IconProps) => <COMBO_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CommentDiscussion = (props: IconProps) => <COMMENT_DISCUSSION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Comment = (props: IconProps) => <COMMENT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CreditCard = (props: IconProps) => <CREDIT_CARD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CrossReference = (props: IconProps) => <CROSS_REFERENCE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Dash = (props: IconProps) => <DASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Dashboard = (props: IconProps) => <DASHBOARD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DesktopDevice = (props: IconProps) => <DESKTOP_DEVICE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DesktopDownload = (props: IconProps) => <DESKTOP_DOWNLOAD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DeviceCameraVideo = (props: IconProps) => <DEVICE_CAMERA_VIDEO_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DeviceMobile = (props: IconProps) => <DEVICE_MOBILE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Diamond = (props: IconProps) => <DIAMOND_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Dot = (props: IconProps) => <DOT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DoubleTab = (props: IconProps) => <DOUBLE_TAB_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Download = (props: IconProps) => <DOWNLOAD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DragVertical = (props: IconProps) => <DRAG_VERTICAL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Duplicate = (props: IconProps) => <DUPLICATE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Expand = (props: IconProps) => <EXPAND_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Export = (props: IconProps) => <EXPORT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Eye = (props: IconProps) => <EYE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const FileMedia = (props: IconProps) => <FILE_MEDIA_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Filter = (props: IconProps) => <FILTER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Fire = (props: IconProps) => <FIRE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Flip = (props: IconProps) => <FLIP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const FoldDown = (props: IconProps) => <FOLD_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const FoldUp = (props: IconProps) => <FOLD_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Fold = (props: IconProps) => <FOLD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const GeoChart = (props: IconProps) => <GEO_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Gift = (props: IconProps) => <GIFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Globe = (props: IconProps) => <GLOBE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Grabber = (props: IconProps) => <GRABBER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Graph = (props: IconProps) => <GRAPH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Grid = (props: IconProps) => <GRID_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Group = (props: IconProps) => <GROUP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Hash24 = (props: IconProps) => <HASH_24_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Heading = (props: IconProps) => <HEADING_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Headset = (props: IconProps) => <HEADSET_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Heart = (props: IconProps) => <HEART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const History = (props: IconProps) => <HISTORY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Home = (props: IconProps) => <HOME_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const HorizontalRule = (props: IconProps) => <HORIZONTAL_RULE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Hourglass = (props: IconProps) => <HOURGLASS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Inbox = (props: IconProps) => <INBOX_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Infinity = (props: IconProps) => <INFINITY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Info = (props: IconProps) => <INFO_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const InserAbove = (props: IconProps) => <INSER_ABOVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const InserBelow = (props: IconProps) => <INSER_BELOW_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Invisible = (props: IconProps) => <INVISIBLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Italic = (props: IconProps) => <ITALIC_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const KebabHorizontal = (props: IconProps) => <KEBAB_HORIZONTAL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Key = (props: IconProps) => <KEY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const KeyboardShortcut = (props: IconProps) => <KEYBOARD_SHORTCUT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Labels = (props: IconProps) => <LABELS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Law = (props: IconProps) => <LAW_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Lightbulb = (props: IconProps) => <LIGHTBULB_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Link = (props: IconProps) => <LINK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ListOrdered = (props: IconProps) => <LIST_ORDERED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ListUnordered = (props: IconProps) => <LIST_UNORDERED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Loading = (props: IconProps) => <LOADING_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Location = (props: IconProps) => <LOCATION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Lock = (props: IconProps) => <LOCK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mail = (props: IconProps) => <MAIL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Megaphone = (props: IconProps) => <MEGAPHONE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mention = (props: IconProps) => <MENTION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Meter = (props: IconProps) => <METER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Milestone = (props: IconProps) => <MILESTONE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Minus = (props: IconProps) => <MINUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mirror = (props: IconProps) => <MIRROR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Moon = (props: IconProps) => <MOON_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const MortarBoard = (props: IconProps) => <MORTAR_BOARD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mouse = (props: IconProps) => <MOUSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Move = (props: IconProps) => <MOVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const MultiSelect = (props: IconProps) => <MULTI_SELECT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mute = (props: IconProps) => <MUTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const NorthStar = (props: IconProps) => <NORTH_STAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Note = (props: IconProps) => <NOTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Number = (props: IconProps) => <NUMBER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const OrganisationalChart = (props: IconProps) => <ORGANISATIONAL_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Page = (props: IconProps) => <PAGE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Paintbrush = (props: IconProps) => <PAINTBRUSH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const PaperAirplane = (props: IconProps) => <PAPER_AIRPLANE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pause = (props: IconProps) => <PAUSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pencil = (props: IconProps) => <PENCIL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const PieChart = (props: IconProps) => <PIE_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pin = (props: IconProps) => <PIN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Play = (props: IconProps) => <PLAY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const PlotArea = (props: IconProps) => <PLOT_AREA_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Plug = (props: IconProps) => <PLUG_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Plus = (props: IconProps) => <PLUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Ppt = (props: IconProps) => <PPT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Project = (props: IconProps) => <PROJECT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pulse = (props: IconProps) => <PULSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Question = (props: IconProps) => <QUESTION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Quote = (props: IconProps) => <QUOTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const RadarChart = (props: IconProps) => <RADAR_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Reply = (props: IconProps) => <REPLY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Report = (props: IconProps) => <REPORT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Rocket = (props: IconProps) => <ROCKET_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Ruby = (props: IconProps) => <RUBY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Save = (props: IconProps) => <SAVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScatterChart = (props: IconProps) => <SCATTER_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScreenFull1 = (props: IconProps) => <SCREEN_FULL_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScreenFull = (props: IconProps) => <SCREEN_FULL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScreenNormal = (props: IconProps) => <SCREEN_NORMAL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Search = (props: IconProps) => <SEARCH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Series = (props: IconProps) => <SERIES_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Settings1 = (props: IconProps) => <SETTINGS_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Settings = (props: IconProps) => <SETTINGS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ShareAndroid = (props: IconProps) => <SHARE_ANDROID_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Share = (props: IconProps) => <SHARE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const SignIn = (props: IconProps) => <SIGN_IN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const SignOut = (props: IconProps) => <SIGN_OUT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Square = (props: IconProps) => <SQUARE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const StackedBarChart = (props: IconProps) => <STACKED_BAR_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const StackedColumnChart = (props: IconProps) => <STACKED_COLUMN_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Star = (props: IconProps) => <STAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Stop = (props: IconProps) => <STOP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Stopwatch = (props: IconProps) => <STOPWATCH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Strikethrough = (props: IconProps) => <STRIKETHROUGH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Sun = (props: IconProps) => <SUN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Sync = (props: IconProps) => <SYNC_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const T = (props: IconProps) => <T_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tab1 = (props: IconProps) => <TAB_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tab = (props: IconProps) => <TAB_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Table = (props: IconProps) => <TABLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tag = (props: IconProps) => <TAG_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tasklist = (props: IconProps) => <TASKLIST_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Telescope = (props: IconProps) => <TELESCOPE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Test = (props: IconProps) => <TEST_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tools = (props: IconProps) => <TOOLS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Trash = (props: IconProps) => <TRASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TreeChart = (props: IconProps) => <TREE_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Trend = (props: IconProps) => <TREND_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleDown = (props: IconProps) => <TRIANGLE_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleLeft = (props: IconProps) => <TRIANGLE_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleRight = (props: IconProps) => <TRIANGLE_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleUp = (props: IconProps) => <TRIANGLE_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Typography = (props: IconProps) => <TYPOGRAPHY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unfold = (props: IconProps) => <UNFOLD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unlock = (props: IconProps) => <UNLOCK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unmute = (props: IconProps) => <UNMUTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unverifed = (props: IconProps) => <UNVERIFED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Upload = (props: IconProps) => <UPLOAD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const VDTMenuGraph = (props: IconProps) => <VDT_MENU_GRAPH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const VDT = (props: IconProps) => <VDT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Verified = (props: IconProps) => <VERIFIED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Video = (props: IconProps) => <VIDEO_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const View = (props: IconProps) => <VIEW_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const WaterfallChart = (props: IconProps) => <WATERFALL_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const WizardPipeline = (props: IconProps) => <WIZARD_PIPELINE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const XAxis = (props: IconProps) => <X_AXIS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const XCircle = (props: IconProps) => <X_CIRCLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const X = (props: IconProps) => <X_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const YAxis = (props: IconProps) => <Y_AXIS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Zap = (props: IconProps) => <ZAP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ZoomIn = (props: IconProps) => <ZOOM_IN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ZoomOut = (props: IconProps) => <ZOOM_OUT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
