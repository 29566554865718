import React from "react";
import "./InlineInfoTooltip.scss";
import { Tooltip } from "@mui/material";
import { Help as HelpIcon } from "@mui/icons-material";

interface Props {
    text: string
}

export default function InlineInfoTooltip(props: Props) {
    const { text } = props;

    return (
        <Tooltip title={text}>
            <HelpIcon className="inline-info-tooltip" />
        </Tooltip>
    );
}