import React from 'react';
import { StorylineState } from "../../../store/storyline/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import clsx from 'clsx';
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

interface TabPanelProps {
    storyline: StorylineState;
    tabsName: string;
    value: string;
    children: any;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const { tabsName, value, children, className, ...other } = props;
    const parameterValues = useSelector((state: RootState) => state.storyline.parameterValues);

    const parameterName = `tabs-${tabsName}`;

    const [activeTab, setActiveTab] = React.useState(parameterValues.get(parameterName));

    React.useEffect(() => {
        if (parameterValues.has(parameterName)) {
            const newValue = parameterValues.get(parameterName);

            if (newValue !== activeTab) {
                setActiveTab(newValue);
            }
        }
    }, [parameterValues]);

    return activeTab === value &&
        <div className={clsx("col-fill", className)} {...other}>
            {children}
        </div>;
}

(TabPanel as DocumentedComponent).metadata = {
    description: "The TabPanel is used as the container for content that should be rendered when a specific Tab is active.  Linked to the associated Tab via the tabsName and value properties.",
    isSelfClosing: false,
    attributes: [
        { name: `tabsName`, type: `string`, description: "The name of the `Tabs` component that this `TabPanel` is linked to.  This allows for multiple groups of `Tabs` per canvas." },
        { name: `value`, type: `string`, description: "The value of the `Tab` that is linked with this panel.  When a `Tab` is selected, the panel with the matching value is displayed." },
    ]
}

export default TabPanel;