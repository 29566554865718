import { ChangeEvent } from "react";
import "./KeyValueEditor.scss";
import { Button, Icon } from "@mui/material";
import TextField from "./TextField";

interface Entry {
    [keyName: string]: string;
    value: string;
}

interface Props {
    keyName: string;
    entries: Entry[];
    updateEntries: (newEntries: Entry[]) => void;
}

export function KeyValueEditor(props: Props) {
    const { keyName, entries, updateEntries } = props;

    const createRow = () => {
        const newEntry = { [keyName]: "", value: "" };
        updateEntries([...(entries || []), newEntry]);
    }

    const deleteRow = (index: number) => () => {
        const newEntries = entries.filter((_, i) => i !== index);
        updateEntries(newEntries);
    }

    const getFieldValue = (index: number, fieldName: string) => {
        return entries?.[index]?.[fieldName];
    }

    const setFieldValue = (index: number, fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const rows = entries.slice();
        rows[index][fieldName] = event.target.value;
        updateEntries(rows);
    }

    return (
        <div className="key-value-editor">
            {
                entries?.length > 0 ?
                    <table className="table compact striped bordered">
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Value</th>
                                <th style={{ width: "auto" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                entries.map((_, index) =>
                                    <tr key={`row-${index}`}>
                                        {
                                            [keyName, "value"].map((key, cellIndex) =>
                                                <td key={`user-metadata-${index}-${cellIndex}`}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={getFieldValue(index, key)}
                                                        onChange={setFieldValue(index, key)}
                                                    />
                                                </td>
                                            )
                                        }
                                        <td>
                                            <Button variant="contained" color="error" onClick={deleteRow(index)} size="small" title="Delete Row">
                                                <Icon fontSize="small">delete</Icon>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table> :
                    <div className="no-data">No Data Captured.</div>
            }
            <div className="footer">
                <Button variant="contained" color="success" onClick={createRow} size="small" title="Add Row">
                    <Icon fontSize="small">add</Icon>
                </Button>
            </div>
        </div>
    );
}