import * as React from "react";
import { Checkbox, TextField } from "./";
import { Autocomplete as MaterialAutocomplete, AutocompleteProps, AutocompleteRenderInputParams, IconButton, Paper, Popper, Tooltip } from "@mui/material";
import { X, XCircle, ChevronDown } from "./icons";
import parse from 'autosuggest-highlight/parse';
import prefixMatch from "autosuggest-highlight/match";
import clsx from "clsx";
import _ from "lodash";
import { useTooltipContainer } from "../providers/TooltipContainerProvider";

function wildcardMatch(text, query) {
    if (!query) return [];

    const results = [];
    const trimmedQuery = query.trim().toLowerCase();
    const textLower = text.toLowerCase();
    const queryLength = trimmedQuery.length;
    let indexOf = textLower.indexOf(trimmedQuery);
    while (indexOf > -1) {
        results.push([indexOf, indexOf + queryLength]);
        indexOf = textLower.indexOf(query, indexOf + queryLength);
    }
    return results;
}

interface MyAutocompleteProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> extends Omit<AutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
>, 'renderInput'> {
    // We're providing a default implementation for "renderInput" below, so omit the original (required) version and override it with an optional equivalent...
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    label?: string;
    highlightMatchPattern?: "prefix" | "wildcard";
    placeholder?: string;
    helperText?: string;
    error?: boolean;
    InputLabelProps?: object;
    footerContent?: JSX.Element;
    getOptionTooltip?: (option: Option) => any;
}

interface Option {
    value: any;
    label: string;
    preventSelection?: boolean;
    disabled?: boolean;
}

function Autocomplete<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(props: MyAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>): JSX.Element {
    const { multiple, label, highlightMatchPattern, placeholder, error, helperText, footerContent, getOptionTooltip } = props;
    const matchHandler = highlightMatchPattern === "prefix" ? prefixMatch : wildcardMatch;

    const tooltipContainerRef = useTooltipContainer();

    const ContentWithFooterSupport = React.useCallback((props) => {
        const { children, ...rest } = props;

        return <Paper {...rest} className="col-fill" onMouseDown={(event) => {
            // Prevent input blur when interacting with the TreeView content
            event.preventDefault();
        }}>
            {children}
            {footerContent &&
                <>
                    <hr />
                    {footerContent}
                </>
            }
        </Paper>;
    }, [footerContent]);

    const PopperWithCustomCssSupport = React.useCallback((props) => {
        const { children, ...rest } = props;

        return <Popper {...rest} container={tooltipContainerRef.current} modifiers={[
            {
                name: 'preventOverflow',
                options: {
                    // Disable overflow check along the x axis.  This prevents the Popper from being displaced
                    // to the right when the Autocomplete is rendered inside a Modal or FocusContainer.
                    mainAxis: false,   
                },
            },
        ]}>
            {children}
        </Popper>;
    }, []);

    return <MaterialAutocomplete
        ChipProps={{ deleteIcon: <IconButton><X size="small" /></IconButton>, ...props?.ChipProps }}
        clearIcon={<XCircle size="small" />}
        popupIcon={<ChevronDown size="small" />}
        blurOnSelect={!multiple}
        disableCloseOnSelect={multiple}
        openOnFocus
        autoHighlight
        renderOption={(props, option, { inputValue, selected }) => {
            const matches = matchHandler(option.label, inputValue);
            const parts = parse(option.label, matches);
            const content = <li {...props} className={clsx(props.className, multiple && "multi-select-item")}>
                {
                    multiple ?
                        <Checkbox
                            color="primary"
                            style={{ marginRight: 8 }}
                            checked={selected}
                        /> :
                        null
                }
                <div>
                    {parts.map((part, index) => (
                        <span key={index} className={part.highlight ? "highlight-text" : ""}>
                            {part.text}
                        </span>
                    ))}
                </div>
            </li>;

            return getOptionTooltip ?
                <OptionTooltip option={option} getOptionTooltip={getOptionTooltip}>{content}</OptionTooltip> :
                content;
        }}
        renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} error={error} helperText={helperText} InputLabelProps={props.InputLabelProps} />}
        isOptionEqualToValue={(option: Option, value: any) => _.isEqual(option?.value, value?.value)}
        getOptionDisabled={(option: Option) => option?.disabled ?? option?.preventSelection}
        getOptionLabel={(option: Option) => option?.label || ""}
        getOptionKey={(option: Option) => _.isString(option.value) ? option.value : JSON.stringify(option.value)}
        PaperComponent={ContentWithFooterSupport}
        PopperComponent={PopperWithCustomCssSupport}
        {...props}
    />
};

type OptionTooltipProps = {
    option: Option;
    getOptionTooltip: (option: Option) => any;
    children: React.ReactElement;
};

function OptionTooltip(props: OptionTooltipProps) {
    const { option, getOptionTooltip, children } = props;
    if (!getOptionTooltip) return <>{children}</>;

    const tooltipContent = getOptionTooltip(option);
    if (!tooltipContent) return <>{children}</>;

    return (
        <Tooltip arrow placement="left" title={tooltipContent}>
            <span>
                {children}
            </span>
        </Tooltip>
    );
}

export { Autocomplete };
export type { Option };
