import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  Tooltip
} from "../../../shared/components";
import "./GeoMapContainer.scss";

export interface GeoEditPolygonDialogProps {
  id: any;
  name: string;
  site?: string
  poiType?: string
  tat?: string
  minTat?: string
  lane?: string
  onSave: (
    name: string,
    site?: string,
    poiType?: string,
    tat?: string,
    minTat?: string,
    lane?: string
  ) => void;
  onDelete: () => void;
}

export const GeoEditPolygonDialog: React.FunctionComponent<
  GeoEditPolygonDialogProps
> = (props) => {
  const [id, setID] = useState(props.id);
  const [name, setName] = useState(props.name);
  const [site, setSite] = useState(props.site  || undefined);
  const [poiType, setPoiType] = useState(props.poiType  || undefined);
  const [tat, setTat] = useState(props.tat  || undefined);
  const [minTat, setMinTat] = useState(props.minTat  || undefined);
  const [lane, setLane] = useState(props.lane  || undefined);

  useEffect(() => {
    setID(props.id);
  }, [props.id]);
  
  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  useEffect(() => {
    setSite(props.site);
  }, [props.site]);

  useEffect(() => {
    setPoiType(props.poiType);
  }, [props.poiType]);

  useEffect(() => {
    setTat(props.tat);
  }, [props.tat]);

  useEffect(() => {
    setMinTat(props.minTat);
  }, [props.minTat]);

  useEffect(() => {
    setLane(props.lane);
  }, [props.lane]);

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function handleChangeSite(event: React.ChangeEvent<HTMLInputElement>) {
    setSite(event.target.value);
  }

  function handleChangePoiType(event: React.ChangeEvent<HTMLInputElement>) {
    setPoiType(event.target.value);
  }

  function handleChangeTat(event: React.ChangeEvent<HTMLInputElement>) {
    setTat(event.target.value);
  }

  function handleChangeMinTat(event: React.ChangeEvent<HTMLInputElement>) {
    setMinTat(event.target.value);
  }

  function handleChangeLane(event: React.ChangeEvent<HTMLInputElement>) {
    setLane(event.target.value);
  }

  function handleSave() {
    if (site || poiType || tat || minTat || lane) {
      // if value left empty then it returns as NaN, change to null
      const arr = [name, site, poiType, tat, minTat, lane];
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i]) {
          arr[i] = null;
        }
      }
      props.onSave(name, site, poiType, tat, minTat, lane);
    } else {
      props.onSave(name);
    }
  }

  function handleDelete() {
    props.onDelete();
  }

  return (
    <>
      <DialogContent>
      <TextField
          label="ID"
          variant="outlined"
          fullWidth
          value={id}
          disabled={true}
        />
        <br />
        <br />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleChangeName}
        />
        {(site || poiType || tat || minTat || lane) && <>
          <br />
          <br />
          <TextField
            label="Site"
            variant="outlined"
            fullWidth
            value={site}
            onChange={handleChangeSite}
          />
          <br />
          <br />
          <TextField
            label="Location Type"
            variant="outlined"
            fullWidth
            value={poiType}
            onChange={handleChangePoiType}
          />
          <br />
          <br />
          <TextField
            label="Turn around time (mins)"
            variant="outlined"
            fullWidth
            value={tat}
            onChange={handleChangeTat}
          />
          <br />
          <br />
          <TextField
            label="Minimum turn around time (mins)"
            variant="outlined"
            fullWidth
            value={minTat}
            onChange={handleChangeMinTat}
          />
          <br />
          <br />
          <TextField
            label="Lane"
            variant="outlined"
            fullWidth
            value={lane}
            onChange={handleChangeLane}
          />
        </>}
      </DialogContent>

      <DialogActions  className="geofence-editor-dialog-actions">
        <Tooltip title="Deletes the entire POI" >
          <Button
            variant="contained"
            className="button-delete"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Tooltip>
        <Tooltip title="Accepts the changes made to the location. This can then be saved using the `Save Updates` button." >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave}
          >
            Accept Changes
          </Button>
        </Tooltip>
      </DialogActions>
    </>
  );
};
