import React from "react";
import "./EditUser.scss";
import * as coreApi from "../../shared/core-api-client";
import * as _ from "lodash";
import { Button, Dialog, DialogContent, TextField, DialogActions, Select, MenuItem, Chip, DialogTitleWithCloseButton, InlineInfoTooltip, InfoAlert, KeyValueEditor, Checkbox, LoadingContainer } from "../../shared/components";
import { Controller, useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { useSettings } from "../../shared/providers/SettingsProvider";

interface EditUserProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    roles: coreApi.RoleLookup[],
    groups: coreApi.GroupLookup[],
    user?: coreApi.UserListModel,
    showSuccess: typeof showSuccess,
    showError: typeof showError,
    onSuccess: () => void
}

function EditUser(props: EditUserProps) {
    const schema = object().shape({
        name: string()
            .required("This field is required."),
        emailAddress: string()
            .required("This field is required."),
        roles: array(),
        groups: array(),
        userMetadata: array(),
        explicitTags: object(),
    });
    const { open, setOpen, roles, groups, user: _user, showSuccess, showError, onSuccess } = props;
    const { register, handleSubmit, errors, reset, setError, control, watch } = useForm({ resolver: yupResolver(schema) });
    const sendWelcomeEmail = watch("sendWelcomeEmail", false);
    const [formErrors, setFormErrors] = React.useState(null);
    const [user, setUser] = React.useState<coreApi.UserDisplayModel>(null);
    const settings = useSettings();

    React.useEffect(() => {
        if (_user) {
            new coreApi.UsersClient(settings.coreApiUrl).getById(_user.id)
                .then(result => {
                    setUser(result);
                    reset(result);
                });
        }
        else {
            reset({ sendWelcomeEmail: true, explicitTags: { allowed: [], denied: [] } });
        }
    }, [_user]);

    const closeDialog = () => {
        setOpen(false);
    }

    const attachFormErrors = (errorDictionary: object) => {
        _.forOwn(errorDictionary, (value, key) => {
            if (key === "non_field_errors") {
                setFormErrors(value[0]);
            }
            else {
                setError(_.camelCase(key), { type: "server", message: value[0] });
            }
        });
    }

    const save = (formModel) => {
        const model = {
            domainName: settings.domainName,
            ...user,
            ...formModel,
        };

        // Bulk create...
        if (!user && (formModel.name.includes(",") || formModel.emailAddress.includes(","))) {
            let names = formModel.name.split(",").map(a => a.trim()) as string[];
            let emailAddresses = formModel.emailAddress.split(",").map(a => a.trim()) as string[];

            if (names.length !== emailAddresses.length) {
                setError("name", { type: "validation", message: "Length of names and email addresses do not match.  Please double-check both." });
                return;
            }

            let apiCalls = _.zip(names, emailAddresses).map(async ([name, emailAddress], _index) => {
                return await new coreApi.UsersClient(settings.coreApiUrl)
                    .create(
                        formModel.sendWelcomeEmail,
                        {
                            ...formModel,
                            name,
                            emailAddress,
                        }
                    )
                    .then(() => showSuccess(`${name} has been created successfully.`))
                    .catch((error: coreApi.ApiException) => showError(`${name} was not created successfully - ${error.response}`));
            });

            Promise.all(apiCalls).then(() => {
                closeDialog();
                onSuccess();
            });

            return;
        }

        const apiCall = user ?
            new coreApi.UsersClient(settings.coreApiUrl).update(user.id, model) :
            new coreApi.UsersClient(settings.coreApiUrl).create(formModel.sendWelcomeEmail, model);

        (apiCall as Promise<any>)
            .then(() => {
                closeDialog();
                showSuccess(`User has been ${user ? "updated" : "created"} successfully.`);
                onSuccess();
            })
            .catch((ex: coreApi.ApiException) => {
                if (ex.status === 400) {
                    attachFormErrors((ex.result as coreApi.ValidationErrorResponse).errors ?? {});
                }
                else {
                    showError(ex.response);
                }
            });
    };

    const getRole = (id) => {
        return roles.find(r => r.id === id);
    }

    const getGroup = (id) => {
        return groups.find(g => g.id === id);
    }

    return (
        <Dialog
            id="edit-user-dialog"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(save)}>
                <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                    {`${user ? "Edit " + user.name : "Create User"}`}
                </DialogTitleWithCloseButton>
                <DialogContent>
                    {
                        sendWelcomeEmail &&
                        <InfoAlert className="margin-bottom-2">
                            A welcome email will be sent to this user, containing instructions for login/registration.
                        </InfoAlert>
                    }
                    {
                        !user &&
                        <>
                            <div className="field-header">Send Welcome Email?</div>
                            <Controller
                                name="sendWelcomeEmail"
                                control={control}
                                defaultValue={true}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        className="margin-bottom-1"
                                        checked={value}
                                        onChange={(event) => onChange(event.target.checked)}
                                        color="primary"
                                    />
                                )}
                            />
                        </>
                    }

                    <div className="field-header">Name</div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />

                    <div className="field-header">Email Address</div>
                    <TextField
                        name="emailAddress"
                        error={!!errors.emailAddress}
                        helperText={errors.emailAddress?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />

                    <div className="field-header">Roles</div>
                    <Controller
                        name="roles"
                        control={control}
                        defaultValue={[]}
                        render={({ onChange, value }) => (
                            <Select
                                className="margin-bottom-2"
                                multiple
                                value={value}
                                onChange={onChange}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    id: 'roles',
                                }}
                                renderValue={(selected) => (
                                    <div>
                                        {(selected as string[] ?? []).map((value) => (
                                            <Chip key={value} label={getRole(value)?.name} color="primary" className="multiselect-chip" />
                                        ))}
                                    </div>
                                )}
                            >
                                {
                                    roles
                                        .filter(role => role.isAssignable)
                                        .map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))
                                }
                            </Select>
                        )}
                    />

                    <div className="field-header">Groups</div>
                    <Controller
                        name="groups"
                        control={control}
                        defaultValue={[]}
                        render={({ onChange, value }) => (
                            <Select
                                className="margin-bottom-2"
                                multiple
                                value={value}
                                onChange={onChange}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    id: 'groups',
                                }}
                                renderValue={(selected) => (
                                    <div>
                                        {(selected as string[] ?? []).map((value) => (
                                            <Chip key={value} label={getGroup(value)?.name} color="primary" className="multiselect-chip" />
                                        ))}
                                    </div>
                                )}
                            >
                                {
                                    groups
                                        .map((group) => (
                                            <MenuItem key={group.id} value={group.id}>
                                                {group.name}
                                            </MenuItem>
                                        ))
                                }
                            </Select>
                        )}
                    />

                    <div className="field-header">Metadata
                        <InlineInfoTooltip
                            text="Any additional information about the user can be captured here.  This will be available to the application and can be utilized for custom logic/bindings in storylines."
                        />
                    </div>
                    <Controller
                        name="userMetadata"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="field"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />

                    <div className="field-header">Allowed Tags
                        <InlineInfoTooltip
                            text="Any additional permission tags can be added here.  This will be available to the application and can be utilized for access control in storylines."
                        />
                    </div>
                    <Controller
                        name="explicitTags.allowed"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="key"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />

                    <div className="field-header">Denied Tags
                        <InlineInfoTooltip
                            text="Any additional permission tags can be added here.  This will be available to the application and can be utilized for access control in storylines."
                        />
                    </div>
                    <Controller
                        name="explicitTags.denied"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="key"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />

                    {
                        formErrors &&
                        <div className="form-errors">
                            {formErrors}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {`${user?.id ? "Update" : "Create"} User`}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default connect(
    null,
    { showSuccess, showError })(EditUser);