import React from "react";
import * as Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import { HighchartsReact } from "highcharts-react-official";
import { useResizeDetector } from "react-resize-detector";
import { createPortal } from "react-dom";
import { JsxRenderer } from "../ContentRenderer/JsxRenderer";
import * as colors from "../../../shared/colors";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";

// Register the "highcharts-more" module with Highcharts...
highchartsMore(Highcharts);

type EventInput<EventType> = {
    point: Highcharts.Point;
    points: Array<Highcharts.Point>;
    chart: Highcharts.Chart;
    event: EventType;
    [key: string]: any;
};

type ChartProps = HighchartsReact.Props & {
    staticPlot?: boolean;
    disableResizeListener?: boolean;
    tooltipTemplate?: string;
    getTooltipContent?: (event: EventInput<Highcharts.Tooltip>) => React.ReactNode;
    onClick?: (event: EventInput<Highcharts.PointClickEventObject>) => void;
    onHover?: (event: EventInput<Event>) => boolean;
    onUnhover?: (event: EventInput<Event>) => void;
    onSelected?: (event: EventInput<Highcharts.PointInteractionEventObject>) => void;
    onDeselect?: (event: EventInput<Highcharts.PointInteractionEventObject>) => void;
};

const defaultOptions: Highcharts.Options = {
    colors: [
        colors.chart_lightblue_3,
        colors.chart_green_3,
        colors.chart_orange_3,
        colors.chart_yellow_3,
        colors.chart_red_3,
        colors.chart_darkblue_3,
        colors.chart_green_7,
        colors.chart_orange_7,
        colors.chart_yellow_7,
        colors.chart_red_7,
        colors.chart_lightblue_7,
        colors.chart_darkblue_6,
    ]
};

const CHART_V2 = (props: ChartProps) => {
    const { key, options: _options, staticPlot: propStaticPlot, disableResizeListener, tooltipTemplate, getTooltipContent, onClick, onHover, onUnhover, onSelected, onDeselect, ...rest } = props;
    const { width, height, ref: resizeRef } = useResizeDetector();
    const chartRef = React.useRef<HighchartsReact.RefObject>(null);
    const tooltipRoot = resizeRef.current?.querySelector?.("#tooltip-root");
    const [tooltipContext, setTooltipContext] = React.useState<EventInput<Highcharts.Tooltip>>(null);
    const useCustomTooltip = Boolean(tooltipTemplate || getTooltipContent);

    // If the user is passing in an explicit value for the `staticPlot` prop, use that, otherwise use the canvas-level value...
    const canvasStaticPlot = useStaticPlot();
    const staticPlot = propStaticPlot !== undefined && propStaticPlot !== null ? propStaticPlot : canvasStaticPlot;

    React.useEffect(() => {
        if (disableResizeListener) {
            return;
        }

        chartRef.current.chart.redraw();
    }, [width, height]);

    const getSharedPoints = React.useCallback((point: Highcharts.Point) => {
        return chartRef.current?.chart?.series?.map?.(s => s.points.find(p => p.x === point.x)) ?? [point];
    }, [chartRef.current]);

    const [options, setOptions] = React.useState(_options);
    React.useEffect(() => {
        const sanitizedOptions: Highcharts.Options = {
            ...defaultOptions,
            ..._options,
            chart: {
                ..._options.chart,
                animation: _options?.chart?.animation ?? (staticPlot ? false : undefined),
            },
            tooltip: useCustomTooltip ? {
                hideDelay: 0,
                ..._options.tooltip,
                useHTML: true,
                formatter(e) {
                    setTooltipContext({ point: this.point, points: getSharedPoints(this.point), chart: chartRef.current.chart, event: e, hoverEvent: e });
                    return "<div id='tooltip-root'></div>";
                },
            } : _options.tooltip,
            plotOptions: {
                ..._options?.plotOptions,
                series: {
                    ..._options?.plotOptions?.series,
                    animation: _options?.plotOptions?.series?.animation ?? (staticPlot ? false : undefined),
                    point: {
                        ..._options?.plotOptions?.series?.point,
                        events: {
                            ..._options?.plotOptions?.series?.point?.events,
                            click(e) {
                                onClick?.({ point: this, points: getSharedPoints(this), chart: chartRef.current.chart, event: e });
                            },
                            select(e) {
                                onSelected?.({ point: this, points: getSharedPoints(this), chart: chartRef.current.chart, event: e });
                            },
                            unselect(e) {
                                onDeselect?.({ point: this, points: getSharedPoints(this), chart: chartRef.current.chart, event: e });
                            },
                            mouseOver(e) {
                                return onHover?.({ point: this, points: getSharedPoints(this), chart: chartRef.current.chart, event: e }) ?? true;
                            },
                            mouseOut(e) {
                                onUnhover?.({ point: this, points: getSharedPoints(this), chart: chartRef.current.chart, event: e });
                            },
                        }
                    }
                }
            }
        };

        setOptions(sanitizedOptions);
    }, [_options]);

    return (
        <div className="qc-chart-container highcharts-light" ref={resizeRef}>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                key={key}
                options={options}
                {...rest}
            />
            {
                tooltipRoot &&
                tooltipContext &&
                useCustomTooltip &&
                createPortal(
                    <ChartTooltip
                        tooltipTemplate={tooltipTemplate}
                        getTooltipContent={getTooltipContent}
                        event={tooltipContext}
                    />,
                    tooltipRoot
                ) as React.ReactNode
            }
        </div>
    );
};

type ChartTooltipProps = {
    event: EventInput<Highcharts.Tooltip>;
    tooltipTemplate?: string;
    getTooltipContent: (event: EventInput<Highcharts.Tooltip>) => React.ReactNode;
};

const ChartTooltip = (props: ChartTooltipProps) => {
    const { event, tooltipTemplate, getTooltipContent } = props;

    return tooltipTemplate ?
        <JsxRenderer template={tooltipTemplate} data={event} /> :
        getTooltipContent(event);
};

export default CHART_V2;
