export const DEFAULT_CHART_LAYOUT = {
    "layout": {
        "title": {
            "font": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            }
        },
        "showlegend": false,
        "legend": {
            "bgcolor": "#ffffff",
            "bordercolor": "#4E4E4E",
            "borderwidth": 0,
            "font": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            },
            "itemclick": "toggleothers",
            "itemdoubleclick": "toggle",
            "orientation": "v",
            "title": {
                "text": "Legend",
                "font": {
                    "size": 12,
                    "color": "#2C2C2C",
                    "family": "Helvetica Neue"
                }
            },
            "x": 0,
            "xanchor": "left",
            "y": 1.02,
            "yanchor": "top"
        },
        "margin": {
            "autoexpand": true,
            "b": 5,
            "l": 5,
            "t": 5,
            "r": 5
        },
        "autosize": true,
        "font": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        },
        "separators": ",",
        "paper_bgcolor": "rgba(255, 255, 255, 0.0)",
        "plot_bgcolor": "rgba(255, 255, 255, 0.0)",
        "xaxis": {
            "automargin": true,
            "autorange": true,
            "type": "date",
            "visible": false,
            "tickangle": 0,
            "tickcolor": "#CDCDCD",
            "tickfont": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            },
            "tickformat": "",
            "zeroline": false,
            "zerolinecolor": "#CDCDCD",
            "zerolinewidth": 2,
            "showgrid": false,
            "title": {
                "font": {
                    "size": 12,
                    "color": "#2C2C2C",
                    "family": "Helvetica Neue"
                }
            }
        },
        "yaxis": {
            "automargin": true,
            "autorange": true,
            "type": "linear",
            "visible": false,
            "tickangle": 0,
            "tickcolor": "#CDCDCD",
            "tickfont": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            },
            "tickformat": "",
            "zeroline": false,
            "zerolinecolor": "#CDCDCD",
            "zerolinewidth": 2,
            "showgrid": false,
            "title": {
                "font": {
                    "size": 12,
                    "color": "#2C2C2C",
                    "family": "Helvetica Neue"
                }
            }
        },
        "hoverlabel": {
            "font": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            },
            "grouptitlefont": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            },
            "align": "auto",
            "bgcolor": "#ffffff",
            "bordercolor": "#4E4E4E",
            "borderwidth": 0,
            "namelength": -1
        }
    }
};