import React from "react";
import "./EditRole.scss";
import * as coreApi from "../../shared/core-api-client";
import * as _ from "lodash";
import { Button, Dialog, DialogContent, TextField, DialogActions, Select, MenuItem, Chip, DialogTitleWithCloseButton, InlineInfoTooltip, InfoAlert, KeyValueEditor, Checkbox, LoadingContainer } from "../../shared/components";
import { Controller, useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { useSettings } from "../../shared/providers/SettingsProvider";

interface EditRoleProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    role?: coreApi.RoleListModel,
    showSuccess: typeof showSuccess,
    showError: typeof showError,
    onSuccess: () => void
}

function EditRole(props: EditRoleProps) {
    const schema = object().shape({
        name: string()
            .required("This field is required."),
        description: string()
            .required("This field is required."),
        explicitTags: object(),
    });
    const { open, setOpen, role: _role, showSuccess, showError, onSuccess } = props;
    const { register, handleSubmit, errors, reset, setError, control } = useForm({ resolver: yupResolver(schema) });
    const [formErrors, setFormErrors] = React.useState(null);
    const [role, setRole] = React.useState(null);
    const settings = useSettings();

    React.useEffect(() => {
        if (_role) {
            new coreApi.RolesClient(settings.coreApiUrl).getById(_role.id)
                .then(result => {
                    setRole(result);
                    reset(result);
                });
        }
        else {
            reset({ explicitTags: { allowed: [], denied: [] } });
        }
    }, [_role]);

    const closeDialog = () => {
        setOpen(false);
    }

    const attachFormErrors = (errorDictionary: object) => {
        _.forOwn(errorDictionary, (value, key) => {
            if (key === "non_field_errors") {
                setFormErrors(value[0]);
            }
            else {
                setError(_.camelCase(key), { type: "server", message: value[0] });
            }
        });
    }

    const save = (formModel) => {
        const model = {
            domainName: settings.domainName,
            ...role,
            ...formModel,
        };

        const apiCall = role ?
            new coreApi.RolesClient(settings.coreApiUrl).update(role.id, model) :
            new coreApi.RolesClient(settings.coreApiUrl).create(model);

        (apiCall as Promise<any>)
            .then(() => {
                closeDialog();
                showSuccess(`Role has been ${role ? "updated" : "created"} successfully.`);
                onSuccess();
            })
            .catch((ex: coreApi.ApiException) => {
                if (ex.status === 400) {
                    attachFormErrors((ex.result as coreApi.ValidationErrorResponse).errors ?? {});
                }
                else {
                    showError(ex.response);
                }
            });
    };

    return (
        <Dialog
            id="edit-role-dialog"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(save)}>
                <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                    {`${role ? "Edit " + role.name : "Create Role"}`}
                </DialogTitleWithCloseButton>
                <DialogContent>
                    <div className="field-header">Name</div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        title={role != null ? "This field cannot be changed once created." : null }
                        disabled={role != null}
                    />

                    <div className="field-header">Description</div>
                    <TextField
                        name="description"
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />

                    <div className="field-header">Allowed Tags
                        <InlineInfoTooltip
                            text="Allowed tags for this role.  These are cumulative with regards to the tags contained within other roles."
                        />
                    </div>
                    <Controller
                        name="explicitTags.allowed"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="key"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />

                    <div className="field-header">Denied Tags
                        <InlineInfoTooltip
                            text="Denied tags for this role.  These are cumulative with regards to the tags contained within other roles."
                        />
                    </div>
                    <Controller
                        name="explicitTags.denied"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="key"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />

                    {
                        formErrors &&
                        <div className="form-errors">
                            {formErrors}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {`${role?.id ? "Update" : "Create"} Role`}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default connect(
    null,
    { showSuccess, showError })(EditRole);