import "./CrudPanel.scss";
import * as React from "react";
import { updateParameterValue } from "../../../store/storyline/actions";
import { Autocomplete, Option } from "../Autocomplete";
import { Button } from "../Button";
import { Pencil } from "../../../shared/components/icons";
import { connect, useSelector } from "react-redux";
import { ClearDialog, CloneDialog, CommitDialog, CreateDialog, RemoveDialog, RenameDialog, UnlinkDialog } from "./dialogs";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import clsx from "clsx";
import { RootState } from "../../../store";
import _ from "lodash";
import { Tooltip } from "../../../shared/components";

type CrudOperation = "create" | "clone" | "rename" | "clear" | "remove" | "commit" | "unlink";

interface CrudPanelProps {
    entityName: string;
    options: Option[];
    getAvailableOptionsForOperation?: (operation: CrudOperation, options: Option[]) => Option[];
    selectedItemParameter: string;
    onCrudOperation?: (operation: CrudOperation, args?: Object) => void;
    onSelectionChanged?: (newValue: Option) => void;
    availableOperations?: CrudOperation[];
    enableLinkedAndWipEntities?: boolean;
    linkedEntities?: Option[];
    wipEntities?: Option[];
    isExpanded?: boolean;
    getTooltipContent?: (operation: "expand" | "collapse" | CrudOperation) => any;
    className?: string;
}

function _CrudPanel(props: CrudPanelProps) {
    const { entityName, options, getAvailableOptionsForOperation, selectedItemParameter, onCrudOperation, onSelectionChanged, enableLinkedAndWipEntities, linkedEntities, wipEntities, className, isExpanded: _isExpanded, getTooltipContent, ...rest } = props;
    const availableOperations = props.availableOperations ??
        (
            enableLinkedAndWipEntities ?
                ["create", "clone", "rename", "clear", "remove", "commit", "unlink"] :
                ["create", "clone", "rename", "clear", "remove"]
        );
    const [selectedValue, setSelectedValue] = React.useState<Option>(null);

    const [isExpanded, setIsExpanded] = React.useState(_isExpanded);
    const toggleIsExpanded = React.useCallback(() => {
        setIsExpanded(value => !value);
    }, [setIsExpanded]);

    React.useEffect(() => {
        setIsExpanded(_isExpanded);
    }, [_isExpanded]);

    const [currentOperation, setCurrentOperation] = React.useState<CrudOperation>(null);
    const acceptModalCallback = React.useCallback((args: Object) => {
        onCrudOperation && onCrudOperation(currentOperation, args);
        setCurrentOperation(null);
    }, [currentOperation, setCurrentOperation, onCrudOperation]);
    const closeModalCallback = React.useCallback(() => {
        setCurrentOperation(null);
    }, [setCurrentOperation]);

    const handleSelection = (_event: React.ChangeEvent<HTMLDivElement>, option: Option | Option[]) => {
        setSelectedValue((option as Option));
        onSelectionChanged && onSelectionChanged(option as Option);
    };

    const parameterValue = useSelector((root: RootState) => root.storyline.parameterValues.get(selectedItemParameter));
    React.useEffect(() => {
        if (_.isEqual(selectedValue?.value, parameterValue))
            return;

        const newSelectedValue = options.find(o => o?.value === parameterValue);
        setSelectedValue(newSelectedValue);
    }, [parameterValue, options]);

    React.useEffect(() => {
        // If the selected value is not in the options anymore, clear the selection...
        if (selectedValue && !options.find(o => o?.value === selectedValue.value)) {
            setSelectedValue(null);
        }
    }, [options]);

    const filterAvailableOptions = React.useCallback((operation: CrudOperation) =>
        getAvailableOptionsForOperation ?
            getAvailableOptionsForOperation(operation, options) ?? options :
            options
        , [getAvailableOptionsForOperation, options]);

    return (
        <div className={clsx("crud-panel", className)}>
            <Autocomplete {...rest} name={selectedItemParameter} options={options} onChange={handleSelection} />
            <Tooltip arrow title={getTooltipContent?.(isExpanded ? "collapse" : "expand") ?? (isExpanded ? `Collapse available operations` : `Show available operations`)}>
                <Button variant="outlined" color="primary" size="small" onClick={toggleIsExpanded}>
                    <Pencil size="small" />
                </Button>
            </Tooltip>
            {
                isExpanded &&
                <div className="crud-buttons">
                    {availableOperations.find(o => o === "create") && <Tooltip arrow title={getTooltipContent?.("create") ?? `Create New ${entityName}`}><Button variant="outlined" color="success" size="small" onClick={() => setCurrentOperation("create")}>New</Button></Tooltip>}
                    {availableOperations.find(o => o === "clone") && <Tooltip arrow title={getTooltipContent?.("clone") ?? `Clone ${entityName}`}><Button variant="outlined" color="info" size="small" onClick={() => setCurrentOperation("clone")}>Clone</Button></Tooltip>}
                    {availableOperations.find(o => o === "rename") && <Tooltip arrow title={getTooltipContent?.("rename") ?? `Rename ${entityName}`}><Button variant="outlined" color="secondary" size="small" onClick={() => setCurrentOperation("rename")}>Rename</Button></Tooltip>}
                    {availableOperations.find(o => o === "clear") && <Tooltip arrow title={getTooltipContent?.("clear") ?? `Clear ${entityName} Data`}><Button variant="outlined" color="warning" size="small" onClick={() => setCurrentOperation("clear")}>Clear</Button></Tooltip>}
                    {availableOperations.find(o => o === "remove") && <Tooltip arrow title={getTooltipContent?.("remove") ?? `Remove ${entityName}`}><Button variant="outlined" color="error" size="small" onClick={() => setCurrentOperation("remove")}>Remove</Button></Tooltip>}
                    {enableLinkedAndWipEntities && availableOperations.find(o => o === "commit") && <Tooltip arrow title={getTooltipContent?.("commit") ?? `Commit ${entityName} Changes`}><Button variant="outlined" color="success" size="small" onClick={() => setCurrentOperation("commit")}>Commit</Button></Tooltip>}
                    {enableLinkedAndWipEntities && availableOperations.find(o => o === "unlink") && <Tooltip arrow title={getTooltipContent?.("unlink") ?? `Unlink ${entityName}`}><Button variant="outlined" color="warning" size="small" onClick={() => setCurrentOperation("unlink")}>Unlink</Button></Tooltip>}
                </div>
            }

            {currentOperation === "create" && <CreateDialog entityName={entityName} allEntities={options} availableEntities={filterAvailableOptions("create")} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} enableLinkedAndWipEntities={enableLinkedAndWipEntities} />}
            {currentOperation === "clone" && <CloneDialog entityName={entityName} allEntities={options} availableEntities={filterAvailableOptions("clone")} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "rename" && <RenameDialog entityName={entityName} allEntities={options} availableEntities={filterAvailableOptions("rename")} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "clear" && <ClearDialog entityName={entityName} allEntities={options} availableEntities={filterAvailableOptions("clear")} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "remove" && <RemoveDialog entityName={entityName} allEntities={options} availableEntities={filterAvailableOptions("remove")} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "commit" && <CommitDialog entityName={entityName} entities={wipEntities ?? []} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "unlink" && <UnlinkDialog entityName={entityName} entities={linkedEntities ?? []} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}

        </div>
    );
}

const CrudPanel = connect(
    null,
    { updateParameterValue: updateParameterValue })(_CrudPanel);

(CrudPanel as DocumentedComponent).metadata = {
    description: "The `CrudPanel` component displays a dropdown list of items with basic CRUD functionality thereof.",
    isSelfClosing: true,
    attributes: [
        { name: `entityName`, type: `string`, description: "The type of entity that will be managed by the control.  Used for specialized text in labels and messages." },
        { name: `selectedItemParameter`, type: `string`, description: "The name of the variable that the selected value will be read from and persisted to." },
        { name: `options`, type: `object`, description: "The list of options that will be displayed in the Autocomplete control.  Each `option` contains a `value` and `label`." },
        { name: `getAvailableOptionsForOperation`, type: `function`, template: `getAvailableOptionsForOperation={(operation, options) => {$1}}`, description: "The optional callback function to be used for determining the available dropdown options for a given operation.  If not provided (or if the result is `null` or `undefined`), all options will be available for the selected operation." },
        { name: `onCrudOperation`, type: "function", template: `onCrudOperation={(operation, args) => {$1}}`, description: "The callback function to execute when an operation is requested.  Occurs after the user has completed all required information and clicked on the relevant dialog's \"Submit\" button.  See below for the available operations and the `args` object structure for each." },
        { name: `onSelectionChanged`, type: "function", template: `onSelectionChanged={(newValue) => {$1}}`, description: "The optional callback function to execute when the selected item has changed." },
        { name: `availableOperations`, type: `object`, template: `availableOperations={["create", "clone", "rename", "clear", "remove"]}`, description: "The list of operations that should be available to the user.  Optional - defaults to all." },
        { name: `enableLinkedAndWipEntities`, type: `boolean`, description: "Determines whether to add support for Linked- and Work-in-Progress entities.  This allows the user to select between these 2 additional types when creating an entity and allows for selecting a related (source) entity.  This also allows the use of the \"commit\" and \"unlink\" operations.  Optional - defaults to `false`." },
        { name: `linkedEntities`, type: `object`, description: "The list of linked entities that will be available in the \"Unlink\" dialog.  Only required and applicable if `enableLinkedAndWipEntities` is truthy." },
        { name: `wipEntities`, type: `object`, description: "The list of WIP entities that will be available in the \"Commit\" dialog.  Only required and applicable if `enableLinkedAndWipEntities` is truthy." },
        { name: `isExpanded`, type: `boolean`, description: "If `true`, the operations panel will be expanded by default.  Optional - defaults to `false`." },
        { name: `getTooltipContent`, type: `function`, template: `getTooltipContent={(operation) => {$1}}`, description: "The (optional) callback function used to customize the tooltips for the various buttons.  If the function returns `false`, no tooltip will be shown for the button.  However, if the function returns `null` or `undefined`, the default tooltip text will be used." },

    ]
};

export default CrudPanel;