import React from "react";

type AuthContext = {
    isAuthenticated: boolean;
    user: { name: string };
    roles: string[];
    allowedTags: string[];
    deniedTags: string[];
    effectiveTags: string[];
    userMetadata: {
        // Non-standard claim names, as returned originally by the "getUserMetadata" function call...
        EmailAddress: string;
        UserId: string;
        // Standardized claims...
        domain: string;
        tenant_name: string;
        roles: string[];
        allowedTags: string[];
        deniedTags: string[];
        effectiveTags: string[];
        user_id: string;
        name: string;
        email: string;
        // User metadata...
        [key: string]: any;
    },
    loading: boolean;
    error: string;
    loginWithPopup: (...a: any) => void;
    handleRedirectPromise: (...p: any) => void;
    onRedirectCallback: (options: any) => void;
    getIdTokenClaims: (p: any) => any;
    loginWithRedirect: (p: any) => void;
    getTokenSilently: () => Promise<string | void>;
    getTokenWithPopup: (req: any) => void;
    logout: (...p: any) => void;
};

export const Auth0Context = React.createContext<AuthContext>(null);
export const useAuth0: () => any = () => React.useContext(Auth0Context);