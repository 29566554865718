import * as metastoreApi from "../../shared/metastore-api-client";
import * as coreApi from "../../shared/core-api-client";
import {
    AppError,
    AppUser,
    BreadcrumbItem,
    LOAD_APP,
    SET_BREADCRUMB_STACK,
    SET_GLOBAL_ERROR,
    SET_MENU_IS_EXPANDED,
    SET_PAGE_ERROR,
    SHOW_APP,
    TOGGLE_MENU
} from "./types";
import { initializeDatasourceFetchPipeline } from "../storyline/actions";
import { RootState } from "..";
import { ClientAppSettings } from "../../shared/providers/SettingsProvider";
import { openWsConnection } from "../vscode/actions";


export function loadApp(user: AppUser, _settings: ClientAppSettings, _activeBranch: string) {
    return async (dispatch, getState: () => RootState) => {
        dispatch({
            type: LOAD_APP,
            user,
            settings: _settings,
        });

        initializeDatasourceFetchPipeline()(dispatch, getState);

        // Immediately try to open the VS Code WebSocket connection if the user is a developer...
        if (user.isDeveloper && window.location.hostname !== "documentation.dev.qerent.ai") {
            dispatch(openWsConnection());
        }
        
        const lastActiveBranch = localStorage.getItem(`${user.name}::activeBranch`);
        const activeBranch = lastActiveBranch ? lastActiveBranch : _activeBranch;

        dispatch(loadAppData(_settings, activeBranch));
    }
}

export function switchBranch(activeBranch: string) {
    return async (dispatch, getState: () => RootState) => {
        const { user, settings } = getState().app;
        const storageKey = `${user.name}::activeBranch`;

        // Clear out previous page error...
        dispatch(setPageError(null));

        if (activeBranch) {
            localStorage.setItem(storageKey, activeBranch);
            dispatch(loadAppData(settings, activeBranch));
        } else {
            localStorage.removeItem(storageKey);
            dispatch(loadAppData(settings, settings.activeBranch));
        }
    }
}

function loadAppData(_settings: ClientAppSettings, activeBranch: string) {
    return async (dispatch, _getState: () => RootState) => {
        const { coreApiUrl, domainName, metastoreUrl } = _settings;
        const settings = {
            ..._settings,
            ...(await new coreApi.SettingsClient(coreApiUrl).getClientSettings(domainName, activeBranch).catch(ex => {
                const getError = (ex): AppError => {
                    if (ex.status == null)
                        return { type: "Connectivity", message: `Qerent was unable to load settings from the Core API.`, details: `Unable to contact the server at \`${coreApiUrl}\`.\n\nPlease ensure you are connected to your corporate network or VPN.\n\nIf the issue persists, please contact the site administrator.` };

                    if (ex.status === 404)
                        return { type: "NotFound", message: "Tenant not found.", details: `No tenant is configured for this domain (\`${domainName}\`).\n\nPlease check the address and try again.` };

                    if (ex.status === 429)
                        return { type: "Connectivity", message: "Rate limit exceeded.", details: `Too many requests were submitted within the last minute.\n\nPlease wait a moment and try again.` };

                    return { type: "Generic", message: "An error occurred while loading settings from the Core API.", details: ex.response };
                };

                dispatch(setPageError(getError(ex)));

                return {};
            }))
        };

        const menuItems = await new metastoreApi.MenuItemsClient(metastoreUrl, activeBranch).getAll().catch(ex => {
            const getError = (ex): AppError => {
                if (ex.status == null)
                    return { type: "Connectivity", message: `Your browser cannot reach the Qerent Data Server.`, details: `Unable to contact the server at \`${metastoreUrl}\`.\n\nPlease ensure you are connected to your corporate network or VPN.\n\nIf the issue persists, please contact the site administrator.` };

                if (ex.status === 429)
                    return { type: "Connectivity", message: "Rate limit exceeded.", details: `Too many requests were submitted within the last minute.\n\nPlease wait a moment and try again.` };

                return { type: "Generic", message: "Failed to load menu items.", details: ex.response };
            };

            dispatch(setPageError(getError(ex)));
            return [];
        });

        dispatch({
            type: SHOW_APP,
            menuItems,
            settings,
            activeBranch,
        });
    }
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU
    }
}

export function setMenuIsExpanded(value: boolean) {
    return {
        type: SET_MENU_IS_EXPANDED,
        value: value
    }
}

export function setBreadcrumbStack(breadcrumbStack: BreadcrumbItem[]) {
    return {
        type: SET_BREADCRUMB_STACK,
        breadcrumbStack: breadcrumbStack
    }
}

export function setGlobalError(error: AppError) {
    return {
        type: SET_GLOBAL_ERROR,
        error
    };
}

export function setPageError(error: AppError) {
    return {
        type: SET_PAGE_ERROR,
        error
    };
}